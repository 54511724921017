import { Component, OnInit, TemplateRef, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpRestClientService } from 'src/app/services/common/http-rest-client.service';
import { ConfirmationPopupService } from 'src/app/services/confirmation-popup/confirmation-popup.service';
import { GlobalVariablesService } from 'src/app/services/global-variables/global-variables.service';
//import { ArrayRangePipe } from 'src/app/services/pipe';
import * as _ from "underscore";
import { CorrelationService } from 'src/app/services/correlation/correlation.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort, SortDirection } from '@angular/material/sort';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';

@Component({
  selector: 'correlation-list',
  templateUrl: './correlation-list.component.html',
  styleUrls: ['./correlation-list.component.scss']
})
export class CorrelationList implements OnInit {
  @Input() isFresh: boolean = false;
  @Input() filterData: any;
  @Input() subject: any;
  public modalRef: BsModalRef | undefined;
  isDelete = false;
  showRestrictedChar = false;
  selectedRow: any;
  listItems: any[] = [];
  reportName: any;
  displayedColumns = ['name', 'date', 'align', 'correlation', 'sourceA', 'sourceB', 'creator', 'download', 'admin'];
  dataSource: any;
  emptyRow = ['empty-row']
  dataSourceWithPageSize: any;
  emptyData = new MatTableDataSource([{ empty: "row" }]);
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  pageSizes = [3, 5, 7];

  public popupData: any = {};

  constructor(public globalVar: GlobalVariablesService,
    private httpRestClient: HttpRestClientService,
    private correlationService: CorrelationService,
    private confirmationPopup: ConfirmationPopupService,
    private modalService: BsModalService,
  ) { }

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSourceWithPageSize.paginator = this.paginatorPageSize;
  // }

  ngOnChanges() {
    if (this.isFresh) {
      this.correlationService.loading.next(true);
      setTimeout(() => {
        this.getListData();
      }, 1000);
    }
  }

  ngOnInit(): void {
    this.popupData.popupMessage = "Are you sure you want to delete this report ?"
    this.popupData.buttonLabel = "yes"

    this.getListData();
    this.confirmationPopup.popup$.subscribe(() => {
      if (this.isDelete) {
        console.log(this.selectedRow);
        const url = 'api/report/delete/' + this.selectedRow.id;
        this.httpRestClient.deleteForUrl(url, undefined, undefined).subscribe((result) => {
          this.correlationService.loading.next(true);
          this.getListData();
        });
        this.isDelete = false;
      }
    })
    this.getUpdatedList();
  }

  getUrl(row: any) {
    const url = "api/report/export/" + row.downloadLink + "/" + row.name;
    console.log()
    window.location.href = window.location.origin + "/cm-web/" + url
  }

  getDocUrl(row: any) {
    const url = "api/report/export/" + row.docdownloadLink + "/" + row.name;
    window.location.href = window.location.origin + "/cm-web/" + url
  }

  getUpdatedList() {
    this.correlationService.isListUpdated.subscribe((data: any) => {
      this.getListData();
    })
  }

  confirmDeleteGrade() {
  }

  getListData() {
    this.dataSource = new MatTableDataSource([]);
    const url = 'api/report/reports';
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      console.log(this.subject)
      if (result?.item.length) {
        this.correlationService.loading.next(false);
        this.listItems = [];
        result.item.forEach((item: any) => {
          if (item.downloadStatus !== 'isProductDeleted') {
            if (this.filterData || this.subject) {
              let isexist = false
              if(this.filterData && this.filterData !=="")
              {
                if (item?.name?.toUpperCase().includes(this.filterData?.toUpperCase())) {
                  isexist = true;
                }
                if (item?.date?.toUpperCase().includes(this.filterData?.toUpperCase())) {
                  isexist = true;
                }
                if (item?.printDigitalAlignment?.toUpperCase().includes(this.filterData?.toUpperCase())) {
                  isexist = true;
                }
                if (item?.correlationType?.toUpperCase().includes(this.filterData?.toUpperCase())) {
                  isexist = true;
                }
                if (item?.leftMappingTitle?.toUpperCase().includes(this.filterData?.toUpperCase())) {
                  isexist = true;
                }
                if (item?.sourceB[0]?.rightMappingTitle.toUpperCase().includes(this.filterData?.toUpperCase())) {
                  isexist = true;
                }
                if (item?.creator?.toUpperCase().includes(this.filterData?.toUpperCase())) {
                  isexist = true;
                }
              }
              if (this.subject && this.subject !=="") {
                let subjectExist=false;
                if (this.subject.subjectCode == item.leftSubjectIRI) {
                  subjectExist = true;
                }
                else {
                  item.sourceB.forEach((sourceBItem: any) => {
                    if (sourceBItem.rightSubjectIRI == this.subject.subjectCode)
                    {
                      subjectExist = true;
                    }
                  })
                }
                isexist = subjectExist;
              }
              if (isexist) {
                this.listItems.push(item);
              }
            }
            else {
              this.listItems.push(item);
            }
          }
        });
        this.dataSource = new MatTableDataSource(this.listItems);
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item: any, property: any) => {
          switch (property) {
            case 'date': {
              let newDate = new Date(item.date);
              return newDate;
            }
            case 'align': {
              if (item.printDigitalAlignment == 'printDigital') {
                return 'Print & Digital'
              }
              else if (item.printDigitalAlignment == 'digital') {
                return 'Digital'
              }
              else
                return 'Print'
            }
            case 'correlation': {
              return item.correlationType;
            }
            case 'sourceA': {
              return item.leftMappingTitle;
            }
            case 'sourceB': {
              return item.sourceB[0].rightMappingTitle;
            }
            case 'creator': {
              return item.creator;
            }
            default: {
              return item[property] as string;
            }
          }
        };
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.correlationService.loading.next(false);
      }
    }, (error) => {
      console.log(error);
    });
  }

  getColor(correlationType: any) {
    if (correlationType?.toLowerCase() === 'standard-standard') {
      return '#ed6b06';
    } else if (correlationType?.toLowerCase() === 'product-product') {
      return '#9d1348';
    } else if (correlationType?.toLowerCase() === 'forward intermediary' || correlationType?.toLowerCase() === 'toc intermediary report' || correlationType?.toLowerCase() === 'full toc intermediary' || correlationType?.toLowerCase() === 'reverse intermediary') {
      return '#2150a3';
    }
    else {
      return '#9d1348';
    }
  }
  getAllignmentDetails(allignment: string) {
    if (allignment === 'printDigital') {
      return 'Print & Digital'
    }
    else if (allignment === 'print') {
      return 'Print'
    }
    else {
      return 'Digital'
    }
  }


  reRunCorrelation(row: any) {
    if (row.downloadStatus === 'Complete') {
      this.correlationService.loading.next(true);
      const url = 'api/report/reRun?reportId=' + row.id + '&userMail=' + this.globalVar?.userProfile?.userMail;
      this.httpRestClient.postForUrl(url, undefined, undefined).subscribe((result) => {
        this.correlationService.loading.next(false);
        setTimeout(() => {
          this.confirmationPopup.showSuccessMessage('We will notify you shortly once your request has been completed via E-mail');
        })
        this.getListData();
      }, (error) => {
        this.confirmationPopup.showErrorMessage('Error in rerunning report');
      });
    }
  }

  delete(row: any, temp: any) {
    if (row.downloadStatus === 'Complete') {
      this.selectedRow = row;
      this.isDelete = true;
      this.modalRef = this.modalService.show(temp);
    }
  }

  editCorrelation(row: any) {
    this.correlationService.editCorrelationEventLeft.next(row);
    this.correlationService.editCorrelationEventRight.next(row);
    this.correlationService.editReportName.next(row);
  }
}