
<div class="row">
    <div class="col-sm-12 paddingLeft0 paddingRight0" [hidden]="topicData?.topicsList?.length == 0" *ngFor="let gradePreviewBean of topicData?.topicsList; let index = index">
        <div class="discoverTermsIcon" [ngClass]="{ 'discoverTermsIconExpand' : expandNodeIds?.indexOf(gradePreviewBean?.topicId) == -1, 'discoverTermsIconCollapse' : expandNodeIds?.indexOf(gradePreviewBean?.topicId) > -1}" 
             [hidden]="gradePreviewBean?.topicsList?.length == 0 || isFromLeft==='false'" (click)="expandCollapseTopic(gradePreviewBean)"></div>
        <div class="discoverTermsText font_12_666666 paddingLeft0 paddingRight0" *ngIf="isFromLeft === undefined">
            <span class="smallAddIcon pull-left" [hidden]="isFromLeft==='false' || isFromLeft==='true'" (click)="addSubTopic(topicPreviewStaticData, gradePreviewBean, true, false)" title="Add sub-topic"></span>
            <span class="smallUpIcon" [hidden]="index == 0" (click)="moveTopicUp(gradePreviewBean?.topicId)" title="{{globalVar?.languageString?.resources?.manageTab?.curriculumEditGradeMoveUpIconLabel}}"></span>
            <span class="smallDownIcon" (click)="moveTopicDown(gradePreviewBean?.topicId)" [hidden]="index == topicData?.topicsList?.length-1" title="{{globalVar?.languageString?.resources?.manageTab?.curriculumEditGradeMoveDownIconLabel}}"></span>
            <span class="smallDeleteIcon pull-right" (click)="deleteTopic(gradePreviewBean)" title="{{globalVar?.languageString?.resources?.manageTab?.curriculumDeleteLocalGradeLabel}}"></span>
            <span class="smallEditIcon pull-right" (click)="addSubTopic(topicPreviewStaticData, gradePreviewBean, false, false)" title="{{globalVar?.languageString?.resources?.manageTab?.curriculumEditLocalGradeLabel}}"></span>
            <span class="discoverTermSpanText" [innerHTML]="gradePreviewBean?.topicDesc"></span>
            <span class="searchResultTextStandardCode" [hidden]="!gradePreviewBean?.standardCode">
                (<span class="searchResultTextStandardCode" [innerHTML]="gradePreviewBean?.standardCode"></span>)
            </span>
        </div>
        <div class="discoverTermsText font_12_666666 paddingLeft0 paddingRight0" *ngIf="isFromLeft !== undefined">
            <span class="smallEditIcon pull-right" [hidden]="isFromLeft==='true'" (click)="addSubTopic(topicPreviewStaticData, gradePreviewBean, false, false)" title="{{globalVar?.languageString?.resources?.manageTab?.curriculumEditLocalGradeLabel}}"></span>
            <span class="discoverTermSpanText" *ngIf="isFromLeft !=='false'" [innerHTML]="gradePreviewBean?.topicDesc"></span>
            <span class="discoverTermSpanText" [ngClass]="{ 'discoverTermSpanChangeText' : gradePreviewBean.topicDesc !== gradePreviewBean.substitutionTopicDesc }" *ngIf="isFromLeft==='false'" [innerHTML]="gradePreviewBean?.substitutionTopicDesc"></span>
            <span class="searchResultTextStandardCode" *ngIf="isFromLeft==='true'" [hidden]="!gradePreviewBean?.standardCode">
                (<span class="searchResultTextStandardCode" [innerHTML]="gradePreviewBean?.standardCode"></span>)
            </span>
            <span class="searchResultTextStandardCode" *ngIf="isFromLeft==='false'" [hidden]="!gradePreviewBean?.substitutionStandardCode">
                (<span class="searchResultTextStandardCode" [ngClass]="{ 'discoverTermSpanChangeText' : gradePreviewBean.standardCode !== gradePreviewBean.substitutionStandardCode }" [innerHTML]="gradePreviewBean?.substitutionStandardCode"></span>)
            </span>
        </div>
        <div class="marginLeft20" *ngIf="gradePreviewBean.topicsList && gradePreviewBean.topicsList.length > 0 && expandNodeIds.indexOf(gradePreviewBean?.topicId) > -1">
            <app-topic-tree-view [addEditTopicPopup]="addEditTopicPopup"  [addSubTopic]="addSubTopic" [topicData]="gradePreviewBean" [isFromLeft]="isFromLeft"
                [expandNodeIds]="expandNodeIds" [loadTopics]="loadTopics" [deleteTopic]="deleteTopic" [topicPreviewStaticData]="topicPreviewStaticData"></app-topic-tree-view>
        </div>
    </div>
    <div [hidden]="topicData?.topicsList?.length > 0">{{globalVar?.languageString?.resources?.manageTab?.curriculumNoLocalGradeLabel}}</div>
</div>

