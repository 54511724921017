<app-curriculum-menu [loadCurriculumList]='globalVar.loadCurriculumList' [addTabClass]='globalVar.addTabClass'></app-curriculum-menu>
<div class="curriculumTab uploadCurriculum">
    <div class="curriculumEmptyListPanel">
        <div class="row upperPartForMble">
            <div class="col-sm-2 validationCheck">
                <label class="col-sm-12 font_13_333333_bold paddingLeft0">{{globalVar?.languageString?.resources?.manageTab?.manageUploadSubjectLabel}}</label>
                <div>
                    <select class="cmDropDown currIngestDropDown"
                            [(ngModel)]="injectCurriculumBean.curriculumUploadBean.subject"
                            [ngClass]="{ 'ng-invalid-required' : injectCurriculumBean.curriculumUploadBean.subject == ''}"
                            tabindex="1"
                            [disabled]="!injectCurriculumBean.curriculumIngestionDataBean.subjectFacets || injectCurriculumBean.curriculumIngestionDataBean.subjectFacets.length == 0">
                        <option value="" hidden selected >{{globalVar?.languageString?.resources?.manageTab?.manageUploadSelectSubjectLabel}}</option>
                        <option [value]="facetInfoBean.facetUri" *ngFor="let facetInfoBean of injectCurriculumBean.curriculumIngestionDataBean.subjectFacets"> {{facetInfoBean.facetName}} </option>
                    </select>
                </div>
            </div>
            <div class="col-sm-2 validationCheck">
                <label class="col-sm-12 font_13_333333_bold paddingLeft0">{{globalVar?.languageString?.resources?.manageTab?.manageUploadCountryLabel}}</label>
                <div>
                    <select class="cmDropDown currIngestDropDown"
                            [(ngModel)]="injectCurriculumBean.curriculumUploadBean.country"
                            tabindex="2"
                            [ngClass]="{ 'ng-invalid-required' : injectCurriculumBean.curriculumUploadBean.country == ''}"
                            (change)="preloadAuthority()"
                            [disabled]="!injectCurriculumBean.curriculumIngestionDataBean.countryFacets || injectCurriculumBean.curriculumIngestionDataBean.countryFacets.length == 0">
                        <option value="" hidden>{{globalVar?.languageString?.resources?.manageTab?.manageUploadSelectCountryLabel}}</option>
                        <option [value]="facetInfoBean.facetUri" *ngFor="let facetInfoBean of injectCurriculumBean.curriculumIngestionDataBean.countryFacets"> {{facetInfoBean.facetName}} </option>
                    </select>
                </div>
            </div>
            <div class="col-sm-2 validationCheck">
                <label class="col-sm-12 font_13_333333_bold paddingLeft0">{{globalVar?.languageString?.resources?.manageTab?.manageUploadAuthorityLabel}}</label>
                <div>
                    <select class="cmDropDown currIngestDropDown"
                            [(ngModel)]="injectCurriculumBean.curriculumUploadBean.authority"
                            tabindex="3"
                            [ngClass]="{ 'ng-invalid-required' : injectCurriculumBean.curriculumUploadBean.authority == ''}"
                            (change)="preloadCurriculumSet()"
                            [disabled]="!injectCurriculumBean.curriculumIngestionDataBean.authorityFacets || injectCurriculumBean.curriculumIngestionDataBean.authorityFacets.length == 0">
                        <option value="" hidden>{{globalVar?.languageString?.resources?.manageTab?.manageUploadSelectAuthorityLabel}}</option>
                        <option [value]="facetInfoBean.facetUri" *ngFor="let facetInfoBean of injectCurriculumBean.curriculumIngestionDataBean.authorityFacets | orderBy: 'facetName'"> {{facetInfoBean.facetName}} </option>
                    </select>
                </div>
            </div>
            <div class="col-sm-2 validationCheck">
                <label class="col-sm-12 font_13_333333_bold paddingLeft0">{{globalVar?.languageString?.resources?.manageTab?.manageUploadCurriculumSetLabel}}</label>
                <div>
                    <select class="cmDropDown currIngestDropDown currSetDrpDown"
                            [(ngModel)]="injectCurriculumBean.curriculumUploadBean.curriculumSet"
                            tabindex="4"
                            [ngClass]="{ 'ng-invalid-required' : injectCurriculumBean.curriculumUploadBean.curriculumSet == ''}"
                            [disabled]="!injectCurriculumBean.curriculumIngestionDataBean.curriculumSetFacets || injectCurriculumBean.curriculumIngestionDataBean.curriculumSetFacets.length == 0">
                        <option value="" hidden>{{globalVar?.languageString?.resources?.manageTab?.manageUploadSelectCurriculumSetLabel}}</option>
                        <option [value]="facetInfoBean.facetUri" *ngFor="let facetInfoBean of injectCurriculumBean.curriculumIngestionDataBean.curriculumSetFacets | orderBy: 'facetName'"> {{facetInfoBean.facetName}} </option>
                    </select>
                </div>
            </div>
            <div class="col-sm-2 validationCheck">
                <label class="col-sm-12 font_13_333333_bold paddingLeft0">State & State code*</label>
                <div>
                    <select class="cmDropDown currIngestDropDown"
                            [(ngModel)]="injectCurriculumBean.curriculumUploadBean.state"
                            tabindex="5"
                            [ngClass]="{ 'ng-invalid-required' : injectCurriculumBean.curriculumUploadBean.state == ''}">
                        <option value="" hidden>Select State and State code</option>
                        <option [value]="facetInfoBean.facetUri" *ngFor="let facetInfoBean of injectCurriculumBean.curriculumIngestionDataBean.stateFacets | orderBy: 'facetName'"> {{facetInfoBean.facetName}} </option>
                    </select>
                </div>
            </div> 
        </div>


        <div class="row sectionTwo">
            <div class="col-sm-2 validationCheck">
                <label class="col-sm-12 font_13_333333_bold paddingLeft0">{{globalVar?.languageString?.resources?.manageTab?.manageUploadAdoptedYearLabel}}</label>
                <div>
                    <input [(ngModel)]="injectCurriculumBean.curriculumUploadBean.year" type="text"
                           class="form-control"
                           placeholder="{{globalVar?.languageString?.resources?.manageTab?.manageUploadAdoptedYearText}}"
                           pattern="^[12][0-9]{3}$" required
                           name="{{globalVar?.languageString?.resources?.manageTab?.manageUploadSelectAdoptedYearName}}"
                           tabindex="5">
                </div>
            </div>
            <div class="col-sm-3 sectionTwoSp4 validationCheck">
                <label class="col-sm-12 font_13_333333_bold paddingLeft0">{{globalVar?.languageString?.resources?.manageTab?.manageUploadSourceURLLabel}}</label>
                <div>
                    <input [(ngModel)]="injectCurriculumBean.curriculumUploadBean.sourceUrl" type="text"
                           class="form-control" placeholder="{{globalVar?.languageString?.resources?.manageTab?.manageUploadSourceURLText}}"
                           tabindex="6">
                </div>
            </div>
            <div class="col-sm-3 sectionTwoSp4">
                <label class="col-sm-12 font_13_333333_bold paddingLeft0">Description</label>
                <div>
                    <input [(ngModel)]="injectCurriculumBean.curriculumUploadBean.description" type="text"
                           class="form-control" placeholder="Description"
                           name="Description"
                           tabindex="7">
                </div>
            </div>
        </div>
        
		 <div class="row sectionTwo" >
		    <div class="col-sm-3" style="padding-left: 0px;">
                <label class="col-sm-12 font_13_333333_bold paddingLeft0">Display Title *</label>
                <div>
                    <input [(ngModel)]="injectCurriculumBean.curriculumUploadBean.customTitle" type="text" [disabled]="injectCurriculumBean?.curriculumUploadBean?.deployedToRealize"
                           class="form-control" [ngClass]="{'customTitleRequired':!injectCurriculumBean?.curriculumUploadBean?.customTitle}"  placeholder="Display Title" name="customTitle" tabindex="9">                         
                </div>
                 
            </div>
             <div class="span10">
                <div style="margin-top: 30px;">
               {{injectCurriculumBean?.curriculumUploadBean?.reminingTitle}}  
              </div>
             </div>
             <div class="col-sm-2 sectionTwoSp4">
                <label class="col-sm-12 font_13_333333_bold paddingLeft0">Curriculum Type:</label>
            </div>
             <div class="col-sm-4 sectionTwoSp4">
                <div>
                    <div>
                        <input type="radio"  name="curriculumType" [(ngModel)]="injectCurriculumBean.curriculumUploadBean.curriculumType" value="Academic benchmarks">
                        <label class="col-sm-2 font_13_333333_bold curriculumTypeLabel">Academic benchmarks</label> 
                    </div>
                    <div>
                        <input type="radio"  name="curriculumType" [(ngModel)]="injectCurriculumBean.curriculumUploadBean.curriculumType" value="Customized Academic Benchmarks">     
                        <label class="col-sm-2 font_13_333333_bold curriculumTypeLabel">Customized Academic Benchmarks</label> 
                    </div>
                    <div>
                        <input type="radio"  name="curriculumType" [(ngModel)]="injectCurriculumBean.curriculumUploadBean.curriculumType" value="Savvas Custom">
                        <label class="col-sm-2 font_13_333333_bold curriculumTypeLabel"> Savvas Custom</label>      
                    </div>
                </div>
                 
            </div>
		 </div>
        <div [hidden]="!injectCurriculumBean.canUploadFile">
            <div style="padding-top: 20px; border-top: 1px solid #E4E4E4;">
                <div style="font-size: 20px; color: #333333;">{{globalVar?.languageString?.resources?.manageTab?.manageUploadFileType}}</div>
                <input type="radio" name="fileType" [value]="'AB XML'" [(ngModel)]="injectCurriculumBean.curriculumUploadBean.fileType" (checked)="true" (change)="resetIncludeIntermediary()"><span style="font-size: 13px; color: #333333;">{{globalVar?.languageString?.resources?.manageTab?.manageUploadABXML}}</span><br>
                <input type="radio" name="fileType" [value]="'Custom Excel'" [(ngModel)]="injectCurriculumBean.curriculumUploadBean.fileType"><span style="font-size: 13px; color: #333333;">{{globalVar?.languageString?.resources?.manageTab?.manageUploadCustomExcel}}</span>
                <input type="checkbox" [disabled]="injectCurriculumBean.curriculumUploadBean.fileType == 'AB XML'" [(ngModel)]="injectCurriculumBean.curriculumUploadBean.includeIntermediaryAlignments"><span style="font-size: 13px; color: #333333; margin-right: 5px;">Include Intermediary Alignments</span> 
            </div>
            <div style="padding-top: 20px; border-top: 1px solid #E4E4E4;">
                <div style="font-size: 20px; color: #333333;">{{globalVar?.languageString?.resources?.manageTab?.manageUploadFileMessage}}</div>
                <div *ngIf="injectCurriculumBean.curriculumUploadBean.fileType" class="curriculumEmptyListPanelText" style="border: none;">{{globalVar?.languageString?.resources?.manageTab?.manageUploadFileInfoMessage}}</div>
                <div *ngIf="!injectCurriculumBean.curriculumUploadBean.fileType" class="curriculumEmptyListPanelText" style="border: none;">Please choose upload file type to select the file</div>
            </div>

            <!-- <div style="padding-top: 10px;">
                <form enctype="multipart/form-data">
                    <uploader action="api/curriculum/uploader" bean="injectCurriculumBean.curriculumUploadBean" data-from-screen=""></uploader>
                </form>
            </div> -->
            <div style="padding-top: 10px;">
                <form enctype="multipart/form-data">
                    <app-uploader [action]="'api/curriculum/uploader'" [bean]="injectCurriculumBean?.curriculumUploadBean" [fromScreen]=""></app-uploader>
                </form>
            </div>
        </div>

        <div [hidden]="injectCurriculumBean.canUploadFile" style="margin-top: 30px;">
            <input type="submit" value="{{globalVar?.languageString?.resources?.others?.saveButtonLabel}}" [disabled]="disableSaveButton" class="cmButton cmButtonFull-green cmButton-large" (click)="saveCurriculum()"/>
            <a (click)="goToCurriculumEdit()" class="cmButton cmButton-url" [hidden]="!injectCurriculumBean.curriculumUploadBean.curriculumId">{{globalVar?.languageString?.resources?.others?.cancelBtnLabel}}</a>
        </div>
    </div>
</div>