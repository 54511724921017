import { Component, EventEmitter, Input, OnInit, TemplateRef } from '@angular/core';
// import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationPopupService } from '../../services/confirmation-popup/confirmation-popup.service';
import { GlobalVariablesService } from '../../services/global-variables/global-variables.service';
import { HttpRestClientService } from '../../services/common/http-rest-client.service';

@Component({
  selector: 'app-warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.scss']
})
export class WarningPopupComponent implements OnInit {
  public postMessageEvent = new EventEmitter();
  @Input() modalRef: any;
  public intermediaryAlignSuccess = false;
  public languageString:any;
  @Input() TemplateDet: TemplateRef<any> | undefined;
  @Input() Data: any;
  @Input() DoFunctionOnSuccess: any;
  @Input() DoFunctionOnClose: any;
  @Input() PopupMessage: any;
  @Input() subPopupMessage: any;
  @Input() ConfirmationMessage: any;
  @Input() ButtonLabel: any;
  @Input() isCancel: any;

  constructor(
    public globalVar: GlobalVariablesService,
    // private modalService: BsModalService,
    public ConfirmationPopup:ConfirmationPopupService,
    public  HttpRestClientService: HttpRestClientService
  ) { }

  ngOnInit(): void {
  }

  doPopupSuccess = () => {
      this.DoFunctionOnSuccess();
      this.intermediaryAlignSuccess = true;
      if(this.ConfirmationMessage)
        {
          this.ConfirmationPopup.showSuccessMessage(this.ConfirmationMessage);

        }
      this.ConfirmationPopup.popup$.next(true);
      this.doClosePopup();
  };

  doClosePopup = () => {
    if(this.globalVar.callForXLS){
      this.modalRef.hide();
      var curriculumID = this.globalVar.curriculumIdToDownload;
      var withTag = "no";
      var url = 'api/curriculum/' + curriculumID + '/export';
      const headers:any = {
        dataType: 'json',
        contentType: 'application/json',
        mimeType: 'application/json',
        params: {'withTag': withTag, 'userMailId': this.globalVar.userProfile.userMail}
      };
      this.HttpRestClientService.postForUrl(url, headers, undefined).subscribe((result) => {
        this.globalVar.generatingXLS = false;
        this.globalVar.callForXLS = false;
      }, (error) => {
        this.globalVar.generatingXLS = false;
        this.globalVar.callForXLS = false;
        this.ConfirmationPopup.showErrorMessage(this.globalVar.languageString.resources.homeTab.curriculumExcelGenerateErrorMessage);
      })

    //   $http(
    //       {
    //           method: 'POST',
    //           url: 'api/curriculum/' + curriculumID + '/export',
    //           dataType: 'json',
    //           contentType: 'application/json',
    //           mimeType: 'application/json',
    //           params: {'withTag': withTag, 'userMailId': $rootScope.userProfile.userMail}
    //       })
    //       .success(function (result) {
    //           $rootScope.generatingXLS = false;
    //           $rootScope.callForXLS = false;
    //       })
    //       .error(function (data, status, headers, config) {
    //           $rootScope.generatingXLS = false;
    //           $rootScope.callForXLS = false;
    //           ConfirmationPopupService.showErrorMessage($rootScope.applicationCache.languageString.resources.homeTab.curriculumExcelGenerateErrorMessage);
    //       }
    //   );
    }else if(!this.intermediaryAlignSuccess){
      this.onFailure();
      this.modalRef.hide();
    }else {
      this.modalRef.hide();
    }
  };

  public onFailure = () => {
    if(this.DoFunctionOnClose){
      this.DoFunctionOnClose();
    }
  }


}
