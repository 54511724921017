import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { any } from 'underscore';
import { HttpRestClientService } from '../common/http-rest-client.service';
import { ConfirmationPopupService } from '../confirmation-popup/confirmation-popup.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalVariablesService {

  public loading: boolean = false;
  public languageString: any;
  public callForXLS: any;
  public generatingXLS: any;
  public curriculumIdToDownload: any;
  public userProfile: any;
  public knowledgeCartDirty: any;
  public checkUnsavedEditsTemp: any;
  public hasUser: boolean | undefined;
  public activeMainMenu: String = "";
  public curriculaListBean: any;
  public noApprovedCurricula: boolean | undefined;
  public country: String = '';
  public isCurriculumClone: boolean | undefined;
  public filterSubjectsHomePage: any;
  public filterCountriesHomePage: any;
  public isFromHome: String = '';
  public homeSelectedSubjDet: any;
  public intermediaryPreSelectedDropdown: any = {
    'intermediaryMappingBean': {
      'intermediaryMappingDataBean': {
        'subjectBeanList': any
      }
    },
    'selectedList': {
      'subject': any
    }
  };
  public tabClassName: String = '';
  // public applicationCache: any;
  public downloadUrl: any;
  public gradeListForProductEdit: any;
  public editSubsPopup: any;
  public mappingDataCopy: any;
  public curriculaEditBean: any = {
    'curriculaPreviewBean': {
      'customCurriculumTitle': ''
    },
    'editTitle': true,
    'subjectFacets': []
  };

  public applicationConstants: any = {
    INTERMEDIARY_MAPPING_DEFAULT_VIEW: "default",
    INTERMEDIARY_MAPPING_HIERARCHY_VIEW: "hierarchy",
    INTERMEDIARY_MAPPING_STANDARDS_VIEW: "mapping",
    CONTENTPLAN_MAPPING_HIERARCHY_VIEW: "contentplan_hierarchy",
    STANDARDS_ADVANCED_SEARCH_VIEW: "standards_advanced_search",
    PRODUCTS_ADVANCED_SEARCH_VIEW: "products_advanced_search",
    INTERMEDIARY_MAPPING_CENTRAL_ASSOCIATION: "centrallyRelated",
    INTERMEDIARY_MAPPING_PERIPHERAL_ASSOCIATION: "peripherallyRelated",
    PRINT: "print",
    DIGITAL: "digital",
    PRINT_DIGITAL: "printDigital",
    INTERMEDIARY_MAPPING_KEY_ASSOCIATION: "keyRelated",
    INTERMEDIARY_TYPE_STFK_SCHEMA: 'http://schema.savvas.com/ns/learn/STFkConcept',
    INTERMEDIARY_TYPE_P_SCHEMA: 'http://schema.savvas.com/ns/learn/ProcessConcept',
    INTERMEDIARY_TYPE_CU_SCHEMA: 'http://schema.savvas.com/ns/learn/UnderstandingConcept',
    INTERMEDIARY_TYPE_STFK: 'STFkConcept',
    INTERMEDIARY_TYPE_P: 'ProcessConcept',
    INTERMEDIARY_TYPE_CU: 'UnderstandingConcept',
    INTERMEDIARY_ABOUT_READING_SCHEMA: 'http://schema.savvas.com/ns/intermediarysubject/reading',
    INTERMEDIARY_ABOUT_WRITING_SCHEMA: 'http://schema.savvas.com/ns/intermediarysubject/writing',
    INTERMEDIARY_ABOUT_SPEAKING_AND_LISTENING_SCHEMA: 'http://schema.savvas.com/ns/intermediarysubject/speakingandlistening',
    INTERMEDIARY_ABOUT_LANGUAGE_SCHEMA: 'http://schema.savvas.com/ns/intermediarysubject/language',
    INTERMEDIARY_ABOUT_REFLECTION_ON_LEARNING_SCHEMA: 'http://schema.savvas.com/ns/intermediarysubject/reflectiononlearning',
    INTERMEDIARY_ABOUT_MEDIA_LITERACY_SCHEMA: 'http://schema.savvas.com/ns/intermediarysubject/medialiteracy',
    INTERMEDIARY_ABOUT_COGNITIVE_PROCESS_SCHEMA: 'http://schema.savvas.com/ns/intermediarysubject/cognitiveprocess',
    INTERMEDIARY_PERIPHERAL_PREDICATE_IRI: 'http://schema.savvas.com/ns/learn/peripherallyRelated',
    INTERMEDIARY_CENTRAL_PREDICATE_IRI: 'http://schema.savvas.com/ns/learn/centrallyRelated',
    CORRELATION_DEFAULT_SEARCH_VIEW: "visual-default",
    CORRELATION_PRODUCTS_SEARCH_VIEW: "visual-products-search",
    CORRELATION_STANDARDS_SEARCH_VIEW: "visual-standards-search",
    CORRELATION_TREE_VIEW: "visual-listing",
    GRADE_RANGE_ARRAY: ["http://schema.savvas.com/ns/school-grade#grade0-preKindergarten",
      "http://schema.savvas.com/ns/school-grade#grade00-kindergarten",
      "http://schema.savvas.com/ns/school-grade#grade01",
      "http://schema.savvas.com/ns/school-grade#grade02",
      "http://schema.savvas.com/ns/school-grade#grade03",
      "http://schema.savvas.com/ns/school-grade#grade04",
      "http://schema.savvas.com/ns/school-grade#grade05",
      "http://schema.savvas.com/ns/school-grade#grade06",
      "http://schema.savvas.com/ns/school-grade#grade07",
      "http://schema.savvas.com/ns/school-grade#grade08",
      "http://schema.savvas.com/ns/school-grade#grade09",
      "http://schema.savvas.com/ns/school-grade#grade10",
      "http://schema.savvas.com/ns/school-grade#grade11",
      "http://schema.savvas.com/ns/school-grade#grade12",
      "http://schema.savvas.com/ns/school-grade#grade13"
    ],
    CORRELATION_DASHBOARD_DEFAULT_VIEW: "default",
    CORRELATION_STANDARD_ADVANCED_SEARCH_VIEW: "correlation-standards-advanced-search",
    CORRELATION_PRODUCT_ADVANCED_SEARCH_VIEW: "correlation-product-advanced-search"
  };

  constructor(
    private httpRestClient: HttpRestClientService,
    private confirmationPopup: ConfirmationPopupService,
    private route: Router,
  ) { }

  public lSideBarClick(event: any) {
    event.stopPropagation();
    var element = document.getElementsByClassName("menuPanelLeftMble");
    var loginPanelMble = document.getElementsByClassName("loginPanelMble");
    element[0].classList.toggle("displayBlockMble");
    element[0].classList.toggle("displayNoneMble");
    loginPanelMble[0].classList.remove('displayBlockMble')
    loginPanelMble[0].classList.add('displayNoneMble')
  }

  public rSideDotClick(event: any) {
    event.stopPropagation();
    var element = document.getElementsByClassName("loginPanelMble");
    var menuPanelLeftMble = document.getElementsByClassName("menuPanelLeftMble");
    element[0].classList.toggle("displayBlockMble");
    element[0].classList.toggle("displayNoneMble");
    menuPanelLeftMble[0].classList.remove('displayBlockMble')
    menuPanelLeftMble[0].classList.add('displayNoneMble')
  }

  public addTabClass = (tab: String) => {
    if (tab === 'new') {
      this.tabClassName = 'draftTab';
    } else if (tab === 'ingested') {
      this.tabClassName = 'awaitingApprTab';
    } else if (tab === 'approved') {
      this.tabClassName = 'approvedTab';
    } else if (tab === 'rejected') {
      this.tabClassName = 'rejectedTab';
    } else if (tab === 'deleted') {
      this.tabClassName = 'deletedTab';
    }
  }

  public loadCurriculumList = (currType: String) => {
    this.loading = true;
    this.curriculaListBean = undefined;
    let url = 'api/curriculum/list?status=' + currType;
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.curriculaListBean = result;
      this.loading = false;
      this.updateListSize();
    }, (error) => {
      if (error.status == 500) {
        this.confirmationPopup.showErrorMessage(this.languageString.resources.common.serverErrorNoDataMessage);
      }
    })
  }

  public updateListSize = () => {
    let listSize = 0;
    for (let x = 0; x < this.curriculaListBean.curriculumCountryListBean.length; x++) {
      var curriculumCountryListBean = this.curriculaListBean.curriculumCountryListBean[x];
      listSize = listSize + curriculumCountryListBean.curriculaInfoList.length;
    }
    this.curriculaListBean.listSize = listSize;
  }

  public reloadCurrentUrl = () => {
    let currentUrl = this.route.url;
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    this.route.onSameUrlNavigation = 'reload';
    this.route.navigate([currentUrl]);
  }

  public saveSubjectMappingData = (mappingData: any) => {
    this.mappingDataCopy = mappingData;
  }

}
