import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRestClientService } from 'src/app/services/common/http-rest-client.service';
import { ConfirmationPopupService } from 'src/app/services/confirmation-popup/confirmation-popup.service';
import { GlobalVariablesService } from 'src/app/services/global-variables/global-variables.service';
import { PreloadDataService } from 'src/app/services/common/preload-data.service';;

@Component({
  selector: 'app-add-authority-curriculum-set',
  templateUrl: './add-authority-curriculum-set.component.html',
  styleUrls: ['./add-authority-curriculum-set.component.scss']
})
export class AddAuthorityCurriculumSetComponent implements OnInit {
  @Input() modalRef: any;
  @Input() createItemBean: any;
  @Input() isAddFlagTrue: boolean = true;
  @Input() doSuccessFunction: any;
  @Input() doSuccessFunctionForCurriculumSet: any;
  @Input() nodeValue: any;
  @Input() gettingEventFrom: any;
  country: any;

  constructor(
    public globalVar: GlobalVariablesService,
    private httpRestClient: HttpRestClientService,
    private confirmationPopup: ConfirmationPopupService,
    private route: Router,
    private preloaddata: PreloadDataService
  ) {
    this.preloaddata.authorityCurrentCountry.subscribe(value => {
      this.country = value;
      console.log('country', this.country)
    })
  }

  ngOnInit(): void {
  }

  ngDoCheck(): void {
    // var urlRegex = new RegExp("^((http|https)://)[a-zA-Z0-9@:%._\\+~#?&//=]{1,256}"); 
    // var yearRegex = new RegExp("^[12][0-9]{3}$");
    // this.disableSaveButton = !this.injectCurriculumBean.curriculumUploadBean.subject ||
    //                           !this.injectCurriculumBean.curriculumUploadBean.country ||
    //                           !this.injectCurriculumBean.curriculumUploadBean.authority ||
    //                           !this.injectCurriculumBean.curriculumUploadBean.curriculumSet ||
    //                           (!this.injectCurriculumBean.curriculumUploadBean.year || !yearRegex.test(this.injectCurriculumBean.curriculumUploadBean.year) ) ||
    //                           !this.injectCurriculumBean.curriculumUploadBean.state ||
    //                           ( (!this.injectCurriculumBean.curriculumUploadBean.sourceUrl || !urlRegex.test(this.injectCurriculumBean.curriculumUploadBean.sourceUrl)) && !this.injectCurriculumBean.canUploadFile);
  }

  doClosePopup = () => {
    this.modalRef.hide();
  };

}
