<app-curriculum-menu></app-curriculum-menu>
<div class="curriculumTab uploadIntermediarySection" style="min-height: 220px;">
    <div class="curriculumEmptyListPanel">
		<div class="row">
			<div class="col-sm-2 validationCheck paddingLeft0">
				<label class="col-sm-12 font_13_333333_bold paddingLeft0">{{globalVar?.languageString?.resources?.manageTab?.manageUploadDisciplineLabel}}</label>
				<div>
                    <select class="cmDropDown currIngestDropDown"
                        [(ngModel)]="injectCurriculumBean.curriculumUploadBean.subject"
                        [ngClass]="{ 'ng-invalid-required' : injectCurriculumBean.curriculumUploadBean.subject == ''}"
                        tabindex="1" (change)="resetGenerateXls()"
                        [disabled]="!injectCurriculumBean.curriculumIngestionDataBean.subjectFacets || injectCurriculumBean.curriculumIngestionDataBean.subjectFacets.length == 0">
                        <option value="" hidden selected >Select Discipline</option>
                        <option [value]="facetInfoBean.facetUri" *ngFor="let facetInfoBean of injectCurriculumBean.curriculumIngestionDataBean.subjectFacets"> {{facetInfoBean.facetName}} </option>
                    </select>
				
			    </div>
			</div>
			<div (click)="exportIntermediary()" class="intermediary-export-link" [hidden]="!(!globalVar?.generatingXLS && !globalVar?.downloadUrl)">
				{{globalVar?.languageString?.resources?.manageTab?.manageUploadExportReingestionBtnLabel}}
			</div>
			<div class="intermediary-export-link" [hidden]="!(globalVar?.generatingXLS && !globalVar?.downloadUrl)">
				{{globalVar?.languageString?.resources?.manageTab?.manageUploadGeneratingMessage}}
			</div>
			<div class="intermediary-export-link" [hidden]="!(!globalVar?.generatingXLS && globalVar?.downloadUrl)"><a (click)="downloadFile()" title="{{globalVar?.languageString?.resources?.manageTab?.manageUploadDownloadLinkLabel}}">{{globalVar?.languageString?.resources?.manageTab?.manageUploadDownloadLinkLabel}}</a></div>
		</div>
        <div>
            <div style="padding-top: 20px; border-top: 1px solid #E4E4E4;">
                <div style="padding-top: 30px;">
                    <input type="checkbox" [(ngModel)]="injectCurriculumBean.curriculumUploadBean.skillMigrationImport" [disabled]="injectCurriculumBean.curriculumUploadBean.skillsGUIDOverwrite" (change)="resetSkillCheckbox()">
                    <label style="padding-left: 0.4em;" >Skill Migration Import</label>
                </div>
                <div style="padding-top: 10px;padding-bottom: 30px;">
                    <input type="checkbox" [(ngModel)]="injectCurriculumBean.curriculumUploadBean.skillsGUIDOverwrite" [disabled]="injectCurriculumBean.curriculumUploadBean.skillMigrationImport" (change)="resetSkillCheckbox()">
                    <label style="padding-left: 0.4em;" >Skills GUID Overwrite</label>
                </div>
                <div style="font-size: 20px; color: #333333;">{{globalVar?.languageString?.resources?.manageTab?.manageUploadFileMessage}}</div>
                <div class="curriculumEmptyListPanelText">{{globalVar?.languageString?.resources?.manageTab?.manageUploadIntermediaryMessage}}</div>
            </div>
            <div style="padding-top: 10px;">
                <app-uploader [action]="'api/intermediary'" [bean]="injectCurriculumBean?.curriculumUploadBean" [fromScreen]="'intermediary'"></app-uploader>
            </div>
        </div>    
    </div>
</div>
