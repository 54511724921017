import { Component, OnInit } from '@angular/core';
import { GlobalVariablesService } from '../../services/global-variables/global-variables.service';
import { HttpRestClientService } from '../../services/common/http-rest-client.service';
import { ApplicationLoaderService } from '../../services/common/application-loader.service';
import { ConfirmationPopupService } from '../../services/confirmation-popup/confirmation-popup.service';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import * as _ from "underscore";
import { Router, ActivatedRoute } from '@angular/router';
import { PreloadDataService } from 'src/app/services/common/preload-data.service';

@Component({
  selector: 'app-update-curriculum',
  templateUrl: './update-curriculum.component.html',
  styleUrls: ['./update-curriculum.component.scss']
})
export class UpdateCurriculumComponent implements OnInit {

  public injectCurriculumBean: any = {
    curriculumIngestionDataBean: {},
    curriculumUploadBean: {
      curriculumId : '',
      subject: '',
      country: '',
      authority:'',
      curriculumSet: '',
      state: '',
      fileType: 'AB XML',
      appendCustom: false,
      includeIntermediaryAlignments : false
    },
    canUploadFile: this.route.getCurrentNavigation()?.extras,
    curriculaPreviewBean : undefined
  };

  constructor(
    private route: Router,
    private actRoute: ActivatedRoute,
    private httpRestClient : HttpRestClientService,
    public globalVar: GlobalVariablesService,
    private appLoadService: ApplicationLoaderService,
    private confirmationPopup: ConfirmationPopupService,
    public PreloadData: PreloadDataService
  ) { }

  ngOnInit(): void {
    this.loadCurriculumList();
  }

  ngDoCheck(): void {
    // this.loadCurriculumList();
  }

  public loadCurriculumList = () => {
    this.globalVar.curriculaListBean = undefined;
    let url = 'api/curriculum/status'
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result: any) => {
      this.globalVar.curriculaListBean = result;
      //updateListSize();
    }, (error) => {
      if(error.status == 500) {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
      } 
    });
  };

  public resetIncludeIntermediary = () => {
    if(this.injectCurriculumBean.curriculumUploadBean.includeIntermediaryAlignments){
      this.injectCurriculumBean.curriculumUploadBean.includeIntermediaryAlignments = false;
    }
  }

  changeCurriculum()
  {
    this.PreloadData.beanValueChanged.next(true)
  }

}
