<app-curriculum-menu [loadCurriculumList]='loadCurriculumList' [addTabClass]='addTabClass'></app-curriculum-menu>
<div class="curriculumTab" [ngClass]="tabClassName">
    <div class="curriculamFilter clearfix" style="margin-top: 115px;">
        <label class="font16 font_13_333333_bold marginBottom5">Filter By</label>
        <div class="row marginTop10">
            <div class="filterContent">
                <label class="curricFilterLabel pull-left font14">Subject:</label>
                <select class="curricFilterSelect" [(ngModel)]="filterData.selectedSubjects">
                    <option value="">All Subjects</option>
                    <option *ngFor="let subject of filterSubjects">{{subject.facetName}}</option>
                </select>
            </div>
            <div class="filterContent">
                <label class="curricFilterLabel pull-left font14">Country:</label>
                <select class="curricFilterSelect" [(ngModel)]="filterData.selectedCountry">
                    <option value="">All Countries</option>
                    <option *ngFor="let country of filterCountries">{{country.facetName}}</option>
                </select>
            </div>
            <div class="filterContent">
                <label class="curricFilterLabel pull-left font14">Curriculum Type:</label>
                <select class="curricFilterSelect" [(ngModel)]="filterData.curriculumType">
                    <option value="">All Curriculum Type</option>
                    <option *ngFor="let type of curriculumTypes">{{type.name}}</option>
                </select>
            </div>
            <div class="filterContent">
                <label class="curricFilterLabel pull-left font14">Deployed to Realize:</label>
                <select class="curricFilterSelect" [(ngModel)]="filterData.deployToRelease">
                    <option value="">Any</option>
                    <option *ngFor="let type of deployToRelease">{{type.name}}</option>
                </select>
            </div>
            <div class="filterContent">
                <label class="curricFilterLabel pull-left font14">Search:</label>
                <input type="text" class="curricFilterSelect currSearchKey font14"
                    [(ngModel)]="filterData.currSearchKey" placeholder="Enter search key" />
            </div>
        </div>
    </div>
    <div class="curriculamFilterMobView clearfix">
        <div class="searchContentCurrFilt dispInlBlk">
            <input type="text" class="curricFilterSelect currSearchKey font14" [(ngModel)]="filterData.currSearchKey"
                placeholder="Enter search key" />
        </div>
        <div class="moreFilterOption dispInlBlk posRelative">
            <i class="fa fa-filter" (click)="openManageFilter($event);"></i>
            <div class="mblermngFilter displayNoneMble">
                <div class="filterContent">
                    <label class="curricFilterLabel pull-left font14">Subject:</label>
                    <select class="curricFilterSelect" [(ngModel)]="filterData.selectedSubjects">
                        <option value="">All Subjects</option>
                        <option *ngFor="let subject of filterSubjects">{{subject.facetName}}</option>
                    </select>
                </div>
                <div class="filterContent">
                    <label class="curricFilterLabel pull-left font14">Country:</label>
                    <select class="curricFilterSelect" [(ngModel)]="filterData.selectedCountry">
                        <option value="">All Countries</option>
                        <option *ngFor="let country of filterCountries">{{country.facetName}}</option>
                    </select>
                </div>
                <div class="filterContent">
                    <label class="curricFilterLabel pull-left font14">Curriculum Type:</label>
                    <select class="curricFilterSelect" [(ngModel)]="filterData.curriculumType">
                        <option value="">All Curriculum Type</option>
                        <option *ngFor="let type of curriculumTypes">{{type.name}}</option>
                    </select>
                </div>
                <div class="filterContent">
                    <label class="curricFilterLabel pull-left font14">Deployed to Realize:</label>
                    <select class="curricFilterSelect" [(ngModel)]="filterData.deployToRelease">
                        <option value="">Any</option>
                        <option *ngFor="let type of deployToRelease">{{type.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="moreMangeOption dispInlBlk">
            <div class="moreMangeOptionBtn" (click)="openMoreOption($event);">
                More <i class="fa fa-angle-down"></i>
            </div>
            <div class="mbleCurrProdMngMenu displayNoneMble">
                <div class="mbleMoreMenu">
                    <label>{{globalVar?.languageString?.resources?.menu?.curriculumMenuCurriculumsLabel}}</label>
                    <a
                        [routerLink]="['/curriculums/upload']">{{globalVar?.languageString?.resources?.menu?.curriculumMenuUploadCurriculumLabel}}</a>
                    <a
                        [routerLink]="['/curriculums/create']">{{globalVar?.languageString?.resources?.menu?.curriculumMenuCreateCurriculumLabel}}</a>
                    <a
                        [routerLink]="['/curriculums/updatecurr']">{{globalVar?.languageString?.resources?.menu?.curriculumMenuUpdateCurriculumLabel}}</a>
                    <a
                        [routerLink]="['/curriculums/authority']">{{globalVar?.languageString?.resources?.menu?.curriculumMenuAuthorityLabel}}</a>
                </div>
                <div class="mbleMoreMenu">
                    <label>{{globalVar?.languageString?.resources?.menu?.curriculumMenuProductsLabel}}</label>
                    <a
                        href="/product/upload">{{globalVar?.languageString?.resources?.menu?.curriculumMenuUploadProductLabel}}</a>
                    <a
                        href="/product/delete">{{globalVar?.languageString?.resources?.menu?.curriculumMenuManageProductLabel}}</a>
                </div>
                <div class="mbleMoreMenu">
                    <label>{{globalVar?.languageString?.resources?.menu?.curriculumMenuIntermediaryLabel}}</label>
                    <a
                        href="/curriculums/intermediary">{{globalVar?.languageString?.resources?.menu?.curriculumMenuUploadIntermediaryLabel}}</a>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="isActiveMenu('new')" class="curriculumTotal"
        [hidden]="globalVar?.curriculaListBean?.curriculumCountryListBean?.length === 0"
        [innerHTML]="'Drafts Curriculum List ' +'('+getmodifiedListLength()+')'">
    </div>
    <div *ngIf="isActiveMenu('approved')" class="curriculumTotal"
        [hidden]="globalVar?.curriculaListBean?.curriculumCountryListBean?.length === 0"
        [innerHTML]="globalVar?.languageString?.resources?.homeTab?.countryApprovedCurriculumListLabel +'('+getmodifiedListLength()+')'">
    </div>
    <div *ngIf="isActiveMenu('deleted')" class="curriculumTotal"
        [hidden]="globalVar?.curriculaListBean?.curriculumCountryListBean?.length === 0"
        [innerHTML]="'Deleted Curriculum List ' +'('+getmodifiedListLength()+')'">
    </div>

    <ng-container
        *ngIf="globalVar?.curriculaListBean && globalVar?.curriculaListBean?.curriculumCountryListBean && globalVar.curriculaListBean?.curriculumCountryListBean?.length > 0">
        <div
            *ngFor="let curriculumCountryListBean of filterByCounSub('parent', globalVar.curriculaListBean?.curriculumCountryListBean, filterData.selectedCountry, filterData.selectedSubjects, filterData.curriculumType, filterData.deployToRelease,filterData.currSearchKey, undefined); let parentIndex = index">
            <div *ngIf="curriculumCountryListBean?.modifiedCurriculaInfoList?.length > 0" class="curriculumCountry">{{
                curriculumCountryListBean?.country }}</div>
            <div
                *ngFor="let curr of filterByCounSub('child', curriculumCountryListBean.curriculaInfoList, filterData.selectedCountry, filterData.selectedSubjects, filterData.curriculumType, filterData.deployToRelease, filterData.currSearchKey, curriculumCountryListBean); let indx = index">
                <div class="curriculumBackground">
                    <table class="curriculumTable">
                        <tbody>
                            <tr>
                                <td class="curriculumButtonPanel" *ngIf="isActiveMenu('new')">
                                    <input type="submit"
                                        value="{{globalVar?.languageString?.resources?.homeTab?.curriculumPreviewApproveBtnLabel}}"
                                        class="cmButton cmButton-green cmButton-large marginLeft24px"
                                        (click)="approveCurricula(curr, parentIndex, indx, statusChangePopupTemp)" />
                                    <span>
                                        <img class="EditRedPng" title="Edit Curriculum" (click)="editCurricula(curr)"
                                            src="assets/images/edit-red.png" />
                                    </span>
                                    <span>
                                        <img class="exportEditPng"
                                            title="{{globalVar.languageString.resources.manageTab.manageDraftExportForEditBtnLabel}}"
                                            (click)="exportForEdit(curr)" src="assets/images/export-icon-blue.png" />
                                    </span>
                                    <span>
                                        <img class="substitutionIcon" title="Manage substitution Set"
                                            (click)="openSubstitutionSet(curr)" src="assets/images/file-exchange.png" />
                                    </span>
                                </td>
                                <td class="curriculumButtonPanel" *ngIf="isActiveMenu('ingested')">
                                    <input
                                        [hidden]="!(curr.autoTaggedPercentage < 0 || curr.autoTaggedPercentage == 100)"
                                        type="submit"
                                        value="{{globalVar?.languageString?.resources?.manageTab?.manageCurriculumDraftBtnLabel}}"
                                        class="cmButton cmButton-grey cmButton-standard marginLeft15px"
                                        title="{{globalVar?.languageString?.resources?.manageTab?.manageCurriculumDraftTitle}}"
                                        (click)="draftCurricula(curr, parentIndex, indx, statusChangePopupTemp)" />
                                    <input
                                        [hidden]="!(curr.autoTaggedPercentage < 0 || curr.autoTaggedPercentage == 100)"
                                        type="submit"
                                        value="{{globalVar?.languageString?.resources?.manageTab?.manageCurriculumApproveBtnLabel}}"
                                        class="cmButton cmButton-green cmButton-standard marginLeft15px"
                                        title="{{globalVar?.languageString?.resources?.manageTab?.manageCurriculumApproveTitle}}"
                                        (click)="approveCurricula(curr, parentIndex, indx, statusChangePopupTemp)"
                                        style="padding-left: 12px;" />
                                    <input
                                        [hidden]="!(curr.autoTaggedPercentage < 0 || curr.autoTaggedPercentage == 100)"
                                        type="submit"
                                        value="{{globalVar?.languageString?.resources?.manageTab?.manageCurriculumRejectBtnLabel}}"
                                        class="cmButton cmButton-red cmButton-standard marginLeft15px"
                                        title="{{globalVar?.languageString?.resources?.manageTab?.manageCurriculumRejectTitle}}"
                                        (click)="rejectCurricula(curr, parentIndex, indx, statusChangePopupTemp)" />
                                    <input
                                        [hidden]="!(curr.autoTaggedPercentage > -1 && curr.autoTaggedPercentage < 100)"
                                        type="submit"
                                        value="{{globalVar.languageString?.resources?.manageTab?.manageCurriculumDraftBtnLabel}}"
                                        class="cmButton cmButton-grey-noHover cmButton-standard marginLeft15px"
                                        title="{{globalVar?.languageString?.resources?.manageTab?.manageCurriculumDraftAutoTaggingMessage}}" />
                                    <input
                                        [hidden]="!(curr.autoTaggedPercentage > -1 && curr.autoTaggedPercentage < 100)"
                                        type="submit"
                                        value="{{globalVar?.languageString?.resources?.manageTab?.manageCurriculumApproveBtnLabel}}"
                                        class="cmButton cmButton-grey-noHover cmButton-standard marginLeft15px"
                                        title="{{globalVar?.languageString?.resources?.manageTab?.manageCurriculumApproveAutoTaggingMessage}}"
                                        style="padding-left: 12px;" />
                                    <input
                                        [hidden]="!(curr.autoTaggedPercentage > -1 && curr.autoTaggedPercentage < 100)"
                                        type="submit"
                                        value="{{globalVar?.languageString?.resources?.manageTab?.manageCurriculumRejectBtnLabel}}"
                                        class="cmButton cmButton-grey-noHover cmButton-standard marginLeft15px"
                                        title="{{globalVar?.languageString?.resources?.manageTab?.manageCurriculumRejectAutoTaggingMessage}}" />
                                    <span>
                                        <img class="substitutionIcon" title="Manage substitution Set"
                                            (click)="openSubstitutionSet(curr)" src="assets/images/file-exchange.png" />
                                    </span>
                                </td>
                                <td class="curriculumButtonPanel mbleButtonApprovedTab"
                                    *ngIf="isActiveMenu('approved')">
                                    <input type="submit"
                                        value="{{globalVar.languageString.resources.manageTab.manageUnApproveBtnLabel}}"
                                        class="cmButton cmButton-green cmButton-large marginLeft24px"
                                        [disabled]="curr.lockedForAlignment"
                                        (click)="draftCurricula(curr, parentIndex, indx, statusChangePopupTemp)" />
                                </td>
                                <td class="curriculumButtonPanel mbleButtonApprovedTab"
                                    *ngIf="isActiveMenu('approved')">
                                    <button type="submit" [innerHTML]="getLockStatusLabel(curr)"
                                        class="cmButton cmButton-green cmButton-large marginLeft24px"
                                        (click)="lockUnlockCurricula(curr)"></button>
                                </td>
                                <td class="mbleButtonApprovedTab" *ngIf="isActiveMenu('approved')">
                                    <span>
                                        <img class="substitutionIcon" title="Manage substitution Set"
                                            (click)="openSubstitutionSet(curr)" src="assets/images/file-exchange.png" />
                                    </span>
                                </td>
                                <td class="curriculumButtonPanel" *ngIf="isActiveMenu('rejected')">
                                    <input type="submit"
                                        value="{{globalVar.languageString.resources.manageTab.manageUnRejectBtnLabel}}"
                                        class="cmButton cmButton-green cmButton-large marginLeft24px"
                                        (click)="unrejectCurricula(curr, parentIndex, indx, statusChangePopupTemp)" />
                                </td>
                                <td class="curriculumButtonPanel" *ngIf="isActiveMenu('deleted')">
                                    <input type="submit"
                                        value="{{globalVar.languageString.resources.manageTab.manageUnDeleteBtnLabel}}"
                                        class="cmButton cmButton-green cmButton-large marginLeft24px"
                                        (click)="draftCurricula(curr, parentIndex, indx, statusChangePopupTemp)" />
                                </td>
                                <td class="curriculumButtonPanel" style="width:95px">
                                    <input type="radio" *ngIf="curr?.deployedToRealizeFlag" [name]="curr.curriculumTitle" [id]="curr.curriculumId" (click)="ChangeDeployToRealeaseStatus($event,curr,toggleChangePopup)" [checked]="curr?.deployedToRealize" >
                                    <img *ngIf="curr?.deployedToRealizeFlag" style="width:20px; margin-right:20px;margin-left:20px" class="deployedtorealizePng" title="Deployed to Realize" src="assets/images/deployed_to_realize_Icon.png">
                                </td>
                                <td>
                                    <div class="curriculumInfoPanel">
                                        <img *ngIf="curr?.deployedToRealize" style="width:12px; margin-right:5px" class="deployedtorealizePng" title="Deployed to Realize" src="assets/images/deployed_to_realize_Icon.png">
                                        <a [routerLink]="'/curriculum/'+curr.curriculumId"
                                            [innerHTML]="getHighlightedText(curr.curriculumTitle, filterData.currSearchKey)"
                                            class="curriculumTitle"></a>
                                    </div>
                                    <div class="curriculumInfo">
                                        Created on {{curr?.createdOn}} |
                                        {{globalVar?.languageString?.resources?.homeTab?.curriculumLastUpdateUserLabel}}
                                        {{curr?.lastUpdatedUser}}
                                        {{globalVar.languageString.resources.homeTab.curriculumLastUpdateTimeLabel}}
                                        {{curr?.lastUpdatedTime | date:'dd MMM yyyy HH:mm:ss'}}
                                    </div>
                                    <div class="curriculumInfo">
                                        {{curr.curriculumType}}
                                    </div>
                                    <div class="curriculumInfo" [innerHtml]="getInnerHtml(curr.description)">
                                    </div>
                                </td>
                                <td *ngIf="(isActiveMenu('new') || isActiveMenu('ingested'))"
                                    class="curriculumDeleteIcon" id="curriculumDeleteIconSource"
                                    (click)="deleteCurricula(curr, parentIndex, indx, statusChangePopupTemp)"
                                    title="{{globalVar?.languageString?.resources?.manageTab?.manageCurriculumDeleteIcon}}">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </ng-container>
    <div [hidden]="globalVar?.curriculaListBean?.curriculumCountryListBean?.length > 0"
        class="curriculumEmptyListPanel">
        <div class="curriculumEmptyListPanelText">
            {{globalVar?.languageString?.resources?.homeTab?.countryNoCurriculumApprovedMessage}}</div>
    </div>

    <ng-template #statusChangePopupTemp>
        <app-warning-popup [DoFunctionOnSuccess]="doUpdateCurriculumStatus" [PopupMessage]="popupData.popupMessage"
            [ConfirmationMessage]="popupData.confirmationMsg" [ButtonLabel]="popupData.buttonLabel"
            [modalRef]="modalRef"></app-warning-popup>
    </ng-template>

    <ng-template #toggleChangePopup>
        <app-warning-popup [DoFunctionOnSuccess]="toggleChange" [PopupMessage]="popupData.popupMessage"
            [ConfirmationMessage]="popupData.confirmationMsg" [ButtonLabel]="popupData.buttonLabel"
            [modalRef]="modalRef"></app-warning-popup>
    </ng-template>

</div>