<div class="uploader">
    <div class="control-group">
        <div class="controls" *ngIf="fromScreen !=='uploadSub' && fromScreen !=='updateSub'">
            <div *ngIf="bean.fileType == 'Custom Excel' || fromScreen ==='product' || fromScreen ==='intermediary'">
                <input #file1 class="" type="file" id="fileName" accept=".xls,.xlsx"
                    name="{{globalVar?.languageString?.resources?.manageTab?.manageUploadUploaderName}}"
                    style="display:none;"
                    [value]="globalVar?.languageString?.resources?.manageTab?.manageUploadUploaderValue"
                    (change)="changeFileName(file1);" [(ngModel)]="uploader" (click)="resetUploadFileName(file1);" />
            </div>
            <div *ngIf="bean.fileType == 'AB XML' && fromScreen !=='intermediary'">
                <input class="" #file2 type="file" id="fileName" accept=".xml"
                    name="{{globalVar?.languageString?.resources?.manageTab?.manageUploadUploaderName}}"
                    style="display:none;"
                    [value]="globalVar?.languageString?.resources?.manageTab?.manageUploadUploaderValue"
                    (change)="changeFileName(file2);" [(ngModel)]="uploader" (click)="resetUploadFileName(file2);" />
            </div>
            <input class="cmButton cmButton-green uploadSelectBtn" type="submit" readonly="readonly"
                (disabled)="!bean.fileType && fromScreen !== 'product' && fromScreen !=='intermediary'" [value]="avatar"
                (click)="clickOnUploadeInput()" />
        </div>

        <div class="controls" *ngIf="fromScreen ==='uploadSub' || fromScreen ==='updateSub'">
            <input class="" #file3 type="file" [(ngModel)]="uploader" id="fileName" accept=".xls,.xlsx"
                name="{{globalVar?.languageString?.resources?.manageTab?.manageUploadUploaderName}}"
                style="display:none;"
                [value]="globalVar?.languageString?.resources?.manageTab?.manageUploadUploaderValue"
                (change)="changeFileName(file3);" (click)="resetUploadFileName(file3);" />
            <input class="cmButton cmButton-green uploadSelectBtn" type="submit" readonly="readonly" [value]="avatar"
                (click)="clickOnUploadeInput()" />
        </div>

        <div>
            <input type="submit" [hidden]="!selectedFile" [disabled]="disbaledAfterClick || disableUploadButton"
                class="cmButton cmButton-green cmButton-standard" style="margin-top: 13px;"
                (click)="sendFile(fileName);"
                value="{{globalVar?.languageString?.resources?.manageTab?.manageUploadBtnLabel}}" />
        </div> 
        <div [hidden]="!(progress > 0)" class="font_13_666666" style="display: inline-block; padding-top: 20px;">
            {{globalVar?.languageString?.resources?.manageTab?.manageUploadWaitMessage}}</div>
    </div>

    <!-- progress bar -->
    <!--<div class="progress progress-bar-success" ng-show="progress > 0" style="width: 400px;">-->
    <!--<span class="label label-success">{{ progress}} % loaded</span><h3></h3>-->
    <!--</div>-->

    <div [hidden]="!errorUploadResult" style="display: inline-block; color: #E34A2F;">
        <div style="margin-top: 30px; padding-bottom: 10px;">
            <div style="font-size: 20px; color: #333333;">
                {{globalVar?.languageString?.resources?.manageTab?.manageUploadFailMessage}}</div>
            <div class="curriculumEmptyListPanelText" style="border: none;">
                {{globalVar?.languageString?.resources?.manageTab?.manageUploadReUploadMessage}}</div>
        </div>

        <div class="uploaderErrorPanel" style="border-color: #E34A2F;">
            <div class="uploaderErrorMessage" style="color: #E34A2F; font-weight: bold;">
                {{ errorUploadResult }}
            </div>
        </div>

        <div [hidden]="!errorMessages" *ngFor="let message of errorMessages" class="uploaderErrorPanel"
            style="border-color: #E34A2F;">
            <div class="uploaderErrorMessage" style="color: #E34A2F;" *ngIf='message.message=="duplicateissue"'>
                To update, Please remove duplicate values and reimport the spreadsheet.
            </div>
            <div class="uploaderErrorMessage" style="color: #E34A2F;" *ngIf='message.message!="duplicateissue"'>
                {{ message.level }} {{globalVar?.languageString?.resources?.manageTab?.manageUploadErrorAtLineMessage}}
                {{ message?.lineNumber }} - {{ message?.message }}
            </div>
        </div>
    </div>

    <div [hidden]="!warningUploadResult" style="display: inline-block;">
        <div [hidden]="fromScreen" style="margin-top: 30px; padding-bottom: 10px;">
            <div style="font-size: 20px; color: #333333;">
                {{globalVar?.languageString?.resources?.manageTab?.manageUploadSuccessMessage}}</div>
            <div class="curriculumEmptyListPanelText" style="border: none;">
                {{globalVar?.languageString?.resources?.manageTab?.manageUploadContinueMessage}}</div>
        </div>

        <div class="uploaderErrorPanel" style="border-color: #FF6600;">
            <div class="uploaderErrorMessage" style="color: #FF6600; font-weight: bold;">
                {{ warningUploadResult }}
            </div>
        </div>

        <div [hidden]="!errorMessages" *ngFor="let message of errorMessages" class="uploaderErrorPanel"
            style="border-color: #FF6600;">
            <div class="uploaderErrorMessage" style="color: #FF6600;">
                {{ message?.level }} {{globalVar?.languageString?.resources?.manageTab?.manageUploadErrorAtLineMessage}}
                {{ message?.lineNumber }} - {{ message?.message }}
            </div>
        </div>
    </div>

    <ng-template #uploaderPopup>
        <app-warning-popup [DoFunctionOnSuccess]="popupData?.popupCallBack" [PopupMessage]="popupData?.popupMessage"
             [subPopupMessage]="popupData?.subPopupMessage" [ButtonLabel]="popupData?.buttonLabel"
            [modalRef]="modalRef"></app-warning-popup>
    </ng-template>

</div>