import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpRestClientService } from 'src/app/services/common/http-rest-client.service';
import { ConfirmationPopupService } from 'src/app/services/confirmation-popup/confirmation-popup.service';
import { GlobalVariablesService } from 'src/app/services/global-variables/global-variables.service';
import { PreloadDataService } from 'src/app/services/common/preload-data.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-manage-authority',
  templateUrl: './manage-authority.component.html',
  styleUrls: ['./manage-authority.component.scss']
})
export class ManageAuthorityComponent implements OnInit {

  public countryFacets: any;
  public modalData: any;
  public addFlag: boolean = true;
  public modalRef: BsModalRef | undefined;
  public currEvent: any;
  // public currNode: any;

  public currentAuthorityNode: any;
  public currentCountryNode: any;
  public currentCurriculumSetNode: any;

  @ViewChild('addEditAuthorityTemp') private addEditAuthorityTemp: TemplateRef<any> | undefined;

  constructor(
    public globalVar: GlobalVariablesService,
    private httpRestClient: HttpRestClientService,
    private confirmationPopup: ConfirmationPopupService,
    private modalService: BsModalService,
    private route: Router,
    private preloadData: PreloadDataService
  ) { }

  ngOnInit(): void {
    if (!this.globalVar.filterSubjectsHomePage || !this.globalVar.filterCountriesHomePage) {
      this.preloadIngestData();
    } else {
      this.countryFacets = _.clone(this.globalVar.filterCountriesHomePage);
    }
  }

  public preloadIngestData = () => {
    this.globalVar.loading = true;
    let url = 'api/curriculum/ingestion'
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.countryFacets = _.clone(result.countryFacets);
      this.globalVar.filterSubjectsHomePage = result.subjectFacets;
      this.globalVar.filterCountriesHomePage = result.countryFacets;
      this.globalVar.loading = false;
    }, (error) => {
      this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.createCurriculumPreloadIngetionDataErrorMessage);
      this.globalVar.loading = false;
    });
  }

  public getAuthority = (currCountry: any, event: any) => {
    let program = [];
    let url = 'api/curriculum/listauthorities?countryIRI=' + encodeURIComponent(currCountry.facetUri);
    const headers: any = {
      'Content-Type': 'text/plain; charset=utf-8'
    };
    let element = document.getElementById(currCountry.facetUri);
    this.httpRestClient.getForUrl(url, headers, undefined).subscribe((result) => {
      currCountry.authorityList = result ? result.authorityFacets : [];
      event?.classList.remove("treeLoading");
      event?.classList.add("treeOpen");
      if (!(document.getElementById(currCountry.facetUri)?.classList.contains('treeOpen'))) {
        element?.classList.add("treeOpen");
        element?.classList.remove("visHidden");
        element?.parentElement?.parentElement?.parentElement?.classList.add("treeOpen");
      }
      if (!result) {
        element?.classList.add("visHidden");
      }
    }, (error) => {
      this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
    });
  }

  public getCurriculumSet = (currAuthority: any, event: any) => {
    let program = [];
    let url = 'api/curriculum/listcurriculumset?authorityIRI=' + encodeURIComponent(currAuthority.facetUri);
    const headers: any = {
      'Content-Type': 'text/plain; charset=utf-8'
    };
    let element = document.getElementById(currAuthority.facetUri);
    this.httpRestClient.getForUrl(url, headers, undefined).subscribe((result) => {
      currAuthority.curriculumSetList = result ? result.curriculumSetFacets : [];
      event?.classList.remove("treeLoading");
      event?.classList.add("treeOpen");
      if (!(document.getElementById(currAuthority.facetUri)?.classList.contains('treeOpen'))) {
        element?.classList.add("treeOpen");
        element?.classList.remove("visHidden");
        element?.parentElement?.parentElement?.parentElement?.classList.add("treeOpen");
      }
      if (!result) {
        element?.classList.add("visHidden")
      }
    }, (error) => {
      this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
    });
  }

  public addEditAuthority = (isAdd: any, country: any, authority: any) => {
    let createItemBean;
    if (isAdd) {
      createItemBean = {
        forAuthority: true,
        authorityUrl: "",
        authorityName: ""
      };
    } else {
      createItemBean = {
        forAuthority: true,
        authorityUrl: authority.facetUrl,
        authorityName: authority.facetName
      };
    }
    this.currentAuthorityNode = authority;
    this.currentCountryNode = country;
    this.preloadData.authorityCurrentCountry.next(this.currentCountryNode);
    this.currentCurriculumSetNode = undefined;
    this.modalData = createItemBean;
    this.addFlag = isAdd;
    this.modalRef = this.modalService.show(this.addEditAuthorityTemp ? this.addEditAuthorityTemp : '');
    this.modalRef.setClass("addAuthorityPopup");
  }

  public addEditCurriculumSet = (isAdd: any, country: any, authority: any, curriculumSet: any) => {
    let createItemBean;
    if (isAdd) {
      createItemBean = {
        forCurriculumSet: true,
        curriculumSetName: ""
      };
    } else {
      createItemBean = {
        forCurriculumSet: true,
        curriculumSetName: curriculumSet.facetName
      };
    }
    this.currentAuthorityNode = authority;
    this.currentCountryNode = country;
    this.currentCurriculumSetNode = curriculumSet;
    this.modalData = createItemBean;
    this.addFlag = isAdd;
    this.modalRef = this.modalService.show(this.addEditAuthorityTemp ? this.addEditAuthorityTemp : '');
    this.modalRef.setClass("addAuthorityPopup");
  }

  public saveOrUpdateAuthority = (addoreditflg: any, createItemBean: any) => {
    let url = '';
    let reqObj = {};
    if (addoreditflg) {
      url = 'api/curriculum/addAuthority';
      reqObj = { 'country': this.currentCountryNode.facetUri, 'authorityLabel': createItemBean.authorityName, 'authorityUrl': createItemBean.authorityUrl };
    } else {
      url = 'api/curriculum/editAuthority';
      reqObj = { 'authorityLabel': createItemBean.authorityName, 'authorityUrl': createItemBean.authorityUrl, 'authorityIri': this.currentAuthorityNode.facetUri };
    }
    const headers: any = {
      responseType: 'text'
    };
    this.httpRestClient.postForUrl(url, reqObj, headers).subscribe((result: any) => {
      if (addoreditflg) {
        this.getAuthority(this.currentCountryNode, undefined);
        this.modalRef?.hide();
      } else {
        this.getAuthority(this.currentCountryNode, undefined);
        this.modalRef?.hide();
      }
    }, (error) => {
      this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorMessage + (addoreditflg ? this.globalVar.languageString.resources.manageTab.curriculumErrorInCreating : this.globalVar.languageString.resources.manageTab.curriculumErrorInUpdating) + " " + "Authority");
    });
  }

  public saveOrUpdateCurriculumSet = (addoreditflg: any, createItemBean: any) => {
    let methodType = '';
    let url = '';
    let reqObj = {};
    if (addoreditflg) {
      url = 'api/curriculum/addCurriculumSet';
      reqObj = { 'country': this.currentCountryNode.facetUri, 'authority': this.currentAuthorityNode.facetUri, 'curriculumSetLabel': createItemBean.curriculumSetName };
    } else {
      url = 'api/curriculum/editCurriculumSet';
      reqObj = { "country": this.currentCountryNode.facetUri, 'curriculumSetLabel': createItemBean.curriculumSetName, 'oldcurriculumSetIri': this.currentCurriculumSetNode.facetUri, 'authorityIri': this.currentAuthorityNode.facetUri };
    }
    const headers: any = {
      responseType: 'text'
    };
    this.httpRestClient.postForUrl(url, reqObj, headers).subscribe((result: any) => {
      if (addoreditflg) {
        this.getCurriculumSet(this.currentAuthorityNode, undefined);
        this.modalRef?.hide();
      } else {
        this.getCurriculumSet(this.currentAuthorityNode, undefined);
        this.modalRef?.hide();
      }
    }, (error) => {
      this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorMessage + (addoreditflg ? this.globalVar.languageString.resources.manageTab.curriculumErrorInCreating : this.globalVar.languageString.resources.manageTab.curriculumErrorInUpdating) + " " + "Authority");
    });
  }

}
