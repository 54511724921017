import { Component, Input, OnInit } from '@angular/core';
import { HttpRestClientService } from '../../services/common/http-rest-client.service';
import { GlobalVariablesService } from '../../services/global-variables/global-variables.service';

@Component({
  selector: 'app-edit-local-grade',
  templateUrl: './edit-local-grade.component.html',
  styleUrls: ['./edit-local-grade.component.scss']
})
export class EditLocalGradeComponent implements OnInit {

  @Input() createItemBean: any; 
  @Input() doFunctionOnSuccess: any;
  @Input() modalRef: any;
  public cmtGrades: any = [];

  constructor(
    private httpRestClient : HttpRestClientService,
    public globalVar: GlobalVariablesService,
  ) { }

  ngOnInit(): void {
    if(!this.globalVar.gradeListForProductEdit) {
      this.getGradeRange();
    } else {
      this.cmtGrades = this.globalVar.gradeListForProductEdit
    }
    this.initEditRangePopup();
  }

  public initEditRangePopup = () => {

  }

  public isMinMaxGradeRangeRight = () => {
    if (!this.createItemBean.gradeMinIRI || !this.createItemBean.gradeMaxIRI) {
      return true;
    }
    var gradeMinIndex = this.getGradeIriIndex(this.createItemBean.gradeMinIRI);
    var gradeMaxIndex = this.getGradeIriIndex(this.createItemBean.gradeMaxIRI);
    return gradeMinIndex > -1 && gradeMaxIndex > -1 && gradeMinIndex <= gradeMaxIndex;
  }

  public getGradeIriIndex = (gradeIRI: any) => {
    if (this.cmtGrades) {
      for (var i = 0; i < this.cmtGrades.length; i++) {
        var cmtGrade = this.cmtGrades[i];
        if (cmtGrade.gradeIRI == gradeIRI) {
          return i;
        }
      }
    }
    return -1;
  }

  public saveLocalGrade = () => {

  }

  public doClosePopup = () => {
    this.modalRef.hide();
  }

  public getGradeRange = () => {
    let url = "api/curriculum/graderange";
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.globalVar.gradeListForProductEdit = result.cmtGradeList;
      this.cmtGrades = result.cmtGradeList;
    });
  }

}
