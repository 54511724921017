import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from "underscore";
import { GlobalVariablesService } from '../../services/global-variables/global-variables.service';
import { HttpRestClientService } from '../../services/common/http-rest-client.service';
import { ConfirmationPopupService } from '../../services/confirmation-popup/confirmation-popup.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-edit-curriculum-grade',
  templateUrl: './edit-curriculum-grade.component.html',
  styleUrls: ['./edit-curriculum-grade.component.scss']
})
export class EditCurriculumGradeComponent implements OnInit {
  public popupData: any = {};
  public modalRef: BsModalRef | undefined;

  constructor(
    private actRoute: ActivatedRoute,
    private route: Router,
    private httpRestClient: HttpRestClientService,
    public globalVar: GlobalVariablesService,
    private modalService: BsModalService,
    private confirmationPopup: ConfirmationPopupService
  ) { }

  ngOnInit(): void {
    this.initCurriculumEdit();
  }

  public initCurriculumEdit = () => {
    this.globalVar.activeMainMenu = 'manage';
    this.globalVar.curriculaEditBean = {};
    this.preloadIngestData();
    this.globalVar.curriculaEditBean.editTitle = true;
  };

  public preloadIngestData = () => {
    // this.globalVar.loading  = true;
    let url = 'api/curriculum/ingestion'
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.globalVar.curriculaEditBean.subjectFacets = result.subjectFacets;
      this.loadCurriculum();
      // this.globalVar.loading  = false;
    }, (error) => {
      this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.createCurriculumPreloadIngetionDataErrorMessage);
      // this.globalVar.loading  = false;
    });
  }

  public loadCurriculum = () => {
    this.globalVar.loading = true;
    let curriculumID = this.actRoute.snapshot.params.curriculumID;
    let url = 'api/curriculum/' + curriculumID + '/metadata?extended=' + true;
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.globalVar.loading = false;
      this.globalVar.curriculaEditBean.curriculaPreviewBean = result;
      let customCurriculumTitle = (!_.isUndefined(this.globalVar.curriculaEditBean.curriculaPreviewBean.displayTitle)) ? (this.globalVar.curriculaEditBean.curriculaPreviewBean.displayTitle) : '';
      this.globalVar.curriculaEditBean.curriculaPreviewBean.customCurriculumTitle = customCurriculumTitle;
      this.globalVar.curriculaEditBean.curriculaPreviewBean.reminingTitle = (this.globalVar.curriculaEditBean.curriculaPreviewBean.curriculumTitle).replace(customCurriculumTitle, '');
      if (this.globalVar.curriculaEditBean.curriculaPreviewBean.status != this.globalVar.languageString.resources.manageTab.manageCurriculumNewLabel) {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.manageCurriculumNotInDraftMessage);
        // $window.history.back();
      }
    }, (error) => {
      this.confirmationPopup.showErrorMessage('Error loading data');
      this.globalVar.loading = false;
    })
  }

  public editCustomTitle = () => {
    this.globalVar.curriculaEditBean.editTitle = false;
  }

  public saveCustomTitle = () => {
    let url = 'api/curriculum/loc-structure/updateTitle?name=' + this.globalVar.curriculaEditBean.curriculaPreviewBean.customCurriculumTitle + '&iri=' + this.globalVar.curriculaEditBean.curriculaPreviewBean.curriculumId;
    const headers: any = {
      responseType: 'text'
    };
    this.httpRestClient.postForUrl(url, undefined, headers).subscribe((result) => {
      this.globalVar.curriculaEditBean.editTitle = true;
      this.globalVar.reloadCurrentUrl();
    }, (error) => {
      this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorMessage + this.globalVar.languageString.resources.manageTab.curriculumErrorInUpdating + " " + this.globalVar.languageString.resources.manageTab.courseLabel);
    });
  }

  public cancelCustomTitle = () => {
    this.globalVar.curriculaEditBean.editTitle = true;
  }

  public goToCurriculumPreview = () => {
    let curriculumID = this.actRoute.snapshot.params.curriculumID;
    if (curriculumID) {
      this.route.navigate(["curriculum/" + curriculumID]);
    }
  };

  public goToDraftsList = () => {
    this.route.navigate(["curriculum/list/new"]);
  };

  public goToCurriculumEditMetadata = () => {
    let curriculumID = this.actRoute.snapshot.params.curriculumID;
    if (curriculumID) {
      this.route.navigate(["curriculum/" + curriculumID + "/edit/metadata"]);
    }
  }

  public confirmDeleteGrade = () => {
    let data = this.popupData.dataTemp;
    let url = 'api/curriculum/loc-structure/' + data.curriculumID + '/localgrade?localGradeIRI=' + data.localGradeIRI;
    const headers: any = {
      responseType: 'text'
    };
    this.httpRestClient.deleteForUrl(url, headers, undefined).subscribe((result) => {
      this.globalVar.reloadCurrentUrl();
    }, (error) => {
      this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorInDeletingLocalGrade);
    })
  }

  public deleteLocalGrade = (localGradeIRI: any, gradeName: any, template: any) => {
    let curriculumID = this.actRoute.snapshot.params.curriculumID;
    if (curriculumID && localGradeIRI) {
      this.popupData.dataTemp = {
        localGradeIRI: localGradeIRI,
        curriculumID: curriculumID
      }
      this.popupData.confirmationMsg = this.globalVar.languageString.resources.manageTab.curriculumLocalGradeDeletedSuccessfullyMessage;
      this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.curriculumLocalGradeConfirmMessageForDelete + " " + gradeName + " " + this.globalVar.languageString.resources.manageTab.curriculumLocalGradeWillDeletedMessage;
      this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.curriculumMessageToDeleteLocalGrade;
      this.modalRef = this.modalService.show(template);
    }
  }

  public moveLocalGradeUp = (localGradeIRI: any) => {
    this.reorderLocalGrade(localGradeIRI, this.globalVar.languageString.resources.manageTab.curriculumMoveUpIconLabel);
  };

  public moveLocalGradeDown = (localGradeIRI: any) => {
    this.reorderLocalGrade(localGradeIRI, this.globalVar.languageString.resources.manageTab.curriculumMoveDownIconLabel);
  };

  public reorderLocalGrade = (localGradeIRI: any, orderType: any) => {
    let curriculumID = this.actRoute.snapshot.params.curriculumID;
    if (curriculumID && localGradeIRI && orderType) {
      const headers: any = {
        responseType: 'text'
      };
      let url = 'api/curriculum/loc-structure/' + curriculumID + '/localgrade/reorder?localGradeIRI=' + localGradeIRI + '&orderType=' + orderType;
      this.httpRestClient.postForUrl(url, undefined, headers).subscribe((result) => {
        this.globalVar.reloadCurrentUrl();
      }, (error) => {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorInReorderGradeMessage);
      })
    }
  }

  public addNewLocalGrade = (template: any) => {
    let createItemBean = {
      addItem: true,
      gradeName: undefined,
      gradeMinIRI: undefined,
      gradeMaxIRI: undefined,
      localGradeName: undefined
    };
    this.popupData = createItemBean;
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass("addLocalGradePopup");
  }

  public addLocalGrade = (standardGradeBean: any, template: any) => {
    let gradeMinIRI = standardGradeBean.gradeMinIRI;
    let gradeMaxIRI = standardGradeBean.gradeMaxIRI;
    let createItemBean = {
      "gradeMinIRI": gradeMinIRI,
      "gradeMaxIRI": gradeMaxIRI,
      "localGradeName": undefined,
      "addItem": true
    };
    this.popupData = createItemBean;
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass("addLocalGradePopup");
  }

  public updateLocalGrade = (gradePreviewBean: any, standardGradeBean: any, template: any) => {
    let gradeIRI = gradePreviewBean.gradeIRI;
    let gradeName = gradePreviewBean.gradeName;
    let gradeMinIRI = standardGradeBean.gradeMinIRI;
    let gradeMaxIRI = standardGradeBean.gradeMaxIRI;
    let createItemBean = {
      "gradeIRI": gradeIRI,
      "localGradeName": gradeName,
      "gradeMinIRI": gradeMinIRI,
      "gradeMaxIRI": gradeMaxIRI,
      "addItem": false
    };
    this.popupData = createItemBean;
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass("addLocalGradePopup");
  }

  public saveLocalGrade = () => {
    let curriculumID = this.actRoute.snapshot.params.curriculumID;
    let createItemBean = this.popupData;
    if (curriculumID && createItemBean && createItemBean.gradeMinIRI && createItemBean.gradeMaxIRI && createItemBean.localGradeName) {
      let addItem = createItemBean.addItem;
      let gradeMinIRI = createItemBean.gradeMinIRI;
      let gradeMaxIRI = createItemBean.gradeMaxIRI;
      let localGradeName = createItemBean.localGradeName;
      let gradeIRI = createItemBean.gradeIRI;
      const headers: any = {
        responseType: 'text'
      };
      if (addItem) {
        let url = 'api/curriculum/loc-structure/' + curriculumID + '/localgrade?localGradeName=' + localGradeName + '&gradeMinIRI=' + encodeURIComponent(gradeMinIRI) + '&gradeMaxIRI=' + encodeURIComponent(gradeMaxIRI);
        this.httpRestClient.putForUrl(url, headers, undefined).subscribe((result) => {
          this.modalRef?.hide();
          this.globalVar.reloadCurrentUrl();
        }, (error) => {
          if (error.status == 200) {
            this.modalRef?.hide();
            this.globalVar.reloadCurrentUrl();
          }
          else {
            this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorMessage + (addItem ? this.globalVar.languageString.resources.manageTab.curriculumErrorInCreating : this.globalVar.languageString.resources.manageTab.curriculumErrorInUpdating) + " " + this.globalVar.languageString.resources.manageTab.productLabel);
          }
        });

      } else {
        let url = 'api/curriculum/loc-structure/' + curriculumID + '/localgrade?localGradeName=' + localGradeName + '&gradeMinIRI=' + encodeURIComponent(gradeMinIRI) + '&gradeMaxIRI=' + encodeURIComponent(gradeMaxIRI) + '&localGradeIRI=' + encodeURIComponent(gradeIRI);
        this.httpRestClient.postForUrl(url, undefined, headers).subscribe((result) => {
          this.modalRef?.hide();
          this.globalVar.reloadCurrentUrl();
        }, (error) => {
          if (error.status == 200) {
            this.modalRef?.hide();
            this.globalVar.reloadCurrentUrl();
          }
          else {
            this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorMessage + (addItem ? this.globalVar.languageString.resources.manageTab.curriculumErrorInCreating : this.globalVar.languageString.resources.manageTab.curriculumErrorInUpdating) + " " + this.globalVar.languageString.resources.manageTab.productLabel);
          }
        });
      }
    }
  }

  public editLocalGradeTopics = (localGradeIRI: object) => {
    let curriculumID = this.actRoute.snapshot.params.curriculumID;
    if (curriculumID && localGradeIRI) {
      this.route.navigate(['/curriculum/' + curriculumID + '/edit/list/', localGradeIRI]);
    }
  }

}
