import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpRestClientService } from 'src/app/services/common/http-rest-client.service';
import { ConfirmationPopupService } from 'src/app/services/confirmation-popup/confirmation-popup.service';
import { GlobalVariablesService } from 'src/app/services/global-variables/global-variables.service';
import { EditTopicComponent } from 'src/app/components/edit-topic/edit-topic.component';
import * as _ from "underscore";

@Component({
  selector: 'app-substitution-set',
  templateUrl: './substitution-set.component.html',
  styleUrls: ['./substitution-set.component.scss']
})
export class SubstitutionSetComponent implements OnInit {
  public selectedSubTab: String = '';
  public substitutionObj: any = {
    selectedSubs: "",
    substitutionName: ""
  };
  public popupData: any = {};
  public selectedSubstitution: any = '';
  public selectedSubsGrade: any;
  public selectedGrade: any = "";
  public selectedLocalGrade: any = "";
  public origTopicList: any;
  public curriculumID: String = this.actRoute.snapshot.params.curriculumID;
  public selectedCurriculum: any;
  public currGrades: any;
  public listedsubs: any;
  public substitutionvalues: any;
  public istopicsLoading: boolean = false;
  public selectedSubsvalue: any;
  public previousOrderId: any;
  public modalRef: BsModalRef | undefined;
  @ViewChild('deleteTopicConfirmation') private deleteTopicConfirmation: TemplateRef<any> | undefined;
  loading: boolean = false;
  loadUploader = true;
  loadUplaoder = false;

  constructor(
    private actRoute: ActivatedRoute,
    private route: Router,
    private httpRestClient: HttpRestClientService,
    public globalVar: GlobalVariablesService,
    private modalService: BsModalService,
    private confirmationPopup: ConfirmationPopupService,
    public editTopicComp: EditTopicComponent
  ) { }

  ngOnInit(): void {
    this.getSubsTab('uploadSub');
    this.loadCurriculum();
    this.loadSubstitution();
  }

  ngAfterViewChecked(): void {
    if (this.currGrades && this.listedsubs) {
      this.globalVar.loading = false;
    } else {
      this.globalVar.loading = true;
    }
  }

  public getSubsTab = (tabName: String) => {
    this.selectedSubTab = _.clone(tabName);
    this.substitutionObj = {};
    this.selectedSubsGrade = {};
    this.origTopicList = {};
    this.selectedSubstitution = '';
  }

  public getUploadSubsTab = (tabName: String) => {
      this.ngOnInit();
  }

  public loadCurriculum = () => {
    let url = 'api/curriculum/' + this.curriculumID + '/metadata?extended=true';
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.selectedCurriculum = result;
      this.currGrades = result.standardGradeList;
    }, (error) => {
      this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorInLoadingMessage);
    });
  }

  public loadSubstitution = () => {
    let url = "api/subset/getsubsetlist?curriculumIRI=" + this.curriculumID;
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.listedsubs = result.data;
    }, (error) => {
      this.confirmationPopup.showErrorMessage("Unable to get substitution set");
    });
  }

  public checkForDuplicateName = (subsName: any) => {
    let subsList = _.clone(this.listedsubs);
    let selectedSubs = _.find(subsList, function (item) {
      return item.subSetName.toLowerCase() === subsName.toLowerCase();
    });
    return (selectedSubs && selectedSubs.subSetName) ? true : false;
  }

  public createSubstitution = () => {
    let subsName = this.substitutionObj.substitutionName;
    if (this.checkForDuplicateName(subsName)) {
      this.confirmationPopup.showErrorMessage("Substitution set name already exist. Please provide different name.");
    } else if (!subsName) {
      this.confirmationPopup.showErrorMessage("Please enter substitution name to create substitution set");
    } else {
      // this.globalVar.loading = true;
      let url = "api/subset/createsubset?curriculumIRI=" + this.curriculumID + "&substitutionSetName=" + subsName;
      // const headers:any = {
      //   responseType: 'text'
      // };
    this.loading = true;
      this.httpRestClient.postForUrl(url, undefined, undefined).subscribe((result) => {
        this.loading = false;
        if (result.conversionSuccessful) {
          this.confirmationPopup.showSuccessMessage("Substitution set created successfully.");
          this.substitutionObj = {};
          this.selectedSubsGrade = {};
          this.origTopicList = {};
          this.loadSubstitution();
          // this.globalVar.loading = false;
        } else {
          this.confirmationPopup.showErrorMessage("Unable to create substitution set");
        }
      }, (error) => {
        this.loading = false;
        this.confirmationPopup.showErrorMessage("Unable to create substitution set");
      });
    }
  }

  public assignSubsToParent = (selectedSubstitution: any) => {
    this.substitutionObj.selectedSubs = selectedSubstitution;
  }

  public getSubsname = (subsIRI: any) => {
    let listedSubs = _.clone(this.listedsubs);
    let selectedSubs = _.find(listedSubs, function (item) {
      return item.subSetIRI === subsIRI;
    });
    return (selectedSubs?.subSetName) ? selectedSubs.subSetName : "";
  }

  public continueExportSubs = () => {
    let subsIRI = this.substitutionObj.selectedSubs;
    let subsName = this.getSubsname(subsIRI);
    let url = "api/subset/export?curriculumIRI=" + this.curriculumID + "&subSetIRI=" + subsIRI + "&subSetName=" + subsName;
    const headers: any = {
      responseType: 'text'
    };
    // this.globalVar.loading = true;
    this.loading = true;
    this.httpRestClient.postForUrl(url, undefined, headers).subscribe((result) => {
      this.loading = false;
      this.istopicsLoading = false;
      if (typeof result === 'boolean') {
        if (result) {
          this.confirmationPopup.showSuccessMessage("Export substitution set triggered successfully. Excel file will be generated and send to your email.");
        } else {
          this.confirmationPopup.showErrorMessage("Export substitution set has been failed.");
        }
      } else {
        if (result === 'true') {
          this.confirmationPopup.showSuccessMessage("Export substitution set triggered successfully. Excel file will be generated and send to your email.");
        } else {
          this.confirmationPopup.showErrorMessage("Export substitution set has been failed.");
        }
      }
    }, (error) => {
      this.loading = false;
      this.confirmationPopup.showErrorMessage("Unable to export substitution set");
      // this.globalVar.loading = true;
    });
  }

  public exportSubstitution = (fromTab: any) => {
    if (fromTab === "uploadSub") {
      this.continueExportSubs();
    } else if (fromTab === "updateSub") {
      if (this.substitutionObj.selectedSubs || this.selectedSubstitution) {
        this.continueExportSubs();

      } else {
        this.confirmationPopup.showErrorMessage("Please select a substitution set to export");

      }
    }
  }

  public loadDefaultTopics = () => {
    let localGradeIRI = "";
    if (!this.substitutionObj.selectedSubs) {
      if (!this.selectedSubstitution) {
        this.confirmationPopup.showErrorMessage("Please select a substitution set to edit");
      }
    } else if (!this.selectedSubsGrade.locGrade && !this.selectedSubsGrade.grade) {
      this.confirmationPopup.showErrorMessage("Please select a grade to edit");
    } else if (!this.selectedSubsGrade.locGrade && this.selectedSubsGrade.grade && this.selectedSubsGrade.grade.gradeBeanList && this.selectedSubsGrade.grade.gradeBeanList.length > 1) {
      this.confirmationPopup.showErrorMessage("Please select a local grade to edit");
    } else {
      this.istopicsLoading = true;
      if (this.selectedSubsGrade.locGrade) {
        // localGradeIRI = this.selectedSubsGrade.locGrade.gradeIRI;
        // localGradeIRI = this.selectedSubsGrade.locGrade;
        localGradeIRI = this.selectedSubsGrade.grade.gradeBeanList[0].gradeIRI;
      } else {
        localGradeIRI = this.selectedSubsGrade.grade.gradeBeanList[0].gradeIRI;
      }
      let url = 'api/curriculum/loc-structure/' + this.curriculumID + '/loc/list?localGradeIRI=' + localGradeIRI;
      this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
        this.origTopicList = result;
        if (this.substitutionvalues?.subSetId && this.substitutionvalues?.subSetId === this.substitutionObj.selectedSubs) {
          var selectedGrade = this.selectedSubsGrade.locGrade ? this.selectedSubsGrade.locGrade : this.selectedSubsGrade.grade.gradeBeanList[0];
          this.getsubsGradeValue(selectedGrade);
          this.istopicsLoading = false;
        } else {
          this.getSelectedSubstitution();
        }
      }, (error) => {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorInTopicLoadingMessage);
      });
    }
  }

  public getsubsGradeValue = (selGrade: any) => {
    selGrade = typeof (selGrade) == 'string' ? selGrade : selGrade.grade;
    var subsValues = _.clone(this.substitutionvalues);
    var selectedGradeSubs = _.find(subsValues?.subGradeList, function (item) {
      return (item.grade.indexOf(selGrade) !== -1);
    });
    this.selectedSubsvalue = _.clone(selectedGradeSubs);
    if (this.origTopicList.topicsList.length > 0) {
      this.getModSubValues(this.origTopicList?.topicsList);
    }
  }

  public getModSubValues = (topicList: any) => {
    let subsTopicLists = _.clone(this.selectedSubsvalue?.topicsList);
    var modGradeId = _.clone(this.selectedSubsvalue?.gradeId);
    var modGrade = _.clone(this.selectedSubsvalue?.grade);
    _.each(topicList, (data, index) => {
      let subsObj = _.find(subsTopicLists, function (item) {
        return (data.topicId.indexOf(item.topicId) !== -1);
      });
      if (subsObj) {
        data.substitutionTopicId = subsObj.substitutionTopicId;
        data.substitutionTopicDesc = subsObj.substitutionTopicDesc;
        data.substitutionStandardCode = subsObj.substitutionStandardCode;
        data.substitutionId = _.clone(this.substitutionObj.selectedSubs);
        data.substitutionGradeTitle = modGrade;
        data.subSetGradeIRI = modGradeId;
        data.order = subsObj.order;
        data.previousOrderId = this.previousOrderId;
        this.previousOrderId = subsObj.order;
      } else {
        data.substitutionTopicId = "";
        data.substitutionTopicDesc = "";
        data.substitutionStandardCode = "";
        data.substitutionId = _.clone(this.substitutionObj.selectedSubs);
        data.substitutionGradeTitle = modGrade;
        data.subSetGradeIRI = modGradeId;
        data.previousOrderId = this.previousOrderId;
      }
      if (data.topicsList.length > 0) {
        this.getModSubValues(data.topicsList);
      }
    });
  }

  public getSelectedSubstitution = () => {
    let subSetIRI = this.substitutionObj?.selectedSubs;
    let url = "api/subset/getsubset?subSetIRI=" + subSetIRI;
    let selectedGrade = this.selectedSubsGrade.locGrade ? this.selectedSubsGrade.locGrade : this.selectedSubsGrade.grade.gradeBeanList[0];
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.substitutionvalues = result;
      this.getsubsGradeValue(selectedGrade);
      this.istopicsLoading = false;
    }, (error) => {
      this.confirmationPopup.showErrorMessage("Unable to get substitution set values");
    });
  }

  public setGrade = (selcGrade: any) => {
    this.selectedSubsGrade.grade = _.find(this.currGrades, (item) => {
      return (item.grade == selcGrade);
    });
  }

  public resetLocalGrade = (selcGrade: any) => {
    // this.selectedSubsGrade.grade = selcGrade;
  }

  public setLocGrade = (selcLocGrade: any) => {
    this.selectedSubsGrade.locGrade = selcLocGrade;
  }

  public updateSingleSubs = (editSubsPopData: any) => {
    if (editSubsPopData.substitutionTopicId) {
      if (!editSubsPopData.substitutionTopicDesc) {
        this.confirmationPopup.showErrorMessage("Please provide substitution topic description to update.");
      } else {
        editSubsPopData.substitutionTopicDesc = (editSubsPopData.substitutionTopicDesc).replace('<p>', '');
      editSubsPopData.substitutionTopicDesc = (editSubsPopData.substitutionTopicDesc).replace('</p>', '');
      editSubsPopData.substitutionTopicDesc = (editSubsPopData.substitutionTopicDesc).replace('</p>', '');
      editSubsPopData.substitutionTopicDesc = (editSubsPopData.substitutionTopicDesc).replace('<strong>', '<b>');
      editSubsPopData.substitutionTopicDesc = (editSubsPopData.substitutionTopicDesc).replace('</strong>', '</b>');
      editSubsPopData.substitutionTopicDesc = (editSubsPopData.substitutionTopicDesc).replace('<em>', '<i>');
      editSubsPopData.substitutionTopicDesc = (editSubsPopData.substitutionTopicDesc).replace('</em>', '</i>');
      editSubsPopData.substitutionTopicDesc = (editSubsPopData.substitutionTopicDesc).replace('<span style=\"text-decoration: underline;\">', '<u>');
      editSubsPopData.substitutionTopicDesc = (editSubsPopData.substitutionTopicDesc).replace('</span>', '</u>');

        let url = `api/subset/updatesubsettopic?subSetIRI=` + editSubsPopData.substitutionId +
          `&subSetTopicIRI=` + editSubsPopData.substitutionTopicId +
          `&subSetTopicDesc=` + editSubsPopData.substitutionTopicDesc +
          `&subSetTopicCode=` + editSubsPopData.substitutionStandardCode;
        const headers: any = {
          responseType: 'text'
        };
        this.httpRestClient.postForUrl(url, undefined, headers).subscribe((result) => {
          this.confirmationPopup.showSuccessMessage("Substitution topic updated successfully.");
          this.substitutionvalues = undefined;
          this.loadDefaultTopics();
          this.modalService.hide();
        }, (error) => {
          this.confirmationPopup.showErrorMessage("Unable to update substitution topic");
        })
      }
    } else {
      this.saveSingleSubs(editSubsPopData);
    }
  }

  public saveSingleSubs = (editSubsPopData: any) => {
    if (!editSubsPopData.substitutionTopicDesc) {
      this.confirmationPopup.showErrorMessage("Please provide substitution topic description to update.");
    } else {
      let topicId = editSubsPopData.topicId ? editSubsPopData.topicId.split('/')[editSubsPopData.topicId.split('/').length - 1] : "";
      let standardCode = editSubsPopData.standardCode ? editSubsPopData.standardCode : "";
      let url = `api/subset/createsubsettopic?subSetIRI=` + editSubsPopData.substitutionId +
        `&subSetTopicOrder=` + (editSubsPopData.previousOrderId + 1) +
        `&subSetTopicDesc=` + editSubsPopData.substitutionTopicDesc + `&subSetTopicCode=` + editSubsPopData.substitutionStandardCode +
        `&topicDesc=` + editSubsPopData.topicDesc + `&topicId=` + editSubsPopData.topicId + `&standardCode=` + standardCode +
        `&subSetGradeIRI=` + editSubsPopData.subSetGradeIRI + `&substitutionGradeTitle=` + editSubsPopData.substitutionGradeTitle;
      const headers: any = {
        responseType: 'text'
      };
      this.httpRestClient.postForUrl(url, undefined, headers).subscribe((result) => {
        this.confirmationPopup.showSuccessMessage("Substitution topic Saved successfully.");
        this.substitutionvalues = undefined;
        this.loadDefaultTopics();
        this.modalService.hide();
      }, (error) => {
        this.confirmationPopup.showErrorMessage("Unable to update substitution topic");
      });
    }
  }

  public deleteSubstitution = () => {
    this.popupData.popupMessage = "Warning: Selected substitution set will get deleted.\nAre you sure you want to delete?";
    this.popupData.buttonLabel = "Yes";
    this.modalRef = this.modalService.show(this.deleteTopicConfirmation ? this.deleteTopicConfirmation : "");
  }

  public confirmDeleteSubs = () => {
    let subSetIRI = this.substitutionObj.selectedSubs;
    let url = "api/subset/deletesubset?curriculumIRI=" + this.curriculumID + "&subSetIRI=" + subSetIRI;
    const headers: any = {
      responseType: 'text'
    };
    this.globalVar.loading = true;
    this.httpRestClient.deleteForUrl(url, headers, undefined).subscribe((result) => {
      this.confirmationPopup.showSuccessMessage("Substitution set deleted successfully.");
      this.substitutionObj = {};
      this.selectedSubsGrade = {};
      this.origTopicList = {};
      this.selectedGrade = "";
      this.selectedLocalGrade = "";
      this.loadSubstitution();
      // this.globalVar.loading  = false;
    }, (error) => {
      this.confirmationPopup.showErrorMessage("Unable to delete substitution set");
    });
  }

}
