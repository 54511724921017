import { Component, OnInit, TemplateRef, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpRestClientService } from 'src/app/services/common/http-rest-client.service';
import { PreloadDataService } from 'src/app/services/common/preload-data.service';
import { ConfirmationPopupService } from 'src/app/services/confirmation-popup/confirmation-popup.service';
import { GlobalVariablesService } from 'src/app/services/global-variables/global-variables.service';
import { ManagePageComponent } from 'src/app/components/manage-page/manage-page.component';
//import { ArrayRangePipe } from 'src/app/services/pipe';
import * as _ from "underscore";
import { any, result } from 'underscore';
import { CorrelationService } from 'src/app/services/correlation/correlation.service';

@Component({
  selector: 'correlation-home',
  templateUrl: './correlation-home.component.html',
  styleUrls: ['./correlation-home.component.scss']
})
export class CorrelationHomeComponent implements OnInit {
  correlationAdminScreenView = 'default';
  loading = false;
  isFresh = false;
  isShow = false;
  filterData: any;
  disciplineList: any;
  subject: any = "";
  constructor(
    private actRoute: ActivatedRoute,
    private route: Router,
    private httpRestClient: HttpRestClientService,
    private preLoadData: PreloadDataService,
    public globalVar: GlobalVariablesService,
    private modalService: BsModalService,
    private confirmationPopup: ConfirmationPopupService,
    private correlationService: CorrelationService
  ) { }


  ngOnInit(): void {
    this.correlationService.loading.subscribe(value => {
      if (value) {
        this.loading = true;
      }
      else {
        this.loading = false;
        this.isFresh = false;
      }
    })
    this.getDiscipline()
  }

  getDiscipline() {
    var url = "api/browse/menu";
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.disciplineList = result.subjectBeanList;
    }, (error) => {
      console.log(error);
    });
  }

  getfilterData() {
    this.isFresh = true
  }

  search() {
    if (this.filterData == '') {
      this.isFresh = true
    }
  }

  loadingEvent(event: any) {
    if (event) {
      this.loading = true;
    }
    else {
      this.loading = false;
    }
  }

  showList() {
    this.isShow = true;
  }

  hideList() {
    this.isShow = false;
  }

  refreshList() {
    this.filterData = '';
    this.isFresh = true;
  }
}
