<button type="button" class="popupXClose close pull-right" aria-label="Close" (click)="doClosePopup()">×
</button>
<div *ngIf="createItemBean.forAuthority" class="addorEditAuthSection">
    <div [hidden]="!isAddFlagTrue" class="font_20_666666 color3333">
        <span>{{globalVar.languageString.resources.manageTab.addAuthorityLabel}} for {{country.facetName}}</span>
    </div>
    <div [hidden]="isAddFlagTrue" class="font_20_666666 color3333">
        <span>{{globalVar.languageString.resources.manageTab.editAuthorityLabel}}</span>
    </div>
    <div class="marginTop30">
        <div class="addOrEditAuthCls1">
            <div class="marginBottom10 validationCheck">
                <div class="font_15_666666 fontBold">{{globalVar.languageString.resources.manageTab.authorityNameLabel}}
                </div>
                <input [(ngModel)]="createItemBean.authorityName" type="text"
                    class="form-control addAuthorityInputs ng-invalid-required"
                    placeholder="{{globalVar.languageString.resources.manageTab.authorityNamePlaceholderLabel}}"
                    [required]="true" [ngClass]="{ 'ng-invalid-required' : createItemBean.authorityName == ''}"
                    autofocus="autofocus">
            </div>
            <div class="marginBottom10 validationCheck">
                <div class="font_15_666666 fontBold">{{globalVar.languageString.resources.manageTab.authorityUrlLabel}}
                </div>
                <input id="localGradeName" [(ngModel)]="createItemBean.authorityUrl" type="url"
                    class="form-control addAuthorityInputs"
                    placeholder="{{globalVar.languageString.resources.manageTab.authorityUrlPlaceholderLabel}}"
                    [required]="true" [ngClass]="{ 'ng-invalid-required' : createItemBean.authorityUrl == ''}"
                    pattern="^((http|https)://)[a-zA-Z0-9@:%._\\+~#?&//=]{1,256}" autofocus="autofocus">
            </div>
        </div>

        <div class="form-horizontal marginTop20">
            <!-- <input type="submit" *ngIf="isAddFlagTrue" [disabled]="!createItemBean.authorityName || !createItemBean.authorityUrl" value="{{globalVar.languageString.resources.others.saveButtonLabel}}" class="cmButton cmButtonFull-green cmButton-standard"
                (click)="doSuccessFunction(isAddFlagTrue, nodeValue, createItemBean, gettingEventFrom)"
                   />
			<input type="submit" *ngIf="!isAddFlagTrue" [disabled]="!createItemBean.authorityName || !createItemBean.authorityUrl" value="{{globalVar.languageString.resources.others.updateButtonLabel}}" class="cmButton cmButtonFull-green cmButton-standard"
                (click)="doSuccessFunction(isAddFlagTrue, nodeValue, createItemBean, gettingEventFrom)"
                   /> -->
            <input type="submit" *ngIf="isAddFlagTrue"
                [disabled]="!createItemBean.authorityName || !createItemBean.authorityUrl"
                value="{{globalVar.languageString.resources.others.saveButtonLabel}}"
                class="cmButton cmButtonFull-green cmButton-standard"
                (click)="doSuccessFunction(isAddFlagTrue, createItemBean)" />
            <input type="submit" *ngIf="!isAddFlagTrue"
                [disabled]="!createItemBean.authorityName || !createItemBean.authorityUrl"
                value="{{globalVar.languageString.resources.others.updateButtonLabel}}"
                class="cmButton cmButtonFull-green cmButton-standard"
                (click)="doSuccessFunction(isAddFlagTrue, createItemBean)" />
            <a class="cmButton cmButton-url"
                (click)="doClosePopup()">{{globalVar.languageString.resources.others.cancelBtnLabel}}</a>
        </div>

    </div>
</div>

<div *ngIf="createItemBean.forCurriculumSet" class="addOrEditCurrSetSection">
    <div class="font_20_666666 color3333"><span
            [hidden]="!isAddFlagTrue">{{globalVar?.languageString?.resources?.manageTab?.addCurriculumSetLabel}}</span>
    </div>
    <div class="font_20_666666 color3333"><span
            [hidden]="isAddFlagTrue">{{globalVar?.languageString?.resources?.manageTab?.editCurriculumSetLabel}}</span>
    </div>
    <div class="marginTop30">
        <div class="addOrEditCurrCls1 validationCheck">
            <div class="marginBottom10">
                <div class="font_15_666666 fontBold">
                    {{globalVar?.languageString?.resources?.manageTab?.CurriculumSetNameLabel}}</div>
                <input [(ngModel)]="createItemBean.curriculumSetName" type="text"
                    class="form-control addAuthorityInputs"
                    placeholder="{{globalVar?.languageString?.resources?.manageTab?.CurriculumSetNamePlaceholderLabel}}"
                    required="required" [ngClass]="{ 'ng-invalid-required' : createItemBean.curriculumSetName == ''}"
                    autofocus="autofocus">
            </div>
        </div>
        <div class="form-horizontal marginTop20">
            <input type="submit" [hidden]="!isAddFlagTrue" [disabled]="!createItemBean.curriculumSetName"
                value="{{globalVar?.languageString?.resources?.others?.saveButtonLabel}}"
                class="cmButton cmButtonFull-green cmButton-standard"
                (click)="doSuccessFunctionForCurriculumSet(isAddFlagTrue, createItemBean)" />
            <input type="submit" [hidden]="isAddFlagTrue" [disabled]="!createItemBean.curriculumSetName"
                value="{{globalVar?.languageString?.resources?.others?.updateButtonLabel}}"
                class="cmButton cmButtonFull-green cmButton-standard"
                (click)="doSuccessFunctionForCurriculumSet(isAddFlagTrue, createItemBean)" />
            <a (click)="doClosePopup()"
                class="cmButton cmButton-url">{{globalVar?.languageString?.resources?.others?.cancelBtnLabel}}</a>
        </div>

    </div>
</div>