import { Component, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalVariablesService } from '../../services/global-variables/global-variables.service';
import { HttpRestClientService } from '../../services/common/http-rest-client.service';
import { ConfirmationPopupService } from '../../services/confirmation-popup/confirmation-popup.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-view-list',
  templateUrl: './view-list.component.html',
  styleUrls: ['./view-list.component.scss']
})
export class ViewListComponent implements OnInit {

  public filterSubjects: any;
  public filterCountries: any;
  public currSearchKey: String = '';
  public selectedSubjects: String = '';
  public selectedCurriculumType:String = '';
  public selectedDeployToRelease:String = '';
  public selectedCountry: any;
  public modifiedCurrListCount: number = 0;
  public modifiedCurrList: any;
  public filterdListCount: number = 0;
  public filter: any;

  public deployToRelease = [
    {
      key: true,
      name: 'Yes'
    },
    {
      key: false,
      name: 'No'
    }
  ]

  public curriculumTypes = [
    {
      key: 'Academic benchmarks',
      name: 'Academic benchmarks'
    },
    {
      key: 'Customized Academic Benchmarks',
      name: 'Customized Academic Benchmarks'
    },
    {
      key: 'Savvas Custom',
      name: 'Savvas Custom'
    },
  ]

  constructor(
    public globalVar : GlobalVariablesService,
    private actRoute : ActivatedRoute,
    private httpRestClient : HttpRestClientService,
    private confirmationPopup : ConfirmationPopupService,
    private route: Router,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.loadApprovedCountryCurriculumList();
    this.preloadIngestData()
  }
  ngDoCheck(): void {
    // this.updateModifiedListCount();
  }
  // public updateModifiedListCount = () => {
  //   if(this.modifiedCurrList && this.modifiedCurrList[0] && this.modifiedCurrList[0].modifiedCurriculaInfoList ) {
  //     this.modifiedCurrListCount = this.modifiedCurrList[0].modifiedCurriculaInfoList.length;
  //   } else {
  //     this.modifiedCurrListCount = 0;
  //   }
  // }

  public loadApprovedCountryCurriculumList = () => {
    this.globalVar.noApprovedCurricula = true;
    this.globalVar.country = this.actRoute.snapshot.params.countryName;
    this.selectedCountry = this.actRoute.snapshot.params.countryName;
    if (!this.globalVar.curriculaListBean || (this.globalVar.curriculaListBean && this.globalVar.curriculaListBean.curriculumCountryListBean[0] && this.globalVar.curriculaListBean.curriculumCountryListBean[0].curriculaInfoList.length == 0)) {
      this.tryGettingManualy();
    } else {
      this.updateListSize();
    }
  };

  public preloadIngestData = () => {
    this.globalVar.loading = true;
    let url = 'api/curriculum/ingestion'
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.filterSubjects = result.subjectFacets;
      this.filterCountries = result.countryFacets;
      this.globalVar.loading = false;
      // this.makeActiveTabVisible();
    }, (error) => {
      this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.createCurriculumPreloadIngetionDataErrorMessage);
      this.globalVar.loading = false;
    });
  }

  public tryGettingManualy = () => {
    let url = 'api/view/list/approved/' + this.globalVar.country;
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.globalVar.curriculaListBean = result;
      if (this.globalVar.curriculaListBean.curriculumCountryListBean[0].curriculaInfoList.length == 0) {
        this.globalVar.noApprovedCurricula = false;
      }
      this.updateListSize();
    }, (error) => {
      if (error.status == 500) {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
      }
    });
  }

  public updateListSize = () => {
    let listSize = 0;
    for (let x = 0; x < this.globalVar.curriculaListBean.curriculumCountryListBean.length; x++) {
      var curriculumCountryListBean = this.globalVar.curriculaListBean.curriculumCountryListBean[x];
      listSize = listSize + curriculumCountryListBean.curriculaInfoList.length;
    }
    this.globalVar.curriculaListBean.listSize = listSize;
  }

  public goBack = () => {
    window.history.back();
  }

  public filterByCounSub = (from:String, contents:any , country:String, subject:String ,curriculumType:any,deployToRelease:any, searchKey: String, parentBean: any) => {
    let modifiedCurrList: any = [];
    if (contents && contents.length >= 0) {
      for (let i = 0; i < contents.length; i++) {
        let cont = contents[i];
        if (contents[i].curriculaInfoList) {
          if (contents[i].country === country && this.isFilterAvailable(contents[i].curriculaInfoList, searchKey)) {
            modifiedCurrList.push(cont);
          }
        } else {
          var title = contents[i].curriculumTitle;
          let curriculum = contents[i]?.curriculumType
          let delopyRelease = deployToRelease === 'Yes' ? true : false;
          if((title?.toUpperCase().includes(searchKey?.toUpperCase()))&& (title.indexOf(subject) !== -1)&& (curriculum?.indexOf(curriculumType) !== -1) && (((curriculumType != "" && curriculum) || curriculumType === ""))&& (deployToRelease === "" || delopyRelease === contents[i].deployedToRealize)) {
            modifiedCurrList.push( cont );
          }
        }
      }
      this.getmodifiedListLength();
    } 
    if(from === 'parent') {
      this.modifiedCurrList = modifiedCurrList;
    } else {
      parentBean.modifiedCurriculaInfoList = modifiedCurrList;
    }
    return modifiedCurrList;
  }

  public getHighlightedText(title: any, searchKey: any) {
    if (!searchKey) {
      return title;
    }
    return title.replace(new RegExp(searchKey, "gi"), (match: any) => {
      return '<span class="highlightText">' + match + '</span>';
    });
  }

  public isFilterAvailable = (arr: any, key: String) => {
    var isAvailable = false;
    for (var i = 0; i < arr.length; i++) {
      var title = arr[i].curriculumTitle;
      if (title.toUpperCase().includes(key.toUpperCase())) {
        isAvailable = true;
        break;
      }
    }
    return isAvailable;
  }

  public getmodifiedListLength = () => {
    var listSize = 0;
    if (this.modifiedCurrList) {
      // for (var x = 0; x < this.modifiedCurrList.length; x++) {
      //   var curriculumCountryListBean = this.modifiedCurrList[x];
      //   if (curriculumCountryListBean.modifiedCurriculaInfoList) {
      //     listSize = listSize + curriculumCountryListBean.modifiedCurriculaInfoList.length;
      //   }
      // }
    }
    this.modifiedCurrList?.forEach((item:any)=>{
      item.modifiedCurriculaInfoList? listSize = listSize + item.modifiedCurriculaInfoList.length:''
    })
    this.filterdListCount = listSize;
  }

  public goToCurriculum = (curr: any, clone: boolean) => {
    this.globalVar.isCurriculumClone = clone;
    this.route.navigate(['view/curriculum/' + curr.curriculumId]);
  }

  getInnerHtml(description: any) {
    if (description) {
      return this.sanitizer.bypassSecurityTrustHtml(description);

    }
    else {
      return this.sanitizer.bypassSecurityTrustHtml('');
    }
  }



}
