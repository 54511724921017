<div class="loader-container" *ngIf="loading">
	<div class="loading-overlay"></div>
	<div class="loading-icon">
		<img src="assets/images/loading_100x100.gif" />
	</div>
</div>
<div class="intermediary-section">
	<div class="mapping-alignment-label-container">
		<span class="mapping-alignment-label">Correlations Reporting Dashboard</span>
	</div>
	<div *ngIf="correlationAdminScreenView === 'default'">
		<div [ngSwitch]="correlationAdminScreenView" ngAnimate="'wave'">
			<div *ngSwitchCase="'default'">
				<div class="dummyBlackForMble"></div>
				<div class="row-fluid correlation-row correlation-tabs-container" style="display: flex;">
					<span class="postionRoundIcon popupCloseCorr smallDeleteIcon marginTop5px" title="Close"></span>
					<correlation-left-side style="display: contents;"></correlation-left-side>
					<correlation-allign class="correlation-row-gap" style="display: contents;"></correlation-allign>
					<correlation-right-side style="display: contents;"></correlation-right-side>
				</div>
				<correlation-save></correlation-save>
				<div class="correlation-report-btn-container">
					<div class="correlation-filter-container" *ngIf="isShow">
						<label class="correlation-view-label" value="View:">View:</label>
						
						<select name="filter" id="filter"[(ngModel)]="subject" class="disciplineSearch" (change)="getfilterData()">
						<option value="" selected >Choose Discipline</option>
						<option value="" selected >None</option>
						<option *ngFor="let subjectList of disciplineList" [ngValue]="subjectList">
							{{subjectList.subjectDesc}}</option>
						</select> 
					</div>
					<button *ngIf="isShow" type="button" style="margin-left: 10px"
						class="correlation-button correlation-report-lo-button" (click)="refreshList()">REFRESH LIST
					</button>
					<button *ngIf="isShow" type="button" style="margin-left: 10px"
						class="correlation-button correlation-report-lo-button" (click)="hideList()">HIDE LIST
					</button>
					<button *ngIf="!isShow" type="button" class="correlation-button correlation-report-lo-button"
						(click)="showList()">SHOW LIST
					</button>
					<!-- <input data-ng-show="!showListFlag" type="button" class="correlation-button correlation-report-lo-button" title="{{applicationCache.languageString.resources.correlationDashboardTab.reportShowBtnToolTip}}" data-ng-click="showReportList(true)" value="{{applicationCache.languageString.resources.correlationDashboardTab.reportShowBtnLabel | uppercase}}"/> -->
				</div>
				<!-- <input type="text" [(ngModel)]="filterData" (change)="getfilterData()" /> -->
				<div *ngIf="isShow" id="correlationsDatatablesId_filter" class="dataTables_filter"><label>
						<img class="correlation-search-icon" 
							src="assets/images/search_icon_black.png" (click)="getfilterData()">
						<input type="search" class="filter-input" placeholder=""  [(ngModel)]="filterData" (search)="search()"  (change)="getfilterData()"></label>
				</div>
				<correlation-list [isFresh]="isFresh" [filterData]="filterData" [subject]="subject" *ngIf="isShow"></correlation-list>
				<!-- Save report section -->
			</div>
		</div>
	</div>
</div>