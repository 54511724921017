import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpRestClientService } from 'src/app/services/common/http-rest-client.service';
import { ConfirmationPopupService } from 'src/app/services/confirmation-popup/confirmation-popup.service';
import { GlobalVariablesService } from 'src/app/services/global-variables/global-variables.service';
import { ManagePageComponent } from 'src/app/components/manage-page/manage-page.component';
//import { ArrayRangePipe } from 'src/app/services/pipe';
import * as _ from "underscore";
import { any } from 'underscore';

@Component({
  selector: 'app-view-curriculum',
  templateUrl: './view-curriculum.component.html',
  styleUrls: ['./view-curriculum.component.scss']
})
export class ViewCurriculumComponent implements OnInit {

  public curriculumID: String = this.actRoute.snapshot.params.curriculumID;
  public curriculum: any;
  public disableGradeURI: any;
  public disableLocalGradeUrI: any;
  public disableStandardURL: any;
  public selectedStandardGrade: any;
  public selectedGradeName: any;
  public loadedGradeBeanList: any;
  public selectedStandardGradeMod: any;
  public selectedLocalGradeUri: any;
  public gradePreviewBean: any;
  public gradePreviewBeanArray: any;
  public standardGradeBean: any;
  public downloadUrlForEdit: any;
  public downloadUrl: any;
  public currentCurriculumId: any;
  public generatingXLSForEdit: any;
  public gradeLoadingVisible: any;
  public popupData: any = {};
  public modalRef: any;
  public callForXLSEdit: any;
  public showAlignment: any;
  public displayTitleIdle: any;
  public disableAllURI = false;
  // public showAutoTagProgressBar: any;

  constructor(
    private actRoute: ActivatedRoute,
    private route: Router,
    private httpRestClient: HttpRestClientService,
    public globalVar: GlobalVariablesService,
    private modalService: BsModalService,
    private confirmationPopup: ConfirmationPopupService
  ) { }

  ngOnInit(): void {
    this.initCurriculum();
  }

  public initCurriculum = () => {
    this.showAlignment = true;
    let currentURL = this.route.url.toLowerCase();
    if (currentURL.indexOf('/curriculum/') == 0) {
      this.globalVar.activeMainMenu = 'manage';
      this.loadCurriculum(this.curriculumID, true);
    } else if (currentURL.indexOf('/view/curriculum/') == 0) {
      this.globalVar.activeMainMenu = 'view';
      if (this.globalVar.isCurriculumClone) {
        // this.loadCloneCurriculum(this.curriculumID, false);
      }
      else {
        this.loadCurriculum(this.curriculumID, false);
      }
    } else {
      console.log('Wrong curriculum preview url', currentURL);
    }
  }

  public loadCurriculum = (curriculumID: any, isCurriculumEditable: any) => {
    this.globalVar.loading = true;
    this.loadedGradeBeanList = [];
    this.gradePreviewBeanArray = [];
    let url = isCurriculumEditable ? 'api/curriculum' + '/' + curriculumID + '/preview' : 'api/view/curriculum' + '/' + curriculumID + '/preview';
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.globalVar.loading = false;
      this.curriculum = result;

      this.getProhibitedListValue(this.curriculum.displayTitle)
      this.disableStandardURL = this.curriculum?.unNumberedLowestLevel;
      this.disableGradeURI = this.curriculum?.standardGradeList[0]?.unNumberedLowestLevel;
      this.disableLocalGradeUrI = this.curriculum?.standardGradeList[0]?.gradeBeanList[0]?.unNumberedLowestLevel;
      console.log(result);
      this.curriculum.curriculumEditable = isCurriculumEditable;
      this.displayTitleIdle = true;
      if (this.curriculum?.standardGradeList && this.curriculum?.standardGradeList?.length > 0) {
        let standardGradeBean = this.curriculum.standardGradeList[0];
        this.loadedGradeBeanList = standardGradeBean?.gradeBeanList;
        this.selectedStandardGrade = standardGradeBean?.grade;
        this.selectedStandardGradeMod = this.getFormatedGarde(standardGradeBean?.grade);
        if (this.loadedGradeBeanList?.length > 0) {
          for (let x = 0; x < this.loadedGradeBeanList?.length; x++) {
            this.loadedGradeBeanList[x].columnsSize = 1;
          }
          var gradePreviewBean = this.loadedGradeBeanList[0];
          //this.gradePreviewBeanArray.push(gradePreviewBean);
          this.selectedGradeName = "0-" + gradePreviewBean?.gradeName;
          var uriArray = gradePreviewBean.gradeIRI.split('/');
          this.selectedLocalGradeUri = uriArray[uriArray.length - 1];
        }
      }
      if (this.curriculum?.displayTitle && this.curriculum?.displayTitle != '') {
        this.displayTitleIdle = false;
      }
      //this.updateColumnSize(this.gradePreviewBeanArray);
    }, (error) => {
      this.globalVar.loading = false;
    });
  }

  getProhibitedListValue(displayTitle: any) {
    this.disableAllURI = false;
    let url = 'api/curriculum/getProhibitedDisplaytitles';
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      if (result.length) {
        result.forEach((item: any) => {
          if (item.toUpperCase() === displayTitle.toUpperCase()) {
            this.disableAllURI = true;
          }
        })
      }
    }, (error) => {
      this.globalVar.loading = false;
    });
  }

  public isSelectedGradeName = (index: any, gradeName: any) => {
    return index + "-" + gradeName == this.selectedGradeName;
  }

  /////////// needs to be moved commonly for 'ManagePageComponent' and this component.... Starts here ends blow......

  public goBack = () => {
    window.history.back();
  }

  public getCurriculaStatus = () => {
    if (this.curriculum == undefined) {
      return '';
    }
    var status = this.curriculum.status;
    if (status == undefined) {
      return '';
    }
    return status;
  }

  public isCurriculumEditable = () => {
    return this.curriculum && this.curriculum?.curriculumEditable;
  }

  public showDeleteButton = () => {
    var status = this.getCurriculaStatus();
    return status == this.globalVar?.languageString?.resources?.manageTab?.manageCurriculumNewLabel || (status == this.globalVar?.languageString?.resources?.manageTab?.manageCurriculumIngestedLabel) && this.isCurriculumEditable();
  }

  public showEditButton = () => {
    var status = this.getCurriculaStatus();
    return status == this.globalVar?.languageString?.resources?.manageTab?.manageCurriculumNewLabel && this.isCurriculumEditable();
  }

  public showRejectButton = () => {
    var status = this.getCurriculaStatus();
    return status == this.globalVar?.languageString?.resources?.manageTab?.manageCurriculumIngestedLabel && this.isCurriculumEditable();
  }
  public showApproveButton = () => {
    var status = this.getCurriculaStatus();
    return status == this.globalVar?.languageString?.resources?.manageTab?.manageCurriculumIngestedLabel && this.isCurriculumEditable();
  }
  public showDraftButton = () => {
    var status = this.getCurriculaStatus();
    return status == this.globalVar?.languageString?.resources?.manageTab?.manageCurriculumIngestedLabel && this.isCurriculumEditable();
  }
  public showUndeleteButton = () => {
    var status = this.getCurriculaStatus();
    return status == this.globalVar?.languageString?.resources?.manageTab?.manageCurriculumDeletedLabel && this.isCurriculumEditable();
  }
  public showUnrejectButton = () => {
    var status = this.getCurriculaStatus();
    return status == this.globalVar?.languageString?.resources?.manageTab?.manageCurriculumRejectedLabel && this.isCurriculumEditable();
  }
  public showSendForApprovalButton = () => {
    var status = this.getCurriculaStatus();
    return status == this.globalVar?.languageString?.resources?.manageTab?.manageCurriculumNewLabel && this.isCurriculumEditable();
  }
  public showUnapproveButton = () => {
    var status = this.getCurriculaStatus();
    return status == this.globalVar?.languageString?.resources?.manageTab?.manageCurriculumApprovedLabel && this.isCurriculumEditable();
  }

  public sendForApprovalCurricula = (curr: any, parentIndex: any, index: any, template: TemplateRef<any>) => {
    this.popupData.dataTemp = {
      'curriculumId': curr.curriculumId,
      'curriculumTitle': curr.curriculumTitle,
      'status': this.globalVar.languageString.resources.manageTab.manageCurriculumIngestedMessage,
      'parentIndex': parentIndex,
      'rowIndex': index,
      'redirectUrl': '/curriculum/list/ingested',
    };
    this.popupData.confirmationMsg = "'" + curr.curriculumTitle + this.globalVar.languageString.resources.manageTab.manageCurriculumSentApprovalSuccessMessage;
    this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.manageCurriculumSendToApprovalConfirmMessage + curr.curriculumTitle + "'?"
    this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.manageCurriculumSendToApprovalMessage;
    this.modalRef = this.modalService.show(template);
  }

  public draftCurricula = (curr: any, parentIndex: any, index: any, template: TemplateRef<any>) => {
    this.popupData.dataTemp = {
      'curriculumId': curr.curriculumId,
      'curriculumTitle': curr.curriculumTitle,
      'status': this.globalVar.languageString.resources.manageTab.manageCurriculumNewLabel,
      'parentIndex': parentIndex,
      'rowIndex': index,
      'redirectUrl': '/curriculum/list/new'
    };
    this.popupData.confirmationMsg = "'" + curr.curriculumTitle + this.globalVar.languageString.resources.manageTab.manageCurriculumDraftSuccessMessage;
    this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.manageCurriculumSendToDraftConfirmMessage + curr.curriculumTitle + "'?";
    this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.manageCurriculumDraftLabel;
    this.modalRef = this.modalService.show(template);
  }

  public approveCurricula = (curr: any, parentIndex: any, index: any, template: TemplateRef<any>) => {
    this.popupData.dataTemp = {
      'curriculumId': curr.curriculumId,
      'curriculumTitle': curr.curriculumTitle,
      'status': this.globalVar.languageString.resources.manageTab.manageCurriculumApprovedLabel,
      'parentIndex': parentIndex,
      'rowIndex': index,
      'redirectUrl': '/curriculum/list/approved'
    };
    this.popupData.confirmationMsg = undefined;
    this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.manageCurriculumApproveConfirmMessage + curr.curriculumTitle + "'?";
    this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.manageCurriculumApproveLabel;
    this.modalRef = this.modalService.show(template);
  }

  public rejectCurricula = (curr: any, parentIndex: any, index: any, template: TemplateRef<any>) => {
    this.popupData.dataTemp = {
      'curriculumId': curr.curriculumId,
      'curriculumTitle': curr.curriculumTitle,
      'status': this.globalVar.languageString.resources.manageTab.manageCurriculumRejectedLabel,
      'parentIndex': parentIndex,
      'rowIndex': index,
      'redirectUrl': '/curriculum/list/rejected'
    };
    this.popupData.confirmationMsg = "'" + curr.curriculumTitle + this.globalVar.languageString.resources.manageTab.manageCurriculumRejectedSuccessMessage;
    this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.manageCurriculumRejectConfirmMessage + curr.curriculumTitle + "'?";
    this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.manageCurriculumRejectLabel;
    this.modalRef = this.modalService.show(template);
  }

  public unapproveCurricula = (curr: any, parentIndex: any, index: any, template: TemplateRef<any>) => {
    this.popupData.dataTemp = {
      'curriculumId': curr.curriculumId,
      'curriculumTitle': curr.curriculumTitle,
      'status': this.globalVar.languageString.resources.manageTab.manageCurriculumDraftLabel,
      'parentIndex': parentIndex,
      'rowIndex': index,
      'redirectUrl': '/curriculum/list/new'
    };
    this.popupData.confirmationMsg = "'" + curr.curriculumTitle + this.globalVar.languageString.resources.manageTab.manageCurriculumUnApproveSuccessMessage;
    this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.manageCurriculumUnApproveConfirmMessage + curr.curriculumTitle + "'?";
    this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.manageCurriculumUnApproveLabel;
    this.modalRef = this.modalService.show(template);
  }
  public unrejectCurricula = (curr: any, parentIndex: any, index: any, template: TemplateRef<any>) => {
    this.popupData.dataTemp = {
      'curriculumId': curr.curriculumId,
      'curriculumTitle': curr.curriculumTitle,
      'status': this.globalVar.languageString.resources.manageTab.manageCurriculumIngestedLabel,
      'parentIndex': parentIndex,
      'rowIndex': index,
      'redirectUrl': '/curriculum/list/ingested'
    };
    this.popupData.confirmationMsg = "'" + curr.curriculumTitle + this.globalVar.languageString.resources.manageTab.manageCurriculumUnRejectSuccessMessage;
    this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.manageCurriculumUnRejectConfirmMessage + curr.curriculumTitle + "'?";
    this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.manageCurriculumUnRejectLabel;
    this.modalRef = this.modalService.show(template);
  }
  public undeleteCurricula = (curr: any, parentIndex: any, index: any, template: TemplateRef<any>) => {
    this.popupData.dataTemp = {
      'curriculumId': curr.curriculumId,
      'curriculumTitle': curr.curriculumTitle,
      'status': this.globalVar.languageString.resources.manageTab.manageCurriculumIngestedLabel,
      'parentIndex': parentIndex,
      'rowIndex': index,
      'redirectUrl': '/curriculum/list/ingested'
    };
    this.popupData.confirmationMsg = "'" + curr.curriculumTitle + this.globalVar.languageString.resources.manageTab.manageCurriculumUnDeleteSuccessMessage;
    this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.manageCurriculumUnDeleteConfirmMessage + curr.curriculumTitle + "'?";
    this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.manageCurriculumUnDeleteLabel;
    this.modalRef = this.modalService.show(template);
  }

  public deleteCurricula = (curr: any, parentIndex: any, index: any, template: TemplateRef<any>) => {
    this.httpRestClient.getForUrl('api/intermediary/alignmentsExits?iri=/curriculum/' + curr.curriculumId, undefined, undefined).subscribe((result) => {
      if (result === 'true') {
        this.popupData.dataTemp = {
          'curriculumId': curr.curriculumId,
          'curriculumTitle': curr.curriculumTitle,
          'status': this.globalVar.languageString.resources.manageTab.manageCurriculumDeletedLabel,
          'parentIndex': -1,
          'rowIndex': -1,
          'redirectUrl': '/curriculum/list/deleted'
        };
        this.popupData.confirmationMsg = "'" + curr.curriculumTitle + this.globalVar.languageString.resources.manageTab.manageCurriculumDeleteSuccessMessage;
        this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.manageCurriculumDeleteConfirmMessageForAlignments + curr.curriculumTitle + "'?";
        this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.manageCurriculumDeleteLabel;
        this.modalRef = this.modalService.show(template);
      } else {
        this.popupData.dataTemp = {
          'curriculumId': curr.curriculumId,
          'curriculumTitle': curr.curriculumTitle,
          'status': this.globalVar.languageString.resources.manageTab.manageCurriculumDeletedLabel,
          'parentIndex': -1,
          'rowIndex': -1,
          'redirectUrl': '/curriculum/list/deleted'
        };
        this.popupData.confirmationMsg = "'" + curr.curriculumTitle + this.globalVar.languageString.resources.manageTab.manageCurriculumDeleteSuccessMessage;
        this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.manageCurriculumDeleteConfirmMessage + curr.curriculumTitle + "'?";
        this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.manageCurriculumDeleteLabel;
        this.modalRef = this.modalService.show(template);
      }
    }, (error) => {
      if (error.status == 500) {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
      }
    });
  }

  sentToRealize( template: TemplateRef<any>,type:any,id:any) {
    this.popupData.dataTemp = {
      'curriculumId': id,
      'type':type
    };
    this.popupData.confirmationMsg = "SNS Message has been sent to Realize successfully"
    this.popupData.popupMessage = "Are you sure you want to send this level of Standards To Realize'?"
    this.popupData.buttonLabel = "Send To Realize";
    this.modalRef = this.modalService.show(template);
  }
  
  public setToRealize = () => {
    var data = this.popupData.dataTemp;
    let url = 'api/curriculum/loc-structure/' + encodeURIComponent(data.curriculumId) +'/sendToRealizeManual?type='+data.type;
    this.httpRestClient.getForUrl(url, {}, {}).subscribe((result) => {
      this.confirmationPopup.showSuccessMessage('');
    }, (error) => {
      if (error.status == 500) {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
      }
    })
  }

  public deleteCurriculaAfterUpload = (curriculumId: any, template: any) => {
    this.popupData.dataTemp = {
      'curriculumId': curriculumId,
      'curriculumTitle': '',
      'status': this.globalVar.languageString.resources.manageTab.manageCurriculumDeletedLabel,
      'parentIndex': -1,
      'rowIndex': -1,
      'redirectUrl': '/curriculum/upload'
    };
    this.popupData.confirmationMsg = "'" + '' + this.globalVar.languageString.resources.manageTab.manageCurriculumDeleteSuccessMessage;
    this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.manageCurriculumDeleteConfirmMessageForAlignments + "'?";
    this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.manageCurriculumDeleteLabel;
    this.modalRef = this.modalService.show(template);
  };

  public lockUnlockCurricula = (curr: any) => {
    let url = 'api/curricula?iri=' + curr.curriculumId + '&lockedBy=' + this.globalVar.userProfile.userName;
    let message = '';
    if (!JSON.parse(curr.lockedForAlignment === undefined ? 'false' : curr.lockedForAlignment)) {
      url = url + '&lock=true';
      message = this.globalVar.languageString.resources.homeTab.curriculaLockSuccessMessage;
    } else {
      url = url + '&lock=false';
      message = this.globalVar.languageString.resources.homeTab.curriculaUnlockSuccessMessage;
    }
    this.httpRestClient.postForUrl(url, {}, {}).subscribe((result) => {
      this.confirmationPopup.showSuccessMessage(message);
      curr.lockedForAlignment = !JSON.parse(curr.lockedForAlignment === undefined ? 'false' : curr.lockedForAlignment);
    }, (error) => {
      if (error.status == 500) {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
      }
    })
  }
  public doUpdateCurriculumStatus = () => {
    var data = this.popupData.dataTemp;
    this.updateCurriculaStatus(data.curriculumId, data.curriculumTitle, data.status, data.parentIndex, data.rowIndex, data.redirectUrl);
  }

  public updateCurriculaStatus = (curriculumId: any, curriculumTitle: string, status: any, parentIndex: any, index: any, redirectUrl: any) => {
    let url = 'api/curriculum/' + curriculumId + '/update?status=' + status;
    this.httpRestClient.postForUrl(url, undefined, undefined).subscribe((result) => {
      if (result.errorMessage) {
        this.confirmationPopup.showErrorMessage(result.errorMessage);
      } else {
        if (this.globalVar.languageString.resources.manageTab.manageCurriculumApprovedLabel == status) {
          this.confirmationPopup.showSuccessMessage("'" + curriculumTitle + this.globalVar.languageString.resources.manageTab.manageCurriculumApprovedSuccessfullyMessage);
        }
        if (parentIndex > -1 && index > -1) {
          var curriculumCountryListBean = this.globalVar.curriculaListBean.curriculumCountryListBean[parentIndex];
          curriculumCountryListBean.curriculaInfoList.splice(index, 1);
          if (curriculumCountryListBean.curriculaInfoList.length == 0) {
            this.globalVar.curriculaListBean.curriculumCountryListBean.splice(parentIndex, 1);
          }
          this.updateListSize();
        } else {
          if (redirectUrl) {
            this.route.navigate([redirectUrl]);
          }
        }
      }
    }, (error) => {
      if (error.status == 500) {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
      }
    });
  }

  public updateListSize = () => {
    let listSize = 0;
    for (let x = 0; x < this.globalVar.curriculaListBean.curriculumCountryListBean.length; x++) {
      var curriculumCountryListBean = this.globalVar.curriculaListBean.curriculumCountryListBean[x];
      listSize = listSize + curriculumCountryListBean.curriculaInfoList.length;
    }
    this.globalVar.curriculaListBean.listSize = listSize;
  }

  public editCurricula = (curr: any) => {
    this.route.navigate(["/curriculum/" + curr.curriculumId + "/edit"]);
  }


  /////////// .... Ends here...... needs to be moved commonly for 'ManagePageComponent' and this component

  public showCmtGradeList = (cmtGradeTemp: TemplateRef<any>) => {
    this.modalRef = this.modalService.show(cmtGradeTemp ? cmtGradeTemp : "");
    this.modalRef.setClass("cmtGradeListPopup");
  }

  public exportForEdit = (curr: any) => {
    this.callForXLSEdit = true;
    this.currentCurriculumId = curr.curriculumId;
    this.generateCurriculumXLSForEdit(curr);
  }

  public generateCurriculumXLSForEdit = (curr: any) => {
    this.downloadUrlForEdit = undefined;
    this.generatingXLSForEdit = true;
    this.callForXLSEdit = false;
    let curriculumID = curr.curriculumId;
    this.confirmationPopup.showSuccessMessage(this.globalVar.languageString.resources.homeTab.curriculumExcelSendingMessage);
    let url = 'api/curriculum/' + curriculumID + '/exportforEdit?userMailId=' + this.globalVar.userProfile.userMail;
    this.httpRestClient.postForUrl(url, undefined, undefined).subscribe((result) => {

    }, (error) => {
      this.generatingXLSForEdit = false;
      // this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.homeTab.curriculumExcelGenerateErrorMessage);
    });
  }

  // public autoTagCurricula = (curr: any) => {

  // }

  // public downloadFile = () => {

  // }

  public copyUriFunc = (uri: any, event: any) => {
    let element = document.createElement('textarea');
    element.value = uri;
    element.setAttribute('readonly', '');
    // element.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(element);
    element.select();
    document.execCommand('copy');
    document.body.removeChild(element);
    let currTarget = event.currentTarget;
    let popEle = document.createElement('div');
    popEle.innerText = "URI copied to clipboard";
    popEle.className = 'uriCopiedClip';
    currTarget.parentElement.appendChild(popEle);
    popEle.classList.add("uriClipView");
    setTimeout(function () {
      popEle.classList.remove("uriClipView");
    }, 300);
    setTimeout(function () {
      currTarget.parentElement.removeChild(popEle);
    }, 2500);
  }

  public getSelectedLocalUri = (loadedGradeBean: any) => {
    let uriArray = loadedGradeBean.gradeIRI.split('/');
    this.selectedLocalGradeUri = uriArray[uriArray.length - 1];
  }

  public doClosePopup = () => {
    this.modalRef.hide();
  }

  public getFormatedGarde = (grade: any) => {
    let formatedGrade = "";
    if (grade.indexOf('-') === -1) {
      if (!isNaN(grade)) {
        formatedGrade = ("0" + grade).slice(-2);
        return formatedGrade;
      }
      return grade;
    } else {
      var grades = grade.split('-');
      _.each(grades, (grade, index) => {
        if (index == 1) {
          formatedGrade = formatedGrade + '-' + this.getFormatedGarde(grade);
        } else {
          formatedGrade = formatedGrade + this.getFormatedGarde(grade);
        }
      })
      return formatedGrade;
    }
  }

  public getLockStatusLabel = (curr: any) => {
    var label = this.globalVar?.languageString?.resources?.homeTab?.curriculumLockLabel;
    if (!_.isEmpty(curr) && JSON.parse(curr.lockedForAlignment === undefined ? 'false' : curr.lockedForAlignment)) {
      label = this.globalVar?.languageString?.resources?.homeTab?.curriculumUnLockLabel;
    }
    return label;
  }

  public generateCurriculumWithTagXLS = (curr: any) => {
    this.globalVar.callForXLS = true;
    this.confirmationPopup.showSuccessMessage("Excel file will be generated and send to your email.");
    this.generateCurriculumXLS(curr);
  }

  public generateCurriculumXLS = (curr: any) => {
    this.downloadUrl = undefined;
    this.globalVar.callForXLS = false;
    let curriculumID = curr.curriculumId;
    let withTag = 'Yes';
    //let url = 'api/curricula?iri=' + curr.curriculumId + '&lockedBy=' + this.globalVar.userProfile.userName;
    //this.confirmationPopup.showSuccessMessage(this.globalVar.languageString.resources.homeTab.curriculumExcelSendingMessage);
    let url = 'api/curriculum/' + curriculumID + '/export?userMailId=' + this.globalVar.userProfile.userMail + '&withTag=' + withTag;
    this.httpRestClient.postForUrl(url, undefined, undefined).subscribe((result) => {

    });
  }

  public loadStandardGradeBean = (grade: any, gradeUriList: any) => {
    if (!this.selectedStandardGrade || this.selectedStandardGrade != grade) {
      this.gradeLoadingVisible = true;
      this.selectedStandardGrade = grade;
      this.selectedStandardGradeMod = this.getFormatedGarde(grade);
      let curriculumId = this.curriculum.curriculumId;

      let url = this.curriculum.curriculumEditable ? 'api/curriculum' : 'api/view/curriculum';
      url = url + '/' + curriculumId + '/topics'
      let curriculaGradeBean = { "curriculumId": curriculumId, "gradeUriList": gradeUriList };
      this.httpRestClient.postForUrl(url, curriculaGradeBean, undefined).subscribe((result) => {
        let gradeBeanList = result.gradeBeanList;
        this.disableGradeURI = result.unNumberedLowestLevel;
        this.disableLocalGradeUrI = result.gradeBeanList[0].unNumberedLowestLevel;
        if (gradeBeanList && gradeBeanList.length > 0) {
          this.loadedGradeBeanList.length = 0;
          for (let i = 0; i < gradeBeanList.length; i++) {
            /*added for columsize*/
            gradeBeanList[i].columnsSize = 1;
            this.loadedGradeBeanList.push(gradeBeanList[i]);
          }
          this.selectedGradeName = "0-" + this.loadedGradeBeanList[0].gradeName;
          let uriArray = this.loadedGradeBeanList[0].gradeIRI.split('/');
          this.selectedLocalGradeUri = uriArray[uriArray.length - 1];
        }
        //this.updateColumnSize(this.loadedGradeBeanList);
        this.gradeLoadingVisible = false;
      }, (error) => {
        this.gradeLoadingVisible = false;
        if (error.status == 500) {
          this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
        }
      });
    }
  }

  public getHeaderColumnWidth = (gradeBean: any, level: any) => {
    if (gradeBean.columnsSize == 1) {
      return {
        width: "0%",
        display: "none"
      };
    } else {
      if (level < gradeBean.maxLevel) {
        if (level == 3) {
          return {
            "width": "122px"
          };
        } else {
          return {
            "width": "102px"
          };
        }
      }
    }
    return {
    };
  }

  public changeLearningObjColumns = (gradeBean: any) => {
    if (gradeBean.columnsSize > 1) {
      gradeBean.columnsSize = 1;
    } else {
      gradeBean.columnsSize = gradeBean.maxLevel;
    }
  }

  public changeAlignmentVisibility = () => {
    this.showAlignment = !this.showAlignment;
  }

  public getSubTopicColumnWidth = (gradeBean: any, level: any, leafNode: any) => {
    if (gradeBean.columnsSize == 1) {
      if (!leafNode) {
        return {
          width: "0%",
          display: "none"
        };
      }
    } else if (level < gradeBean.maxLevel) {
      if (level == 3) {
        return {
          "width": "120px",
          "word-break": "break-word"
        };
      } else {
        return {
          "width": "100px"
        };
      }
    }
    return {
    };
  }

  public updateColumnSize = (gradeBeanList: any) => {
    if (gradeBeanList) {
      for (let x = 0; x < gradeBeanList.length; x++) {
        gradeBeanList[x].columnsSize = 1;
      }
    }
  }


  public scrollToGradeName = (index: any, gradeName: any) => {
    this.showAlignment = true;
    // if (index == 0) {
    //     UtilsService.scrollTo("menu");
    // } else {
    //     UtilsService.scrollTo(index + "-" + gradeName);
    // }
    this.selectedGradeName = index + "-" + gradeName;
  }

}
