<div class="loader-container" *ngIf="loading">
	<div class="loading-overlay"></div>
	<div class="loading-icon">
		<img src="assets/images/loading_100x100.gif" />
	</div>
</div>
<div class="standardURITitle"> Standards URI Lookup</div>
<div class="standardURIField">
	<span class="copyUriText">Standard URI:</span>
	<input [(ngModel)]="iriValue" class="standard-input" type="text" name="iriValue">
	<button class="standardSearchbutton"><img class="cursor_pointer" style="top:125px" title="search" src="assets/images/search_icon_black.png" (click)="setStandardsRequest()"></button>
<div class="standardURIResults"> Results:</div>
<div class="standardURILink">
 <a (click)="getCurriculumDetails(curriculumId)">{{curriculumTitle}}</a>
 <span *ngIf="pathValue!=''" class="standardURIPath">Path: {{ pathValue }}</span>
</div>