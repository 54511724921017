<div class="curriculumTab viewListByCount" [hidden]="!globalVar.curriculaListBean">
    <div>
        <input type="submit" value="{{globalVar?.languageString?.resources?.homeTab?.curriculumBackLabel}}"
            class="cmButton-goBack marginTop5px floatRight" (click)="goBack()" />
        <span class="input-group-addon"><i class="fa fa-chevron-left floatRight marginLeft15px"></i></span>
    </div>

    <div class="curriculamFilter clearfix">
        <label class="font16 font_13_333333_bold marginBottom5">Filter By</label>
        <div class="marginTop10">
            <div class="filterContent">
                <label class="curricFilterLabel pull-left font14">Subject:</label>
                <select class="curricFilterSelect" [(ngModel)]="selectedSubjects" (click)="getmodifiedListLength()">
                    <option value="">All Subjects</option>
                    <option *ngFor="let subject of filterSubjects">{{subject.facetName}}</option>
                </select>
            </div>
            <div class="filterContent">
                <label class="curricFilterLabel pull-left font14">Curriculum Type:</label>
                <select class="curricFilterSelect" [(ngModel)]="selectedCurriculumType" (click)="getmodifiedListLength()">
                    <option value="">All Curriculum Type</option>
                    <option *ngFor="let type of curriculumTypes">{{type.name}}</option>
                </select>
            </div>
            <div class="filterContent">
                <label class="curricFilterLabel pull-left font14">Deployed to Realize:</label>
                <select class="curricFilterSelect" [(ngModel)]="selectedDeployToRelease" (click)="getmodifiedListLength()">
                    <option value="">Any</option>
                    <option *ngFor="let type of deployToRelease">{{type.name}}</option>
                </select>
            </div>
            <div class="filterContent">
                <label class="curricFilterLabel pull-left font14">Search:</label>
                <input type="text" class="curricFilterSelect currSearchKey font14" [(ngModel)]="currSearchKey" (change)="getmodifiedListLength()"
                    placeholder="Enter search key" />
            </div>
        </div>
    </div>

    <div class="curriculumTotal" [hidden]="globalVar?.curriculaListBean?.curriculumCountryListBean?.length === 0"
        [innerHTML]="globalVar?.languageString?.resources?.homeTab?.countryApprovedCurriculumListLabel +' ('+filterdListCount+')'">
    </div>

    <ng-container
        *ngIf="globalVar?.curriculaListBean && globalVar?.curriculaListBean?.curriculumCountryListBean && globalVar.curriculaListBean?.curriculumCountryListBean?.length > 0">
        <div
            *ngFor="let curriculumCountryListBean of filterByCounSub('parent', globalVar.curriculaListBean?.curriculumCountryListBean, selectedCountry, selectedSubjects, selectedCurriculumType,selectedDeployToRelease, currSearchKey, undefined)">
            <div class="curriculumCountry">{{ curriculumCountryListBean?.country }}</div>
            <div
                *ngFor="let curr of filterByCounSub('child', curriculumCountryListBean.curriculaInfoList, selectedCountry, selectedSubjects, selectedCurriculumType,selectedDeployToRelease,currSearchKey, curriculumCountryListBean)">
                <div class="curriculumBackground">
                    <table class="curriculumTable">
                        <tbody>
                            <tr>
                                <td class="curriculumButtonPanel">
                                    <input type="submit"
                                        value="{{globalVar.languageString.resources.homeTab.curriculumViewLabel}}"
                                        class="cmButton cmButton-green cmButton-large marginLeft24px"
                                        (click)="goToCurriculum(curr, false)" />
                                </td>
                                <td>
                                    <div class="curriculumInfoPanel">
                                        <img *ngIf="curr?.deployedToRealize" style="width:12px; margin-right:5px"
                                            class="deployedtorealizePng" title="Deployed to Realize"
                                            src="assets/images/deployed_to_realize_Icon.png">
                                        <a [routerLink]="'/view/curriculum/'+curr.curriculumId"
                                            [innerHTML]="getHighlightedText(curr.curriculumTitle, currSearchKey)"
                                            class="curriculumTitle"></a>
                                    </div>
                                    <div class="curriculumInfo">
                                        Created on {{curr?.createdOn}} |
                                        {{globalVar.languageString.resources.homeTab.curriculumLastUpdateUserLabel}}
                                        {{curr?.lastUpdatedUser}}
                                        {{globalVar.languageString.resources.homeTab.curriculumLastUpdateTimeLabel}}
                                        {{curr?.lastUpdatedTime | date:'dd MMM yyyy HH:mm:ss'}}
                                    </div>
                                    <div class="curriculumInfo">
                                        {{curr?.curriculumType}}
                                    </div>
                                    <div class="curriculumInfo" [innerHtml]="getInnerHtml(curr.description)">
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </ng-container>
    <div [hidden]="globalVar?.noApprovedCurricula" class="curriculumEmptyListPanel">
        <div class="curriculumEmptyListPanelText">
            {{globalVar?.languageString?.resources?.homeTab?.countryNoCurriculumApprovedMessage}}</div>
    </div>
</div>