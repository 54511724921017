import { Component, Injectable, Input, OnInit } from '@angular/core';
import {map} from 'rxjs/operators';


@Component({
  selector: 'app-authority-tree',
  templateUrl: './authority-tree.component.html',
  styleUrls: ['./authority-tree.component.scss',
            '../manage-authority/manage-authority.component.scss']
})
export class AuthorityTreeComponent implements OnInit {
  @Input() country: any;
  @Input() authority: any;
  @Input() curriculumSet: any;
  @Input() doFunction: any;
  @Input() addAuthorityFunc: any;
  @Input() addCurriculumSetFunc: any;
  
  constructor() { }

  ngOnInit(): void {
  }

  public expandCollapse = (data: any, event: any) => {
    var currNode = event.target as HTMLInputElement;
    if(currNode?.parentElement?.parentElement?.parentElement?.classList.contains('treeOpen')) {
      currNode?.classList.remove("treeOpen");
      currNode?.parentElement?.parentElement?.parentElement?.classList.remove("treeOpen");
    } else {
      currNode?.parentElement?.parentElement?.parentElement?.classList.add("treeOpen");
      this.getNodeDetails(currNode);
    }
  }

  public getNodeDetails = (currNode: any) => {
    if(this.country && typeof this.country.authorityList == "undefined") {
      currNode?.classList.add("treeLoading");
      this.doFunction(this.country, currNode);
    } else if(this.authority && typeof this.authority.curriculumSetList == "undefined") {
      currNode?.classList.add("treeLoading");
      this.doFunction(this.authority, currNode);
    } else {
      currNode?.classList.add("treeOpen");
    }
  }

}
