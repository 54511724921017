import { Component, OnInit, TemplateRef, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpRestClientService } from 'src/app/services/common/http-rest-client.service';
import { PreloadDataService } from 'src/app/services/common/preload-data.service';
import { ConfirmationPopupService } from 'src/app/services/confirmation-popup/confirmation-popup.service';
import { GlobalVariablesService } from 'src/app/services/global-variables/global-variables.service';
import { ManagePageComponent } from 'src/app/components/manage-page/manage-page.component';
//import { ArrayRangePipe } from 'src/app/services/pipe';
import * as _ from "underscore";
import { any, result } from 'underscore';
import { CorrelationService } from 'src/app/services/correlation/correlation.service';

@Component({
  selector: 'correlation-save',
  templateUrl: './correlation-save.component.html',
  styleUrls: ['./correlation-save.component.scss']
})
export class CorrelationSaveComponent implements OnInit {
  showRestrictedChar = false;
  reportName: any;
  includePeripheral = 0;
  includeIntermediaryDetailsStandard = 0;
  includeAlfresco = 0;
  showKeyAlignments = 0;
  realizeStandards = 0;
  reverseIntermediary = 0;
  leftSelected: any;
  rightSelected: any;
  dataForSave = {
    "createdBy": "",
    "leftAlfrescoInclude": 0,
    "leftCountry": "",
    "leftCountryIRI": "",
    "leftCourseIRI": "",
    "leftCourseTitle": "",
    "leftGradeMaxIRI": "",
    "leftGradeMinIRI": "",
    "leftMappingIRI": "",
    "leftPeripheralInclude": 0,
    "leftProgramIRI": "",
    "leftProgramTitle": "",
    "leftState": "",
    "leftStrandIRI": "",
    "leftSubjectIRI": "",
    "leftTitle": "",
    "leftestype": "",
    "printDigitalAlignment": "",
    "realizeStandards": 0,
    "reportName": "",
    "reportRightMappingBeanList": [{
      "country": "",
      "countryIRI": "",
      "courseIRI": "",
      "courseTitle": "",
      "grade": "1",
      "gradeIRI": "",
      "gradeMaxIRI": "",
      "gradeMinIRI": "",
      "mappingIRI": "",
      "programIRI": "",
      "programTitle": "",
      "state": "",
      "strandIRI": "",
      "strand": "",
      "subjectIRI": "",
      "title": "",
    }],
    "responseMessage": "",
    "reverseIntermediary": 0,
    "rightIntermediaryDetailsStandard": 0,
    "rightPeripheralInclude": 0,
    "rightestype": "curricula",
    "showKeyAlignments": 0,
    "userMail": ''
  }

  constructor(public globalVar: GlobalVariablesService,
    private httpRestClient: HttpRestClientService,
    public correlationService: CorrelationService,
    private confirmationPopup: ConfirmationPopupService,
  ) { }

  ngOnInit(): void {
    this.dataForSave.userMail = this.globalVar?.userProfile?.userMail;
    this.editCorrelation();
  }


  editCorrelation() {
    this.correlationService.editReportName.subscribe((data: any) => {
      this.reportName = data?.name;
    })
    this.correlationService.editCorrelationEventLeft.subscribe((data: any) => {
      this.includePeripheral = data.leftPeripheralInclude;
      this.includeAlfresco = data.leftAlfrescoInclude;
      this.includeIntermediaryDetailsStandard = data.rightIntermediaryDetailsStandard;
      this.showKeyAlignments = data.showKeyAlignments;
      this.realizeStandards = data.realizeStandards;
      this.reverseIntermediary = data.reverseIntermediary;
    })
  }


  restrictedCharOff() {
    this.showRestrictedChar = true;
  }

  saveCorrelationReport() {
    if (this.correlationService.leftSelectedCurriculumDetails) {
      this.populateLeftPanelDetails('standard')
    }
    if (this.correlationService.leftSelectedProductDetails) {
      this.populateLeftPanelDetails('product')
    }

    if (this.correlationService.leftSelectedInterMediaryDetails) {
      this.populateLeftPanelDetails('intermediary')
    }
    if (this.correlationService.rightelectedCurriculumDetails.length) {
      this.populateRightPanelDetails('standard')
    }
    if (this.correlationService.rightSelectedProductDetails.length) {
      this.populateRightPanelDetails('product')
    }
    if (this.correlationService.leftSelectedProductDetails || this.correlationService.leftSelectedCurriculumDetails || this.correlationService.leftSelectedInterMediaryDetails) {
      if (this.reportName) {
        const specialChars = /[#?;\/*":]/;
        if (specialChars.test(this.reportName)) {
          this.confirmationPopup.showErrorMessage('Report Name is a mandatory field.');
        }
        else {
          if (this.correlationService.rightSelectedProductDetails.length > 0 || this.correlationService.rightelectedCurriculumDetails.length > 0) {
            this.saveData();
          }
          else {
            this.confirmationPopup.showErrorMessage('Please select report(s) from the right side.');
          }
        }
      }
      else {
        this.confirmationPopup.showErrorMessage('Report Name is a mandatory field.');
      }
    }
    else {
      this.confirmationPopup.showErrorMessage('Please select report from the left side.');
    }
  }

  resetReportName() {
    this.reportName = undefined;
    this.correlationService.isSave = false;
    this.correlationService.isReset.next(true);
    this.includePeripheral = 0;
    this.includeIntermediaryDetailsStandard = 0;
    this.includeAlfresco = 0;
    this.showKeyAlignments = 0;
    this.realizeStandards = 0;
    this.reverseIntermediary = 0;
  }

  saveData() {
    this.correlationService.loading.next(true);
    this.dataForSave.reportName = this.reportName;
    this.dataForSave.userMail = this.globalVar?.userProfile?.userMail;
    this.dataForSave.createdBy = this.globalVar?.userProfile?.userName;
    this.dataForSave.leftPeripheralInclude = this.includePeripheral ? 1 : 0;
    this.dataForSave.leftAlfrescoInclude = this.includeAlfresco ? 1 : 0;
    this.dataForSave.rightPeripheralInclude = this.includePeripheral ? 1 : 0;
    this.dataForSave.rightIntermediaryDetailsStandard = this.includeIntermediaryDetailsStandard ? 1 : 0;
    this.dataForSave.showKeyAlignments = this.showKeyAlignments ? 1 : 0;
    this.dataForSave.realizeStandards = this.realizeStandards ? 1 : 0;
    this.dataForSave.reverseIntermediary = this.reverseIntermediary ? 1 : 0;
    if (this.correlationService.printAllign && !this.correlationService.degitialAllign) {
      this.dataForSave.printDigitalAlignment = 'print';
    }
    if (!this.correlationService.printAllign && this.correlationService.degitialAllign) {
      this.dataForSave.printDigitalAlignment = 'digital';
    }
    if ((this.correlationService.printAllign && this.correlationService.degitialAllign) || (!this.correlationService.printAllign && !this.correlationService.degitialAllign)) {
      this.dataForSave.printDigitalAlignment = 'printDigital';
    }

    const url = 'api/report';
    this.httpRestClient.postForUrl(url, this.dataForSave, undefined).subscribe((result) => {
      if (this.correlationService.isSave) {
        this.correlationService.isListUpdated.next(true);
      }
      else {
        this.correlationService.loading.next(false);
      }
      this.reportName = undefined;
      setTimeout(() => {
        this.confirmationPopup.showSuccessMessage('We will notify you shortly once your request has been completed via E-mail');
      }, 1000)
    }, (error) => {
      console.log(error);
    });
  }

  populateRightPanelDetails(tab: any) {
    if (tab == 'standard') {
      if (this.correlationService.rightelectedCurriculumDetails.length) {
        this.correlationService.rightelectedCurriculumDetails.forEach((item: any, key) => {
          let mappingIRI = "https://data.savvas.com/curriculum/loc-structure/" + item.curriculum.curriculumId + "#this";
          if (item.strand != 'NA') {
            const gradeIRI = item.strand.gradeIRI;
          } else {
            const gradeIRI = item.curGrade.gradeUriList[0];
          }
          if (key !== 0) {
            this.dataForSave.reportRightMappingBeanList.push({
              "country": "",
              "countryIRI": "",
              "courseIRI": "",
              "courseTitle": "",
              "grade": "1",
              "gradeIRI": "",
              "gradeMaxIRI": "",
              "gradeMinIRI": "",
              "mappingIRI": "",
              "programIRI": "",
              "programTitle": "",
              "state": "",
              "strandIRI": "",
              "strand": "",
              "subjectIRI": "",
              "title": "",
            });
          }
          this.dataForSave.reportRightMappingBeanList[key].mappingIRI = mappingIRI;
          this.dataForSave.reportRightMappingBeanList[key].gradeMinIRI = item.curGrade.gradeMinIRI;
          this.dataForSave.reportRightMappingBeanList[key].gradeMaxIRI = item.curGrade.gradeMaxIRI;
          this.dataForSave.reportRightMappingBeanList[key].title = item.curriculum.curriculumTitle;
          this.dataForSave.reportRightMappingBeanList[key].country = item.country.countryDesc;
          if (item.strand != 'NA') {
            this.dataForSave.reportRightMappingBeanList[key].strandIRI = item.strand.gradeIRI;
            this.dataForSave.reportRightMappingBeanList[key].gradeIRI = item.strand.gradeIRI;
            this.dataForSave.reportRightMappingBeanList[key].strand = item.strand.gradeName;
          } else {
            this.dataForSave.reportRightMappingBeanList[key].strandIRI = "";
            this.dataForSave.reportRightMappingBeanList[key].gradeIRI = item.curGrade.gradeUriList[0];
            this.dataForSave.reportRightMappingBeanList[key].strand = item.curGrade.gradeName;
          }
          this.dataForSave.reportRightMappingBeanList[key].subjectIRI = item.subject.subjectCode;
          this.dataForSave.reportRightMappingBeanList[key].countryIRI = item.country.countryCode;
          this.dataForSave.reportRightMappingBeanList[key].programIRI = "";
          this.dataForSave.reportRightMappingBeanList[key].courseIRI = "";
          this.dataForSave.reportRightMappingBeanList[key].courseTitle = "";
          this.dataForSave.reportRightMappingBeanList[key].programTitle = "";
          this.dataForSave.reportRightMappingBeanList[key].state = "";
          this.dataForSave.reportRightMappingBeanList[key].grade = item.curGrade.grade;
          this.dataForSave.rightestype = "curricula";
        })
      }

    }
    if (tab == 'product') {
      console.log(this.correlationService.rightSelectedProductDetails);
      if (this.correlationService.rightSelectedProductDetails.length) {
        this.correlationService.rightSelectedProductDetails.forEach((item: any, key) => {
          const mappingIRI = item.product.id;
          this.dataForSave.rightestype = "product";
          if (key !== 0) {
            this.dataForSave.reportRightMappingBeanList.push({
              "country": "",
              "countryIRI": "",
              "courseIRI": "",
              "courseTitle": "",
              "grade": "1",
              "gradeIRI": "",
              "gradeMaxIRI": "",
              "gradeMinIRI": "",
              "mappingIRI": "",
              "programIRI": "",
              "programTitle": "",
              "state": "",
              "strandIRI": "",
              "strand": "",
              "subjectIRI": "",
              "title": "",
            });
          }
          if (this.correlationService.leftSelectedInterMediaryDetails) {
            this.dataForSave.reportRightMappingBeanList[key].mappingIRI = mappingIRI;
            this.dataForSave.reportRightMappingBeanList[key].gradeMinIRI = item.prdGrade.minLevel;
            this.dataForSave.reportRightMappingBeanList[key].gradeMaxIRI = item.prdGrade.maxLevel;
            this.dataForSave.reportRightMappingBeanList[key].title = item.product.name;
            this.dataForSave.reportRightMappingBeanList[key].country = "";
            this.dataForSave.reportRightMappingBeanList[key].courseTitle = item.course.name;
            this.dataForSave.reportRightMappingBeanList[key].programTitle = item.program.name;
            this.dataForSave.reportRightMappingBeanList[key].programIRI = item.program.id;
            this.dataForSave.reportRightMappingBeanList[key].courseIRI = item.course.id;
            this.dataForSave.reportRightMappingBeanList[key].subjectIRI = item.prdSubject.subjectCode;
            this.dataForSave.reportRightMappingBeanList[key].countryIRI = "";
            this.dataForSave.reportRightMappingBeanList[key].strandIRI = "";
            this.dataForSave.reportRightMappingBeanList[key].state = "";
            this.dataForSave.reportRightMappingBeanList[key].grade = item.prdGrade.notation;
          }
          else {
            this.dataForSave.reportRightMappingBeanList[key].mappingIRI = mappingIRI;
            this.dataForSave.reportRightMappingBeanList[key].gradeMinIRI = item.prdGrade.minLevel;
            this.dataForSave.reportRightMappingBeanList[key].gradeMaxIRI = item.prdGrade.maxLevel;
            this.dataForSave.reportRightMappingBeanList[key].title = item.product.name;
            this.dataForSave.reportRightMappingBeanList[key].country = item.product.country;
            this.dataForSave.reportRightMappingBeanList[key].courseTitle = item.course.name;
            this.dataForSave.reportRightMappingBeanList[key].programTitle = item.program.name;
            this.dataForSave.reportRightMappingBeanList[key].programIRI = item.program.id;
            this.dataForSave.reportRightMappingBeanList[key].courseIRI = item.course.id;
            this.dataForSave.reportRightMappingBeanList[key].subjectIRI = item.prdSubject.subjectCode;
            this.dataForSave.reportRightMappingBeanList[key].countryIRI = "";
            this.dataForSave.reportRightMappingBeanList[key].strandIRI = "";
            this.dataForSave.reportRightMappingBeanList[key].state = "";
            this.dataForSave.reportRightMappingBeanList[key].grade = item.prdGrade.notation;
          }
        })

      }
    }
  }

  populateLeftPanelDetails(tab: any) {
    if (tab == 'standard') {
      const mappingIRI = "https://data.savvas.com/curriculum/loc-structure/" + this.correlationService.leftSelectedCurriculumDetails.curriculum.curriculumId + "#this";
      this.dataForSave.leftMappingIRI = mappingIRI;
      this.dataForSave.leftestype = "curricula";
      this.dataForSave.leftGradeMinIRI = this.correlationService.leftSelectedCurriculumDetails.curGrade.gradeMinIRI;
      this.dataForSave.leftGradeMaxIRI = this.correlationService.leftSelectedCurriculumDetails.curGrade.gradeMaxIRI;
      this.dataForSave.leftTitle = this.correlationService.leftSelectedCurriculumDetails.curriculum.curriculumTitle;
      this.dataForSave.leftCountry = this.correlationService.leftSelectedCurriculumDetails.country.countryDesc;
      if (this.correlationService.leftSelectedCurriculumDetails.strand != 'NA') {
        this.dataForSave.leftStrandIRI = this.correlationService.leftSelectedCurriculumDetails.strand.gradeIRI;
      } else {
        this.dataForSave.leftStrandIRI = "";
      }
      this.dataForSave.leftSubjectIRI = this.correlationService.leftSelectedCurriculumDetails.subject.subjectCode;
      this.dataForSave.leftCountryIRI = this.correlationService.leftSelectedCurriculumDetails.country.countryCode;
      this.dataForSave.leftProgramIRI = "";
      this.dataForSave.leftCourseIRI = "";
      this.dataForSave.leftCourseTitle = "";
      this.dataForSave.leftProgramTitle = "";
      this.dataForSave.leftState = "";
    }
    if (tab == 'product') {
      const mappingIRI = this.correlationService.leftSelectedProductDetails.product.id;
      this.dataForSave.leftMappingIRI = mappingIRI;
      this.dataForSave.leftestype = "product";
      this.dataForSave.leftGradeMinIRI = this.correlationService.leftSelectedProductDetails.prdGrade.minLevel;
      this.dataForSave.leftGradeMaxIRI = this.correlationService.leftSelectedProductDetails.prdGrade.maxLevel;
      this.dataForSave.leftTitle = this.correlationService.leftSelectedProductDetails.product.name;
      this.dataForSave.leftCountry = this.correlationService.leftSelectedProductDetails.product.country;
      this.dataForSave.leftCourseTitle = this.correlationService.leftSelectedProductDetails.course.name;
      this.dataForSave.leftProgramTitle = this.correlationService.leftSelectedProductDetails.program.name;
      this.dataForSave.leftProgramIRI = this.correlationService.leftSelectedProductDetails.program.id;
      this.dataForSave.leftCourseIRI = this.correlationService.leftSelectedProductDetails.course.id;
      this.dataForSave.leftSubjectIRI = this.correlationService.leftSelectedProductDetails.prdSubject.subjectCode;
      this.dataForSave.leftCountryIRI = "";
      this.dataForSave.leftStrandIRI = "";
      this.dataForSave.leftState = "";
    }
    if (tab == 'intermediary') {
      console.log(this.correlationService.leftSelectedInterMediaryDetails);
      this.dataForSave.leftMappingIRI = "";
      this.dataForSave.leftestype = "intermediary";
      this.dataForSave.leftGradeMinIRI = "";
      this.dataForSave.leftGradeMaxIRI = "";
      if (this.correlationService?.leftSelectedInterMediaryDetails?.intermediarySubject) {
        this.dataForSave.leftTitle = this.correlationService.leftSelectedInterMediaryDetails.intermediarySubject.subjectDesc;
        this.dataForSave.leftSubjectIRI = this.correlationService.leftSelectedInterMediaryDetails.intermediarySubject.subjectCode;
      } else {
        this.dataForSave.leftTitle = "";
        this.dataForSave.leftSubjectIRI = "";
      }
      this.dataForSave.leftCountry = "";
      this.dataForSave.leftStrandIRI = "";

      this.dataForSave.leftCountryIRI = "";
      this.dataForSave.leftProgramIRI = "";
      this.dataForSave.leftCourseIRI = "";
      this.dataForSave.leftCourseTitle = "";
      this.dataForSave.leftProgramTitle = "";
      this.dataForSave.leftState = "";
    }
  }

}
