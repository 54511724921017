import { JsonPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreloadDataService {

  private selectedAlignData = new BehaviorSubject<any>({});
  currentSelectedAlignData = this.selectedAlignData.asObservable();
  private gradeList = new BehaviorSubject<any>([]);
  currentGradeList = this.gradeList.asObservable();
  private intermediaryAlignData = new BehaviorSubject<any>({});
  currentIntermediaryAlignData = this.intermediaryAlignData.asObservable();
  private curriculumTreeDataNodes = new BehaviorSubject<any>({});
  currentCurriculumTreeData = this.curriculumTreeDataNodes.asObservable();
  private isProductMapping = new BehaviorSubject<any>({});
  productMapping = this.isProductMapping.asObservable();
  private productIRI = new BehaviorSubject<any>({});
  productIRIValue = this.productIRI.asObservable();
  public cartCount: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  public knowledgeCartList: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  public intialItemAddedToCard: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  public selectedTopics: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  public enableUnalign: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  public curriculumURIParent: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  public copiedIntermediaries: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  public isTabChange: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  public authorityCurrentCountry: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  public beanValueChanged: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  public objectValueChange: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  public backTODefault: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  public removeData: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  private jstreeChilDdata = new BehaviorSubject<any>(0);
  jsChildTreeDataNode = this.jstreeChilDdata.asObservable();
  public selectedCurriculumDetails:any;
  public selectedNoteList:any;
  // private newCartItemCount = new BehaviorSubject<any>({});
  // cartItemCount = this.newCartItemCount.asObservable();

  componentOneFn!: Function;
  componentOneFnIntermediary!: Function;
  componentOneFnProduct!: Function;
  isExapand = false;
  cartCountFunction!: Function;
  disableTreeFunction!: Function;
  enableTreeFunction!: Function;
  expandAllProductFunction!: Function;
  expandAllFunction!: Function;
  refreshTreeAfterSave!: Function;
  treeAligned: any;
  selectedTopicsList = new Array();
  treeDataNode: any;

  constructor() { }

  updateProductIRI(data: any) {
    this.productIRI.next(data);
    localStorage.setItem('productIRI',data);
  }

  updateProductMapping(data: any) {
    this.isProductMapping.next(data);
  }

  updateAlignmentData(data: any) {
    this.selectedAlignData.next(data);
  }

  updateGradeBeanList(data: any) {
    this.gradeList.next(data);
  }

  updateIntermediaryAlignData(data: any) {
    this.intermediaryAlignData = data;
  }

  updateCurriculumTreeData(data: any) {
    this.curriculumTreeDataNodes.next(data);
  }

  updateChildNode(data:any)
  {
    this.jstreeChilDdata.next(data);
  }

  // updateCartItemCount(data: any) {
  //   this.newCartItemCount.next(data);
  // }
  refreshLearningObj(parm1:any, param2:any, param3:any, param4:any, param5:any)
  {
   
      this.objectValueChange.next({
        'param1':parm1,
        'param2':param2,
        'param3':param3,
        'param4':param4,
        'param5':param5
      });
  }

  unsbscibeData()
  {
    // this.curriculumTreeDataNodes?this.curriculumTreeDataNodes.unsubscribe():'';
    // this.selectedAlignData?this.selectedAlignData.unsubscribe():'';
    // this.gradeList?this.gradeList.unsubscribe():'';
    // this.intermediaryAlignData?this.intermediaryAlignData.unsubscribe():'';
    // this.isProductMapping?this.isProductMapping.unsubscribe():'';
    // this.productIRI?this.productIRI.unsubscribe():'';
    // this.copiedIntermediaries?this.copiedIntermediaries.unsubscribe():'';
    // this.curriculumURIParent?this.curriculumURIParent.unsubscribe():'';
    // this.cartCount?this.cartCount.unsubscribe():'';
  }
}
