<app-curriculum-menu></app-curriculum-menu>
<div class="parent advance-search-product-browse-section">
    <ul class="treeContainerUl treeNode ">
        <li class="treeItem" *ngFor="let currCountry of countryFacets">
            <app-authority-tree [country]="currCountry" [doFunction]="getAuthority" [addAuthorityFunc]="addEditAuthority"></app-authority-tree>
            <ul *ngIf="currCountry.authorityList" class="treeContainerUl treeNode ">
                <li class="treeItem" *ngFor="let currAuthority of currCountry.authorityList">
                    <app-authority-tree [authority]="currAuthority" [country]="currCountry" [doFunction]="getCurriculumSet" [addCurriculumSetFunc]="addEditCurriculumSet" [addAuthorityFunc]="addEditAuthority"></app-authority-tree>
                    <ul *ngIf="currAuthority.curriculumSetList" class="treeContainerUl treeNode ">
                        <li class="treeItem" *ngFor="let currCurriculumSet of currAuthority.curriculumSetList">
                            <app-authority-tree [country]="currCountry" [authority]="currAuthority" [addCurriculumSetFunc]="addEditCurriculumSet" [curriculumSet]="currCurriculumSet" [doFunction]="saveOrUpdateCurriculumSet"></app-authority-tree>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</div>

<ng-template #addEditAuthorityTemp>
    <app-add-authority-curriculum-set [modalRef]="modalRef" [createItemBean]="modalData" [isAddFlagTrue]="addFlag" 
    [doSuccessFunctionForCurriculumSet]="saveOrUpdateCurriculumSet" [doSuccessFunction]="saveOrUpdateAuthority"></app-add-authority-curriculum-set>
</ng-template>