import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from "underscore";
import { GlobalVariablesService } from '../../services/global-variables/global-variables.service';
import { HttpRestClientService } from '../../services/common/http-rest-client.service';
import { ConfirmationPopupService } from '../../services/confirmation-popup/confirmation-popup.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DomSanitizer } from '@angular/platform-browser';
import { PreloadDataService } from '../../services/common/preload-data.service';


@Component({
  selector: 'app-manage-page',
  templateUrl: './manage-page.component.html',
  styleUrls: ['./manage-page.component.scss']
})
export class ManagePageComponent implements OnInit {

  public activeMenuName = this.actRoute.snapshot.params.listType;
  public filterData: any = {
    'selectedSubjects': '',
    'selectedCountry': '',
    'currSearchKey': '',
    'curriculumType': '',
    'deployToRelease': ''
  };
  public filterSubjects: any;
  public filterCountries: any;
  public modifiedCurrList: any;
  public filterdListCount: any;
  public tabClassName: String = '';
  public popupData: any = {};
  public modalRef: BsModalRef | undefined;
  public callForXLSEdit: boolean | undefined;
  public currentCurriculumId: any;
  public downloadUrlForEdit: any;
  public generatingXLSForEdit: boolean | undefined;
  public deployToRelease = [
    {
      key: true,
      name: 'Yes'
    },
    {
      key: false,
      name: 'No'
    }
  ]
  public curriculumTypes = [
    {
      key: 'Academic benchmarks',
      name: 'Academic benchmarks'
    },
    {
      key: 'Customized Academic Benchmarks',
      name: 'Customized Academic Benchmarks'
    },
    {
      key: 'Savvas Custom',
      name: 'Savvas Custom'
    },
  ]
  curriculumId!: string;
  deployToReleaseFlag = false;

  constructor(
    private actRoute: ActivatedRoute,
    private route: Router,
    private httpRestClient: HttpRestClientService,
    public globalVar: GlobalVariablesService,
    private modalService: BsModalService,
    private confirmationPopup: ConfirmationPopupService,
    private sanitizer: DomSanitizer,
    public preloadData: PreloadDataService
  ) {
    this.preloadData.isTabChange.subscribe(value => {
      this.filterData = {
        'selectedSubjects': '',
        'selectedCountry': '',
        'currSearchKey': '',
        'curriculumType': '',
        'deployToRelease': ''
      };
    });
  }


  ngOnInit(): void {
    this.globalVar.activeMainMenu = 'manage';
    this.initCurriculumList(this.activeMenuName);
  }

  // ngDoCheck(): void {
  //   this.getmodifiedListLength();
  // }

  public isActiveMenu = (menuItem: String) => {
    let activeMenuName = this.actRoute.snapshot.params.listType;
    return activeMenuName == menuItem;
  };

  // public openModal = (template: TemplateRef<any>) => {
  //   this.modalRef = this.modalService.show(template);
  // }

  public initCurriculumList = (status: String) => {
    var currType = status || "new";
    this.loadCurriculumList(currType);
    if (this.globalVar.filterSubjectsHomePage && this.globalVar.filterCountriesHomePage) {
      this.filterSubjects = this.globalVar.filterSubjectsHomePage;
      this.filterCountries = this.globalVar.filterCountriesHomePage;
    } else {
      this.preloadIngestData();
    }
    this.addTabClass(currType);
  };

  public addTabClass = (tab: String) => {
    if (tab === 'new') {
      this.tabClassName = 'draftTab';
    } else if (tab === 'ingested') {
      this.tabClassName = 'awaitingApprTab';
    } else if (tab === 'approved') {
      this.tabClassName = 'approvedTab';
    } else if (tab === 'rejected') {
      this.tabClassName = 'rejectedTab';
    } else if (tab === 'deleted') {
      this.tabClassName = 'deletedTab';
    }
  }

  public loadCurriculumList = (currType: String) => {
    this.globalVar.loading = true;
    this.globalVar.curriculaListBean = undefined;
    let url = 'api/curriculum/list?status=' + currType;
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.globalVar.curriculaListBean = result;
      this.globalVar.loading = false;
      this.updateListSize();
    }, (error) => {
      if (error.status == 500) {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
      }
    })
  }

  public getLockStatusLabel = (curr: any) => {
    var label = this.globalVar.languageString.resources.homeTab.curriculumLockLabel;
    if (!_.isEmpty(curr) && JSON.parse(curr.lockedForAlignment === undefined ? 'false' : curr.lockedForAlignment)) {
      label = this.globalVar.languageString.resources.homeTab.curriculumUnLockLabel;
    }
    return label;
  }

  public preloadIngestData = () => {
    this.globalVar.loading = true;
    let url = 'api/curriculum/ingestion'
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.filterSubjects = result.subjectFacets;
      this.filterCountries = result.countryFacets;
      this.globalVar.loading = false;
      // this.makeActiveTabVisible();
    }, (error) => {
      this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.createCurriculumPreloadIngetionDataErrorMessage);
      this.globalVar.loading = false;
    });
  }

  public updateListSize = () => {
    let listSize = 0;
    for (let x = 0; x < this.globalVar.curriculaListBean.curriculumCountryListBean.length; x++) {
      var curriculumCountryListBean = this.globalVar.curriculaListBean.curriculumCountryListBean[x];
      listSize = listSize + curriculumCountryListBean.curriculaInfoList.length;
    }
    this.globalVar.curriculaListBean.listSize = listSize;
  }

  public filterByCounSub = (from: String, contents: any, country: String, subject: String, curriculumType: any, deployToRelease: any, searchKey: String, parentBean: any) => {
    let modifiedCurrList: any = [];
    if (contents && contents.length >= 0) {
      for (let i = 0; i < contents.length; i++) {
        let cont = contents[i];
        if (contents[i].curriculaInfoList) {
          if ((country === '' || contents[i].country === country) && this.isFilterAvailable(contents[i].curriculaInfoList, searchKey)) {
            modifiedCurrList.push(cont);
          }
        } else {
          let title = contents[i]?.curriculumTitle;
          let curriculum = contents[i]?.curriculumType
          let delopyRelease = deployToRelease === 'Yes' ? true : false;
          if ((title?.toUpperCase().indexOf(searchKey.toUpperCase()) !== -1) && (title?.indexOf(subject) !== -1) && (curriculum?.indexOf(curriculumType) !== -1) && (((curriculumType != "" && curriculum) || curriculumType === "")) && (deployToRelease === "" || delopyRelease === contents[i].deployedToRealize)) {
            modifiedCurrList.push(cont);
          }
        }
      }
    }
    if (from === 'parent') {
      this.modifiedCurrList = modifiedCurrList;
    } else {
      parentBean.modifiedCurriculaInfoList = modifiedCurrList;
    }
    // this.getmodifiedListLength();
    console.log()
    return modifiedCurrList;
  }

  public getHighlightedText(title: any, searchKey: any) {
    if (!searchKey) {
      return title;
    }
    return title.replace(new RegExp(searchKey, "gi"), (match: any) => {
      return '<span class="highlightText">' + match + '</span>';
    });
  }

  public isFilterAvailable = (arr: any, key: String) => {
    var isAvailable = false;
    for (var i = 0; i < arr.length; i++) {
      var title = arr[i].curriculumTitle;
      if (title.toUpperCase().includes(key.toUpperCase())) {
        isAvailable = true;
        break;
      }
    }
    return isAvailable;
  }


  public getmodifiedListLength = () => {
    let listSize = 0;
    if (this.modifiedCurrList) {
      for (var x = 0; x < this.modifiedCurrList.length; x++) {
        var curriculumCountryListBean = this.modifiedCurrList[x];
        if (curriculumCountryListBean.modifiedCurriculaInfoList) {
          listSize = listSize + curriculumCountryListBean.modifiedCurriculaInfoList.length;
        }
      }
    }
    this.filterdListCount = listSize;
    return listSize;
  }

  public goToCurriculum = (curr: any, clone: boolean) => {
    this.globalVar.isCurriculumClone = clone;
    this.route.navigate(['/view/curriculum/' + curr.curriculumId]);
  }

  public sendForApprovalCurricula = (curr: any, parentIndex: any, index: any, template: TemplateRef<any>) => {
    this.popupData.dataTemp = {
      'curriculumId': curr.curriculumId,
      'curriculumTitle': curr.curriculumTitle,
      'status': this.globalVar.languageString.resources.manageTab.manageCurriculumIngestedMessage,
      'parentIndex': parentIndex,
      'rowIndex': index,
      'redirectUrl': '/curriculum/list/ingested',
    };
    this.popupData.confirmationMsg = "'" + curr.curriculumTitle + this.globalVar.languageString.resources.manageTab.manageCurriculumSentApprovalSuccessMessage;
    this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.manageCurriculumSendToApprovalConfirmMessage + curr.curriculumTitle + "'?"
    this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.manageCurriculumSendToApprovalMessage;
    this.modalRef = this.modalService.show(template);
  }

  public draftCurricula = (curr: any, parentIndex: any, index: any, template: TemplateRef<any>) => {
    this.popupData.dataTemp = {
      'curriculumId': curr.curriculumId,
      'curriculumTitle': curr.curriculumTitle,
      'status': this.globalVar.languageString.resources.manageTab.manageCurriculumNewLabel,
      'parentIndex': parentIndex,
      'rowIndex': index,
      'redirectUrl': '/curriculum/list/new'
    };
    this.popupData.confirmationMsg = "'" + curr.curriculumTitle + this.globalVar.languageString.resources.manageTab.manageCurriculumDraftSuccessMessage;
    this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.manageCurriculumSendToDraftConfirmMessage + curr.curriculumTitle + "'?";
    this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.manageCurriculumDraftLabel;
    this.modalRef = this.modalService.show(template);
  }

  public approveCurricula = (curr: any, parentIndex: any, index: any, template: TemplateRef<any>) => {
    this.popupData.dataTemp = {
      'curriculumId': curr.curriculumId,
      'curriculumTitle': curr.curriculumTitle,
      'status': this.globalVar.languageString.resources.manageTab.manageCurriculumApprovedLabel,
      'parentIndex': parentIndex,
      'rowIndex': index,
      'redirectUrl': '/curriculum/list/ingested'
    };
    this.popupData.confirmationMsg = undefined;
    this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.manageCurriculumApproveConfirmMessage + curr.curriculumTitle + "'?";
    this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.manageCurriculumApproveLabel;
    this.modalRef = this.modalService.show(template);
  }

  public rejectCurricula = (curr: any, parentIndex: any, index: any, template: TemplateRef<any>) => {
    this.popupData.dataTemp = {
      'curriculumId': curr.curriculumId,
      'curriculumTitle': curr.curriculumTitle,
      'status': this.globalVar.languageString.resources.manageTab.manageCurriculumRejectedLabel,
      'parentIndex': parentIndex,
      'rowIndex': index,
      'redirectUrl': '/curriculum/list/rejected'
    };
    this.popupData.confirmationMsg = "'" + curr.curriculumTitle + this.globalVar.languageString.resources.manageTab.manageCurriculumRejectedSuccessMessage;
    this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.manageCurriculumRejectConfirmMessage + curr.curriculumTitle + "'?";
    this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.manageCurriculumRejectLabel;
    this.modalRef = this.modalService.show(template);
  }

  public unapproveCurricula = (curr: any, parentIndex: any, index: any, template: TemplateRef<any>) => {
    this.popupData.dataTemp = {
      'curriculumId': curr.curriculumId,
      'curriculumTitle': curr.curriculumTitle,
      'status': this.globalVar.languageString.resources.manageTab.manageCurriculumDraftLabel,
      'parentIndex': parentIndex,
      'rowIndex': index,
      'redirectUrl': '/curriculum/list/new'
    };
    this.popupData.confirmationMsg = "'" + curr.curriculumTitle + this.globalVar.languageString.resources.manageTab.manageCurriculumUnApproveSuccessMessage;
    this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.manageCurriculumUnApproveConfirmMessage + curr.curriculumTitle + "'?";
    this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.manageCurriculumUnApproveLabel;
    this.modalRef = this.modalService.show(template);
  }
  public unrejectCurricula = (curr: any, parentIndex: any, index: any, template: TemplateRef<any>) => {
    this.popupData.dataTemp = {
      'curriculumId': curr.curriculumId,
      'curriculumTitle': curr.curriculumTitle,
      'status': this.globalVar.languageString.resources.manageTab.manageCurriculumIngestedLabel,
      'parentIndex': parentIndex,
      'rowIndex': index,
      'redirectUrl': '/curriculum/list/ingested'
    };
    this.popupData.confirmationMsg = "'" + curr.curriculumTitle + this.globalVar.languageString.resources.manageTab.manageCurriculumUnRejectSuccessMessage;
    this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.manageCurriculumUnRejectConfirmMessage + curr.curriculumTitle + "'?";
    this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.manageCurriculumUnRejectLabel;
    this.modalRef = this.modalService.show(template);
  }
  public undeleteCurricula = (curr: any, parentIndex: any, index: any, template: TemplateRef<any>) => {
    this.popupData.dataTemp = {
      'curriculumId': curr.curriculumId,
      'curriculumTitle': curr.curriculumTitle,
      'status': this.globalVar.languageString.resources.manageTab.manageCurriculumIngestedLabel,
      'parentIndex': parentIndex,
      'rowIndex': index,
      'redirectUrl': '/curriculum/list/ingested'
    };
    this.popupData.confirmationMsg = "'" + curr.curriculumTitle + this.globalVar.languageString.resources.manageTab.manageCurriculumUnDeleteSuccessMessage;
    this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.manageCurriculumUnDeleteConfirmMessage + curr.curriculumTitle + "'?";
    this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.manageCurriculumUnDeleteLabel;
    this.modalRef = this.modalService.show(template);
  }

  public deleteCurricula = (curr: any, parentIndex: any, index: any, template: TemplateRef<any>) => {
    this.httpRestClient.getForUrl('api/intermediary/alignmentsExits?iri=/curriculum/' + curr.curriculumId, undefined, undefined).subscribe((result) => {
      if (result === 'true') {
        this.popupData.dataTemp = {
          'curriculumId': curr.curriculumId,
          'curriculumTitle': curr.curriculumTitle,
          'status': this.globalVar.languageString.resources.manageTab.manageCurriculumDeletedLabel,
          'parentIndex': -1,
          'rowIndex': -1,
          'redirectUrl': '/curriculum/list/deleted'
        };
        this.popupData.confirmationMsg = "'" + curr.curriculumTitle + this.globalVar.languageString.resources.manageTab.manageCurriculumDeleteSuccessMessage;
        this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.manageCurriculumDeleteConfirmMessageForAlignments + curr.curriculumTitle + "'?";
        this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.manageCurriculumDeleteLabel;
        this.modalRef = this.modalService.show(template);
      } else {
        this.popupData.dataTemp = {
          'curriculumId': curr.curriculumId,
          'curriculumTitle': curr.curriculumTitle,
          'status': this.globalVar.languageString.resources.manageTab.manageCurriculumDeletedLabel,
          'parentIndex': -1,
          'rowIndex': -1,
          'redirectUrl': '/curriculum/list/deleted'
        };
        this.popupData.confirmationMsg = "'" + curr.curriculumTitle + this.globalVar.languageString.resources.manageTab.manageCurriculumDeleteSuccessMessage;
        this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.manageCurriculumDeleteConfirmMessage + curr.curriculumTitle + "'?";
        this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.manageCurriculumDeleteLabel;
        this.modalRef = this.modalService.show(template);
      }
    }, (error) => {
      if (error.status == 500) {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
      }
    });
  }

  public deleteCurriculaAfterUpload = (curriculumId: any, template: any) => {
    this.popupData.dataTemp = {
      'curriculumId': curriculumId,
      'curriculumTitle': '',
      'status': this.globalVar.languageString.resources.manageTab.manageCurriculumDeletedLabel,
      'parentIndex': -1,
      'rowIndex': -1,
      'redirectUrl': '/curriculum/upload'
    };
    this.popupData.confirmationMsg = "'" + '' + this.globalVar.languageString.resources.manageTab.manageCurriculumDeleteSuccessMessage;
    this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.manageCurriculumDeleteConfirmMessageForAlignments + "'?";
    this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.manageCurriculumDeleteLabel;
    this.modalRef = this.modalService.show(template);
  };

  public doUpdateCurriculumStatus = () => {
    var data = this.popupData.dataTemp;
    this.updateCurriculaStatus(data.curriculumId, data.curriculumTitle, data.status, data.parentIndex, data.rowIndex, data.redirectUrl);
  }

  public editCurricula = (curr: any) => {
    this.route.navigate(["/curriculum/" + curr.curriculumId + "/edit"]);
  }
  public exportForEdit = (curr: any) => {
    this.callForXLSEdit = true;
    this.currentCurriculumId = curr.curriculumId;
    this.generateCurriculumXLSForEdit(curr);
  }
  public openSubstitutionSet = (curr: any) => {
    this.preloadData.selectedCurriculumDetails = curr;
    this.route.navigate(["/substitution/" + curr.curriculumId]);
  }
  public updateCurriculaStatus = (curriculumId: any, curriculumTitle: string, status: any, parentIndex: any, index: any, redirectUrl: any) => {
    let url = 'api/curriculum/' + curriculumId + '/update?status=' + status;
    this.httpRestClient.postForUrl(url, undefined, undefined).subscribe((result) => {
      if (result.errorMessage) {
        this.confirmationPopup.showErrorMessage(result.errorMessage);
      } else {
        if (this.globalVar.languageString.resources.manageTab.manageCurriculumApprovedLabel == status) {
          this.confirmationPopup.showSuccessMessage("'" + curriculumTitle + this.globalVar.languageString.resources.manageTab.manageCurriculumApprovedSuccessfullyMessage);
        }
        if (parentIndex > -1 && index > -1) {
          var curriculumCountryListBean = this.globalVar.curriculaListBean.curriculumCountryListBean[parentIndex];
          curriculumCountryListBean.curriculaInfoList.splice(index, 1);
          if (curriculumCountryListBean.curriculaInfoList.length == 0) {
            this.globalVar.curriculaListBean.curriculumCountryListBean.splice(parentIndex, 1);
          }
          this.updateListSize();
        } else {
          if (redirectUrl) {
            this.route.navigate([redirectUrl]);
            this.initCurriculumList(status);
          } else {
            this.route.navigate(["/curriculum/list/new"]);
            this.initCurriculumList('new');
          }
        }
      }
    }, (error) => {
      if (error.status == 500) {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
      }
    });
  }

  public lockUnlockCurricula = (curr: any) => {
    let url = 'api/curricula?iri=' + curr.curriculumId + '&lockedBy=' + this.globalVar.userProfile.userName;
    let message = '';
    if (!JSON.parse(curr.lockedForAlignment === undefined ? 'false' : curr.lockedForAlignment)) {
      url = url + '&lock=true';
      message = this.globalVar.languageString.resources.homeTab.curriculaLockSuccessMessage;
    } else {
      url = url + '&lock=false';
      message = this.globalVar.languageString.resources.homeTab.curriculaUnlockSuccessMessage;
    }
    this.httpRestClient.postForUrl(url, {}, {}).subscribe((result) => {
      this.confirmationPopup.showSuccessMessage(message);
      curr.lockedForAlignment = !JSON.parse(curr.lockedForAlignment === undefined ? 'false' : curr.lockedForAlignment);
    }, (error) => {
      if (error.status == 500) {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
      }
    })
  }

  public generateCurriculumXLSForEdit = (curr: any) => {
    this.downloadUrlForEdit = undefined;
    this.generatingXLSForEdit = true;
    this.callForXLSEdit = false;
    let curriculumID = curr.curriculumId;
    this.confirmationPopup.showSuccessMessage(this.globalVar.languageString.resources.homeTab.curriculumExcelSendingMessage);
    let url = 'api/curriculum/' + curriculumID + '/exportforEdit?userMailId=' + this.globalVar.userProfile.userMail;
    // const params = new HttpParams().set('userMailId', this.globalVar.userProfile.userMail);
    // let header = {'params': params}
    this.httpRestClient.postForUrl(url, undefined, undefined).subscribe((result) => {

    }, (error) => {
      this.generatingXLSForEdit = false;
      // this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.homeTab.curriculumExcelGenerateErrorMessage);
    });
  }

  public openManageFilter = (event: any) => {
    event.stopPropagation();
    var element = document.getElementsByClassName("mblermngFilter");
    var mblermngFilter = document.getElementsByClassName("mbleCurrProdMngMenu");
    element[0].classList.toggle("displayBlockMble");
    element[0].classList.toggle("displayNoneMble");
    mblermngFilter[0].classList.remove('displayBlockMble')
    mblermngFilter[0].classList.add('displayNoneMble')
  }

  public openMoreOption = (event: any) => {
    event.stopPropagation();
    var element = document.getElementsByClassName("mbleCurrProdMngMenu");
    var mblermngFilter = document.getElementsByClassName("mblermngFilter");
    element[0].classList.toggle("displayBlockMble");
    element[0].classList.toggle("displayNoneMble");
    mblermngFilter[0].classList.remove('displayBlockMble')
    mblermngFilter[0].classList.add('displayNoneMble')
  }

  getInnerHtml(description: any) {
    if (description) {
      return this.sanitizer.bypassSecurityTrustHtml(description);

    }
    else {
      return this.sanitizer.bypassSecurityTrustHtml('');
    }
  }

  ChangeDeployToRealeaseStatus(event: any, curr: any, template: any) {
    this.popupData.dataTemp = {
      'curriculumId': curr.curriculumId,
      'curriculumTitle': curr.curriculumTitle,
    };
    this.curriculumId = curr.curriculumId;
    this.deployToReleaseFlag = curr.deployedToRealize;
    // this.popupData.confirmationMsg = "'" + curr.curriculumTitle + this.globalVar.languageString.resources.manageTab.manageCurriculumSentApprovalSuccessMessage;
    if (curr.deployedToRealize) {
      this.popupData.popupMessage = "Do you want to toggle off"

    }
    else {
      this.popupData.popupMessage = "Do you want to toggle on"
    }
    this.popupData.buttonLabel = "yes"
    // this.popupData.isCancel = false;
    this.modalRef = this.modalService.show(template);
  }

  toggleChange = () => {
    let deployString = ''
    if (this.deployToReleaseFlag) {
      deployString = '&deployedToRealize=false'
    }
    else {
      deployString = '&deployedToRealize=true';
    }
    let url = 'api/curriculum/updateRealizeStatus?curriculumId=' + this.curriculumId + deployString;
    const headers: any = {
      responseType: 'text'
    };
    this.httpRestClient.postForUrl(url, undefined, headers).subscribe((result) => {
      this.globalVar.activeMainMenu = 'manage';
      this.initCurriculumList(this.activeMenuName);
    }, (error) => {
      this.confirmationPopup.showErrorMessage('Toggle off failed');
    });
  }

}