import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-product-tree',
  templateUrl: './manage-product-tree.component.html',
  styleUrls: ['./manage-product-tree.component.scss']
})
export class ManageProductTreeComponent implements OnInit {
  @Input() discipline: any;
  @Input() disciplinedprogram: any;
  @Input() program: any;
  @Input() product: any;

  @Input() doFunction: any;

  @Input() addEditProgramedDiscipline: any;
  @Input() addEditProgram: any;
  @Input() addEditProduct: any;

  @Input() deleteProduct: any;
  @Input() deleteProgram: any;
  @Input() deleteProgramedDiscipline: any;
  

  constructor() { }

  ngOnInit(): void {
  }

  public expandCollapse = (data: any, event: any) => {
    var currNode = event.target as HTMLInputElement;
    if(currNode?.parentElement?.parentElement?.parentElement?.classList.contains('treeOpen')) {
      currNode?.classList.remove("treeOpen");
      currNode?.parentElement?.parentElement?.parentElement?.classList.remove("treeOpen");
    } else {
      currNode?.parentElement?.parentElement?.parentElement?.classList.add("treeOpen");
      this.getNodeDetails(currNode);
    }
  }

  public getNodeDetails = (currNode: any) => {
    if(this.discipline && typeof this.discipline.disciplinedprogramFacet == "undefined") {
      currNode?.classList.add("treeLoading");
      this.doFunction(this.discipline, currNode);
    } else if(this.disciplinedprogram && typeof this.disciplinedprogram.programFacet == "undefined") {
      currNode?.classList.add("treeLoading");
      this.doFunction(this.disciplinedprogram, currNode);
    } else if(this.program && typeof this.program.productFacet == "undefined") {
      currNode?.classList.add("treeLoading");
      this.doFunction(this.program, currNode);
    } else {
      currNode?.classList.add("treeOpen");
    }
  }

}
