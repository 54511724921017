import { Component, OnInit, TemplateRef, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpRestClientService } from 'src/app/services/common/http-rest-client.service';
import { PreloadDataService } from 'src/app/services/common/preload-data.service';
import { ConfirmationPopupService } from 'src/app/services/confirmation-popup/confirmation-popup.service';
import { GlobalVariablesService } from 'src/app/services/global-variables/global-variables.service';
import { ManagePageComponent } from 'src/app/components/manage-page/manage-page.component';
//import { ArrayRangePipe } from 'src/app/services/pipe';
import * as _ from "underscore";
import { any, result } from 'underscore';

@Component({
  selector: 'app-intermediary-align-home',
  templateUrl: './intermediary-align-home.component.html',
  styleUrls: ['./intermediary-align-home.component.scss']
})
export class IntermediaryAlignHomeComponent implements OnInit {

  public intermediaryScreenView = 'default';
  public loading = false;
  public prevTab: any;
  public activeTab = 'tab-head-1';
  public selectedTab1 = true;
  public intermediaryMappingDataBean: any;
  public countryList: any;
  public tempCurriculumList: any[] = [];
  public gradeBeanList: any[] = [];
  public tempYearList: any[] = [];
  public tempGradeList: any[] = [];
  public tempProdList: any[] = [];
  public subjectIntermediaryList: any;
  public intermediaryMappingBeanSubject: any;
  public intermediaryMappingBeanProgram: any;
  public intermediaryMappingBeanCourse: any;
  public intermediaryMappingBeanTempProdList: any;
  public intermediaryMappingBeanYear: any;
  public intermediaryMappingBeanPrdGrade: any;
  public intermediaryMappingBeanProdList: any;
  public disciplineMessage = "Choose Discipline";
  public gradeMessage = "Choose Grade";
  public productMessage = "Choose Product";
  public programMessage = "Choose Program";
  public courseMessage = "Choose Course";
  public curriculumMessage = "Choose Curriculum/Standard";
  public countryMessage = "Choose Country";
  public subjectIntermediaryListCopy: any;
  public hasPlan: any;
  public intermediaryAlignData: any = {
    treeRequestData: {
      iri: '',
      gradeMaxIRI: '',
      gradeMinIRI: '',
      strandIRI: ''
    },
    gradeInfo: {
      selectedGrade: {},
      selectedLocalGrade: {}
    },
    rootIntermediary: {},
    lockedForAlignment: false,
    metadataInfo: {
      mappingType: '',
      disciplineName: '',
      countryName: '',
      title: '',
    },
    canUploadFile: this.route.getCurrentNavigation()?.extras,
    curriculaPreviewBean: undefined
  };
  selectedData: any;
  public selectedList: any = {
    subject: "",
    country: "",
    curriculum: "",
    curGrade: "",
    prdSubject: {},
    program: {},
    course: {},
    prdGrade: {},
    product: {}
  };

  public intermediaryPreSelectedDropdown: any = {
    'intermediaryMappingBean': {
      'intermediaryMappingDataBean': {
        'subjectBeanList': any
      }
    },
    'selectedList': {
      'subject': any
    }
  };

  @Input() prdSubject: any = "";
  @Input() program: any = "";
  @Input() course: any = "";
  @Input() prdGrade: any = "";
  @Input() product: any = "";
  isDataExist = false;

  // @Output() login = new EventEmitter<any>();

  constructor(
    private actRoute: ActivatedRoute,
    private route: Router,
    private httpRestClient: HttpRestClientService,
    private preLoadData: PreloadDataService,
    public globalVar: GlobalVariablesService,
    private modalService: BsModalService,
    private confirmationPopup: ConfirmationPopupService
  ) { }

  ngOnInit(): void {
    this.globalVar.isFromHome = "";
    this.globalVar.intermediaryPreSelectedDropdown = this.intermediaryPreSelectedDropdown;
    this.subjectIntermediaryList = this.globalVar.languageString?.resources?.subjectMappingsMetadata;
    if (this.subjectIntermediaryList != undefined) {
      this.globalVar.saveSubjectMappingData(this.subjectIntermediaryList);
    }
    this.loadList();
    this.loadProductDiscipline();
  }

  public getIntermediaryScreenName = () => {
    var name;
    if (this.intermediaryScreenView === 'standards_advanced_search') {
      name = 'Curriculum/Standards Search';
    } else if (this.intermediaryScreenView === 'products_advanced_search') {
      name = 'Product Search';
    } else {
      name = "Alignment";
    }
    return name;
  }

  public loadList = () => {
    let data: any = localStorage.getItem("dataForBackToSelection");
    if (JSON.parse(data)) {
      this.selectedData = JSON.parse(data);
      localStorage.removeItem("dataForBackToSelection");
    }
    this.disciplineMessage = "Please wait Discipline is loading..";
    var url = "api/browse/menu";
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.intermediaryMappingDataBean = result.subjectBeanList;
      if (this.selectedData && this.selectedData.tabselected) {
        let selectedSubject = this.intermediaryMappingDataBean.find((item: any) => { return item.subjectCode == this.selectedData?.subject?.subjectCode })
        this.selectedList.subject = selectedSubject;
        this.loadSubject();
      }
      else {
        this.selectedList.subject = "";
      }
      this.disciplineMessage = "Choose Discipline";
    }, (error) => {
      console.log("error occured")
    });
  }

  public loadSubject = () => {
    this.countryMessage = "Please wait country is loading..";
    var subject = this.selectedList.subject;
    for (var i = 0; i < this.intermediaryMappingDataBean.length; i++) {
      if (this.intermediaryMappingDataBean[i].subjectDesc === subject.subjectDesc) {
        this.countryList = this.intermediaryMappingDataBean[i];
      }
    }
    this.globalVar.intermediaryPreSelectedDropdown.intermediaryMappingBean.intermediaryMappingDataBean.subjectBeanList = this.intermediaryMappingDataBean;
    this.globalVar.intermediaryPreSelectedDropdown.selectedList.subject = this.selectedList.subject;
    this.countryMessage = "Choose Country";
    if (this.selectedData) {
      let selectedCountry = this.countryList.countryBeanList.find((item: any) => { return item.countryDesc == this.selectedData?.country })
      this.selectedList.country = selectedCountry?.countryDesc;
      this.loadCountry();
    }
    else {
      this.selectedList.country = "";
    }
  }

  public loadCountry = () => {
    this.curriculumMessage = "Please wait Curriculum/Standard is loading..";
    this.tempCurriculumList = [];
    var country = this.selectedList.country;
    var url = 'api/view/list/approved/' + country;
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      console.log(result);
      result.curriculumCountryListBean[0].curriculaInfoList.forEach((attr: any) => {
        if (attr.curriculumTitle.toLowerCase().indexOf(this.selectedList.subject.subjectDesc.toLowerCase()) != -1) {
          this.tempCurriculumList.push(attr);
        }
      });
      this.curriculumMessage = "Choose Curriculum/Standard";
      if (this.selectedData) {
        let selectedCurriculum = this.tempCurriculumList.find((item: any) => { return item.curriculumId == this.selectedData?.curriculum?.curriculumId })
        this.selectedList.curriculum = selectedCurriculum;
        this.loadCurriculum();
      }
      else {
        this.selectedList.curriculum = "";
      }
      console.log(this.tempCurriculumList);
    }, (error) => {
      console.log(error);
    });
  }

  public loadCurriculum = () => {
    this.gradeMessage = "Please wait Grade is loading..";
    var curriculumId = this.selectedList?.curriculum?.curriculumId;
    var url = 'api/objective/grades?iri=' + curriculumId;
    this.preLoadData.updateProductIRI(curriculumId);
    const headers: any = {
      dataType: 'json',
      contentType: 'application/json',
      mimeType: 'application/json'
    };
    this.httpRestClient.getForUrl(url, headers, undefined).subscribe((result) => {
      this.gradeBeanList = result.standardGradeList;
      this.gradeMessage = "Choose Grade";
      if (this.selectedData) {
        let selectedGrade = this.gradeBeanList.find((item: any) => { return item.grade == this.selectedData?.curGrade?.grade })
        this.selectedList.curGrade = selectedGrade;
        this.selectedData = undefined;
      }
      else {
        this.selectedList.curGrade = "";
      }
    }, (error) => {
      console.log(error);
    });
  }

  public viewHierarchyScreen = () => {
    //this.preLoadData.isFromIntermediaryAlignPage.next(true);
    let selectedDetails = this.selectedList;
    selectedDetails.tabselected = this.selectedTab1;
    localStorage.setItem("selectedData", JSON.stringify(this.selectedList));
    this.preLoadData.updateProductMapping(false);
    this.preLoadData.updateAlignmentData(this.selectedList);
    this.preLoadData.updateGradeBeanList(this.gradeBeanList);
    this.intermediaryAlignData.treeRequestData = {
      iri: 'https://data.savvas.com/curriculum/loc-structure/' + this.selectedList.curriculum.curriculumId + "#this",
      gradeMaxIRI: this.selectedList.curGrade.gradeMaxIRI,
      gradeMinIRI: this.selectedList.curGrade.gradeMinIRI
    };
    if (this.selectedList.curGrade.gradeBeanList?.length > 1) {
      this.intermediaryAlignData.gradeInfo.selectedLocalGrade = this.selectedList.curGrade.gradeBeanList[0];
      this.intermediaryAlignData.treeRequestData.strandIRI = this.selectedList.curGrade.gradeBeanList[0].gradeIRI;
      //this.preLoadData.updateProductIRI(this.intermediaryAlignData.treeRequestData.strandIRI);
    }
    this.preLoadData.updateIntermediaryAlignData(this.intermediaryAlignData)
    if (this.selectedList.country && this.selectedList.subject && this.selectedList.curGrade && this.selectedList.curriculum) {
      this.route.navigate(['intermediary/align']);
    }
    else {
      this.confirmationPopup.showErrorMessage('All Values are mandatory');
    }
  }

  public isBlankObject = (obj: any) => {
    var show = true;
    if (!_.isEmpty(obj)) {
      show = false;
    }
    return show;
  }

  public loadProductDiscipline = () => {
    if (this.subjectIntermediaryList == undefined) {
      this.subjectIntermediaryList = this.globalVar.mappingDataCopy;
    }
    var productDisciplineList = [];
    var url = "api/program";
    this.disciplineMessage = "please wait discipline is loading..";
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      productDisciplineList = result.item;
      this.intermediaryMappingBeanSubject = [];
      if (productDisciplineList !== undefined) {
        for (var i = 0; i < productDisciplineList.length; i++) {
          var subjectMappingLength = this.subjectIntermediaryList?.length;
          if (this.subjectIntermediaryList != undefined) {
            for (var j = 0; j < subjectMappingLength; j++) {
              if (this.subjectIntermediaryList[j].subjectID === productDisciplineList[i].subject) {
                if (!this.intermediaryMappingBeanSubject.includes(this.subjectIntermediaryList[j].subjectID)) {
                  this.intermediaryMappingBeanSubject.push({ subjectCode: productDisciplineList[i].subject, subjectDesc: this.subjectIntermediaryList[j].name });
                  break;
                }
              }
            }
          }
        }
        this.intermediaryMappingBeanSubject = _.uniq(this.intermediaryMappingBeanSubject, 'subjectCode');
        this.globalVar.intermediaryPreSelectedDropdown.intermediaryMappingBean.intermediaryMappingDataBean.subjectBeanList = this.intermediaryMappingBeanSubject;
        console.log('intermediaryMappingBeanSubject', this.intermediaryMappingBeanSubject);
        if (this.selectedData && !this.selectedData.tabselected) {
          this.selectedTab1 = false;
          let selectedPrdSubject = this.intermediaryMappingBeanSubject.find((item: any) => { return item.subjectDesc == this.selectedData?.prdSubject?.subjectDesc })
          this.prdSubject = selectedPrdSubject;
          this.loadProgram();
        }
        this.disciplineMessage = "Choose Discipline";
      }
    }, (error) => {
      console.log(error);
    });
  }

  public loadProgram = () => {
    if (this.prdSubject) {
      this.selectedList.prdSubject = this.prdSubject;
      var url = "api/program";
      this.programMessage = "Please wait program loading";
      this.intermediaryMappingBeanProgram = [];
      this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
        result.item.forEach((attr: any) => {
          if (this.prdSubject.subjectCode === attr.subject) {
            this.intermediaryMappingBeanProgram.push(attr);
          }
        });
        if (this.selectedData) {
          let selectedProgram = this.intermediaryMappingBeanProgram.find((item: any) => { return item.id == this.selectedData?.program?.id })
          this.program = selectedProgram;
          this.loadCourse();
        }
        this.programMessage = "Choose program";
      }, (error) => {
        console.log(error);
      });
    }
  }

  public loadCourse = () => {
    this.courseMessage = "Please wait course loading";
    this.selectedList.program = this.program;
    if (this.program) {
      var url = 'api/program?iri=' + this.program.id + '&expand=partial';
      this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
        this.intermediaryMappingBeanCourse = result?.hasPart;
        if (this.selectedData) {
          let selectedCourse = this.intermediaryMappingBeanCourse.find((item: any) => { return item.id == this.selectedData?.course })
          this.course = selectedCourse.id
          this.loadGrade();
        }
        this.courseMessage = "Choose Course";
      }, (error) => {
        console.log(error);
      });
    }
  }

  public loadGrade = () => {
    this.gradeMessage = "Please wait grade loading..";
    this.tempGradeList = [];
    this.intermediaryMappingBeanPrdGrade = [];
    this.selectedList.course = this.course;
    if (this.course) {
      var url = 'api/course?iri=' + this.course + '&expand=partial';
      this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
        if (result) {
          this.intermediaryMappingBeanTempProdList = result.hasPart;
          this.intermediaryMappingBeanTempProdList?.forEach((attr: any) => {
            this.tempYearList.push(attr);
            this.tempGradeList.push(attr.levelRange);
          });
          this.intermediaryMappingBeanYear = this.tempYearList;
          this.intermediaryMappingBeanPrdGrade = _.uniq(this.tempGradeList, 'notation');
        }
        if (this.selectedData) {
          let selectedGarde = this.intermediaryMappingBeanPrdGrade.find((item: any) => { return item.notation == this.selectedData?.prdGrade })
          this.prdGrade = selectedGarde.notation;
          this.loadProduct();
        }
        this.gradeMessage = "Choose Grade";
      }, (error) => {
        console.log(error);
      });
    }
  }

  public loadProduct = () => {
    this.productMessage = "Please wait product loading..";
    this.intermediaryMappingBeanProdList = [];
    this.selectedList.prdGrade = this.prdGrade;
    this.tempProdList = [];
    if (this.prdGrade) {
      this.intermediaryMappingBeanTempProdList.forEach((attr: any) => {
        if (attr.levelRange.notation === this.prdGrade) {
          this.tempProdList.push(attr);
        }
      });
      this.intermediaryMappingBeanProdList = this.tempProdList;
    }
    if (this.selectedData) {
      let selectedProduct = this.intermediaryMappingBeanProdList.find((item: any) => { return item.name == this.selectedData?.product })
      this.product = selectedProduct.name;
      this.selectedData = undefined;
    }
    this.productMessage = "Choose product";
  }

  public viewProductHierarchyScreen = () => {
    this.selectedList.product = this.product;
    let selectedDetails = this.selectedList;
    selectedDetails.tabselected = this.selectedTab1;
    localStorage.setItem("selectedData", JSON.stringify(this.selectedList));
    this.preLoadData.updateAlignmentData(this.selectedList);
    this.preLoadData.updateGradeBeanList(this.gradeBeanList);
    console.log(this.intermediaryMappingBeanProdList);
    if (this.program && this.course && this.prdGrade && this.prdSubject && this.product) {
      this.preLoadData.updateAlignmentData(this.selectedList);
      this.preLoadData.updateGradeBeanList(this.gradeBeanList);
      for (var i = 0; i < this.intermediaryMappingBeanProdList.length; i++) {
        if (this.intermediaryMappingBeanProdList[i].name === this.product) {
          this.hasPlan = this.intermediaryMappingBeanProdList[i].hasPlan;
        }
      }
      this.preLoadData.updateProductIRI(this.hasPlan);
      this.intermediaryAlignData.treeRequestData = {
        iri: 'https://data.savvas.com/curriculum/plan/' + this.hasPlan + "#this"
      };
      if (this.selectedList.prdGrade.gradeBeanList?.length > 1) {
        this.intermediaryAlignData.gradeInfo.selectedLocalGrade = this.selectedList.prdGrade.gradeBeanList[0];
        this.intermediaryAlignData.treeRequestData.strandIRI = this.selectedList.prdGrade.gradeBeanList[0].gradeIRI;
      }
      this.preLoadData.updateProductMapping(true);
      this.preLoadData.updateIntermediaryAlignData(this.intermediaryAlignData)

      this.route.navigate(['intermediary/align']);
    }
    else {
      this.confirmationPopup.showErrorMessage('All Values are mandatory');
    }
  }

  public selectTab = (selectedTab: any) => {
    if (selectedTab === 'tab-head-1') {
      this.selectedTab1 = true;
    }
    else {
      this.selectedTab1 = false;
    }

  }

}
