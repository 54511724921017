import { Component, OnInit, TemplateRef, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpRestClientService } from 'src/app/services/common/http-rest-client.service';
import { PreloadDataService } from 'src/app/services/common/preload-data.service';
import { ConfirmationPopupService } from 'src/app/services/confirmation-popup/confirmation-popup.service';
import { GlobalVariablesService } from 'src/app/services/global-variables/global-variables.service';
import { ManagePageComponent } from 'src/app/components/manage-page/manage-page.component';
//import { ArrayRangePipe } from 'src/app/services/pipe';
import * as _ from "underscore";
import { any, result } from 'underscore';
import { CorrelationService } from 'src/app/services/correlation/correlation.service';

@Component({
  selector: 'correlation-left-side',
  templateUrl: './correlation-left-side.component.html',
  styleUrls: ['./correlation-left-side.component.scss']
})
export class CorrelationLeftSideComponent implements OnInit {
  public popupData: any = {};
  public modalRef: BsModalRef | undefined;
  correlationAdminScreenView = 'default';
  selectTab = 'left-standard-tabcontent';
  disciplineMessage = "Choose Discipline";
  prdDisciplineMessage = "Choose Discipline";
  interMediaryDisciplineMessage = "Choose Discipline";
  countryMessage = "Choose Country";
  curriculumMessage = "Choose Curriculum/Standard";
  programMessage = "Choose program";
  courseMessage = "Choose Course";
  prdGradeMessage = "Choose Grade";
  prdProductMessage = "Choose Product";
  programApiDetails: any;
  programList: any[] = [];
  courses: any;
  gradeMessage = "Choose Grade";
  strandMessage = "Choose Strand";
  disciplineList: any;
  interMediaryDisciplineList: any;
  prdDisciplineList: any[] = [];
  prgrades: any[] = [];
  pdProducts: any[] = [];
  countryList: any;
  curriculumList: any[] = [];
  subjectIntermediaryList: any;
  selectedCourseDetails: any;
  grades: any;
  strandList: any[] = [];
  selectedProduct: any;
  selectedCurriculum: any;
  isEdit = false;
  isTabChange = false;
  public selectedList: any = {
    subject: "",
    country: "",
    curriculum: "",
    curGrade: "",
    strand: "",
    prdSubject: "",
    program: "",
    course: "",
    prdGrade: "",
    product: "",
    intermediarySubject: ""
  };
  selectedIntermediarySubject: any;
  selectedTab: any;
  selectedStandardDetails: any;
  selectedProductDetails: any;
  selectedIntermediaryDetails: any;

  constructor(
    private actRoute: ActivatedRoute,
    private route: Router,
    private httpRestClient: HttpRestClientService,
    private preLoadData: PreloadDataService,
    public globalVar: GlobalVariablesService,
    private modalService: BsModalService,
    private confirmationPopup: ConfirmationPopupService,
    private correlationService: CorrelationService
  ) { }

  ngOnInit(): void {
    this.popupData.popupMessage = "Are you sure? There is some data selected.Any selection in the tab will not be persisted."
    this.popupData.buttonLabel = "yes"
    this.loadCurriculumDiscipline();
    this.loadProductDiscipline()
    this.loadIntermediaryDiscipline()
    this.confirmationPopup.popup$.subscribe(() => {
      if (this.isTabChange) {
        this.isTabChange = false;
        this.removeSelection();
        if (this.selectedTab == 'curriculum') {
          this.selectTab = 'left-standard-tabcontent';
        }
        if (this.selectedTab == 'product') {
          this.selectTab = 'left-product-tabcontent';
        }
        if (this.selectedTab == 'intermediary') {
          this.selectTab = 'left-intermediary-tabcontent';
        }
      }
    })
    this.resetLeftSide();
  }

  resetLeftSide() {
    this.correlationService.isReset.subscribe((data: any) => {
      if (data) {
        this.selectedList = {
          subject: "",
          country: "",
          curriculum: "",
          curGrade: "",
          strand: "",
          prdSubject: "",
          program: "",
          course: "",
          prdGrade: "",
          product: "",
          intermediarySubject: ""
        };
      }
      this.removeSelection();
    })
  }
  editCorrelation() {
    this.correlationService.editCorrelationEventLeft.subscribe((data: any) => {
      this.correlationService.isSave = true;
      this.correlationService.leftSelectedCurriculumDetails = undefined;
      this.correlationService.leftSelectedProductDetails = undefined;
      this.correlationService.leftSelectedInterMediaryDetails = undefined;
      console.log("list", data);
      if (data.leftMappingType === 'curricula') {
        this.selectedTab = 'curriculum';
        this.selectTab = 'left-standard-tabcontent';
        this.selectedList.subject = this.disciplineList.find((item: any) => {
          return item.subjectCode == data.leftSubjectIRI
        });
        this.loadCountry();
        this.selectedList.country = this.countryList.find((item: any) => {
          return item.countryCode == data.leftCountryIRI
        });
        this.loadCurriculum(true, data);
      }

      if (data.leftMappingType === 'product') {
        this.selectedTab = 'product';
        this.selectTab = 'left-product-tabcontent';
        this.selectedList.prdSubject = this.prdDisciplineList.find((item: any) => {
          return item.subjectCode == data.leftSubjectIRI
        });
        this.loadProgram();
        this.selectedList.program = this.programList.find((item: any) => {
          return item.id == data.leftProgramIRI
        });
        this.loadCourse(true, data);
      }
      if (data.leftMappingType === 'intermediary') {
        this.selectedTab = 'intermediary';
        this.selectTab = 'left-intermediary-tabcontent';
        this.selectedList.intermediarySubject = this.interMediaryDisciplineList.find((item: any) => {
          return item.subjectCode == data.leftSubjectIRI
        });
      }
    })
  }

  selectProduct() {
    if (this.selectedList.program != "" && this.selectedList.course != "" && this.selectedList.prdGrade != "" && this.selectedList.product != "") {
      this.selectedProduct = this.selectedList.product.name;
      this.selectedProductDetails = {
        prdSubject: this.selectedList.prdSubject,
        program: this.selectedList.program,
        course: this.selectedList.course,
        prdGrade: this.selectedList.prdGrade,
        product: this.selectedList.product
      }
      this.correlationService.leftSelectedProductDetails = this.selectedProductDetails;
      this.correlationService.getReportOptionsData();
    }
    else {
      this.confirmationPopup.showErrorMessage('All Values are mandatory');
    }
  }

  selectCurriculum() {
    if (this.selectedList.country != "" && this.selectedList.curriculum != "" && this.selectedList.curGrade != "") {
      if (!this.strandList.length || this.selectedList.strand != "") {
        this.selectedStandardDetails = {
          subject: this.selectedList.subject,
          country: this.selectedList.country,
          curriculum: this.selectedList.curriculum,
          curGrade: this.selectedList.curGrade,
          strand: (this.selectedList.strand != "" && this.strandList.length) ? this.selectedList.strand : 'NA'
        }
        this.selectedCurriculum = this.selectedList.curriculum.curriculumTitle;
        this.correlationService.leftSelectedCurriculumDetails = this.selectedStandardDetails;
        this.correlationService.getReportOptionsData();
      }
      else {
        this.confirmationPopup.showErrorMessage('All Values are mandatory');
      }
    }
    else {
      this.confirmationPopup.showErrorMessage('All Values are mandatory');
    }
  }

  selectInterMediary() {
    if (this.selectedList.intermediarySubject != "") {
      this.selectedIntermediaryDetails = {
        intermediarySubject: this.selectedList.intermediarySubject
      }
      this.selectedIntermediarySubject = this.selectedList.intermediarySubject.subjectDesc;
      this.correlationService.leftSelectedInterMediaryDetails = this.selectedIntermediaryDetails;
      console.log("kkk", this.selectedIntermediaryDetails)
      this.correlationService.getReportOptionsData();
    }
    else {
      this.confirmationPopup.showErrorMessage('All Values are mandatory');
    }
  }

  showConfirmationPopup(temp: any, selectedTab: any) {
    if (this.selectedCurriculum || this.selectedProduct || this.selectedIntermediarySubject) {
      this.selectedTab = selectedTab;
      this.isTabChange = true;
      this.modalRef = this.modalService.show(temp);
    }
    else {
      if (selectedTab == 'curriculum') {
        this.selectTab = 'left-standard-tabcontent';
      }
      if (selectedTab == 'product') {
        this.selectTab = 'left-product-tabcontent';
      }
      if (selectedTab == 'intermediary') {
        this.selectTab = 'left-intermediary-tabcontent';
      }
    }
  }

  public loadCurriculumDiscipline = () => {
    var url = "api/browse/menu";
    this.disciplineMessage = "Please wait discipline is loading..";
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.editCorrelation();
      this.disciplineList = result.subjectBeanList;
      this.disciplineMessage = "Choose Discipline"
    }, (error) => {
      console.log(error);
    });
  }
  public loadProductDiscipline = () => {
    var url = "api/program";
    this.prdDisciplineMessage = "Please wait discipline is loading..";
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      let prdDisciplineList = result.item;
      this.programApiDetails = result.item;
      this.prdDisciplineMessage = "Choose Discipline"
      this.subjectIntermediaryList = this.globalVar?.languageString?.resources?.subjectMappingsMetadata;
      if (this.subjectIntermediaryList?.length) {
        prdDisciplineList.forEach((prod: any) => {
          this.subjectIntermediaryList.forEach((sub: any) => {
            if (sub.subjectID === prod.subject) {
              this.prdDisciplineList.push({ subjectCode: prod.subject, subjectDesc: sub.name });
            }
          })
        })
        this.prdDisciplineList = this.prdDisciplineList.reduce((accumulator, current) => {
          if (!accumulator.find((item: any) => item.subjectDesc === current.subjectDesc)) {
            accumulator.push(current);
          }
          return accumulator;
        }, []);
      }
    }, (error) => {
      console.log(error);
    });
  }
  loadCountry() {
    if (this.selectedTab != 'intermediary') {
      this.selectedList.country = "";
    }
    if (this.disciplineList.length) {
      this.countryMessage = "Please wait country is loading"
      this.countryList = this.selectedList.subject.countryBeanList;
      this.countryMessage = "Choose Country"
    }
  }

  loadCurriculum(isEdit?: boolean, selectedCurriculum?: any) {
    this.strandList = [];
    this.selectedList.strand = "";
    this.curriculumList = [];
    this.selectedList.curriculum = '';
    this.curriculumMessage = "Please wait curriculum/standard is loading";
    var url = "api/view/list/approved/" + this.selectedList?.country?.countryDesc;
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.curriculumMessage = "Choose Curriculum/Standard";
      result.curriculumCountryListBean[0].curriculaInfoList.forEach((attr: any) => {
        if (attr.curriculumTitle.toLowerCase().includes(this.selectedList.subject.subjectDesc.toLowerCase())) {
          this.curriculumList.push(attr);
        }
      });
      if (isEdit) {
        this.selectedList.curriculum = this.curriculumList.find((item: any) => {
          return item.curriculumTitle == selectedCurriculum.leftMappingTitle
        });
        this.loadGrade(true, selectedCurriculum);
      }
    }, (error) => {
      console.log(error);
    });
  }

  loadGrade(isEdit?: any, selectedCurriculum?: any) {
    this.strandList = [];
    this.selectedList.strand = "";
    this.selectedList.curGrade = "";
    this.gradeMessage = "Please wait grade is loading";
    let url = "api/objective/grades?iri=" + this.selectedList?.curriculum?.curriculumId;
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.gradeMessage = "Choose Grade";
      this.grades = result.standardGradeList;
      this.grades = this.grades.reduce((accumulator: any, current: any) => {
        if (!accumulator.find((item: any) => item.grade === current.grade)) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
      if (isEdit) {
        this.selectedList.curGrade = this.grades.find((item: any) => {
          return ((item.gradeMaxIRI == selectedCurriculum.leftGradeMaxIRI) && (item.gradeMinIRI == selectedCurriculum.leftGradeMinIRI))
        });
        this.loadStrand(true, selectedCurriculum)
      }
    }, (error) => {
      console.log(error);
    });
  }

  loadStrand(isEdit?: any, selectedCurriculum?: any) {
    this.selectedList.strand = "";
    this.strandMessage = "Please wait strand is loading";
    this.strandList = [];
    let selectedGrade: any;
    this.grades.forEach((grade: any) => {
      if (grade.grade == this.selectedList.curGrade.grade) {
        selectedGrade = grade;
      }
    })
    if (selectedGrade?.gradeBeanList.length > 1) {
      this.strandList = selectedGrade?.gradeBeanList;
      this.strandMessage = "Choose Strand";
    }
    if (isEdit) {
      this.selectedList.strand = this.strandList.find((item: any) => {
        return item.gradeIRI == selectedCurriculum.leftStrandIRI
      });
    }
  }

  loadProgram() {
    this.selectedList.program = "";
    this.programList = [];
    this.programMessage = "Please wait program is loading";
    this.programApiDetails.forEach((pgm: any) => {
      if (pgm.subject == this.selectedList.prdSubject.subjectCode) {
        this.programList.push(pgm)
      }
    })
    this.programMessage = "Choose Program";
  }

  loadCourse(isEdit?: any, selectedProduct?: any) {
    this.selectedList.course = "";
    this.courseMessage = "Please wait course is loading";
    let url = "api/program?iri=" + this.selectedList.program.id + "&expand=partial"
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.courseMessage = "Choose Course";
      this.courses = result.hasPart;
      if (isEdit) {
        this.selectedList.course = this.courses.find((item: any) => {
          return item.id == selectedProduct.leftCourseIRI
        });
        this.loadPdGrade(true, selectedProduct);
      }
    }, (error) => {
      console.log(error);
    });
  }

  loadPdGrade(isEdit?: any, selectedProduct?: any) {
    this.selectedList.prdGrade = "";
    this.prdGradeMessage = "Please wait grade is loading";
    let url = "api/course?iri=" + this.selectedList.course.id + "&expand=partial"
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.prdGradeMessage = "Choose Grade";
      let prgrades = result.hasPart;
      this.selectedCourseDetails = prgrades;
      this.prgrades = [];
      prgrades.forEach((item: any) => {
        this.prgrades.push(item.levelRange)
      })
      this.prgrades = this.prgrades.reduce((accumulator: any, current: any) => {
        if (!accumulator.find((item: any) => item.notation === current.notation)) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
      if (isEdit) {
        this.selectedList.prdGrade = this.prgrades.find((item: any) => {
          return ((item.minLevel == selectedProduct.leftGradeMinIRI) && (item.maxLevel == selectedProduct.leftGradeMaxIRI))
        });
        this.loadPdProduct();
        this.selectedList.product = this.pdProducts.find((item: any) => {
          return ((item.levelRange.minLevel == selectedProduct.leftGradeMinIRI) && (item.levelRange.maxLevel == selectedProduct.leftGradeMaxIRI))
        });
      }
    }, (error) => {
      console.log(error);
    });
  }

  loadPdProduct() {
    this.selectedList.product = "";
    this.prdProductMessage = "Please wait product is loading"
    this.selectedCourseDetails.forEach((course: any) => {
      if (course.levelRange.notation == this.selectedList.prdGrade.notation) {
        this.pdProducts.push(course);
      }
    })
    this.prdProductMessage = "Choose Product"
  }



  loadIntermediaryDiscipline() {
    var url = "api/browse/intermediateMenu";
    this.interMediaryDisciplineMessage = "Please wait discipline is loading..";
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.interMediaryDisciplineList = result.subjectBeanList;
      this.interMediaryDisciplineMessage = "Choose Discipline"
    }, (error) => {
      console.log(error);
    });
  }

  confirmDeleteGrade() {
    console.log("")

  }

  removeSelection() {
    this.selectedCurriculum = undefined;
    this.selectedProduct = undefined;
    this.selectedIntermediarySubject = undefined;
    this.selectedProductDetails = undefined;
    this.selectedStandardDetails = undefined;
    this.selectedIntermediaryDetails = undefined;
    this.correlationService.leftSelectedProductDetails = undefined;
    this.correlationService.leftSelectedCurriculumDetails = undefined
    this.correlationService.leftSelectedInterMediaryDetails = undefined;
    this.correlationService.getReportOptionsData();
  }
}
