<div class="correlation-report-options-container" *ngIf="correlationService.incPeripherals || correlationService.incIntermediaryDetails || correlationService.alfrescoExportFormat || correlationService.realizeStandFormat || correlationService.showKeyAlignments || correlationService.reverseIntFormat">		
	<span class="correlation-report-options">Report Options:</span>
	<div class="correlation-report-input correlation-checkbox" *ngIf="correlationService.incPeripherals" style="margin-left: 10px;">
		<input type="checkbox" [(ngModel)]="includePeripheral"><label style="font-size: small;">Include Peripherals</label>
	</div>
	<div class="correlation-report-input correlation-checkbox" *ngIf="correlationService.incIntermediaryDetails">
		<span style="margin-left: 10px;">
			<input type="checkbox" [(ngModel)]="includeIntermediaryDetailsStandard"><label style="font-size: small;">Include Intermediary Details</label>
		</span>
	</div>
	<div class="correlation-report-input correlation-checkbox" *ngIf="correlationService.alfrescoExportFormat">
		<span style="margin-left: 10px;">
			<input type="checkbox" [(ngModel)]="includeAlfresco"><label style="font-size: small;">Alfresco Export Format(Intermediary Reports)</label>
		</span>
	</div>
	<div class="correlation-report-input correlation-checkbox" *ngIf="correlationService.showKeyAlignments">	
		<span style="margin-left: 10px;">
			<input type="checkbox" [(ngModel)]="showKeyAlignments"><label style="font-size: small;">Show Only Key Alignments</label>
		</span>
	</div>
	<div class="correlation-report-input correlation-checkbox" *ngIf="correlationService.realizeStandFormat">	
		<span style="margin-left: 10px;">
			<input type="checkbox" [(ngModel)]="realizeStandards"><label style="font-size: small;">Realize Standards format</label>
		</span>
	</div>
	<div class="correlation-report-input correlation-checkbox" *ngIf="correlationService.reverseIntFormat">	
		<span style="margin-left: 10px;">
			<input type="checkbox" [(ngModel)]="reverseIntermediary"><label style="font-size: small;">Reverse Intermediary Format</label>
		</span>
	</div>
</div>
<div class="correlation-save-container">
	<span>
		<input type="text" [(ngModel)]="reportName" class="correlation-text-box correlation-save-input" (keyup)="restrictedCharOff()" (focusout)="showRestrictedChar=false" required />
		<span class="restrictedChar"[ngClass]="{'restrictedCharView': showRestrictedChar}">File name cannot contain # ?
			; \ / * " :</span>
	</span>
	<button type="button" class="correlation-button correlation-report-lo-button-save" (click)="saveCorrelationReport()" aria-label="save">Save
	</button>
	<button type="button" class="correlation-button correlation-report-lo-button-reset" aria-label="reset"  (click)="resetReportName()">Reset
	</button>

</div>