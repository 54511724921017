import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HttpRestClientService } from 'src/app/services/common/http-rest-client.service';
import { ConfirmationPopupService } from 'src/app/services/confirmation-popup/confirmation-popup.service';
import { GlobalVariablesService } from 'src/app/services/global-variables/global-variables.service';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-edit-topic-popup',
  templateUrl: './add-edit-topic-popup.component.html',
  styleUrls: ['./add-edit-topic-popup.component.scss'],
  providers:[ToolbarService]
})
export class AddEditTopicPopupComponent implements OnInit {
  @Input() createItemBean: any;
  public tools: object = {
    items: [ 'Bold', 'Italic', 'Underline'],
    bold:{
      tag:'strong'
    }
  };
  @Input() modalRef: any;
  @Input() saveTopic: any;
  @Input() deployedToRealize:any;

  public height: number = 500;
  public pleasewait: boolean = false;
  public disbaledAfterClick: boolean = false;

  @ViewChild('templateRTE') rteEle: RichTextEditorComponent | undefined;

  constructor(
    private httpRestClient : HttpRestClientService,
    public globalVar: GlobalVariablesService,
    private modalService: BsModalService,
    private confirmationPopup: ConfirmationPopupService,
    private actRoute : ActivatedRoute
  ) { }

  ngOnInit(): void {
    
  }

  public saveTopicData = () => {
    this.disbaledAfterClick=true;
    this.pleasewait=true;
    // this.createItemBean.topicDesc = "" + this.rteEle?.getHtml();
    console.log(this.createItemBean);
    this.saveTopic();
  }

  public closePopup = () => {
    this.modalRef.hide();
  }

}
