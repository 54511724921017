<div class="loader-container" *ngIf="loading">
    <div class="loading-overlay"></div>
    <div class="loading-icon">
        <img src="assets/images/loading_100x100.gif" />
    </div>
</div>
<div class="substitutionSetSection">
	<div class="subsMenu">
		<ul>
			<li [ngClass]="{'active': selectedSubTab === 'uploadSub'}" (click)="selectedSubTab !== 'uploadSub' ? getUploadSubsTab('uploadSub') : ''">Upload Substitution Set</li>
			<li [ngClass]="{'active': selectedSubTab === 'updateSub'}" (click)="selectedSubTab !== 'updateSub' ? getSubsTab('updateSub') : ''">Update Substitution Set</li>
			<li [ngClass]="{'active': selectedSubTab === 'createSub'}" (click)="selectedSubTab !== 'createSub' ? getSubsTab('createSub') : ''">Create/Update Substitution Set</li>
		</ul>
	</div>
	<div class="subsContent">
		<div *ngIf="selectedSubTab === 'uploadSub'" class="uploadSub">
			<div class="row">
				<div class="col-sm-12 clearfix">
					<div class="pull-left">
						<label class="font_13_333333_bold">Selected Curriculum: </label>
						<label class="selectedCurrForSub">{{selectedCurriculum?.curriculumTitle}}</label>
					</div>
				</div>
				<div class="col-sm-12">
					<div style="padding-top: 20px; border-top: 1px solid #E4E4E4;">
		                <div>Select File for Upload</div>
		                <div class="curriculumEmptyListPanelText">You can select an Excel file for upload below.</div>
		            </div>
		            <div style="padding-top: 10px;">
                        <app-uploader [action]="'api/subset/upload'" [bean]="selectedCurriculum" [fromScreen]="'uploadSub'"></app-uploader>
		            </div>
				</div>
			</div>
		</div>
		<div *ngIf="selectedSubTab === 'updateSub'" class="updateSub">
			<div class="row">
				<div class="col-sm-12 clearfix">
					<label class="font_13_333333_bold">Selected Curriculum: </label>
					<label class="selectedCurrForSub">{{selectedCurriculum?.curriculumTitle}}</label>
				</div>
				<div class="col-sm-12 clearfix" style="padding-top: 20px; border-top: 1px solid #E4E4E4;">
					<div class="pull-left selectSubsSetLab">
						<label class="font_13_333333_bold">Substitution Set*</label>
						<div class="validationCheck">
							<select class="cmUpdateDraftDropDown currUpdateDraftDropDown" [(ngModel)]="selectedSubstitution" 
								[ngClass]="{ 'ng-invalid-required' : selectedSubstitution == ''}" (change)="assignSubsToParent(selectedSubstitution)">
								<option value="" hidden>Select Substitution Set</option>
								<option *ngFor="let currSub of listedsubs" [value]="currSub?.subSetIRI">{{currSub?.subSetName}}</option>
							</select>
						</div>
					</div>
					<div class="pull-left clearfix">
						<div (click)="exportSubstitution('updateSub')" class="substitution-export-link" [hidden]="!(!globalVar?.generatingXLS && !globalVar?.downloadUrl)">
							{{globalVar?.languageString?.resources?.manageTab?.manageUploadExportReingestionBtnLabel}}
						</div>
					</div>
				</div>
				<div class="col-sm-12">
					<div style="padding-top: 20px; border-top: 1px solid #E4E4E4;">
		                <div style="font-size: 20px; color: #333333;">{{globalVar?.languageString?.resources?.manageTab?.manageUploadUpdateFileMessage}}</div>
		                <div class="curriculumEmptyListPanelText" style="border: none;">{{globalVar?.languageString?.resources?.manageTab?.manageUploadUpdateFileInfoMessage}}</div>
		            </div>
		            <div style="padding-top: 10px;">
						<app-uploader  [action]="'api/subset/update'" [bean]="selectedCurriculum" [subSet]="substitutionObj?.selectedSubs" [subsList]="listedsubs" [fromScreen]="'updateSub'"></app-uploader>
		            </div>
				</div>
			</div>
		</div>
		<div *ngIf="selectedSubTab === 'createSub'" class="createSub updateSub">
			<div class="row">
				<div class="col-sm-12">
					<label class="font_17_666666 fontBold">Selected Curriculum: </label>
					<label class="selectedCurrForSub">{{selectedCurriculum?.curriculumTitle}}</label>
				</div>
			</div>
			<div class="row">
				<label class="createSubsLabel col-sm-12 font_17_666666 fontBold">Create Substitution<i class="fa fa-info-circle" title="Create a substitution set to edit the values" ></i></label>
				<div class="col-sm-12 width70Per row">
					<div class="col-sm-3 paddingLeft50 font_13_333333_bold">
						<label class="font_13_333333_bold">Substitution Name: </label>
						<input type="text" [(ngModel)]="substitutionObj.substitutionName" 
							class="form-control subsNameInput ng-pristine ng-untouched ng-valid" placeholder="Substitution Name">
					</div>
					<div class="col-sm-1 paddingLeft0" style="width:0px">
						<input type="submit"  value="Create" (click)="createSubstitution()" class="createSubsButton correlation-button correlation-report-lo-button">
					</div>
				</div>
			</div>
			<div class="row updateSubsRow" style="padding-top: 20px; border-top: 1px solid #E4E4E4;">
				<label class="updateSubsLabel col-sm-12 font_17_666666 fontBold">Update Substitution</label>
				<div class="row col-sm-12 updateSubsRowSpan12">
					<div class="col-sm-3 paddingLeft20">
						<label class="font_13_333333_bold">Substitution Set*</label>
						<div class="validationCheck">
							<select [(ngModel)]="selectedSubstitution" class="cmUpdateDraftDropDown currUpdateDraftDropDown" 
								(change)="assignSubsToParent(selectedSubstitution)" style="width: 100%;"
								[ngClass]="{ 'ng-invalid-required' : selectedSubstitution == ''}">
								<option value="" hidden>Select Substitution Set</option>
								<option [value]="currSub.subSetIRI" *ngFor="let currSub of listedsubs"> {{currSub.subSetName}} </option>
							</select>
						</div>
					</div>
					<div class="col-sm-2 validationCheck">
						<label class="font_13_333333_bold">Select Grade: </label>
						<select class="cmUpdateDraftDropDown currUpdateDraftDropDown" [ngClass]="{ 'ng-invalid-required' : selectedGrade == ''}"
							[(ngModel)]="selectedGrade" (change)="setGrade(selectedGrade); resetLocalGrade(selectedGrade)" style="width: 100%;">
							<option value="" hidden>Choose Grades</option>
							<option [value]="currGrade.grade" *ngFor="let currGrade of currGrades"> {{currGrade.grade}} </option>
						</select>
					</div>
					<!-- *ngIf="selectedSubsGrade?.grade?.gradeBeanList?.length > 1" -->
					<div class="col-sm-2" *ngIf="selectedSubsGrade?.grade?.gradeBeanList?.length > 1" >
						<label class="font_13_333333_bold">Select Local Grade: </label>
						<select class="cmUpdateDraftDropDown currUpdateDraftDropDown"
							[(ngModel)]="selectedLocalGrade" (change)="setLocGrade(selectedGrade)" style="width: 100%;">
							<option value="" hidden>Choose Local Grades</option>
							<option [value]="currLocGrade" *ngFor="let currLocGrade of selectedSubsGrade.grade.gradeBeanList"> {{currLocGrade.gradeName}} </option>
						</select>
					</div>
					<div class="col-sm-1 myCol1">
						<input type="submit" value="Edit" (click)="loadDefaultTopics();" class="correlation-button correlation-report-lo-button">
					</div>
					<div class="col-sm-1 myCol1">
						<input type="submit" style="width:115%" value="Delete" (click)="deleteSubstitution()" class="correlation-button correlation-report-lo-button deleteSubsBtn">
					</div>
					<!-- <div class="col-sm-1 myCol1 hideFromDesk" ng-if="origTopicList.topicsList && !istopicsLoading">
						<input type="submit" value="Show Original Data" ng-click="showOriginalSubstitution()" class="correlation-button correlation-report-lo-button viewEditSubsMbleFull">
					</div> -->

					<div class="spinnerSubs col-sm-12" *ngIf="istopicsLoading">
						<img src="assets/images/loading_32x32.gif"/>
					</div>
				</div>
			</div>
			<div class="row-fluid editSubsTopicTree marginTop20" *ngIf="!istopicsLoading && origTopicList?.topicsList">
				<div class="forMbleScrollEditSub row marginTop20">
					<div class="col-sm-6 subsTemplateLeft">
						<app-topic-tree-view [topicData]="origTopicList" [addSubTopic]="editTopicComp.addSubTopic" 
							[addEditTopicPopup]="addEditTopicPopup" [deleteTopic]="editTopicComp.deleteTopic" [expandNodeIds]="editTopicComp.expandNodeIds"
							[loadTopics]="editTopicComp.loadTopics" [topicPreviewStaticData]="origTopicList" [isFromLeft]="'true'"></app-topic-tree-view>
					</div>
					<div class="col-sm-6 subsTemplateRight">
						<app-topic-tree-view [topicData]="origTopicList" [addSubTopic]="editTopicComp.addSubTopic" 
							[addEditTopicPopup]="addEditTopicPopup" [deleteTopic]="editTopicComp.deleteTopic" [expandNodeIds]="editTopicComp.expandNodeIds"
							[loadTopics]="editTopicComp.loadTopics" [topicPreviewStaticData]="origTopicList" [isFromLeft]="'false'"></app-topic-tree-view>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #deleteTopicConfirmation>
    <app-warning-popup [DoFunctionOnSuccess]="confirmDeleteSubs" [PopupMessage]="popupData?.popupMessage"
         [ButtonLabel] ="popupData?.buttonLabel" [modalRef]="modalRef"></app-warning-popup>
 </ng-template>

 <ng-template #addEditTopicPopup>
    <app-edit-subs-popup [modalRef]="editTopicComp.modalRef" [createItemBean]="editTopicComp.createItemBean" [saveTopic]="updateSingleSubs"></app-edit-subs-popup>
 </ng-template>