<div class="editCurrTopicEditContr">
    <div class="font_20_666666">
        <span [hidden]="!createItemBean?.addItem">{{globalVar?.languageString?.resources?.manageTab?.curriculumEditPopupAddLabel}}</span>
        <span [hidden]="createItemBean.addItem">{{globalVar?.languageString?.resources?.manageTab?.curriculumEditPopupEditLabel}}</span>
        {{globalVar?.languageString?.resources?.manageTab?.curriculumEditPopupTopicLabel}}
    </div>
    <div class="marginTop15">
        <div class="mainTopicArea">
            <div class="marginBottom10">
                <div class="font_15_666666 fontBold">{{globalVar?.languageString?.resources?.manageTab?.curriculumEditPopupTopicGradeLabel}}{{ createItemBean.gradeName }}. {{ createItemBean.localGradeName }}</div>
            </div>

            <div [hidden]="!(createItemBean.addItem && !createItemBean.addTopLevelTopic)" class="marginBottom10">
                <div class="font_15_666666 fontBold">{{globalVar?.languageString?.resources?.manageTab?.curriculumEditPopupParentTopicLabel}}</div>
                <div><span [innerHTML]="createItemBean.parentTopicDesc"></span></div>
            </div>

            <div class="marginBottom10">
                <div class="font_15_666666 fontBold">{{globalVar?.languageString?.resources?.manageTab?.curriculumEditPopupTopicDesLabel}}</div>
                <ejs-richtexteditor #templateRTE  [(ngModel)]="createItemBean.topicDesc" [toolbarSettings]='tools' [height]='height' placeholder="{{globalVar?.languageString?.resources?.manageTab?.curriculumEditPopupTopicDesTextMsg}}">
                </ejs-richtexteditor>
            </div>

            <div class="marginBottom10">
                <div class="font_15_666666 fontBold">{{globalVar?.languageString?.resources?.manageTab?.curriculumEditPopupStdCodeLabel}}</div>
                <input id="standardCode" [disabled]="deployedToRealize" [(ngModel)]="createItemBean.standardCode" type="text" class="form-control standardCodeArea" placeholder="{{globalVar?.languageString?.resources?.manageTab?.curriculumEditPopupStdCodeTextMsg}}"
                       required="required">
            </div>

        </div>
 		<div [hidden]="!pleasewait" class="font_13_666666" style="display: inline-block; padding-top: 20px;">{{globalVar?.languageString?.resources?.manageTab?.manageUploadWaitMessage}}</div>
        <div class="form-horizontal marginTop30">
            <input type="submit" ng-disabled="!createItemBean.topicDesc || !createItemBean.standardCode ||disbaledAfterClick" value="{{globalVar?.languageString?.resources?.others?.saveButtonLabel}}" class="cmButton cmButtonFull-green cmButton-standard"
                   (click)="saveTopicData()" />
            <a (click)="closePopup()" class="cmButton cmButton-url">{{globalVar?.languageString?.resources?.others?.cancelBtnLabel}}</a>
        </div>

    </div>
</div>

