<app-curriculum-menu [loadCurriculumList]='globalVar.loadCurriculumList' [addTabClass]='globalVar.addTabClass'></app-curriculum-menu>
<div class="curriculumTab uploadProducTab">
	<div class="curriculumEmptyListPanel">
		<div class="row">
			<div class="col-sm-2 product-textbox-width validationCheck">
				<label class="col-sm-12 font_13_333333_bold">{{globalVar?.languageString?.resources?.manageTab?.manageUploadDisciplineLabel}}</label>
				<div>
				<select class="cmDropDown currIngestDropDown"
					[(ngModel)]="injectCurriculumBean.curriculumUploadBean.subject"
					(disabled)="!injectCurriculumBean.curriculumIngestionDataBean.subjectFacets || injectCurriculumBean.curriculumIngestionDataBean.subjectFacets.length == 0"
					(change)="changeLink(injectCurriculumBean.curriculumUploadBean.subject)"
					[ngClass]="{ 'ng-invalid-required' : injectCurriculumBean.curriculumUploadBean.subject == ''}">
					<option value="" hidden selected >{{globalVar?.languageString?.resources?.manageTab?.manageUploadDisciplineSelectLabel}}</option>
					<option [value]="facetInfoBean?.facetUri" *ngFor="let facetInfoBean of injectCurriculumBean?.curriculumIngestionDataBean?.subjectFacets"> {{facetInfoBean?.facetName}} </option>
				</select>
				</div>
			</div> 
			<div class="col-sm-2 product-textbox-width validationCheck">
				<label class="col-sm-12 font_13_333333_bold">{{globalVar?.languageString.resources?.manageTab?.manageUploadProgramDrpDwnLabel}}</label>
				<div>
					<select class="cmDropDown currIngestDropDown" [ngClass]="{ 'ng-invalid-required' : selectedList.program == ''}" 
						[(ngModel)]="selectedList.program" (change)="refreshPrograms(selectedList.program)">
						<option value="" hidden selected >{{globalVar?.languageString?.resources?.manageTab?.manageUploadProgramDrpDwnMessage}}</option>
						<option [value]="programList.id" *ngFor="let programList of uploadProductBean.program"> {{programList.name}} </option>
					</select>
				</div>	
			</div>		
			<div class="col-sm-2 product-textbox-width validationCheck" >
				<label class="col-sm-12 font_13_333333_bold">{{globalVar?.languageString?.resources?.manageTab?.manageUploadCourseDrpDwnLabel}}</label>
				<div>
					<select class="cmDropDown currIngestDropDown" [(ngModel)]="selectedList.course" 
						[ngClass]="{ 'ng-invalid-required' : selectedList.course == ''}" (change)="refreshCourses(selectedList.course)">
						<option value="" hidden selected >{{globalVar?.languageString?.resources?.manageTab?.manageUploadCourseDrpDwnMessage}}</option>
						<option [value]="courseList.id" *ngFor="let courseList of uploadProductBean.course"> {{courseList.name}} </option>
					</select>
				</div>	
			</div>										
			<div class="col-sm-2 product-textbox-width validationCheck" >
				<label class="col-sm-12 font_13_333333_bold">{{globalVar?.languageString?.resources?.manageTab?.manageUploadProductDrpDwnLabel}}</label>			
				<div>	
					<select class="cmDropDown currIngestDropDown" [(ngModel)]="selectedList.product"
						[ngClass]="{ 'ng-invalid-required' : selectedList.product == ''}" (change)="refreshProducts(selectedList.product)">
						<option value="" hidden selected >{{globalVar?.languageString?.resources?.manageTab?.manageUploadCourseDrpDwnMessage}}</option>
						<option [value]="productList.id" *ngFor="let productList of uploadProductBean.product"> {{productList.name}} </option>
					</select>
				</div>	
			</div>
			<div class="col-sm-2 product-download-container product-textbox-width"> 
				<div (click)="exportProductSheet()" class="product-download" *ngIf="!globalVar?.generatingXLS && !globalVar?.downloadUrl">
					{{globalVar?.languageString?.resources?.manageTab?.manageUploadExportReingestionBtnLabel}}
				</div>
				<div class="product-download" *ngIf="globalVar?.generatingXLS && !globalVar?.downloadUrl">
					{{globalVar?.languageString?.resources?.manageTab?.manageUploadGeneratingMessage}}
				</div>
				<div *ngIf="selectedList.product" (click)="lockUnlockProduct()" class="product-lock" >
					<span [innerHTML]="getLockStatusLabel()" ></span> 
				</div>
			</div>	
			<!-- <div class="col-sm-2 product-lock-container product-textbox-width"> 
				<div *ngIf="selectedList.product" (click)="lockUnlockProduct()" class="product-lock" >
					<span [innerHTML]="getLockStatusLabel()" ></span> 
				</div>
			</div> -->
		</div>
		<div>
			<div class="product-select-file">{{globalVar?.languageString?.resources?.manageTab?.manageUploadFileMessage}}</div>
			<div class="curriculumEmptyListPanelText product-filetext-border">{{globalVar?.languageString?.resources?.manageTab?.manageUploadProgramMessage}}</div>
		</div>
		<div class="marginTop10">
			<app-uploader [action]="'api/product'" [fromScreen]="'product'" [bean]="injectCurriculumBean?.curriculumUploadBean"></app-uploader>
		</div>
		<div class="ignoreIntAlignUploadProd">
			<input type="checkbox"  [(ngModel)]="injectCurriculumBean.curriculumUploadBean.ignoreIntermediaryAlignments" name="Ignore Intermediary Alignments in Import / Update">
			<label class="" style="display: inline-block;margin-left: 6px;">Ignore Intermediary Alignments in Import / Update </label>
		</div>
	</div>
</div>