<div class="countryCss" *ngIf="discipline && !disciplinedprogram && !program && !product"> 
    <i class="treeIcon collapsedTree" [hidden]="discipline.disciplinedprogram" id="{{discipline.facetName}}" (click)="expandCollapse(discipline, $event)" ></i>
    <div class="contentContainer">
        <span class="product-browse-add-icon" (click)="addEditProgramedDiscipline(true, discipline)"></span>
        {{ discipline.label }}
    </div>
</div>
<div class="countryCss" *ngIf="discipline && disciplinedprogram && !program && !product"> 
    <i class="treeIcon collapsedTree" id="{{disciplinedprogram.id}}" (click)="expandCollapse(disciplinedprogram, $event)" ></i>
    <div class="contentContainer browse-program-color">
        <span class="product-browse-add-icon" (click)="addEditProgram(true, discipline, disciplinedprogram)"></span>
        <span class="product-browse-edit-icon" (click)="addEditProgramedDiscipline(false, discipline, disciplinedprogram)"></span>
        <span class="product-browse-delete-icon" (click)="deleteProgramedDiscipline(disciplinedprogram, discipline)"></span>
        {{ disciplinedprogram.name }}
    </div>
</div>
<div class="countryCss" *ngIf="discipline && disciplinedprogram && program && !product"> 
    <i class="treeIcon collapsedTree" id="{{program.id}}" (click)="expandCollapse(program, $event)" ></i>
    <div class="contentContainer browse-course-color">
        <span class="product-browse-add-icon" (click)="addEditProduct(true, discipline, disciplinedprogram, program)"></span>
        <span class="product-browse-edit-icon" (click)="addEditProgram(false, discipline, disciplinedprogram, program)"></span>
        <span class="product-browse-delete-icon" (click)="deleteProgram(program, disciplinedprogram, discipline)"></span>
        {{ program.name }}
    </div>
</div>
<div class="countryCss" *ngIf="discipline && disciplinedprogram && program && product && product.name"> 
    <div class="contentContainer browse-product-color marginBottom5">
        <span class="product-browse-edit-icon" (click)="addEditProduct(false, discipline, disciplinedprogram, program, product)"></span>
        <span class="product-browse-delete-icon" (click)="deleteProduct(product, program, disciplinedprogram, discipline)"></span>
        {{ product.name }}
    </div>
</div>