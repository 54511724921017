import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationPopupService {

  popup$ = new Subject();

  constructor() { 
  }
  private DEFAULT_DELAY:number = 5000;
  private delay:any;
  public successMessage:any = '';
  public errorMessage:any = '';

  public showSuccessMessage = (text:string) => {
    if (this.delay) {
      this.onTimeout();
    }
    this.successMessage = text;
    this.delay = setTimeout(()=>{
      this.onTimeout()
    }, this.DEFAULT_DELAY);
  }

  showErrorMessage= (text:string) => {
    if (this.delay) {
      this.onTimeout();
    }
    this.errorMessage = text;
    this.delay = setTimeout(()=>{
      this.onTimeout()
    }, this.DEFAULT_DELAY);
  }

  public onTimeout = () => {
      clearTimeout(this.delay);
      this.successMessage = undefined;
      this.errorMessage = undefined;
  }
}
