<div class="row view-country-list-conatiner">
    <ng-container>
        <div class="col-sm-2 paddingLeft0 paddingRight0" [hidden]="!globalVar?.curriculaListBean">
            <div class="countrySectionView">
                <div class="countrySelect"
                    [hidden]="!(globalVar?.curriculaListBean?.curriculumCountryListBean?.length > 0)">
                   Standards</div>
                <div class="countrySectionViewScroll">
                    <div
                        *ngFor="let curriculumCountryListBean of globalVar?.curriculaListBean?.curriculumCountryListBean">
                        <div class="curriculumCountryList">
                            <div><a (click)="setSelectedCountryDetails(curriculumCountryListBean)"
                                    title="{{ curriculumCountryListBean.country }}">{{
                                    curriculumCountryListBean?.country }}</a></div>
                        </div>
                    </div>
                </div>
                <hr style="width:45%;border-top:2px solid #333333;margin-left:30px;margin-right:60px">
                <div class="curriculumCountryList">
                    <div><a (click)="getStandardUriLookup()" title="standard-uri-lookup">Standards URI Lookup</a></div>
                </div>
            </div>
            <div class="subjectsSectionView">
                <div class="countrySelect font_21 intermediarySelect"
                    [hidden]="!(globalVar?.curriculaListBean?.curriculumCountryListBean?.length > 0)"> Intermediaries
                </div>
                <div *ngFor="let selectedSubject of globalVar?.filterSubjectsHomePage">
                    <div class="curriculumCountryList">
                        <div><a (click)="setSelectedIntermediaryDetails(selectedSubject)"
                                title="{{ selectedSubject.facetName }}">{{ selectedSubject.facetName }}</a></div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <!-- <div *ngIf="!hasUser" class="col-sm-1"> {{hasUser}}
    </div> -->
    <div class="viewSearchResultPanel col-sm-10 paddingLeft0 paddingRight0">
        <div class="searchResultNotInitiatedPanel">
            <div class="searchResultNotInitiatedTitle">
                {{globalVar?.languageString?.resources?.homeTab?.countryListWelcomeMessage}}</div>
            <!-- <div class="searchResultNotInitiatedText">{{globalVar?.languageString?.resources?.homeTab?.countryListSelectMessage}}</div> -->
        </div>
    </div>
</div>