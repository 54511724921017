import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalVariablesService } from '../../services/global-variables/global-variables.service';
import { PreloadDataService } from '../../services/common/preload-data.service';

@Component({
  selector: 'app-curriculum-menu',
  templateUrl: './curriculum-menu.component.html',
  styleUrls: ['./curriculum-menu.component.scss']
})
export class CurriculumMenuComponent implements OnInit {
  public activeMenuName: String = '';
  public selectOptCurr: String = '';
  public selectOptProd: String = '';
  public selectOptInter: String = '';
  @Input() loadCurriculumList: any;
  @Input() addTabClass: any;

  constructor(
    private actRoute: ActivatedRoute,
    private route: Router,
    public globalVar: GlobalVariablesService,
    public preloadData: PreloadDataService
  ) { }

  ngOnInit(): void {
  }

  public isActiveMenu = (menuItem: String) => {
    let activeMenuName = this.actRoute.snapshot.params.listType;
    return activeMenuName == menuItem;
  };

  public goToTarget = (target: any) => {
    this.route.navigate([target]);
    //this.route.navigateByUrl(target);
  }

  public proccedForClickEvent = (type: String) => {
    if (this.loadCurriculumList !== undefined) {
      this.loadCurriculumList(type);
      this.addTabClass(type);
    }
    this.preloadData.isTabChange.next(true);
  }

}
