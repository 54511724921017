import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { ViewListComponent } from './components/view-list/view-list.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ManagePageComponent } from './components/manage-page/manage-page.component';
import { IntermediaryStatementsComponent } from './components/intermediary-statements/intermediary-statements.component';
import { UploadCurriculumComponent } from './components/upload-curriculum/upload-curriculum.component';
import { UpdateCurriculumComponent } from './components/update-curriculum/update-curriculum.component';
import { ManageAuthorityComponent } from './components/manage-authority/manage-authority.component';
import { UploadDownloadProductComponent } from './components/upload-download-product/upload-download-product.component';
import { ManageProductComponent } from './components/manage-product/manage-product.component';
import { UploadIntermediaryComponent } from './components/upload-intermediary/upload-intermediary.component';
import { EditCurriculumGradeComponent } from './components/edit-curriculum-grade/edit-curriculum-grade.component';
import { EditTopicComponent } from './components/edit-topic/edit-topic.component';
import { SubstitutionSetComponent } from './components/substitution-set/substitution-set.component';
import { ViewCurriculumComponent } from './components/view-curriculum/view-curriculum.component';
import { IntermediaryAlignHomeComponent } from './components/intermediary-align-home/intermediary-align-home.component';
import { IntermediaryAlignmentComponent } from './components/intermediary-alignment/intermediary-alignment.component';
import { CorrelationLeftSideComponent } from './components/correlation/correlation-left-side/correlation-left-side.component';
import { CorrelationHomeComponent } from './components/correlation/correlation-home.component';
import { StandardUriLookup } from './components/standards-uri-lookup/standard-uri-lookup.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'view/approved',
    component: HomePageComponent
  },
  {
    path: 'view/list/approved/:countryName',
    component: ViewListComponent
  },
  {
    path: '404',
    component: UnauthorizedComponent
  },
  {
    path: 'curriculum/list/:listType',
    component: ManagePageComponent
  },
  {
    path: 'curriculum/:curriculumID/edit',
    component: EditCurriculumGradeComponent
  },
  {
    path: 'curriculum/:curriculumID/edit/list/:localGradeIRI',
    component: EditTopicComponent
  },
  {
    path: 'view/intermediaries/::subjectName',
    component: IntermediaryStatementsComponent
  },
  {
    path: 'curriculums/upload',
    component: UploadCurriculumComponent,
    data: { canUploadFile: true }
  },
  {
    path: 'curriculums/create',
    component: UploadCurriculumComponent,
    data: { canUploadFile: false }
  },
  {
    path: 'curriculum/:curriculumID/edit/metadata',
    component: UploadCurriculumComponent,
    data: { canUploadFile: false }
  },
  {
    path: 'curriculum/:curriculumID',
    component: ViewCurriculumComponent,
  },
  {
    path: 'view/curriculum/:curriculumID',
    component: ViewCurriculumComponent,
  },
  {
    path: 'curriculums/update',
    component: UpdateCurriculumComponent
  },
  {
    path: 'substitution/:curriculumID',
    component: SubstitutionSetComponent
  },
  {
    path: 'curriculums/authority',
    component: ManageAuthorityComponent
  },
  {
    path: 'product/upload',
    component: UploadDownloadProductComponent
  },
  {
    path: 'product/delete',
    component: ManageProductComponent
  },
  {
    path: 'curriculums/intermediary',
    component: UploadIntermediaryComponent
  },
  // {
  //   path: 'curriculum/intermediary',
  //   component: UploadIntermediaryComponent
  // },
  {
    path: '',
    redirectTo: 'view/approved',
    pathMatch: 'full'
  },
  {
    path: 'intermediary/align/home',
    component: IntermediaryAlignHomeComponent
  },
  {
    path: 'intermediary/align',
    component: IntermediaryAlignmentComponent
  },
  {
    path: 'correlation',
    component: CorrelationHomeComponent
  },
  {
    path: 'view/standardURI',
    component: StandardUriLookup
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router) {
    this.router.errorHandler = (error: any) => {
      this.router.navigate(['404']);
    }
  }
}
