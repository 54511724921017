import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationPopupService } from 'src/app/services/confirmation-popup/confirmation-popup.service';
import { HttpRestClientService } from '../../services/common/http-rest-client.service';
import { GlobalVariablesService } from '../../services/global-variables/global-variables.service';
import * as _ from "underscore";

@Component({
  selector: 'app-edit-topic',
  templateUrl: './edit-topic.component.html',
  styleUrls: ['./edit-topic.component.scss']
})
export class EditTopicComponent implements OnInit {
  public createItemBean: any = {
    "gradeName": "",
    "localGradeIRI": undefined,
    "localGradeName": "",
    "parentTopicIRI": undefined,
    "parentTopicDesc": "",
    "topicId": undefined,
    "topicDesc": "",
    "standardCode": undefined,
    "addTopLevelTopic": false,
    "addItem": false
  };
  public curriculumID = this.actRoute.snapshot.params.curriculumID;
  public topicPreviewBean: any;
  public expandNodeIds: any = [];
  public popupData: any = {};
  public modalRef: BsModalRef | undefined;
  public nodeIdsForDelete: any = [];
  public nodeIdsForDeleteCnfrm: any = [];
  public curriculaTopicBeanTemp: any = {};
  @ViewChild('deleteTopicConfirmation') private deleteTopicConfirmation: TemplateRef<any> | undefined;
  @ViewChild('addEditTopicPopup') private addEditTopicPopup: TemplateRef<any> | undefined;

  constructor(
    private route: Router,
    private httpRestClient: HttpRestClientService,
    public globalVar: GlobalVariablesService,
    private modalService: BsModalService,
    private confirmationPopup: ConfirmationPopupService,
    private actRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.initCurriculumLocalGradeEdit()
  }

  public loadCurriculum = () => {
    this.globalVar.loading = true;
    let curriculumID = this.actRoute.snapshot.params.curriculumID;
    let url = 'api/curriculum/' + curriculumID + '/metadata?extended=' + true;
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.globalVar.loading = false;
      this.globalVar.curriculaEditBean.curriculaPreviewBean = result;
      let customCurriculumTitle = (!_.isUndefined(this.globalVar.curriculaEditBean.curriculaPreviewBean.displayTitle)) ? (this.globalVar.curriculaEditBean.curriculaPreviewBean.displayTitle) : '';
      this.globalVar.curriculaEditBean.curriculaPreviewBean.customCurriculumTitle = customCurriculumTitle;
      this.globalVar.curriculaEditBean.curriculaPreviewBean.reminingTitle = (this.globalVar.curriculaEditBean.curriculaPreviewBean.curriculumTitle).replace(customCurriculumTitle, '');
      if (this.globalVar.curriculaEditBean.curriculaPreviewBean.status != this.globalVar.languageString.resources.manageTab.manageCurriculumNewLabel) {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.manageCurriculumNotInDraftMessage);
        // $window.history.back();
      }
    }, (error) => {
      this.confirmationPopup.showErrorMessage('Error loading data');
      this.globalVar.loading = false;
    })
  }

  public initCurriculumLocalGradeEdit = () => {
    this.createItemBean = {};
    this.globalVar.activeMainMenu = 'manage';
    this.globalVar.curriculaEditBean = this.globalVar.curriculaEditBean || {};
    this.globalVar.curriculaEditBean.gradePreviewBean = undefined;

    if (!this.globalVar.curriculaEditBean.curriculaPreviewBean || !this.globalVar.curriculaEditBean.curriculaPreviewBean.curriculumTitle) {
      this.loadCurriculum();
    }

    this.loadTopics(undefined);
  };

  public loadTopics = (topicIRI: any) => {
    let curriculumID = this.curriculumID;
    this.globalVar.loading = true;
    let localGradeIRI = this.actRoute.snapshot.params.localGradeIRI;
    let url = 'api/curriculum/loc-structure/' + curriculumID + '/loc/list?localGradeIRI=' + localGradeIRI;
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.topicPreviewBean = result;
      this.globalVar.loading = false;
    })
  }

  goBackToCurriculumEdit()
  {
    this.route.navigate(['/curriculum/' + this.curriculumID + '/edit'])
  }

  public getNodeIdsFordeletion = (curriculaTopicBean: any) => {
    this.nodeIdsForDelete.push(curriculaTopicBean.topicId);
    this.nodeIdsForDeleteCnfrm.push(curriculaTopicBean.topicId);
    if (curriculaTopicBean.topicsList.length > 0) {
      _.each(curriculaTopicBean.topicsList, (currTopicBean) => {
        this.getNodeIdsFordeletion(currTopicBean);
      });
    }
  }

  public stripFormat = (topicDesc: any) => {
    var myContent = topicDesc;
    topicDesc = myContent.replace(/\<(?!i|\/i|b|\/b|u|\/u).*?\>/g, "").replace(/(<br*\/>)*/g, "");
    return topicDesc;
  };

  public deleteTopic = (curriculaTopicBean: any) => {
    let topicId = curriculaTopicBean.topicId;
    this.curriculaTopicBeanTemp = curriculaTopicBean;
    this.getNodeIdsFordeletion(curriculaTopicBean);
    let url = 'api/curriculum/loc-structure/getnodestatus';
    let urlData = {
      "nodeIds": this.nodeIdsForDelete
    }
    this.httpRestClient.patchForUrl(url, urlData, undefined).subscribe((result) => {
      let topicDesc = this.stripFormat(curriculaTopicBean.topicDesc);
      if (result.body && result.body.status) {
        this.popupData.popupMessage = 'There are some alignments in the topic or subtopic\n' + this.globalVar.languageString.resources.manageTab.curriculumTopicConfirmMessageForDelete + topicDesc + '?\nAll sub-topics and alignments will be deleted.'
        this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.curriculumMessageToDeleteTopic;
        this.popupData.doSuccessFunction = this.continueDeleteTopic;
        this.modalRef = this.modalService.show(this.deleteTopicConfirmation ? this.deleteTopicConfirmation : '');
      } else {
        this.popupData.popupMessage = this.globalVar.languageString.resources.manageTab.curriculumTopicConfirmMessageForDelete + topicDesc + this.globalVar.languageString.resources.manageTab.curriculumTopicsWillDeletedMessage;
        this.popupData.buttonLabel = this.globalVar.languageString.resources.manageTab.curriculumMessageToDeleteTopic;
        this.popupData.doSuccessFunction = this.continueDeleteTopic;
        this.modalRef = this.modalService.show(this.deleteTopicConfirmation ? this.deleteTopicConfirmation : '');
      }
    })
  }

  public continueDeleteTopic = () => {
    let curriculaTopicBean = _.clone(this.curriculaTopicBeanTemp);
    let curriculumID = this.curriculumID;;
    let topicId = curriculaTopicBean.topicId;
    let url = 'api/curriculum/loc-structure/' + curriculumID + '/loc/delete?locIRI=' + topicId;
    let urlData = {
      "nodeIds": this.nodeIdsForDelete
    }
    const headers: any = {
      responseType: 'text',
      body: urlData
    };
    this.globalVar.loading = true;
    if (curriculumID && topicId) {
      this.httpRestClient.deleteForUrl(url, headers, undefined).subscribe(result => {
        this.confirmationPopup.showSuccessMessage(this.globalVar.languageString.resources.manageTab.curriculumTopicsGradeDeletedSuccessfullyMessage);
        this.loadTopics(undefined);
        this.curriculaTopicBeanTemp = {};
      }, (error) => {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorInRemovingTopicMessage);
        this.globalVar.loading = false;
      })
    }
  }

  public addSubTopic = (gradePreviewBean: any, curriculaTopicBean: any, isAddItem: any, isFromToplevel: any) => {
    if (isFromToplevel) {
      this.createItemBean = {
        "gradeName": gradePreviewBean.grade,
        "localGradeIRI": gradePreviewBean.gradeIRI,
        "localGradeName": gradePreviewBean.gradeName,
        "parentTopicIRI": undefined,
        "parentTopicDesc": undefined,
        "topicId": undefined,
        "topicDesc": "",
        "standardCode": undefined,
        "addTopLevelTopic": true,
        "addItem": true,
        "leafNode": true
      }
    } else if (isAddItem) {
      curriculaTopicBean.topicDesc ? curriculaTopicBean.topicDesc = this.stripFormat(curriculaTopicBean.topicDesc) : curriculaTopicBean.topicDesc = undefined;
      this.createItemBean = {
        "gradeName": gradePreviewBean.grade,
        "localGradeIRI": gradePreviewBean.gradeIRI,
        "localGradeName": gradePreviewBean.gradeName,
        "parentTopicIRI": curriculaTopicBean.topicId ? curriculaTopicBean.topicId : undefined,
        "parentTopicDesc": curriculaTopicBean.topicDesc ? curriculaTopicBean.topicDesc : "",
        "topicId": undefined,
        "topicDesc": "",
        "standardCode": undefined,
        "addTopLevelTopic": false,
        "addItem": true,
        "leafNode": curriculaTopicBean?.topicsList?.length > 0 ? true : false
      }
    } else {
      curriculaTopicBean.topicDesc ? curriculaTopicBean.topicDesc = this.stripFormat(curriculaTopicBean.topicDesc) : curriculaTopicBean.topicDesc = undefined;
      this.createItemBean = {
        "gradeName": gradePreviewBean.grade,
        "localGradeIRI": gradePreviewBean.gradeIRI,
        "localGradeName": gradePreviewBean.gradeName,
        "parentTopicIRI": undefined,
        "parentTopicDesc": undefined,
        "topicId": curriculaTopicBean.topicId ? curriculaTopicBean.topicId : undefined,
        "topicDesc": curriculaTopicBean.topicDesc ? curriculaTopicBean.topicDesc : "",
        "standardCode": curriculaTopicBean.standardCode ? curriculaTopicBean.standardCode : undefined,
        "addTopLevelTopic": false,
        "addItem": false,
        "leafNode": curriculaTopicBean?.topicsList?.length > 0 ? true : false
      }
    }
    if(curriculaTopicBean?.subSetGradeIRI) {
      this.createItemBean.subSetGradeIRI = curriculaTopicBean.subSetGradeIRI;
      this.createItemBean.substitutionStandardCode = curriculaTopicBean.substitutionStandardCode ? curriculaTopicBean.substitutionStandardCode : "";
      this.createItemBean.substitutionTopicDesc = curriculaTopicBean.substitutionTopicDesc ? curriculaTopicBean.substitutionTopicDesc : "";
      this.createItemBean.substitutionTopicId = curriculaTopicBean.substitutionTopicId ? curriculaTopicBean.substitutionTopicId : "";
      this.createItemBean.substitutionId = curriculaTopicBean.substitutionId ? curriculaTopicBean.substitutionId : "";
      this.createItemBean.subSetGradeIRI = curriculaTopicBean.subSetGradeIRI ? curriculaTopicBean.subSetGradeIRI : "";
      this.createItemBean.substitutionGradeTitle = curriculaTopicBean.substitutionGradeTitle ? curriculaTopicBean.substitutionGradeTitle : "";
      this.createItemBean.previousOrderId = curriculaTopicBean.previousOrderId ? curriculaTopicBean.previousOrderId : 0;
    }
    this.showEditTopicPopup();
  }

  public showEditTopicPopup = () => {
    if (this.globalVar.editSubsPopup) {
      this.modalRef = this.modalService.show(this.globalVar.editSubsPopup ? this.globalVar.editSubsPopup : '');
      this.modalRef.setClass("addTopicPopup");
    } else {
      this.modalRef = this.modalService.show(this.addEditTopicPopup ? this.addEditTopicPopup : '');
      this.modalRef.setClass("addTopicPopup");
    }

  }

  public saveTopic = () => {

    let curriculumID = this.actRoute.snapshot.params.curriculumID;
    let createItemBean = this.createItemBean;
    if (curriculumID && createItemBean && createItemBean.localGradeIRI && createItemBean.topicDesc &&
      createItemBean.standardCode && ((!createItemBean.addItem && createItemBean.topicId)
        || (createItemBean.addItem && !createItemBean.addTopLevelTopic && createItemBean.parentTopicIRI)
        || (createItemBean.addItem && createItemBean.addTopLevelTopic && !createItemBean.parentTopicIRI))) {

      let topicDesc = createItemBean.topicDesc;
      // createItemBean.topicDesc = this.stripFormat(topicDesc); 

      let createTopicBean = {
        "localGradeIRI": createItemBean.localGradeIRI,
        "parentTopicIRI": createItemBean.parentTopicIRI,
        "topicId": createItemBean.topicId,
        "topicDesc": createItemBean.topicDesc,
        "standardCode": createItemBean.standardCode
      };
      createTopicBean.topicDesc=createTopicBean.topicDesc.replaceAll('<p>', '');
      createTopicBean.topicDesc=createTopicBean.topicDesc.replaceAll('</p>', '');
      createTopicBean.topicDesc.replaceAll('</p>', '');
      createTopicBean.topicDesc=createTopicBean.topicDesc.replaceAll('<strong>', '<b>');
      createTopicBean.topicDesc=createTopicBean.topicDesc.replaceAll('</strong>', '</b>');
      createTopicBean.topicDesc=createTopicBean.topicDesc.replaceAll('<em>', '<i>');
      createTopicBean.topicDesc=createTopicBean.topicDesc.replaceAll('</em>', '</i>');
      createTopicBean.topicDesc=createTopicBean.topicDesc.replaceAll('<span style=\"text-decoration: underline;\">', '<u>');
      createTopicBean.topicDesc=createTopicBean.topicDesc.replaceAll('</span>', '</u>');
      createTopicBean.topicDesc=createTopicBean.topicDesc.replace(/<br>/g, '');
      createTopicBean.topicDesc=createTopicBean.topicDesc.replace(/<span style="color: rgb\(51, 51, 51\); font-family: Verdana, serif; font-size: 16px; font-style: normal; font-weight: 400; text-align: left; text-indent: 0px; white-space: normal; background-color: rgb\(255, 255, 255\); display: inline !important; float: none;">/g, '');
      const headers: any = {
        responseType: 'text'
      };

      if (createItemBean.addItem) {
        let url = 'api/curriculum/loc-structure/' + curriculumID + '/loc/create';
        this.httpRestClient.putForUrl(url, createTopicBean,headers).subscribe((result) => {
          if (createItemBean.addTopLevelTopic) {
            this.loadTopics(undefined);
          } else if (createItemBean.addItem) {
            this.loadTopics(createItemBean.parentTopicIRI);
          } else {
            this.loadTopics(createItemBean.topicId);
          }
          if (createItemBean.leafNode) {
            this.sendForSNS(result, curriculumID, createItemBean.addItem);
          }
          this.modalRef?.hide();
        }, (error) => {
          // this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorMessage + (createItemBean.addItem ? this.globalVar.languageString.resources.manageTab.curriculumErrorInCreating : this.globalVar.languageString.resources.manageTab.curriculumErrorInUpdating) + this.globalVar.languageString.resources.manageTab.curriculumTopicsMessage);
          if (createItemBean.addTopLevelTopic) {
            this.loadTopics(undefined);
          } else if (createItemBean.addItem) {
            this.loadTopics(createItemBean.parentTopicIRI);
          } else {
            this.loadTopics(createItemBean.topicId);
          }
          this.modalRef?.hide();
        });

      } else {
        let url = 'api/curriculum/loc-structure/' + curriculumID + '/loc/update';
        this.httpRestClient.postForUrl(url, createTopicBean, headers).subscribe((result) => {
          if (createItemBean.addTopLevelTopic) {
            this.loadTopics(undefined);
          } else if (createItemBean.addItem) {
            this.loadTopics(createItemBean.parentTopicIRI);
          } else {
            this.loadTopics(createItemBean.topicId);
          }
          if (createItemBean.leafNode) {
            this.sendForSNS(result, curriculumID, createItemBean.addItem);
          }
          this.modalRef?.hide();
        }, (error) => {
          // this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorMessage + (createItemBean.addItem ? this.globalVar.languageString.resources.manageTab.curriculumErrorInCreating : this.globalVar.languageString.resources.manageTab.curriculumErrorInUpdating) + this.globalVar.languageString.resources.manageTab.curriculumTopicsMessage);
          if (createItemBean.addTopLevelTopic) {
            this.loadTopics(undefined);
          } else if (createItemBean.addItem) {
            this.loadTopics(createItemBean.parentTopicIRI);
          } else {
            this.loadTopics(createItemBean.topicId);
          }
          this.modalRef?.hide();
        });
      }
    }

  }

  public sendForSNS = (topicId: any, currId: any, addItem: any) => {
    var url = 'api/curriculum/loc-structure/' + currId + '/loc/snspost?topicId=' + topicId;
    const headers: any = {
      responseType: 'text'
    };
    this.httpRestClient.putForUrl(url, headers, undefined).subscribe(result => {
      // ConfirmationPopupService.showSuccessMessage("New standard node is created. Post SNS message has been sent.");
    }, (error) => {
      this.confirmationPopup.showErrorMessage(this.globalVar?.languageString?.resources?.manageTab?.curriculumErrorMessage + (addItem ? this.globalVar?.languageString?.resources?.manageTab?.curriculumErrorInCreating : this.globalVar?.languageString?.resources?.manageTab?.curriculumErrorInUpdating) + this.globalVar.languageString.resources.manageTab.curriculumTopicsMessage);
    });
  }

}
