import { Component, OnInit } from '@angular/core';
import { GlobalVariablesService } from '../../services/global-variables/global-variables.service';
import { HttpRestClientService } from '../../services/common/http-rest-client.service';
import { ApplicationLoaderService } from '../../services/common/application-loader.service';
import { ConfirmationPopupService } from '../../services/confirmation-popup/confirmation-popup.service';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import * as _ from "underscore";
import { Router, ActivatedRoute } from '@angular/router';
import { result } from 'underscore';
import { PreloadDataService } from '../../services/common/preload-data.service';

@Component({
  selector: 'app-upload-download-product',
  templateUrl: './upload-download-product.component.html',
  styleUrls: ['./upload-download-product.component.scss']
})
export class UploadDownloadProductComponent implements OnInit {
  public injectCurriculumBean: any = {
    curriculumIngestionDataBean: {},
    curriculumUploadBean: {
      curriculumId: '',
      subject: '',
      country: '',
      authority: '',
      curriculumSet: '',
      state: '',
      fileType: 'AB XML',
      appendCustom: false,
      includeIntermediaryAlignments: false
    },
    canUploadFile: this.route.getCurrentNavigation()?.extras,
    curriculaPreviewBean: undefined
  };
  public selectedList: any = {
    program: '',
    course: '',
    product: ''
  };
  public uploadProductBean: any = {}
  public exportProductUrl: any;
  public selectedProduct: any;


  constructor(
    private route: Router,
    private actRoute: ActivatedRoute,
    private httpRestClient: HttpRestClientService,
    public globalVar: GlobalVariablesService,
    private appLoadService: ApplicationLoaderService,
    private confirmationPopup: ConfirmationPopupService,
    private preloadData: PreloadDataService
  ) { }

  ngOnInit(): void {
    this.initCurriculumIngest();
  }

  public initCurriculumIngest = () => {
    this.appLoadService.initilizeApplicationCache(() => {
      this.globalVar.activeMainMenu = 'manage';
      this.globalVar.generatingXLS = false;
      this.globalVar.downloadUrl = undefined;
      this.uploadProductBean = {};
      this.exportProductUrl = 'api/plan/export';
      this.preloadIngestData();
    });
  };

  public preloadIngestData = () => {
    this.globalVar.loading = true;
    let url = 'api/curriculum/ingestion';
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.injectCurriculumBean.curriculumIngestionDataBean = result;
      this.globalVar.loading = false;
    }, (error) => {
      this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.createCurriculumPreloadIngetionDataErrorMessage);
      this.globalVar.loading = false;
    });
  }
  public loadDisciplinedProgram = (subject: any) => {
    let url = "api/disciplinedprogram?sub=" + encodeURIComponent(subject);
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.uploadProductBean.program = result.item;
      this.uploadProductBean.course = undefined;
      this.uploadProductBean.product = undefined;
      this.injectCurriculumBean.curriculumUploadBean.ignoreIntermediaryAlignments = false;
      this.globalVar.downloadUrl = undefined;
      this.globalVar.generatingXLS = false;
    }, (error) => {
      if (error.status == 500) {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
      }
    })
  }

  public changeLink = (subject: any) => {
    this.loadDisciplinedProgram(subject);
    this.globalVar.generatingXLS = false;
    this.globalVar.downloadUrl = undefined;
    this.preloadData.beanValueChanged.next(true);
  }

  public refreshPrograms = (pgm: any) => {
    let url = 'api/program?iri=' + pgm + '&expand=partial';
    this.injectCurriculumBean.curriculumUploadBean.program = _.find(this.uploadProductBean.program, function (item) { return item.id == pgm; });
    if (pgm !== undefined) {
      this.preloadData.beanValueChanged.next(true);
      this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
        this.uploadProductBean.course = result.hasPart;
        this.uploadProductBean.product = undefined;
        this.globalVar.downloadUrl = undefined;
        this.globalVar.generatingXLS = false;
      }, (error) => {
        if (error.status == 500) {
          this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
        }
      })
    }
  }

  public refreshCourses = (course: any) => {
    let url = 'api/course?iri=' + course + '&expand=partial';
    this.injectCurriculumBean.curriculumUploadBean.course = _.find(this.uploadProductBean.course, function (item) { return item.id == course; });
    if (course !== undefined) {
      this.preloadData.beanValueChanged.next(true);
      this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
        this.uploadProductBean.product = result.hasPart;
        this.globalVar.downloadUrl = undefined;
        this.globalVar.generatingXLS = false;
      }, (error) => {
        if (error.status == 500) {
          this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
        }
      })
    }
  }

  public refreshProducts = (product: any) => {
    this.injectCurriculumBean.curriculumUploadBean.product = _.find(this.uploadProductBean.product, function (item) { return item.id == product; });
    this.selectedProduct = _.clone(this.injectCurriculumBean.curriculumUploadBean.product);
    if (product !== undefined) {
      this.preloadData.beanValueChanged.next(true);
      this.globalVar.downloadUrl = false;
      this.globalVar.generatingXLS = false;
    }
  }

  public exportProductSheet = () => {
    if (this.selectedList && !_.isEmpty(this.selectedList.product)) {
      this.globalVar.generatingXLS = true;
      var userMail = this.globalVar.userProfile.userMail;
      var exportUrl = this.exportProductUrl + '?iri=' + this.selectedList.product + '&userMail=' + userMail;
      const headers: any = {
        responseType: 'text'
      };
      this.httpRestClient.getForUrl(exportUrl, headers, undefined).subscribe((result) => {
        if (_.isEmpty(result)) {
          this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.manageUploadErrorInFileMessage);
        } else {
          result = result.replace("#", '%23');
          this.globalVar.downloadUrl = result;
          this.globalVar.generatingXLS = false;
          this.confirmationPopup.showSuccessMessage(this.globalVar.languageString.resources.manageTab.manageUploadExcelExportSuccessMessage);
        }
      })
    } else {
      this.globalVar.generatingXLS = false;
      this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.manageUploadIntermediaryProductMandatory);
    }
  }

  public lockUnlockProduct = () => {
    let product = this.selectedProduct;
    if (this.selectedList && !_.isEmpty(this.selectedList.product)) {
      let url = 'api/product/lock?iri=' + this.selectedList.product + '&lockedBy=' + this.globalVar.userProfile.userName;
      let message = '';
      if (!JSON.parse(product.lockedForAlignment === undefined ? 'false' : product.lockedForAlignment)) {
        url = url + '&lock=true';
        message = this.globalVar.languageString.resources.manageTab.productLockedSuccessMessage;
      } else {
        url = url + '&lock=false';
        message = this.globalVar.languageString.resources.manageTab.productUnLockedSuccessMessage;
      }
      this.httpRestClient.postForUrl(url, undefined, undefined).subscribe((result) => {
        this.confirmationPopup.showSuccessMessage(message);
        this.selectedProduct.lockedForAlignment = !JSON.parse(this.selectedProduct.lockedForAlignment === undefined ? 'false' : this.selectedProduct.lockedForAlignment);
      }, (error) => {
        if (error.status == 500) {
          this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
        }
      })
    }
    else {
      this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.productAlertMessageForSelect);
    }
  }

  public getLockStatusLabel = () => {
    if (!_.isEmpty(this.globalVar.languageString)) {
      var label = this.globalVar.languageString.resources.manageTab.productLockLabel;
      if (!_.isEmpty(this.selectedProduct) && JSON.parse(this.selectedProduct.lockedForAlignment === undefined ? 'false' : this.selectedProduct.lockedForAlignment)) {
        label = this.globalVar.languageString.resources.manageTab.productUnLockLabel;
      }
      return label
    }
  }

}
