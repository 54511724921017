import { Component, OnInit } from '@angular/core';
import { GlobalVariablesService } from '../../services/global-variables/global-variables.service';
import { HttpRestClientService } from '../../services/common/http-rest-client.service';
import { ApplicationLoaderService } from '../../services/common/application-loader.service';
import { ConfirmationPopupService } from '../../services/confirmation-popup/confirmation-popup.service';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import * as _ from "underscore";
import { Router, ActivatedRoute } from '@angular/router';
import { PreloadDataService } from '../../services/common/preload-data.service';

@Component({
  selector: 'app-upload-intermediary',
  templateUrl: './upload-intermediary.component.html',
  styleUrls: ['./upload-intermediary.component.scss']
})
export class UploadIntermediaryComponent implements OnInit {
  public selectedList: any;
  public uploadProductBean: any;
  public exportProductUrl: any;
  public injectCurriculumBean: any = {
    curriculumIngestionDataBean: {},
    curriculumUploadBean: {
      curriculumId: '',
      subject: '',
      country: '',
      authority: '',
      curriculumSet: '',
      state: '',
      fileType: 'AB XML',
      includeIntermediaryAlignments: false
    },
    canUploadFile: this.route.getCurrentNavigation()?.extras,
    curriculaPreviewBean: undefined
  };

  constructor(
    private route: Router,
    private actRoute: ActivatedRoute,
    private httpRestClient: HttpRestClientService,
    public globalVar: GlobalVariablesService,
    private appLoadService: ApplicationLoaderService,
    private confirmationPopup: ConfirmationPopupService,
    private PreloadData: PreloadDataService
  ) { }

  ngOnInit(): void {
    this.globalVar.activeMainMenu = 'manage';
    this.initCurriculumIngest();
  }

  public initCurriculumIngest = () => {
    this.appLoadService.initilizeApplicationCache(() => {
      this.globalVar.activeMainMenu = 'manage';
      this.globalVar.generatingXLS = false;
      this.globalVar.downloadUrl = undefined;
      this.selectedList = [];
      this.uploadProductBean = {};
      this.exportProductUrl = 'api/plan/export';
      this.preloadIngestData();
    });
  };

  public preloadIngestData = () => {
    this.globalVar.loading = true;
    let url = 'api/curriculum/ingestion';
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.injectCurriculumBean.curriculumIngestionDataBean = result;
      this.globalVar.loading = false;
    }, (error) => {
      this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.createCurriculumPreloadIngetionDataErrorMessage);
      this.globalVar.loading = false;
    });
  }

  public exportIntermediary = () => {
    let subject = this.injectCurriculumBean.curriculumUploadBean.subject;
    if (subject) {
      this.globalVar.generatingXLS = true;
      let subArray = subject.split('#');
      let url = 'api/intermediary/' + subArray[1] + '/export';
      const headers: any = {
        responseType: 'text'
      };
      this.httpRestClient.getForUrl(url, headers, undefined).subscribe((result: any) => {
        this.globalVar.generatingXLS = false;
        if (result === "") {
          this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.manageUploadIntermediaryExportNoDataMessage);
          this.globalVar.downloadUrl = undefined;
        } else {
          this.globalVar.downloadUrl = result;
        }
      }, (error) => {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.manageUploadExcelExportFailureMessage);
      })
    } else {
      this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.manageUploadIntermediarySubjectMandatory);
    }

  }

  public resetGenerateXls = () => {
    this.globalVar.generatingXLS = false;
    this.globalVar.downloadUrl = undefined;
    this.PreloadData.beanValueChanged.next(true);
  }
  public downloadFile = () => {
    let url = 'api/intermediary/export/' + this.globalVar?.downloadUrl;
    //let locUrl = window.location.href.split('cmtWebApp')[0] + 'cm-web/';
    let locUrl = '';
    window.open(locUrl + url);
  }

}
