import { Component, OnInit, TemplateRef, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRestClientService } from 'src/app/services/common/http-rest-client.service';
import { ConfirmationPopupService } from 'src/app/services/confirmation-popup/confirmation-popup.service';


@Component({
  selector: 'standard-uri-lookup',
  templateUrl: './standard-uri-lookup.component.html',
  styleUrls: ['./standard-uri-lookup.component.scss']
})
export class StandardUriLookup implements OnInit {
  iriValue: any;
  loading = false;
  gradeInfo: any = {};
  curriculumTitle = '';
  pathValue = '';
  curriculumId = '';
  gradeName = '';
  invalidIRI = false;
  standardGradeInfo: any;
  gradeValue: any;
  constructor(private httpRestClient: HttpRestClientService,
    private confirmationPopup: ConfirmationPopupService,
    private route: Router
  ) { }


  ngOnInit(): void {

  }


  getStandards(iriValue: any, slot: any, callback: any) {
    let url = 'api/curriculum/getStandards?iri=' + encodeURIComponent(iriValue);
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      if (result) {
        if (!result.Message) {
          slot.curriculumInfo = result
          if (result.standardGradeList.length > 0) {
            slot.standardGradeList = result.standardGradeList;
            slot.selectedGrade = result.standardGradeList[0];
            if (slot.selectedGrade.gradeBeanList && slot.selectedGrade.gradeBeanList.length > 1) {
              slot.selectedLocalGrade = slot.selectedGrade.gradeBeanList[0];
            }
            this.loading = false
            if (callback) {
              callback();
            }
          }
          else {
            if (this.iriValue.startsWith("loc-structure")) {
              this.curriculumTitle = this.gradeInfo?.curriculumInfo?.curriculumTitle;
              this.curriculumId = this.gradeInfo?.curriculumInfo?.curriculumId;
              this.setPathValue(this.gradeInfo?.curriculumInfo);
              this.loading = false;
            }
            else {
              this.loading = false;
              this.confirmationPopup.showErrorMessage('Grades Not Found');
            }
          }
        }
        else {
          this.loading = false;
          this.confirmationPopup.showErrorMessage('Standard Not Found');
        }
      }
      else {
        this.loading = false;
        this.confirmationPopup.showErrorMessage('Standard Not Found');
      }
    }, (error) => {
      this.confirmationPopup.showErrorMessage('Error loading data');
      this.loading = false;
    })


  }

  setStandardsRequest = () => {
    this.invalidIRI = this.checkForValidIri();
    if (!this.invalidIRI) {
      this.loading = true;
      this.getStandards(this.iriValue, this.gradeInfo, () => {
        if (this.gradeInfo && this.gradeInfo.curriculumInfo) {
          this.standardGradeInfo = this.gradeInfo;
          this.curriculumTitle = this.gradeInfo.curriculumInfo.curriculumTitle;
          this.curriculumId = this.gradeInfo.curriculumInfo.curriculumId;
          this.gradeValue = this.setGrade(this.gradeInfo.standardGradeList);
          this.pathValue = this.setPathValue(this.gradeInfo.curriculumInfo);
        }
      })
    }
  }

  setGrade = (gradeList: any) => {
    let gradeVal = '';
    let gradeName = '';
    if (gradeList.length > 0) {
      gradeList.forEach((element: any, key: number) => {
        if (element.gradeBeanList.length > 0) {
          gradeVal = element.gradeBeanList[0].grade;
          gradeName = element.gradeBeanList[0].gradeName;
        }
      });
    }
    this.gradeName = gradeName;
    return gradeVal;
  }

  setPathValue = (curriculum: any) => {
    if (this.iriValue.startsWith('loc-structure', 0)) {
      if (curriculum.displayTitle)
        this.pathValue = curriculum.displayTitle;
      else
        this.pathValue = curriculum.curriculumTitle;
    }
    else if (this.iriValue.startsWith('loc/', 0)) {
      if (curriculum.displayTitle) {
        this.pathValue = curriculum.displayTitle.concat('\\', this.gradeValue);
        this.pathValue = this.pathValue.concat('\\', this.gradeName);
      }
      else {
        this.pathValue = curriculum.curriculumTitle.concat('\\', this.gradeValue);
        this.pathValue = this.pathValue.concat('\\', this.gradeName);
      }
    }
    else {
      if (curriculum.displayTitle)
        this.pathValue = curriculum.displayTitle.concat('\\', this.gradeValue);
      else
        this.pathValue = curriculum.curriculumTitle.concat('\\', this.gradeValue);
    }
    return this.pathValue;
  }

  checkForValidIri = () => {
    let invalidIRI = false;
    if (this.iriValue.includes('|')) {
      invalidIRI = true;
      this.confirmationPopup.showErrorMessage('Please do not submit multiple URI’s. Please submit one URI at a time');
    }
    return invalidIRI;
  }

  getCurriculumDetails(id: any) {
    this.route.navigate(['view/curriculum/' + id]);
  }


}
