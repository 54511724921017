<div id="correlationsDatatablesDivId" class="correlation-report-list-container">
	<table style="width:100%" id="correlationsDatatablesId" mat-table
		[dataSource]="dataSource.data.length > 0 && dataSource.filteredData.length > 0 ? dataSource : emptyData"
		class="correlation-report-datatable" matSort matSortActive="date" matSortDirection="desc" >
		<ng-container matColumnDef="name">
			<th class="correlation-datatable-th-style"  mat-sort-header *matHeaderCellDef> Name </th>
			<td mat-cell *matCellDef="let row"> {{row.name}} </td>
		</ng-container>

		<ng-container matColumnDef="date">
			<th class="correlation-datatable-th-style date-content" style="margin-left:47px" 
				mat-sort-header *matHeaderCellDef> Date </th>
			<td mat-cell *matCellDef="let row"> {{row.date|date:'dd/MM/yyyy HH:mm:ss'}} </td>
		</ng-container>

		<ng-container matColumnDef="align">
			<th class="correlation-datatable-th-style"  mat-sort-header *matHeaderCellDef>Print/Digital
			</th>
			<td mat-cell *matCellDef="let row">{{getAllignmentDetails(row.printDigitalAlignment)}} </td>
		</ng-container>

		<ng-container matColumnDef="correlation">
			<th class="correlation-datatable-th-style" mat-header-cell mat-sort-header *matHeaderCellDef>Correlation
			</th>
			<td mat-cell *matCellDef="let row"> {{row.correlationType}} </td>
		</ng-container>
		<ng-container matColumnDef="sourceA">
			<th class="correlation-datatable-th-style" mat-header-cell mat-sort-header *matHeaderCellDef>Source A</th>
			<td mat-cell *matCellDef="let row"> {{row.leftMappingTitle}} </td>
		</ng-container>
		<ng-container matColumnDef="sourceB">
			<th class="correlation-datatable-th-style" mat-header-cell mat-sort-header *matHeaderCellDef>Source B</th>
			<td mat-cell *matCellDef="let row"> {{row.sourceB[0].rightMappingTitle}} </td>
		</ng-container>
		<ng-container matColumnDef="creator">
			<th class="correlation-datatable-th-style" mat-header-cell mat-sort-header *matHeaderCellDef>Creator</th>
			<td mat-cell *matCellDef="let row"> {{row.creator}} </td>
		</ng-container>
		<ng-container matColumnDef="download">
			<th class="correlation-datatable-th-style download-width" mat-header-cell  *matHeaderCellDef>Download</th>
			<td mat-cell *matCellDef="let row">
				<a *ngIf="row.downloadStatus === 'Complete'" (click)="getUrl(row)"><img class="cursor-pointer"
						title='download' src='assets/images/download-excel-icon.png'></a>
				<img *ngIf="row.downloadStatus === 'New' || row.downloadStatus === 'inProgress' || row.downloadStatus === 'Failed'"
					class="correlation-disable-item" src='assets/images/download-excel-icon.png'>
				<a *ngIf="(row.downloadStatus === 'Complete' && (row.correlationType === 'Reverse' || row.correlationType === 'Forward') && row.sourceB.length === 1)"
					(click)="getDocUrl(row)" class="word-document-download">
					<img class="cursor-pointer correlation-item-word" src='assets/images/word-landscape.png'
						title='download'>
				</a>
				<img *ngIf="(row.downloadStatus !== 'Complete')&&(row.correlationType === 'Reverse' || row.correlationType === 'Forward') && (row.sourceB.length === 1)"
					class="correlation-disable-item word-image" src='assets/images/word-landscape.png'>
			</td>
		</ng-container>
		<ng-container matColumnDef="admin">

			<th class="correlation-datatable-th-style" mat-header-cell  *matHeaderCellDef>Admin</th>
			<td mat-cell *matCellDef="let row">
				<span
					*ngIf="row.downloadStatus === 'New' || row.downloadStatus === 'inProgress' || row.downloadStatus === 'Complete'|| row.downloadStatus === 'Failed'"
					[ngClass]="{'correlation-disable-item': row.downloadStatus !== 'Complete'}"
					class="correlation-report-btns correlation-report-btn-delete"
					(click)="delete(row,statusChangePopupTemp)">DELETE</span>
				<span
					*ngIf="row.downloadStatus === 'New' || row.downloadStatus === 'inProgress' || row.downloadStatus === 'Complete'|| row.downloadStatus === 'Failed'"
					[ngClass]="{'correlation-disable-item': row.downloadStatus !== 'Complete'}"
					class="correlation-report-btns correlation-report-btn-rerun"
					(click)="reRunCorrelation(row)">RE-RUN</span>
				<span
					*ngIf="row.downloadStatus === 'New' || row.downloadStatus === 'inProgress' || row.downloadStatus === 'Complete'|| row.downloadStatus === 'Failed'"
					[ngClass]="{'correlation-disable-item': row.downloadStatus !== 'Complete'}"
					class="correlation-report-btns correlation-report-btn-rerun"
					(click)="editCorrelation(row)">EDIT</span>
			</td>
		</ng-container>
		<ng-container matColumnDef="empty-row">
			<mat-cell *matCellDef="let row"></mat-cell>
		</ng-container>
		<tr class="correlation-datatable-th-style" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr class="correlation-datatable-tr-style"
			[ngStyle]="{'background-color':dataSource.filteredData.length?getColor(row.correlationType):'white'}"
			mat-row
			*matRowDef="let row; columns: dataSource.data.length > 0 && dataSource.filteredData.length > 0 ? displayedColumns : emptyRow;">
		</tr>
	</table>
	<mat-paginator *ngIf="listItems.length" [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
		aria-label="Select page of users">
	</mat-paginator>
	<div *ngIf="!listItems.length" class="correlation-no-data-found">No Correlation Report</div>

</div>

<ng-template #statusChangePopupTemp>
	<app-warning-popup [DoFunctionOnSuccess]="confirmDeleteGrade" [PopupMessage]="popupData.popupMessage"
		[ButtonLabel]="popupData.buttonLabel" [modalRef]="modalRef"></app-warning-popup>
</ng-template>