import { Component, OnInit, TemplateRef, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpRestClientService } from 'src/app/services/common/http-rest-client.service';
import { PreloadDataService } from 'src/app/services/common/preload-data.service';
import { ConfirmationPopupService } from 'src/app/services/confirmation-popup/confirmation-popup.service';
import { GlobalVariablesService } from 'src/app/services/global-variables/global-variables.service';
import { ManagePageComponent } from 'src/app/components/manage-page/manage-page.component';
//import { ArrayRangePipe } from 'src/app/services/pipe';
import * as _ from "underscore";
import { any, result } from 'underscore';
import { CorrelationService } from 'src/app/services/correlation/correlation.service';

@Component({
  selector: 'correlation-allign',
  templateUrl: './correlation-allign.component.html',
  styleUrls: ['./correlation-allign.component.scss']
})
export class CorrelationAllignComponent implements OnInit {
  showRestrictedChar = false;
  reportName: any;
  dataForSave = {
    "createdBy": "",
    "leftAlfrescoInclude": 0,
    "leftCountry": "",
    "leftCountryIRI": "",
    "leftCourseIRI": "",
    "leftCourseTitle": "",
    "leftGradeMaxIRI": "",
    "leftGradeMinIRI": "",
    "leftMappingIRI": "",
    "leftPeripheralInclude": 0,
    "leftProgramIRI": "",
    "leftProgramTitle": "",
    "leftState": "",
    "leftStrandIRI": "",
    "leftSubjectIRI": "",
    "leftTitle": "",
    "leftestype": "",
    "printDigitalAlignment": "",
    "realizeStandards": 0,
    "reportName": "",
    "reportRightMappingBeanList": [{
      "country": "",
      "countryIRI": "",
      "courseIRI": "",
      "courseTitle": "",
      "grade": "1",
      "gradeIRI": "",
      "gradeMaxIRI": "",
      "gradeMinIRI": "",
      "mappingIRI": "",
      "programIRI": "",
      "programTitle": "",
      "state": "",
      "strandIRI": "",
      "strand": "",
      "subjectIRI": "",
      "title": "",
    }],
    "responseMessage": "",
    "reverseIntermediary": "0",
    "rightIntermediaryDetailsStandard": 0,
    "rightPeripheralInclude": 0,
    "rightestype": "curricula",
    "showKeyAlignments": 0,
    "userMail": ''
  }

  constructor(public globalVar: GlobalVariablesService,
    private httpRestClient: HttpRestClientService,
    public correlationService: CorrelationService,
    private confirmationPopup: ConfirmationPopupService,
  ) { }

  ngOnInit(): void {
    this.dataForSave.userMail = this.globalVar?.userProfile?.userMail;
  }

}
