<div id="tabsContainerId" class="span6 correlationTabContainerId leftCorrelation">

	<ul id="tabs" class="tabs">
		<li id="left-standard-tab-head" class="tab-link tab-head-settings"
			[ngClass]="{'current': (selectTab === 'left-standard-tabcontent') }"
			(click)="showConfirmationPopup(statusChangePopupTemp,'curriculum')" data-tab="left-standard-tabcontent">
			Curriculum Standard </li>
		<li id="left-product-tab-head" class="tab-link tab-head-settings current"
			(click)="showConfirmationPopup(statusChangePopupTemp,'product')" data-tab="left-product-tabcontent"
			[ngClass]="{'current': (selectTab === 'left-product-tabcontent') }"> Product</li>
		<li id="left-intermediary-tab-head" class="tab-link tab-head-settings"
			(click)="showConfirmationPopup(statusChangePopupTemp,'intermediary')"
			data-tab="left-intermediary-tabcontent"
			[ngClass]="{'current': (selectTab === 'left-intermediary-tabcontent') }">Intermediary
		</li>
	</ul>
	<div id="left-standard-tab-content" class="correlation-report-tab-content"
		[ngClass]="{'current': (selectTab === 'left-standard-tabcontent') }">
		<div class="correlation-list-panel">
			<div>
				<select id="left-standard-tab" class="correlation-dropdown" [(ngModel)]="selectedList.subject"
					(change)="loadCountry()" required="true">
					<option value="" hidden selected>
						{{disciplineMessage}}
					</option>
					<option *ngFor="let subjectList of disciplineList" [ngValue]="subjectList">
						{{subjectList.subjectDesc}}</option>
				</select>
			</div>
			<div>
				<select id="left-standard-tab" class="correlation-dropdown" [(ngModel)]="selectedList.country"
					(change)="loadCurriculum()" required="true">
					<option value="" hidden selected>
						{{countryMessage}}
					</option>
					<option *ngFor="let subjectList of countryList" [ngValue]="subjectList">
						{{subjectList.countryDesc}}</option>
				</select>
			</div>
			<div>
				<select id="left-standard-tab" class="correlation-dropdown" [(ngModel)]="selectedList.curriculum"
					(change)="loadGrade()" required="true">
					<option value="" hidden selected>
						{{curriculumMessage}}
					</option>
					<option *ngFor="let subjectList of curriculumList" [ngValue]="subjectList">
						{{subjectList.curriculumTitle}}</option>
				</select>
			</div>
			<div>
				<select id="left-standard-tab" class="correlation-dropdown" [(ngModel)]="selectedList.curGrade"
					required="true" (change)="loadStrand()">
					<option value="" hidden selected>
						{{gradeMessage}}
					</option>
					<option *ngFor="let subjectList of grades" [ngValue]="subjectList">
						{{subjectList.grade}}</option>
				</select>
			</div>
			<div *ngIf="strandList?.length">
				<select id="left-standard-tab" class="correlation-dropdown" [(ngModel)]="selectedList.strand" 
					required="true">
					<option value="" hidden selected>
						{{strandMessage}}
					</option>
					<option *ngFor="let subjectList of strandList" [ngValue]="subjectList">
						{{subjectList.gradeName}}</option>
				</select>
			</div>

			<div class="row-fluid correlation-row-gab">
				<div class="span6 text-right" style="margin-left: -36px; margin-bottom: 20px;">
					<button type="button" class="correlation-button correlation-report-lo-button" aria-label="select"
						(click)="selectCurriculum()">Select
					</button>
				</div>
			</div>
			<div class="row-fluid correlation-row-gab">
				<div class="span11" style="margin-left: 0px">
					<input type="text" class="correlation-text-box" [(ngModel)]="selectedCurriculum" disabled />
					<span class="postionRoundIcon smallDeleteIcon marginTop5px" title="remove"
						(click)="removeSelection()"></span>
				</div>
			</div>
		</div>
	</div>
	<div id="left-product-tab-content" class="correlation-report-tab-content"
		[ngClass]="{'current': (selectTab === 'left-product-tabcontent') }">
		<div class="correlation-list-panel content-background-color" style="min-height: 390px;">
			<div>
				<select id="left-standard-tab" class="correlation-dropdown" [(ngModel)]="selectedList.prdSubject"
					required="true" (change)="loadProgram()">
					<option value="" hidden selected>
						{{prdDisciplineMessage}}
					</option>
					<option *ngFor="let subjectList of prdDisciplineList" [ngValue]="subjectList">
						{{subjectList.subjectDesc}}</option>
				</select>
			</div>
			<div>
				<select id="left-standard-tab" class="correlation-dropdown" [(ngModel)]="selectedList.program"
					required="true" (change)="loadCourse()">
					<option value="" hidden selected>
						{{programMessage}}
					</option>
					<option *ngFor="let subjectList of programList" [ngValue]="subjectList">
						{{subjectList.name}}</option>
				</select>
			</div>
			<div>
				<select id="left-standard-tab" class="correlation-dropdown" [(ngModel)]="selectedList.course"
					required="true" (change)="loadPdGrade()">
					<option value="" hidden selected>
						{{courseMessage}}
					</option>
					<option *ngFor="let subjectList of courses" [ngValue]="subjectList">
						{{subjectList.name}}</option>
				</select>
			</div>
			<div>
				<select id="left-standard-tab" class="correlation-dropdown" (change)="loadPdProduct()"
					[(ngModel)]="selectedList.prdGrade" required="true">
					<option value="" hidden selected>
						{{prdGradeMessage}}
					</option>
					<option *ngFor="let subjectList of prgrades" [ngValue]="subjectList">
						{{subjectList.notation}}</option>
				</select>
			</div>
			<div>
				<select id="left-standard-tab" class="correlation-dropdown" [(ngModel)]="selectedList.product"
					required="true">
					<option value="" hidden selected>
						{{prdProductMessage}}
					</option>
					<option *ngFor="let subjectList of pdProducts" [ngValue]="subjectList">
						{{subjectList.name}}</option>
				</select>
			</div>
			<div id="left-product-tab" correlationadmindropdowns correlation-data="correlationData" data-tab="left"
				data-type="product" tab-detail="tabDetail"></div>
			<div class="row-fluid correlation-row-gab">
				<div class="span6 text-right" style="margin-left: -36px; margin-bottom: 20px;">
					<!-- <input type="button" class="correlation-button" title="{{applicationCache.languageString.resources.correlationDashboardTab.reportsSelectToolTip}}" value="{{applicationCache.languageString.resources.correlationDashboardTab.reportsSelectLabel |uppercase}}" data-ng-click="getSelectedLeftData(false)"/> -->
					<button type="button" class="correlation-button" aria-label="select"
						(click)="selectProduct()">Select
					</button>
				</div>
			</div>
			<div class="row-fluid correlation-row-gab">
				<div class="span11" style="margin-left: 0px">
					<input type="text" class="correlation-text-box" [(ngModel)]="selectedProduct" disabled />
					<span class="postionRoundIcon smallDeleteIcon marginTop5px" title="remove"
						(click)="removeSelection()"></span>
				</div>
			</div>
		</div>
	</div>
	<div id="left-intermediary-tab-content" class="correlation-report-tab-content"
		[ngClass]="{'current': (selectTab === 'left-intermediary-tabcontent') }">
		<div class="correlation-list-panel intermediary-background-color">
			<div>
				<select id="left-standard-tab" class="correlation-dropdown"
					[(ngModel)]="selectedList.intermediarySubject" (change)="loadCountry()" required="true">
					<option value="" hidden selected>
						{{interMediaryDisciplineMessage}}
					</option>
					<option *ngFor="let subjectList of interMediaryDisciplineList" [ngValue]="subjectList">
						{{subjectList.subjectDesc}}</option>
				</select>
			</div>
			<div>
				<select id="left-standard-tab" class="correlation-dropdown" disabled [(ngModel)]="selectedList.country"
					(change)="loadCurriculum()" required="true">
					<option value="" hidden selected>
						{{countryMessage}}
					</option>
					<option *ngFor="let subjectList of countryList" [ngValue]="subjectList">
						{{subjectList.countryDesc}}</option>
				</select>
			</div>
			<div>
				<select id="left-standard-tab" class="correlation-dropdown" disabled
					[(ngModel)]="selectedList.curriculum" (change)="loadGrade()" required="true">
					<option value="" hidden selected>
						{{curriculumMessage}}
					</option>
					<option *ngFor="let subjectList of curriculumList" [ngValue]="subjectList">
						{{subjectList.curriculumTitle}}</option>
				</select>
			</div>
			<div>
				<select id="left-standard-tab" class="correlation-dropdown" disabled [(ngModel)]="selectedList.curGrade"
					required="true">
					<option value="" hidden selected>
						{{gradeMessage}}
					</option>
					<option *ngFor="let subjectList of grades" [ngValue]="subjectList">
						{{subjectList.grade}}</option>
				</select>
			</div>
			<div id="left-intermediary-tab" correlationadmindropdowns correlation-data="correlationData" data-tab="left"
				data-type="intermediary" tab-detail="tabDetail"></div>
			<div class="row-fluid correlation-row-gab" data-ng-disabled="true">
				<div class="span6 text-right" style="margin-left: -36px; margin-bottom: 20px;">
					<button type="button" class="correlation-button correlation-report-lo-button" aria-label="select"
						(click)="selectInterMediary()">Select
					</button>
				</div>
			</div>
			<div class="row-fluid correlation-row-gab" data-ng-disabled="true">
				<div class="span11" style="margin-left: 0px">
					<input type="text" class="correlation-text-box" [(ngModel)]="selectedIntermediarySubject"
						disabled />.
					<span class="postionRoundIcon smallDeleteIcon marginTop5px" title="remove"
						(click)="removeSelection()"></span>
				</div>
			</div>

		</div>
	</div>
</div>


<ng-template #statusChangePopupTemp>
	<app-warning-popup [DoFunctionOnSuccess]="confirmDeleteGrade" [PopupMessage]="popupData.popupMessage"
		[ButtonLabel]="popupData.buttonLabel" [modalRef]="modalRef"></app-warning-popup>
</ng-template>