<div class="currEditHeader">
    <div class="marginLeft10 marginRight10">
        <div class="alignmentLoadingPanel" ng-hide="globalVar?.curriculaEditBean.curriculaPreviewBean || errorMessage"></div>
        <div ng-show="curriculaEditBean.curriculaPreviewBean">
            <div class="clearfix currEditTopButton" style="margin-right: 24px;">
                <input type="submit" value="{{globalVar?.languageString?.resources?.manageTab?.curriculumEditDraftListBtnLabel}}" class="cmButton cmButton-yellow cmButton-auto marginLeft15px floatRight" style="height: 33px; margin-top: 4px;" (click)="goToDraftsList()"/>
                <input type="submit" value="{{globalVar?.languageString?.resources?.manageTab?.curriculumEditPreviewBtnLabel}}" class="cmButton cmButton-red cmButton-auto marginLeft15px floatRight" style="height: 33px; margin-top: 4px;" (click)="goToCurriculumPreview()"/>
                <input type="submit" value="{{globalVar?.languageString?.resources?.manageTab?.curriculumEditMetadataBtnLabel}}" class="cmButton cmButton-green cmButton-auto marginLeft15px floatRight" style="height: 33px; margin-top: 4px;" (click)="goToCurriculumEditMetadata()"/>
            </div>
            <div style="margin-left: 10px;" class="font_20_666666">
                <div class="font_18_666666 fontBold">{{globalVar?.languageString?.resources?.manageTab?.curriculumEditTitle}}</div>
                <div class="font_18_666666" style="margin-top: 3px;" [hidden]="!globalVar?.curriculaEditBean.editTitle">{{globalVar?.curriculaEditBean?.curriculaPreviewBean?.curriculumTitle}}  
                 <span *ngIf="!globalVar?.curriculaEditBean?.curriculaPreviewBean?.deployedToRealize" class="smallEditIcon" title="{{globalVar?.languageString?.resources?.manageTab?.curriculumEditLocalGradeLabel}}" (click)="editCustomTitle()"></span>
                </div>
             <div class="font_18_666666" *ngIf="this.globalVar?.curriculaEditBean?.curriculaPreviewBean" style="margin-top: 3px;" [hidden]="globalVar?.curriculaEditBean.editTitle">
                <input id="customtitle" [(ngModel)]="this.globalVar.curriculaEditBean.curriculaPreviewBean.customCurriculumTitle" type="text" class="form-control" placeholder="Display Title">{{globalVar?.curriculaEditBean?.curriculaPreviewBean?.reminingTitle}}  
                <input type="submit" value="{{globalVar?.languageString?.resources?.others?.saveButtonLabel}}" class="cmButton cmButtonFull-green cmButton-standard"
                (click)="saveCustomTitle()" />                 
                <input type="submit" value="Cancel" class="cmButton cmButtonFull-green cmButton-standard"
                    (click)="cancelCustomTitle()" />
			  </div>   
            </div>
        </div>
        <div class="marginTop20 marginLeft10">
            <div class="font_18_666666">{{globalVar?.languageString?.resources?.manageTab?.curriculumEditGradeListLabel}}</div>
            <div class="marginTop10">
                <div class="font_12_666666" title="{{globalVar?.languageString?.resources?.manageTab?.curriculumAddLocalGradeLabel}}"><span class="smallAddIcon" (click)="addNewLocalGrade(addEditLocalGrade)"></span><span class="cmButton-goBack" (click)="addNewLocalGrade(addEditLocalGrade)">{{globalVar?.languageString?.resources?.manageTab?.curriculumAddLocalGradeLabel}}</span></div>
            </div>
            <div class="marginTop10">
                <div *ngFor="let standardGradeBean of globalVar?.curriculaEditBean?.curriculaPreviewBean?.standardGradeList" class="discoverTermsText"
                        style="margin-bottom: 15px;">
                        <div class="borderMarginParent">
                        <span class="smallAddIcon" title="{{globalVar?.languageString?.resources?.manageTab?.curriculumAddLocalGradeLabel}}" (click)="addLocalGrade(standardGradeBean, addEditLocalGrade)"></span>
                        <span class="font_18_666666">{{ standardGradeBean?.grade }}</span>
                        </div>
                    <div class="borderMarginChild">
                        <div [hidden]="standardGradeBean?.gradeBeanList?.length == 0">
                            <div *ngFor="let gradePreviewBean of standardGradeBean?.gradeBeanList; let index = index" class="discoverTermsText">
                                <span style="padding-right: 20px;" [hidden]="!(index == 0)"></span>
                                <span class="smallUpIcon" [hidden]="index == 0" title="{{globalVar?.languageString?.resources?.manageTab?.curriculumEditGradeMoveUpIconLabel}}" (click)="moveLocalGradeUp(gradePreviewBean.gradeIRI)"></span>
                                <span style="padding-right: 20px;" [hidden]="!(index == standardGradeBean?.gradeBeanList.length-1)"></span>
                                <span class="smallDownIcon" [hidden]="index == standardGradeBean?.gradeBeanList.length-1" title="{{globalVar?.languageString?.resources?.manageTab?.curriculumEditGradeMoveDownIconLabel}}" (click)="moveLocalGradeDown(gradePreviewBean.gradeIRI)"></span>
                                <span class="smallDeleteIcon pull-right" title="{{globalVar?.languageString?.resources?.manageTab?.curriculumDeleteLocalGradeLabel}}" (click)="deleteLocalGrade(gradePreviewBean.gradeIRI, gradePreviewBean.gradeName, statusChangePopupTemp)"></span>
                                <span class="smallEditIcon pull-right" title="{{globalVar?.languageString?.resources?.manageTab?.curriculumEditLocalGradeLabel}}" (click)="updateLocalGrade(gradePreviewBean, standardGradeBean, addEditLocalGrade)"></span>
                                <span class="font_18_666666 cursorPointer" (click)="editLocalGradeTopics(gradePreviewBean.gradeIRI)">{{ gradePreviewBean?.gradeName }}</span>
                            </div>
                        </div>
                        <div [hidden]="standardGradeBean?.gradeBeanList?.length > 0">{{globalVar?.languageString?.resources?.manageTab?.curriculumNoLocalGradeLabel}}</div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #statusChangePopupTemp>
    <app-warning-popup [DoFunctionOnSuccess]="confirmDeleteGrade" [PopupMessage]="popupData.popupMessage"
            [ConfirmationMessage]="popupData.confirmationMsg" [ButtonLabel] ="popupData.buttonLabel" 
            [modalRef]="modalRef"></app-warning-popup>
</ng-template>

<ng-template #addEditLocalGrade>
    <app-edit-local-grade [doFunctionOnSuccess]="saveLocalGrade" [createItemBean]="popupData" [modalRef]="modalRef"></app-edit-local-grade>
</ng-template>
<!-- 
</div> -->