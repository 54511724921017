import { Injectable } from '@angular/core';
import _ from 'underscore';
import { any, isBoolean } from 'underscore';
import { HttpRestClientService } from './http-rest-client.service';

@Injectable({
  providedIn: 'root'
})
export class LearningObjectiveServiceService {
  public BASE_LEARNING_OBJECTIVE_URL = 'api/objective';
  public BASE_STATEMENT_URL = 'api/statement';
  public pre_central_string = "<span class='relation-image relation-image-C-color'>C</span>";
  public pre_peripheral_string = "<span class='relation-image relation-image-P-color'>P</span>";
  public lo_pre_key_string = "<span class='relation-image relation-image-K-color'>K</span>";
  public stfk_mapping_string = "<span class='type-mapping-container intermediary-stfK-type'>STFk</span>";
  public p_mapping_string = "<span class='type-mapping-container intermediary-P-type'>P</span>";
  public cu_mapping_string = "<span class='type-mapping-container intermediary-C-type'>CU</span>";

  public lo_pre_print_string = "<span class='print-P-icon marleft-25'></span>";
  public lo_pre_digital_string = "<span class='print-D-icon marleft-25'></span>";
  public lo_pre_printdigital_string = "<span class='print-PD-icon marleft-25'></span>";

  public loc_code_prefix = " (";
  public loc_code_suffix = ") ";

  public span_text_row_declaration = "<span class='row-fluid std-text-container'>";
  public span_row_declaration = "<span class='row-fluid'>";
  public mapping_type_container = "<span class='span1'>";
  public mapping_text_container = "<span class='span10'>";
  public newmapping_text_container = "<span class='span10 textContainer'>";
  public std_text_container = "<span class='span11_standard'>";
  public mapping_count_container = "<span class='span1'>";
  public mapping_count_span = "<span class='mapping-count-span'>";
  public mapping_count_span_hidden = "<span class='mapping-count-span-hidden'>";
  public mapping_children_count_span_hidden = "<span class='mapping-children-count-span-hidden'>";
  public mapping_children_count_span = "<span class='mapping-children-count-span'>";
  public mapping_parent_count_span = "<span class='parent-count'>";
  public span_declaration_end = "</span>";
  public ariaNodeLevel = 1;
  public alignmentTypeIcon: string | undefined;

  constructor(private httpRestClient: HttpRestClientService) { }

  public fetchLEarningObjectives(node: any, response: any, successCallback: any) {
    console.log(response);
    var treeStructure = this.convertResponseToTreeData(node, response);
    successCallback(treeStructure);

  }

  public convertResponseToTreeData = (node: any, response: any) => {
    var treeNodes = new Array();

    if (node && node !== undefined && node !== null && node.data && node.data !== undefined && node.data !== null) {
      var nodeMappingCount = 0;

      if (node.data.centrallyRelated && node.data.centrallyRelated !== undefined && node.data.centrallyRelated !== null && Array.isArray(node.data.centrallyRelated) && node.data.centrallyRelated.length > 0) {
        nodeMappingCount = nodeMappingCount + node.data.centrallyRelated.length;
        for (var j = 0; j < node.data.centrallyRelated.length; j++) {
          var intNode = node.data.centrallyRelated[j];
          var intText = intNode.prefLabel.en;
          var printDigitalAlignment = intNode.printDigitalAlignment;
          var tempTextNode;
          this.alignmentTypeIcon = this.getAlignmentTypeIcon(printDigitalAlignment);
          if (!_.isEmpty(intNode.intermediaryStatementCode) && !_.isEmpty(intNode.intermediaryStatementCode.en.trim())) {
            intText = intNode.intermediaryStatementCode.en + " - " + intText;
          }
          var print_string = "<span class='print-P-icon marleft-25'></span>";
          var digital_string = "<span class='print-D-icon marleft-25'></span>";
          var printdigital_string = "<span class='print-PD-icon marleft-25'></span>";
          if (this.alignmentTypeIcon === print_string) {
            this.alignmentTypeIcon = "<span class='print-P-icon pdIconCommon marleft-25' id='" + intNode.id + "' parent-id='" + node.id + "' relation-status='centrallyRelated' pd-statement='print'></span>";
            this.pre_central_string = "<span class='relation-image relation-image-C-color' id='" + intNode.id + "' parent-id='" + node.id + "' relation-status='centrallyRelated' pd-statement='print'>C</span>";
          } else if (this.alignmentTypeIcon === digital_string) {
            this.alignmentTypeIcon = "<span class='print-D-icon pdIconCommon marleft-25' id='" + intNode.id + "' parent-id='" + node.id + "' relation-status='centrallyRelated' pd-statement='digital'></span>";
            this.pre_central_string = "<span class='relation-image relation-image-C-color' id='" + intNode.id + "' parent-id='" + node.id + "' relation-status='centrallyRelated' pd-statement='digital'>C</span>";
          } else if (this.alignmentTypeIcon === printdigital_string) {
            this.alignmentTypeIcon = "<span class='print-PD-icon pdIconCommon marleft-25' id='" + intNode.id + "' parent-id='" + node.id + "' relation-status='centrallyRelated' pd-statement='printDigital'></span>";
            this.pre_central_string = "<span class='relation-image relation-image-C-color' id='" + intNode.id + "' parent-id='" + node.id + "' relation-status='centrallyRelated' pd-statement='printDigital'>C</span>";
          } else {
            this.pre_central_string = "<span class='relation-image relation-image-C-color' id='" + intNode.id + "' parent-id='" + node.id + "' relation-status='centrallyRelated'>C</span>";
          }

          if (this.alignmentTypeIcon !== undefined && this.alignmentTypeIcon !== null) {
            tempTextNode = this.span_row_declaration +
              this.mapping_type_container + this.pre_central_string + this.span_declaration_end +
              this.mapping_type_container + this.alignmentTypeIcon + this.span_declaration_end +
              this.newmapping_text_container + intText + this.span_declaration_end +
              this.mapping_type_container + this.getMappingType(intNode.type) + this.span_declaration_end +
              this.span_declaration_end;
          } else {
            tempTextNode = this.span_row_declaration +
              this.mapping_type_container + this.pre_central_string + this.span_declaration_end +
              this.mapping_text_container + intText + this.span_declaration_end +
              this.mapping_type_container + this.getMappingType(intNode.type) + this.span_declaration_end +
              this.span_declaration_end;
          }
          var treeNode = {
            "id": node.id + '__' + intNode.id,
            "text": tempTextNode,
            "a_attr": { "class": "is-central" }
          };
          treeNodes.push(treeNode);
        }
      }
      if (node.data.peripherallyRelated && node.data.peripherallyRelated !== undefined && node.data.peripherallyRelated !== null && Array.isArray(node.data.peripherallyRelated) && node.data.peripherallyRelated.length > 0) {
        nodeMappingCount = nodeMappingCount + node.data.peripherallyRelated.length;
        for (var k = 0; k < node.data.peripherallyRelated.length; k++) {
          var intNode = node.data.peripherallyRelated[k];
          var intText = intNode.prefLabel.en;
          var printDigitalAlignment = intNode.printDigitalAlignment;
          var tempTextNode;
          this.alignmentTypeIcon = this.getAlignmentTypeIcon(printDigitalAlignment);
          if (!_.isEmpty(intNode.intermediaryStatementCode) && !_.isEmpty(intNode.intermediaryStatementCode.en.trim())) {
            intText = intNode.intermediaryStatementCode.en + " - " + intText;
          }

          var print_string = "<span class='print-P-icon marleft-25'></span>";
          var digital_string = "<span class='print-D-icon marleft-25'></span>";
          var printdigital_string = "<span class='print-PD-icon marleft-25'></span>";
          if (this.alignmentTypeIcon === print_string) {
            this.alignmentTypeIcon = "<span class='print-P-icon pdIconCommon marleft-25' id='" + intNode.id + "' parent-id='" + node.id + "' relation-status='peripherallyRelated' pd-statement='print'></span>";
            this.pre_peripheral_string = "<span class='relation-image relation-image-P-color' id='" + intNode.id + "' parent-id='" + node.id + "' relation-status='peripherallyRelated' pd-statement='print'>P</span>";
          } else if (this.alignmentTypeIcon === digital_string) {
            this.alignmentTypeIcon = "<span class='print-D-icon pdIconCommon marleft-25' id='" + intNode.id + "' parent-id='" + node.id + "' relation-status='peripherallyRelated' pd-statement='digital'></span>";
            this.pre_peripheral_string = "<span class='relation-image relation-image-P-color' id='" + intNode.id + "' parent-id='" + node.id + "' relation-status='peripherallyRelated' pd-statement='digital'>P</span>";
          } else if (this.alignmentTypeIcon === printdigital_string) {
            this.alignmentTypeIcon = "<span class='print-PD-icon pdIconCommon marleft-25' id='" + intNode.id + "' parent-id='" + node.id + "' relation-status='peripherallyRelated' pd-statement='printDigital'></span>";
            this.pre_peripheral_string = "<span class='relation-image relation-image-P-color' id='" + intNode.id + "' parent-id='" + node.id + "' relation-status='peripherallyRelated' pd-statement='printDigital'>P</span>";
          } else {
            this.pre_peripheral_string = "<span class='relation-image relation-image-P-color' id='" + intNode.id + "' parent-id='" + node.id + "' relation-status='peripherallyRelated'>P</span>";
          }

          if (this.alignmentTypeIcon !== undefined && this.alignmentTypeIcon !== null) {
            tempTextNode = this.span_row_declaration +
              this.mapping_type_container + this.pre_peripheral_string + this.span_declaration_end +
              this.mapping_type_container + this.alignmentTypeIcon + this.span_declaration_end +
              this.newmapping_text_container + intText + this.span_declaration_end +
              this.mapping_type_container + this.getMappingType(intNode.type) + this.span_declaration_end +
              this.span_declaration_end;
          } else {
            tempTextNode = this.span_row_declaration +
              this.mapping_type_container + this.pre_peripheral_string + this.span_declaration_end +
              this.mapping_text_container + intText + this.span_declaration_end +
              this.mapping_type_container + this.getMappingType(intNode.type) + this.span_declaration_end +
              this.span_declaration_end;
          }
          var treeNode = {
            "id": node.id + '__' + intNode.id,
            "text": tempTextNode,
            "a_attr": { "class": "is-peripheral" }
          };
          treeNodes.push(treeNode);
        }
      }
      if (node.data.keyRelated && node.data.keyRelated !== undefined && node.data.keyRelated !== null && Array.isArray(node.data.keyRelated) && node.data.keyRelated.length > 0) {
        nodeMappingCount = nodeMappingCount + node.data.keyRelated.length;
        for (var k = 0; k < node.data.keyRelated.length; k++) {
          var intNode = node.data.keyRelated[k];
          var intText = intNode.prefLabel.en;
          var printDigitalAlignment = intNode.printDigitalAlignment;
          var tempTextNode;
          this.alignmentTypeIcon = this.getAlignmentTypeIcon(printDigitalAlignment);
          if (!_.isEmpty(intNode.intermediaryStatementCode) && !_.isEmpty(intNode.intermediaryStatementCode.en.trim())) {
            intText = intNode.intermediaryStatementCode.en + " - " + intText;
          }

          var print_string = "<span class='print-P-icon marleft-25'></span>";
          var digital_string = "<span class='print-D-icon marleft-25'></span>";
          var printdigital_string = "<span class='print-PD-icon marleft-25'></span>";
          if (this.alignmentTypeIcon === print_string) {
            this.alignmentTypeIcon = "<span class='print-P-icon pdIconCommon marleft-25' id='" + intNode.id + "' parent-id='" + node.id + "' relation-status='keyRelated' pd-statement='print'></span>";
            this.lo_pre_key_string = "<span class='relation-image relation-image-K-color' id='" + intNode.id + "' parent-id='" + node.id + "' relation-status='keyRelated' pd-statement='print'>K</span>";
          } else if (this.alignmentTypeIcon === digital_string) {
            this.alignmentTypeIcon = "<span class='print-D-icon pdIconCommon marleft-25' id='" + intNode.id + "' parent-id='" + node.id + "' relation-status='keyRelated' pd-statement='digital'></span>";
            this.lo_pre_key_string = "<span class='relation-image relation-image-K-color' id='" + intNode.id + "' parent-id='" + node.id + "' relation-status='keyRelated' pd-statement='digital'>K</span>";
          } else if (this.alignmentTypeIcon === printdigital_string) {
            this.alignmentTypeIcon = "<span class='print-PD-icon pdIconCommon marleft-25' id='" + intNode.id + "' parent-id='" + node.id + "' relation-status='keyRelated' pd-statement='printDigital'></span>";
            this.lo_pre_key_string = "<span class='relation-image relation-image-K-color' id='" + intNode.id + "' parent-id='" + node.id + "' relation-status='keyRelated' pd-statement='printDigital'>K</span>";
          } else {
            this.lo_pre_key_string = "<span class='relation-image relation-image-K-color' id='" + intNode.id + "' parent-id='" + node.id + "' relation-status='keyRelated'>K</span>";
          }

          if (this.alignmentTypeIcon !== undefined && this.alignmentTypeIcon !== null) {
            tempTextNode = this.span_row_declaration +
              this.mapping_type_container + this.lo_pre_key_string + this.span_declaration_end +
              this.mapping_type_container + this.alignmentTypeIcon + this.span_declaration_end +
              this.newmapping_text_container + intText + this.span_declaration_end +
              this.mapping_type_container + this.getMappingType(intNode.type) + this.span_declaration_end +
              this.span_declaration_end;
          } else {
            tempTextNode = this.span_row_declaration +
              this.mapping_type_container + this.lo_pre_key_string + this.span_declaration_end +
              this.mapping_text_container + intText + this.span_declaration_end +
              this.mapping_type_container + this.getMappingType(intNode.type) + this.span_declaration_end +
              this.span_declaration_end;
          }
          var treeNode = {
            "id": node.id + '__' + intNode.id,
            "text": tempTextNode,
            "a_attr": { "class": "is-key" }
          };
          treeNodes.push(treeNode);
        }
      }
    }

    if (response && response !== undefined && response !== null) {
      if (response.hasPart !== undefined && response.hasPart !== null && response.hasPart.length > 0) {
        //var childrenMappingCount = 0;
        for (var i = 0; i < response.hasPart.length; i++) {
          var mappingCount = 0;
          var res = response.hasPart[i];
          var childres = (res?.hasPart)?res.hasPart:[res];
          var childrenMappingCount = this.countChildren(childres);
          if (res.definition) {
            var stdText = this.getLocCodeSuffix(res.locCode) + res.definition.description;
          }
          else {
            var stdText = this.getLocCodeSuffix(res.locCode) + res.name;
          }


          var treeData = {
            "id": res.id,
            "originalResult": res,
            "ariaNodeLevel": this.ariaNodeLevel,
            "data": {
              centrallyRelated: any,
              originalText: any,
              hasChild: true,
              peripherallyRelated: any,
              keyRelated: any,
              locCode: any
            },
            "text": "",
            "children": false,
            "a_attr": {}
          };
          //treeData.data = {};
          treeData.data.originalText = res.definition ? res.definition.description : res.name;
          if (res.hasPart && res.hasPart !== undefined && res.hasPart !== null && res.hasPart.length > 0) {
            treeData.children = true;
            treeData.data.hasChild = true;
          }
          if (res.centrallyRelated && res.centrallyRelated !== undefined && res.centrallyRelated !== null && res.centrallyRelated.length > 0) {
            treeData.data.centrallyRelated = res.centrallyRelated;
            treeData.children = true;
            mappingCount = mappingCount + res.centrallyRelated.length;
            //childrenMappingCount = childrenMappingCount + res.centrallyRelated.length;
          }
          if (res.peripherallyRelated && res.peripherallyRelated !== undefined && res.peripherallyRelated !== null && res.peripherallyRelated.length > 0) {
            treeData.data.peripherallyRelated = res.peripherallyRelated;
            treeData.children = true;
            mappingCount = mappingCount + res.peripherallyRelated.length;
            //childrenMappingCount = childrenMappingCount + res.peripherallyRelated.length;
          }
          if (res.keyRelated && res.keyRelated !== undefined && res.keyRelated !== null && res.keyRelated.length > 0) {
            treeData.data.keyRelated = res.keyRelated;
            treeData.children = true;
            mappingCount = mappingCount + res.keyRelated.length;
            //childrenMappingCount = childrenMappingCount + res.keyRelated.length;
          }

          if (childrenMappingCount > 0) {
            if (mappingCount > 0) {
              stdText = this.span_text_row_declaration +
                this.std_text_container + stdText + this.span_declaration_end +
                this.mapping_count_container + this.mapping_children_count_span + childrenMappingCount + this.span_declaration_end + this.span_declaration_end +
                this.mapping_count_container + this.mapping_count_span + mappingCount + this.span_declaration_end + this.span_declaration_end +
                this.span_declaration_end;
              treeData.a_attr = { "class": "has-intermediary" };
            } else {
              stdText = this.span_text_row_declaration +
                this.std_text_container + stdText + this.span_declaration_end +
                this.mapping_count_container + this.mapping_children_count_span + childrenMappingCount + this.span_declaration_end + this.span_declaration_end +
                this.mapping_count_container + this.mapping_count_span_hidden + mappingCount + this.span_declaration_end + this.span_declaration_end +
                this.span_declaration_end;
            }
          } else {
            if (mappingCount > 0) {
              stdText = this.span_text_row_declaration +
                this.std_text_container + stdText + this.span_declaration_end +
                this.mapping_count_container + this.mapping_children_count_span_hidden + this.span_declaration_end + this.span_declaration_end +
                this.mapping_count_container + this.mapping_count_span + mappingCount + this.span_declaration_end + this.span_declaration_end +
                this.span_declaration_end;
              treeData.a_attr = { "class": "has-intermediary" };
            } else {
              stdText = this.span_text_row_declaration +
                this.std_text_container + stdText + this.span_declaration_end +
                this.mapping_count_container + this.mapping_children_count_span_hidden + this.span_declaration_end + this.span_declaration_end +
                this.mapping_count_container + this.mapping_count_span_hidden + this.span_declaration_end + this.span_declaration_end +
                this.span_declaration_end;
            }
          }

          treeData.text = stdText;
          if (res.locCode && res.locCode !== undefined && res.locCode !== null) {
            treeData.data.locCode = res.locCode;
          }
          treeNodes.push(treeData);
        }
      }
    }
    this.ariaNodeLevel++;
    return treeNodes;
  }

  public AlignIntermediary(childIri: any) {
    var reqApi = 'api/plan';

    var treeRequestData = {
      iri: childIri
    };
    var url = reqApi + '?iri=' + treeRequestData.iri
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      console.log(result);
      //this.curriculumTreeData = result;
      //this.preloadData.updateCurriculumTreeData(this.curriculumTreeData);
      //this.displayResult(result);
    }, (error) => {
      console.log(error);
    });
  }

  public getMappingType(typeArray: any) {
    const ApplicationConstants = ["STFkConcept", "ProcessConcept", "UnderstandingConcept"];
    var typeName;
    var type;
    var returnValue;
    var typeArrlength = typeArray.length;
    for (let i = 0; i < typeArrlength; i++) {
      type = typeArray[i];
      for (var key in ApplicationConstants) {
        if (ApplicationConstants.hasOwnProperty(key)) {
          if (type === ApplicationConstants[key]) {
            typeName = type;
            break;
          }
        }
      }
    }
    if (typeName === 'STFkConcept') {
      returnValue = this.stfk_mapping_string;
    }
    else if (typeName === 'ProcessConcept') {
      returnValue = this.p_mapping_string;
    }
    else if (typeName === 'UnderstandingConcept') {
      returnValue = this.cu_mapping_string;
    }
    else {
      returnValue = "";
    }
    return returnValue;
  }

  public getAlignmentTypeIcon(printDigitalAlignment: any) {
    var alignmentIconSpan;

    if (printDigitalAlignment !== undefined && printDigitalAlignment !== null && printDigitalAlignment !== "") {
      if (printDigitalAlignment === 'print') {
        alignmentIconSpan = this.lo_pre_print_string;
      }
      else if (printDigitalAlignment === 'digital') {
        alignmentIconSpan = this.lo_pre_digital_string;
      }
      else if (printDigitalAlignment === 'printDigital') {
        alignmentIconSpan = this.lo_pre_printdigital_string;
      }
    }
    return alignmentIconSpan;
  }
  public countChildren(childres: any) {
    var childrenMappingCount = 0;
    if (childres && childres !== undefined && childres !== null) {
      for (var i = 0; i < childres.length; i++) {

        if (childres[i]?.centrallyRelated && childres[i]?.centrallyRelated !== undefined && childres[i]?.centrallyRelated !== null && childres[i]?.centrallyRelated.length > 0) {
          childrenMappingCount = childrenMappingCount + childres[i].centrallyRelated.length;
        }
        if (childres[i]?.peripherallyRelated && childres[i]?.peripherallyRelated !== undefined && childres[i]?.peripherallyRelated !== null && childres[i]?.peripherallyRelated.length > 0) {
          childrenMappingCount = childrenMappingCount + childres[i].peripherallyRelated.length;
        }
        if (childres[i]?.keyRelated && childres[i]?.keyRelated !== undefined && childres[i]?.keyRelated !== null && childres[i]?.keyRelated.length > 0) {
          childrenMappingCount = childrenMappingCount + childres[i].keyRelated.length;
        }
      }
    }
    return childrenMappingCount
  };

  public getLocCodeSuffix = (locCode: any) => {
    if (locCode !== undefined && locCode !== null && locCode !== '') {
      return this.loc_code_prefix + locCode + this.loc_code_suffix;
    }
    else {
      return '';
    }
  };

  public refreshTree = () => {
    // var reqApi = 'api/plan';
    // //this.preloadData.productIRIValue.subscribe(data => this.productIRI = data)
    // var selectedGrade = this.intermediaryAlignmentData.gradeInfo.selectedGrade;
    // this.intermediaryAlignData.treeRequestData = {
    //   iri: this.productIRI
    // };
    // this.intermediaryAlignData.gradeInfo = {
    //   selectedLocalGrade: {}
    // }
    // // if (selectedGrade.gradeBeanList.length > 1) {
    // //   this.intermediaryAlignData.gradeInfo.selectedLocalGrade = selectedGrade.gradeBeanList[0];
    // //   this.intermediaryAlignData.treeRequestData.strandIRI = selectedGrade.gradeBeanList[0].gradeIRI;
    // // }
    // var url = reqApi + '?iri=' + this.intermediaryAlignData.treeRequestData.iri
    // var url = url + '&mapping=' + this.productMapping;
    // console.log(url);
    // this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
    //   console.log(result, 'xxxxxxxxx');
    //   this.curriculumTreeData = result;
    //   this.preloadData.updateCurriculumTreeData(this.curriculumTreeData);
    //   //this.displayResult(result);
    // }, (error) => {
    //   console.log(error);
    // });
  }
}
