<div class="currEditHeader">
    <div class="marginLeft10 marginRight10">
        <div [hidden]="!topicPreviewBean">
            <div>
                <input type="submit" value="{{globalVar?.languageString?.resources?.manageTab?.manageBackBtnLabel}}" class="cmButton-goBack marginTop5px floatRight" style="height: 33px;" (click)="goBackToCurriculumEdit()"/>
                <span class="input-group-addon"><i class="fa fa-chevron-left floatRight"></i></span>
            </div>
            <div class="marginLeft10 paddingTop20">
                <div class="font_18_666666">{{globalVar?.curriculaEditBean?.curriculaPreviewBean?.curriculumTitle}}</div>
                <div class="font_18_666666" style="margin-top: 7px;">{{globalVar?.languageString?.resources?.manageTab?.curriculumGradeEditGradeLabel}}<span>{{ topicPreviewBean?.grade }}</span></div>
                <div class="font_18_666666">{{globalVar?.languageString?.resources?.manageTab?.curriculumLocalGradeEditLabel}}<span>{{ topicPreviewBean?.gradeName }}</span></div>
            </div>
            <div class="marginLeft10 paddingTop20">
                <div class="searchItemContainer">
                    <input id="topicsSearchText" ng-model="curriculaEditBean.searchText" type="text"
                            class="form-control alignmentSearchText" placeholder="{{globalVar?.languageString?.resources?.manageTab?.curriculumEditSearchText}}"
                            ng-change="filterTopics()"/>
                    <span class="input-group-addon cursorPointer" ng-click="clearSearchText()">
                        <i class="fa fa-times alignmentSearchTextCross" title="{{globalVar?.languageString?.resources?.manageTab?.curriculumEditClearsearch}}"></i>
                    </span>
                </div>

                <div class="marginTop20">
                    <div class="font_12_666666" title="{{globalVar?.languageString?.resources?.manageTab?.curriculumEditAddTopic}}">
                        <span class="smallAddIcon" title="{{globalVar?.languageString?.resources?.manageTab?.curriculumEditAddTopic}}" (click)="addSubTopic(topicPreviewBean, undefined, true, true)"></span>
                        <span class="cmButton-goBack addTopicBtn" (click)="addSubTopic(topicPreviewBean, undefined, true, true)">{{globalVar?.languageString?.resources?.manageTab?.curriculumEditAddTopic}}</span>
                    </div>
                    <app-topic-tree-view [topicData]="topicPreviewBean" [addSubTopic]="addSubTopic" [addEditTopicPopup]="addEditTopicPopup" 
                        [deleteTopic]="deleteTopic" [expandNodeIds]="expandNodeIds" [loadTopics]="loadTopics" [topicPreviewStaticData]="topicPreviewBean"></app-topic-tree-view>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #deleteTopicConfirmation>
    <app-warning-popup [DoFunctionOnSuccess]="popupData.doSuccessFunction" [PopupMessage]="popupData.popupMessage"
         [ConfirmationMessage]="popupData.confirmationMsg" [ButtonLabel] ="popupData.buttonLabel" 
         [modalRef]="modalRef"></app-warning-popup>
 </ng-template>

 <ng-template #addEditTopicPopup>
    <app-add-edit-topic-popup [modalRef]="modalRef" [createItemBean]="createItemBean" [deployedToRealize]="globalVar.curriculaEditBean.curriculaPreviewBean.deployedToRealize" [saveTopic]="saveTopic"></app-add-edit-topic-popup>
 </ng-template>