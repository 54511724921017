import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  public isEmpty = (inputString: any) => {
      var empty = false;
      if (inputString === undefined ||
              inputString === null ||
              inputString === "" ||
              inputString === "undefined" ||
              inputString === "null" ||
              inputString === "NULL" ||
              inputString === "UNDEFINED")
      {
          empty = true;
      }
      return empty;
  }

  public appendStrings = (string: any, value: any) => {
    if(!this.isEmpty(string)){
      string = string + ',' + value;
      }
      else{
        string = string + value;
      }
    return string;
  }
  
}
