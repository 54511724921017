import { Component, OnInit, ViewChild } from '@angular/core';
import { LearningObjectiveServiceService } from 'src/app/services/common/learning-objective-service.service';
import { PreloadDataService } from 'src/app/services/common/preload-data.service';
import { ConfirmationPopupService } from 'src/app/services/confirmation-popup/confirmation-popup.service';
import { GlobalVariablesService } from '../../services/global-variables/global-variables.service';
import $ from 'jquery';
import 'jstree';
import { any, isEmpty, object, result } from 'underscore';
import _ from 'underscore';
import { IntermediaryAlignmentComponent } from '../intermediary-alignment/intermediary-alignment.component';
import { HttpRestClientService } from 'src/app/services/common/http-rest-client.service';



@Component({
  selector: 'app-learning-objective-tree',
  templateUrl: './learning-objective-tree.component.html',
  styleUrls: ['./learning-objective-tree.component.scss']
})
export class LearningObjectiveTreeComponent implements OnInit {

  [x: string]: any;
  isChild = false;
  public response: any;
  public treeNodeAligned = false;
  public mappingExpandAllBtnLabel = "EXPAND ALL";
  public selectedTopicsList = new Array();
  public hasPart: boolean = false;
  public saveResponse: any;
  public aligned: any;
  public isChildApi = false;
  public childData: any = [];
  isExapnd = false;
  loading = false;

  constructor(
    private httpRestClient: HttpRestClientService,
    private learningObjectiveService: LearningObjectiveServiceService,
    private preloadData: PreloadDataService,
    private confirmationPopup: ConfirmationPopupService,
    private globalVar: GlobalVariablesService) {
    this.preloadData.selectedTopicsList = this.selectedTopicsList;
    this.preloadData.disableTreeFunction = this.disableTree;
    this.preloadData.enableTreeFunction = this.enableTree;
    this.preloadData.refreshTreeAfterSave = this.callRefresh;
    this.preloadData.expandAllFunction = this.alignStandardExpand;
    this.preloadData.expandAllProductFunction = this.alignStandardExpandByNode;
    // function call() {
    //   return result;
    // };
    this.preloadData.selectedTopics.subscribe(value => {
      if (value.length === 0) {
        this.selectedTopicsList = [];
      }
    })
    // this.preloadData.currentCurriculumTreeData.subscribe(value => {
    //   if (value.hasPart) {
    //     this.getLearningObjectives(value, call);
    //   }
    // })
  }

  ngOnInit(): void {
    $('#learningObjectiveSelectionTree').jstree({
      "plugins": [
        "checkbox",
        "state",
      ],
      "checkbox": {
        "keep_selected_style": false,
        "whole_node": false,
        "three_state": false,
        "visible": true,
        "tie_selection": false
      },
      "core": {
        'data': this.getLearningObjectives,
        "expand_selected_onload": false,
        "animation": 0,
        "check_callback": true,
        "dblclick_toggle": false,
        'themes': {
          "icons": false,
          "dots": false
        }
      }
    });

    $("#learningObjectiveSelectionTree").on("select_node.jstree deselect_node.jstree", function (e: any, treeData: any) {
      //this.treeData = (<HTMLElement | null>document.getElementById("treeData"));

      //this.treeData = treeData;
      if (!_.isEmpty(treeData.node.data)) {
        //this.parentId = treeData.node.id;
        var parentIdVal = '#' + treeData.node.id;
        $('#learningObjectiveSelectionTree').jstree("toggle_node", parentIdVal);
        e.preventDefault();
        e.stopImmediatePropagation();
        expandTree(treeData);
      }
    });


    // $('#learningObjectiveSelectionTree').on("open_node.jstree", function (e, data) {
    //   //expandTree(this.parentId);
    //   expandTree(data);
    // });

    // $("#learningObjectiveSelectionTree").on("click.jstree", function (e: any, treeData: HTMLElement) {
    //   expandTree(this.preloadData.treeDataNode);
    // });

    $("#learningObjectiveSelectionTree").bind("check_node.jstree uncheck_node.jstree", function (e: any, data: any) {
      if (e !== undefined && e !== null) {
        var isSelected = false;
        if (e.type === 'check_node') {
          isSelected = true;
        }
        if (data !== undefined && data !== null && data.node !== undefined && data.node !== null) {
          var topic = {
            data: any,
            topicId: data.node.id
          };
          if (data.node.data !== undefined && data.node.data !== null) {
            topic.data = data.node.data;
          }
          updateCheckedTopics(topic, isSelected, null);
        }
      }
    });

    const updateCheckedTopics = (topic: any, isSelected: any, val: any) => {
      if (topic.data.centrallyRelated && Array.isArray(topic.data.centrallyRelated) && topic.data.centrallyRelated.length > 0) {
        this.preloadData.enableUnalign.next(true);
      }
      else {
        this.preloadData.enableUnalign.next(false);
      }
      var addNode = true;
      var selectedTopic = {
        id: any,
        data: any
      }
      selectedTopic.id = topic.topicId;
      selectedTopic.data = topic.data;
      if (isSelected == true) {
        if (this.selectedTopicsList.length > 0) {
          this.selectedTopicsList.forEach((item: any) => {
            if (item.id === selectedTopic.id) {
              addNode = false;
            }
          })
        }
        if (addNode) { this.selectedTopicsList.push(selectedTopic); }
      } else {
        if (this.selectedTopicsList.length == 0) {
          this.preloadData.enableUnalign.next(false);
        }
        let index = this.selectedTopicsList.indexOf(selectedTopic);
        this.selectedTopicsList.splice(index, 1);
      }
      this.preloadData.selectedTopics.next(this.selectedTopicsList);
      this.preloadData.selectedTopicsList = this.selectedTopicsList;
      //this.preloadData.selectedTopicsList = this.selectedTopicsList;
      var treeHierarchyData = $('#learningObjectiveSelectionTree').jstree(true).get_json('#', { flat: true });
      var tempData = Object.assign(treeHierarchyData);
      //this.disableTree('#learningObjectiveSelectionTree', tempData);
    }

    var expandTree = (treeDataNode: any) => {
      this.hasPart = false;
      if (this.preloadData.componentOneFnIntermediary || this.preloadData.componentOneFnProduct) {
        function call() {
          return result;
        };
        if (treeDataNode?.node?.data?.centrallyRelated && Array.isArray(treeDataNode.node.data.centrallyRelated)) {
          treeDataNode?.node?.original?.originalResult?.hasPart?.forEach((item: any) => {
            if (item.name) {
              this.hasPart = true;
            }
          });
          if (this.hasPart) {
            this.getLearningObjectives(treeDataNode, call);
          }
          else {
            let data: any = localStorage.getItem("isProductMapping");
            if (JSON.parse(data)) {
              this.preloadData.componentOneFnProduct(treeDataNode.node.id, this.httpRestClient, this.preloadData);
            }
            else {
              this.preloadData.componentOneFnIntermediary(true, treeDataNode.node.id, this.selectedGradeForCurriculum, this.httpRestClient, this.preloadData);
            }
          }
          //this.getLearningObjectives(treeDataNode, call);
          setTimeout(() => {
            this.getLearningObjectives(treeDataNode, call);
            //$('#learningObjectiveSelectionTree').jstree(true).settings!.core.data = treeData;
            //$('#learningObjectiveSelectionTree').jstree(true).redraw(true);
            //$('#learningObjectiveSelectionTree').jstree(true).refresh();
          }, 2000);
        }
        else {
          let data: any = localStorage.getItem("isProductMapping");
          if (JSON.parse(data)) {
            this.preloadData.componentOneFnProduct(treeDataNode.node.id, this.httpRestClient, this.preloadData);
          }
          else {
            this.preloadData.componentOneFnIntermediary(true, treeDataNode.node.id, this.selectedGradeForCurriculum, this.httpRestClient, this.preloadData);
          }
          setTimeout(() => {
            this.getLearningObjectives(treeDataNode, call);
            //$('#learningObjectiveSelectionTree').jstree(true).settings!.core.data = treeData;
            //$('#learningObjectiveSelectionTree').jstree(true).redraw(true);
            //$('#learningObjectiveSelectionTree').jstree(true).refresh();
          }, 2000);

        }
      }
    }


    $("#learningObjectiveSelectionTree").bind("after_open.jstree", function (e, data) {

      //$('#learningObjectiveSelectionTree').jstree('disable_node', data.node.children);
      $('#learningObjectiveSelectionTree li a').each(function () {
        if ($(this).hasClass('jstree-checked')) {
          $(this).addClass('intermediary-selected-tree-node');
        }
        // else {
        //   $(this).addClass('intermediary-disabled-tree-node');
        // }
      });

    });

    $("#learningObjectiveSelectionTree").bind("refresh.jstree", function (e, data) {
      // if (this.treeNodeAligned) {
      //   var tempData = Object.assign(this.treeHierarchyData);
      //   this.disableTree('#learningObjectiveSelectionTree', tempData);
      // } else {
      //   var isFromPasteOption = Object.assign(this.isFromPasteOption);
      //   if (!isFromPasteOption) {
      //     var id = this.selectedTopicsList[0];
      //     $('#learningObjectiveSelectionTree').jstree('uncheck_node', id);
      //     //this.selectedTopicsList = [];
      //   }
      //   //this.isFromPasteOption = false;
      // }
      recheckCollapse();
    });

    $("#learningObjectiveSelectionTree").bind("refresh.jstree", function (event, data) {
      //alert("the tree has been refreshed");
      $("#learningObjectiveSelectionTree").jstree("open_all");  //Example: open all nodes after refreshing
    })

    // $("#learningObjectiveSelectionTree").bind("open_node.jstree", function (event, data) {
    //   if ((data.node._get_parent(data.node.parents)).length) {
    //     data.node._get_parent(data.node.parents).open_node(this, false);
    //   }
    // });

    // var $treeview = $("#learningObjectiveSelectionTree");
    // $treeview
    //   .jstree()
    //   .on('loaded.jstree', function () {
    //     $treeview.jstree('open_all');
    //   });

    var recheckCollapse = () => {
      setTimeout(() => {
        if (this.mappingExpandAllBtnLabel == 'COLLAPSE ALL') {
          $("#learningObjectiveSelectionTree").jstree("open_all");
        }
        var lastSelectedNode = document.getElementById(this.lastAlignedNodeId);
        if (lastSelectedNode) {
          lastSelectedNode.scrollIntoView({
            behavior: 'smooth',
            block: "start"
          });
        }
      }, 1000);
    }
    setTimeout(() => {
      $("#learningObjectiveSelectionTree").jstree('close_all');
    }, 1000)

  }

  public disableTree = function (treeId: any, tempData: any) {
    $(treeId).jstree('disable_node', tempData);
    $(treeId + ' li a').addClass('intermediary-disabled-tree-node');
    $(treeId + ' .jstree-checked').removeClass('intermediary-disabled-tree-node').addClass('intermediary-selected-tree-node');
    // $scope.disableCopy = true;
    // $scope.disablePaste = true;
    // $scope.disableStandardCopy = true;
    // $scope.disableUnalign = true;
    // $scope.disableStandardPaste = true;
  };

  public enableTree = (treeId: any, tempData: any) => {
    $(treeId).jstree('enable_node', tempData);
    $(treeId + ' li a').removeClass('intermediary-disabled-tree-node');
    $(treeId + ' li a').removeClass('intermediary-selected-tree-node');
    //$(treeId + ' .jstree-checked').removeClass('intermediary-selected-tree-node');
    //$(treeId + ' .jstree-checked').removeClass("jstree-checked").css("font-size", "20px !important");
    // $(treeId).jstree).removeClass("jstree-checked").css("opacity", "1");
  }


  public alignStandardExpand(mappingExpandAllBtnLabel: any) {
    var mappingExpandAllBtnLabelReturn = '';
    if (mappingExpandAllBtnLabel === 'EXPAND ALL') {
      $("#learningObjectiveSelectionTree").jstree('open_all');
      mappingExpandAllBtnLabelReturn = 'COLLAPSE ALL';
      // $rootScope.applicationCache.languageString.resources.intermediaryAlignTab.mappingExpandAllBtnLabel = 'COLLAPSE ALL';
    }
    else if (mappingExpandAllBtnLabel === 'COLLAPSE ALL') {
      $("#learningObjectiveSelectionTree").jstree('close_all');
      mappingExpandAllBtnLabelReturn = 'EXPAND ALL';

      // $rootScope.applicationCache.languageString.resources.intermediaryAlignTab.mappingExpandAllBtnLabel = 'EXPAND ALL';
    }
    return mappingExpandAllBtnLabelReturn;
  }

  alignStandardExpandByNode(mappingExpandAllBtnLabel: any, id: any) {
    var mappingExpandAllBtnLabelReturn = '';
    if (mappingExpandAllBtnLabel === 'EXPAND ALL') {
      // $("#learningObjectiveSelectionTree").jstree('open_all');

      $("#learningObjectiveSelectionTree").jstree('open_node', id);
      mappingExpandAllBtnLabelReturn = 'COLLAPSE ALL';
      // $rootScope.applicationCache.languageString.resources.intermediaryAlignTab.mappingExpandAllBtnLabel = 'COLLAPSE ALL';
    }
    else if (mappingExpandAllBtnLabel === 'COLLAPSE ALL') {
      $("#learningObjectiveSelectionTree").jstree('close_all');
      mappingExpandAllBtnLabelReturn = 'EXPAND ALL';

      // $rootScope.applicationCache.languageString.resources.intermediaryAlignTab.mappingExpandAllBtnLabel = 'EXPAND ALL';
    }
    return mappingExpandAllBtnLabelReturn;
  }

  public callRefresh = (response: any) => {
    function call(this: any) {
      // $('#learningObjectiveSelectionTree').jstree(true).settings!.core.data = this.saveResponse;
      // $('#learningObjectiveSelectionTree').jstree().refresh();
      // $('#learningObjectiveSelectionTree').jstree(true).refresh(true);
      //$("#learningObjectiveSelectionTree").jstree('open_all');
      // $("#learningObjectiveSelectionTree").jstree(true).refresh_node($("#learningObjectiveSelectionTree").jstree(true).get_node("node_1").parent);
      // var treeId = '#learningObjectiveSelectionTree';
      // var treeHierarchyData = $(treeId).jstree(true).get_json('#', { flat: true });
      // var tempData = Object.assign(treeHierarchyData);
      // this.enableTree(treeId, tempData);
      //$('#learningObjectiveSelectionTree').jstree(true).redraw(true);
      $('#learningObjectiveSelectionTree').jstree(true).refresh(true);
      return result;
    };
    this.refreshTree('', call, response);
  }

  public refreshTree = (nodeValue: any, treeCallback: Function, response: any) => {
    var treeData;
    this.learningObjectiveService.fetchLEarningObjectives(nodeValue, response, (result: any) => {
      this.saveResponse = result;
      console.log(result);
      treeCallback.call(this, result);
      // if (isEmpty(result)) {
      //   this.confirmationPopup.showErrorMessage('Please add an intermediary to the cart');
      // }
      this.globalVar.loading = false;
      this.hasPart = false;
      return this.saveResponse;
    });
  }


  public getLearningObjectives = (node: any, treeCallback: Function) => {
    let treeData: any;
    this.globalVar.loading = true;
    let nodeValue = node.node ? node.node : node;
    if (nodeValue?.original?.originalResult?.hasPart?.length > 0) {
      nodeValue?.original?.originalResult?.hasPart.forEach((item: any) => {
        if (item?.name) {
          this.response = nodeValue.original.originalResult;
          this.hasPart = true;
        }
        else if (item?.definition?.description) {
          this.response = nodeValue.original.originalResult;
          this.hasPart = true;
        }
        else if (item?.isEmpty == false) {
          this.response = nodeValue.original.originalResult;
          this.hasPart = true;
        }
      })
      if (this.hasPart === false) {
        this.preloadData.componentOneFn(nodeValue.id, this.httpRestClient, this.preloadData);
        this.response = undefined;
        setTimeout(() => {
          this.preloadData?.currentCurriculumTreeData.subscribe(currentTreeData => {
            this.response = currentTreeData
            this.fetchLEarningObjectives(nodeValue, treeData, treeCallback);
          }
          );
        }, 1000);
      }
    }
    // else if (nodeValue?.parents.length > 1) {
    //   this.response = undefined;
    // }
    else if (nodeValue?.parents?.length === 0) {
      this.preloadData?.currentCurriculumTreeData.subscribe(currentTreeData => this.response = currentTreeData);
    }
    else if (nodeValue?.hasPart?.length > 0) {
      this.preloadData?.currentCurriculumTreeData.subscribe(currentTreeData => this.response = currentTreeData);
    }
    else {
      this.response = undefined;
      this.isChild = true;
    }
    // if(isEmpty(this.response)){}
    this.fetchLEarningObjectives(nodeValue, treeData, treeCallback);

  }

  fetchLEarningObjectives(nodeValue: any, treeData: any, treeCallback: any) {
    if (this.response || this.isChild)
      this.learningObjectiveService.fetchLEarningObjectives(nodeValue, this.response, (result: any) => {
        treeData = result;
        console.log(result);
        treeCallback.call(this, result);
        // if (isEmpty(result)) {
        //   this.confirmationPopup.showErrorMessage('Please add an intermediary to the cart');
        // }
        this.globalVar.loading = false;
        this.hasPart = false;
        this.isChild = false;
        return treeData;
      });
  }

}
