<div class="curriculumMenu curriculumMenuPanel">
    <ul class=" nav nav-pills navPillMbleMng">
        <li [ngClass]="{ 'active' : isActiveMenu('new')}"><a [routerLink]=" '/curriculum/list/new' "
                (click)="proccedForClickEvent('new');">{{globalVar?.languageString?.resources?.menu?.curriculumMenuDraftsLabel}}</a>
        </li>
        <!-- <li [ngClass]="{ 'active' : isActiveMenu('ingested')}"><a [routerLink]=" '/curriculum/list/ingested' "
                (click)="proccedForClickEvent('ingested');">{{globalVar?.languageString?.resources?.menu?.curriculumMenuAwaitingApprovalLabel}}</a> 
        </li> -->
        <li [ngClass]="{ 'active' : isActiveMenu('approved')}"><a [routerLink]=" '/curriculum/list/approved' "
                (click)="proccedForClickEvent('approved');">{{globalVar?.languageString?.resources?.menu?.curriculumMenuApprovedLabel}}</a>
        </li>
        <!-- <li [ngClass]="{ 'active' : isActiveMenu('rejected')}"><a [routerLink]=" '/curriculum/list/rejected' "
                (click)="proccedForClickEvent('rejected');">{{globalVar?.languageString?.resources?.menu?.curriculumMenuRejectedLabel}}</a>
        </li> -->
        <li [ngClass]="{ 'active' : isActiveMenu('deleted')}"><a [routerLink]=" '/curriculum/list/deleted' "
                (click)="proccedForClickEvent('deleted');">{{globalVar?.languageString?.resources?.menu?.curriculumMenuDeletedLabel}}</a>
        </li>
        <li class="webViewManageList floatRight" style="margin-left:15px">
            <select [(ngModel)]="selectOptCurr" (change)="goToTarget(selectOptCurr)">
                <option value="" hidden>{{globalVar?.languageString?.resources?.menu?.curriculumMenuCurriculumsLabel}}
                </option>
                <option value="curriculums/upload">
                    {{globalVar?.languageString?.resources?.menu?.curriculumMenuUploadCurriculumLabel}}</option>
                <option value="curriculums/create">
                    {{globalVar?.languageString?.resources?.menu?.curriculumMenuCreateCurriculumLabel}}</option>
                <option value="curriculums/update">
                    {{globalVar?.languageString?.resources?.menu?.curriculumMenuUpdateCurriculumLabel}}</option>
                <option value="curriculums/authority">
                    {{globalVar?.languageString?.resources?.menu?.curriculumMenuAuthorityLabel}}</option>
            </select>
            <select [(ngModel)]="selectOptProd" (change)="goToTarget(selectOptProd)">
                <option value="" hidden>{{globalVar?.languageString?.resources?.menu?.curriculumMenuProductsLabel}}
                </option>
                <option value="product/upload">
                    {{globalVar?.languageString?.resources?.menu?.curriculumMenuUploadProductLabel}}</option>
                <option value="product/delete">
                    {{globalVar?.languageString?.resources?.menu?.curriculumMenuManageProductLabel}}</option>
            </select>
            <select [(ngModel)]="selectOptInter" (change)="goToTarget(selectOptInter)">
                <option value="" hidden>{{globalVar?.languageString?.resources?.menu?.curriculumMenuIntermediaryLabel}}
                </option>
                <option value="/curriculums/intermediary">
                    {{globalVar?.languageString?.resources?.menu?.curriculumMenuUploadIntermediaryLabel}}</option>
            </select>
        </li>
    </ul>
</div>