import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpRestClientService {

  // public locUrl = 'http://localhost:8080/cm-web/';
  // public locUrl = window.location.href.split('cmtWebApp')[0] + 'cm-web/';
   public locUrl = '';

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) { }

  public getForUrl(requestUrl: any , header: any, data: any): Observable<any>{
    return this.httpClient.get(this.locUrl + requestUrl, header);
  }

  public postForUrl(requestUrl: any , data: any, header:any): Observable<any>{
    return this.httpClient.post(this.locUrl + requestUrl, data,header);
  }

  public deleteForUrl(requestUrl: any , header: any, data: any): Observable<any>{
    return this.httpClient.delete(this.locUrl + requestUrl, header);
  }

  public patchForUrl(requestUrl: any , header: any, data: any): Observable<any>{
    return this.httpClient.patch(this.locUrl + requestUrl,header,data);
  }

  public putForUrl(requestUrl: any , data: any, header: any): Observable<any>{
    return this.httpClient.put(this.locUrl + requestUrl, data, header );
  }
  
}
