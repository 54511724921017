
<div id="learningObjectiveSelectionTree">
    <!-- <ul>
        <li>Root node 1
            <ul>
                <li>Child node 1</li>
                <li><a href="#">Child node 2</a></li>
            </ul>
        </li>
    </ul> -->
</div>