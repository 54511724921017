import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationPopupService } from 'src/app/services/confirmation-popup/confirmation-popup.service';
import { HttpRestClientService } from '../../services/common/http-rest-client.service';
import { GlobalVariablesService } from '../../services/global-variables/global-variables.service';
import * as _ from "underscore";

@Component({
  selector: 'app-topic-tree-view',
  templateUrl: './topic-tree-view.component.html',
  styleUrls: ['./topic-tree-view.component.scss']
})
export class TopicTreeViewComponent implements OnInit {
  @Input() topicData: any;
  @Input() topicPreviewStaticData: any;
  @Input() expandNodeIds: any;
  @Input() loadTopics: any;
  @Input() deleteTopic: any;
  @Input() addEditTopicPopup: TemplateRef<any> | undefined;
  @Input() addSubTopic: any;
  @Input() isFromLeft: any;

  public modalRef: BsModalRef | undefined;

  constructor(
    private route: Router,
    private httpRestClient : HttpRestClientService,
    public globalVar: GlobalVariablesService,
    private modalService: BsModalService,
    private confirmationPopup: ConfirmationPopupService,
    private actRoute : ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.globalVar.editSubsPopup = this.addEditTopicPopup;
  }

  public moveTopicUp = (topicId: any) => {
    this.reorderTopic(topicId, this.globalVar.languageString.resources.manageTab.curriculumMoveUpIconLabel);
  };

  public moveTopicDown = (topicId: any) => {
    this.reorderTopic(topicId, this.globalVar.languageString.resources.manageTab.curriculumMoveDownIconLabel);
  };

  public reorderTopic = (topicId: any, orderType: any) => {
    let curriculumID = this.actRoute.snapshot.params.curriculumID;
    this.globalVar.loading = true;
    if (curriculumID && topicId && orderType) {
        const headers:any = {
          responseType: 'text'
        };
        let url = 'api/curriculum/loc-structure/' + curriculumID + '/loc/reorder?locIRI='+topicId+'&orderType='+orderType;
        this.httpRestClient.postForUrl(url, undefined, headers).subscribe((result) => {
          // this.globalVar.reloadCurrentUrl();
          this.loadTopics(undefined)
        }, (error) => {
          this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorInReorderGradeMessage);
        })
    }
  }

  public expandCollapseTopic = (node: any) => {
    if(this.expandNodeIds.indexOf(node.topicId) == -1) {
      this.expandNodeIds.push(node.topicId);
    } else if (this.expandNodeIds.indexOf(node.topicId) >= 0) {
      this.expandNodeIds.splice(this.expandNodeIds.indexOf(node.topicId), 1);
    }
  }


}
