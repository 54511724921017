import { Component, OnInit } from '@angular/core';
import { GlobalVariablesService } from '../../services/global-variables/global-variables.service';
import { HttpRestClientService } from '../../services/common/http-rest-client.service';
import { ApplicationLoaderService } from '../../services/common/application-loader.service';
import { ConfirmationPopupService } from '../../services/confirmation-popup/confirmation-popup.service';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import * as _ from "underscore";
import { Router, ActivatedRoute } from '@angular/router';
import { CurriculumService } from 'src/app/services/curriculum/curriculum.service';

@Component({
  selector: 'app-upload-curriculum',
  templateUrl: './upload-curriculum.component.html',
  styleUrls: ['./upload-curriculum.component.scss']
})
export class UploadCurriculumComponent implements OnInit {
  private codec = new HttpUrlEncodingCodec;
  public selectedList: any;
  isChange = true;
  public injectCurriculumBean: any = {
    curriculumIngestionDataBean: {},
    curriculumUploadBean: {
      curriculumId: '',
      subject: '',
      country: '',
      authority: '',
      curriculumSet: '',
      state: '',
      deployedToRealize:false,
      fileType: 'AB XML',
      includeIntermediaryAlignments: false,
      curriculumType:'Academic benchmarks'
    },
    canUploadFile: this.route.getCurrentNavigation()?.extras,
    curriculaPreviewBean: undefined
  };
  public uploadProductBean: any;
  public exportProductUrl: any;
  public updatedCurriculumFacets: any;
  public removeCurriculumFacets: any;
  public disableSaveButton: any = false;


  constructor(
    private route: Router,
    private actRoute: ActivatedRoute,
    private httpRestClient: HttpRestClientService,
    public globalVar: GlobalVariablesService,
    private appLoadService: ApplicationLoaderService,
    private confirmationPopup: ConfirmationPopupService,
    private curriculumService:CurriculumService
  ) { }

  ngOnInit(): void {
    this.globalVar.activeMainMenu = 'manage';
    this.initCurriculumIngest();
    this.actRoute.data.subscribe(data => {
      this.injectCurriculumBean.canUploadFile = data.canUploadFile;
    });
    this.injectCurriculumBean.curriculumUploadBean.curriculumId = this.actRoute.snapshot.params.curriculumID;
  }
  ngOnChanges() {
  }

  
  ngDoCheck(): void {
    this.curriculumService.uploadDetails.next(this.injectCurriculumBean.curriculumUploadBean);
    var urlRegex = new RegExp("^((http|https)://)[a-zA-Z0-9@:%._\\+~#?&//=]{1,256}");
    var yearRegex = new RegExp("^[12][0-9]{3}$");
    this.disableSaveButton = !this.injectCurriculumBean.curriculumUploadBean.subject ||
      !this.injectCurriculumBean.curriculumUploadBean.country ||
      !this.injectCurriculumBean.curriculumUploadBean.authority ||
      !this.injectCurriculumBean.curriculumUploadBean.curriculumSet ||
      !this.injectCurriculumBean.curriculumUploadBean.customTitle ||
      (!this.injectCurriculumBean.curriculumUploadBean.year || !yearRegex.test(this.injectCurriculumBean.curriculumUploadBean.year)) ||
      !this.injectCurriculumBean.curriculumUploadBean.state 
  }


  public initCurriculumIngest = () => {
    this.appLoadService.initilizeApplicationCache(() => {
      this.globalVar.activeMainMenu = 'manage';
      this.globalVar.generatingXLS = false;
      this.globalVar.downloadUrl = undefined;
      this.selectedList = [];
      this.uploadProductBean = {};
      this.exportProductUrl = 'api/plan/export';
      this.preloadIngestData();
    });
  };

  public preloadIngestData = () => {
    this.globalVar.loading = true;
    let url = 'api/curriculum/ingestion';
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.injectCurriculumBean.curriculumIngestionDataBean = result;
      if (this.injectCurriculumBean.curriculumUploadBean.curriculumId) {
        this.loadCurriculum();
      }
      this.globalVar.loading = false;
    }, (error) => {
      this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.createCurriculumPreloadIngetionDataErrorMessage);
      this.globalVar.loading = false;
    });
  }

  public loadCurriculum = () => {
    let curriculumID = this.injectCurriculumBean.curriculumUploadBean.curriculumId;
    let url = 'api/curriculum/' + curriculumID + '/metadata';
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.injectCurriculumBean.curriculaPreviewBean = result;
      if (this.injectCurriculumBean.curriculaPreviewBean.status != this.globalVar.languageString.resources.manageTab.manageCurriculumNewLabel) {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.manageCurriculumNotInDraftMessage);
        window.history.back();
      }
      let curriculumUploadBean = this.injectCurriculumBean.curriculumUploadBean;
      curriculumUploadBean.subject = this.findFacetUriByFacetNameSubject(this.injectCurriculumBean.curriculumIngestionDataBean.subjectFacets, this.injectCurriculumBean.curriculaPreviewBean.subject);
      curriculumUploadBean.country = this.findFacetUriByFacetName(this.injectCurriculumBean.curriculumIngestionDataBean.countryFacets, this.injectCurriculumBean.curriculaPreviewBean.country);
      curriculumUploadBean.state = this.findFacetUriByFacetNameState(this.injectCurriculumBean.curriculumIngestionDataBean.stateFacets, this.injectCurriculumBean.curriculaPreviewBean);
      curriculumUploadBean.year = this.injectCurriculumBean.curriculaPreviewBean.year;
      curriculumUploadBean.deployedToRealize = this.injectCurriculumBean.curriculaPreviewBean.deployedToRealize;
      curriculumUploadBean.sourceUrl = this.injectCurriculumBean.curriculaPreviewBean.sourceUrl&&this.injectCurriculumBean.curriculaPreviewBean.sourceUrl!='undefined'?this.injectCurriculumBean.curriculaPreviewBean.sourceUrl:'';
      curriculumUploadBean.description = this.injectCurriculumBean.curriculaPreviewBean.description;
      curriculumUploadBean.curriculumTitle = this.injectCurriculumBean.curriculaPreviewBean.curriculumTitle;
      curriculumUploadBean.curriculumType = this.injectCurriculumBean.curriculaPreviewBean.curriculumType;
      var customCurriculumTitle = this.injectCurriculumBean.curriculaPreviewBean.curriculumTitle;
      // var rslt = _.contains( this.injectCurriculumBean.curriculumIngestionDataBean.subjectFacets, customCurriculumTitle );
      // // var result = containsAny(customCurriculumTitle, this.injectCurriculumBean.curriculumIngestionDataBean.subjectFacets);
      // var reminingTitle = customCurriculumTitle.substring(customCurriculumTitle.lastIndexOf(rslt),customCurriculumTitle.length);
      // customCurriculumTitle = customCurriculumTitle.substring(0,customCurriculumTitle.lastIndexOf(rslt));
      // curriculumUploadBean.customTitle=customCurriculumTitle;
      // curriculumUploadBean.reminingTitle=reminingTitle;
      curriculumUploadBean.customTitle = (!_.isUndefined(this.injectCurriculumBean.curriculaPreviewBean.displayTitle) ? this.injectCurriculumBean.curriculaPreviewBean.displayTitle : '');
      if (this.injectCurriculumBean.curriculaPreviewBean.curriculumTitle &&
        this.injectCurriculumBean.curriculaPreviewBean.curriculumTitle.lastIndexOf('(Custom)') != -1) {
        curriculumUploadBean.appendCustom = true;
      } else {
        curriculumUploadBean.appendCustom = false;
      }
      // this.injectCurriculumBean.curriculumUploadBean = _.clone(curriculumUploadBean);
      this.preloadAuthority();
    }, (error) => {
      this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorInLoadingMessage);
    });
  }

  public findFacetUriByFacetName(facetInfoBeanList: any, facetName: any) {
    if (facetInfoBeanList && facetInfoBeanList.length > 0 && facetName) {
      for (var i = 0; i < facetInfoBeanList.length; i++) {
        var facetInfoBean = facetInfoBeanList[i];
        if (facetInfoBean.facetName.toLowerCase() == facetName.toLowerCase()) {
          return facetInfoBean.facetUri;
        }
      }
    }
    return undefined;
  }

  public findFacetUriByFacetNameState(facetInfoBeanList: any, metadata: any) {
    var facetName = metadata.stateDescription + ", " + metadata.stateCode;
    if (facetInfoBeanList && facetInfoBeanList.length > 0 && facetName) {
      for (var i = 0; i < facetInfoBeanList.length; i++) {
        var facetInfoBean = facetInfoBeanList[i];
        if (facetInfoBean.facetName.toLowerCase() == facetName.toLowerCase()) {
          return facetInfoBean.facetUri;
        }
      }
    }
    return undefined;
  }

  public findFacetUriByFacetNameSubject(facetInfoBeanList: any, facetName: any) {
    if (facetInfoBeanList && facetInfoBeanList.length > 0 && facetName) {
      for (var i = 0; i < facetInfoBeanList.length; i++) {
        var facetInfoBean = facetInfoBeanList[i];
        var updatedFacetName = facetInfoBean.facetName.replace(/ /g, '');
        updatedFacetName = updatedFacetName.replace('-', '');
        if (updatedFacetName.toLowerCase() == facetName.toLowerCase()) {
          return facetInfoBean.facetUri;
        }
      }
    }
    return undefined;
  }


  public preloadAuthority = () => {

    this.injectCurriculumBean.curriculumUploadBean.curriculumSet = "";
    this.injectCurriculumBean.curriculumIngestionDataBean.curriculumSetFacets = [];
    this.injectCurriculumBean.curriculumUploadBean.authority = "";
    this.injectCurriculumBean.curriculumIngestionDataBean.authorityFacets = [];
    var country = this.injectCurriculumBean.curriculumUploadBean.country;
    if (country) {
      let url = 'api/curriculum/listauthorities?countryIRI=' + this.codec.encodeKey(country);
      this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
        this.injectCurriculumBean.curriculumIngestionDataBean.authorityFacets = result.authorityFacets;
        if (this.injectCurriculumBean.curriculumUploadBean.curriculumId && this.injectCurriculumBean.curriculaPreviewBean) {
          this.injectCurriculumBean.curriculumUploadBean.authority = this.findFacetUriByFacetName(this.injectCurriculumBean.curriculumIngestionDataBean.authorityFacets, this.injectCurriculumBean.curriculaPreviewBean.authorityName);
          this.preloadCurriculumSet();
        }
      }, (error) => {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorInGettingAuthorityMessage);
      });
    }

  }

  public loadProgram = () => {

  }

  public loadCurriculumList = () => {
    this.globalVar.curriculaListBean = undefined;
    let url = 'api/curriculum/status';
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.globalVar.curriculaListBean = result;
      //updateListSize();
    }, (error) => {
      if (error.status == 500) {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
      }
    })
  }

  public preloadCurriculumSet = () => {
    this.injectCurriculumBean.curriculumUploadBean.curriculumSet = "";
    this.injectCurriculumBean.curriculumIngestionDataBean.curriculumSetFacets = [];
    var authority = this.injectCurriculumBean.curriculumUploadBean.authority;

    if (authority) {
      let url = 'api/curriculum/listcurriculumset?authorityIRI=' + this.codec.encodeKey(authority);
      this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
        this.updatedCurriculumFacets = [];
        this.removeCurriculumFacets = [];
        _.each(result.curriculumSetFacets, (value) => {
          console.log('new value ::' + value.facetName);
          if ((this.injectCurriculumBean.curriculumUploadBean.authority == 'https://data.savvas.com/authority/MDOEASE#this' && value.facetUri == 'https://data.savvas.com/curriculum/curriculum-set/LearningStandard#this') ||
            (this.injectCurriculumBean.curriculumUploadBean.authority == 'https://data.savvas.com/authority/VDE#this' && value.facetUri == 'https://data.savvas.com/curriculum/curriculum-set/StandardsOfLearningVirginia#this')) {
            this.removeCurriculumFacets.push(value);
          } else {
            this.updatedCurriculumFacets.push(value);
          }
        });
        this.injectCurriculumBean.curriculumIngestionDataBean.curriculumSetFacets = this.updatedCurriculumFacets;
        if (this.injectCurriculumBean.curriculumUploadBean.curriculumId && this.injectCurriculumBean.curriculaPreviewBean) {
          this.injectCurriculumBean.curriculumUploadBean.curriculumSet = this.findFacetUriByFacetName(this.injectCurriculumBean.curriculumIngestionDataBean.curriculumSetFacets, this.injectCurriculumBean.curriculaPreviewBean.curriculumSet)
        }
      }, (error) => {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorInGettingCurriculumSetMessage);
      });
    }
  }

  public saveCurriculum = () => {
    let queryBean = this.injectCurriculumBean.curriculumUploadBean;
    let curriculumId = this.injectCurriculumBean.curriculumUploadBean.curriculumId;
    let url = curriculumId ? ('api/curriculum/loc-structure/' + curriculumId + '/update') : 'api/curriculum/loc-structure/create';
    let method = curriculumId ? 'POST' : 'PUT';
    this.disableSaveButton = true;
    this.globalVar.languageString.resources.others.saveButtonLabel = 'Saving...';
    if (method === 'POST') {
      const headers: any = {
        responseType: 'text'
      };
      this.httpRestClient.postForUrl(url, queryBean, headers).subscribe((result) => {
        if (result) {
          this.confirmationPopup.showSuccessMessage(this.globalVar.languageString.resources.manageTab.curriculumSavingSuccessMessage);
          this.route.navigate(["/curriculum/" + result + "/edit"]);
          this.globalVar.languageString.resources.others.saveButtonLabel = 'Save';
        }
      }, (error) => {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorInSavingMessage);
        this.globalVar.languageString.resources.others.saveButtonLabel = 'Save';
      });
    } else {
      const headers: any = {
        responseType: 'text'
      };
      this.httpRestClient.putForUrl(url, queryBean,headers).subscribe((result) => {
        if (result) {
          this.confirmationPopup.showSuccessMessage(this.globalVar.languageString.resources.manageTab.curriculumSavingSuccessMessage);
          this.route.navigate(["/curriculum/" + result + "/edit"]);
          this.globalVar.languageString.resources.others.saveButtonLabel = 'Save';
        }
      }, (error) => {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorInSavingMessage);
        this.globalVar.languageString.resources.others.saveButtonLabel = 'Save';
      });
    }
  }

  public goToCurriculumEdit = () => {
    if (this.injectCurriculumBean.curriculumUploadBean.curriculumId) {
      var curriculumID = this.injectCurriculumBean.curriculumUploadBean.curriculumId;
      this.route.navigate(["/curriculum/" + curriculumID + "/edit"]);
    }
  }

  public resetIncludeIntermediary = () => {
    if (this.injectCurriculumBean.curriculumUploadBean.includeIntermediaryAlignments) {
      this.injectCurriculumBean.curriculumUploadBean.includeIntermediaryAlignments = false;
    }
  }

}
