import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CorrelationService {
  leftSelectedProductDetails:any;
  leftSelectedCurriculumDetails:any;
  leftSelectedInterMediaryDetails:any;
  rightSelectedProductDetails:any[]=[];
  rightelectedCurriculumDetails:any[]=[];
  printAllign=true;
  degitialAllign=false;
  isSave = false;
  incPeripherals = false;
  incIntermediaryDetails  = false;
  alfrescoExportFormat = false;
  showKeyAlignments = false;
  realizeStandFormat = false;
  reverseIntFormat = false;
  isListUpdated: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  isReset : BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public loading: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public editCorrelationEventLeft: Subject<any> = new Subject<any>();
  public editCorrelationEventRight: Subject<any> = new Subject<any>();
  public editReportName: Subject<any> = new Subject<any>();
  constructor() { }

  getReportOptionsData() {
    if ((this.leftSelectedProductDetails || this.leftSelectedCurriculumDetails || this.leftSelectedInterMediaryDetails) && (this.rightelectedCurriculumDetails.length >= 1 || this.rightSelectedProductDetails.length >= 1)) {
      if (!this.leftSelectedInterMediaryDetails) {
        this.incPeripherals = true;
        this.incIntermediaryDetails = true;
        this.alfrescoExportFormat = false;
        this.showKeyAlignments = true;
        this.realizeStandFormat = false;
        this.reverseIntFormat = false;
      } else {
        if (this.rightelectedCurriculumDetails.length === 1) {
          this.incPeripherals = true;
          this.incIntermediaryDetails = false;
          this.alfrescoExportFormat = false;
          this.showKeyAlignments = true;
          this.realizeStandFormat = true;
          this.reverseIntFormat = true;
        } else if (this.rightelectedCurriculumDetails.length > 1) {
          this.incPeripherals = true;
          this.incIntermediaryDetails = false;
          this.alfrescoExportFormat = false;
          this.showKeyAlignments = true;
          this.realizeStandFormat = false;
          this.reverseIntFormat = false;
        } else if (this.rightSelectedProductDetails.length === 1) {
          this.incPeripherals = true;
          this.incIntermediaryDetails = false;
          this.alfrescoExportFormat = true;
          this.showKeyAlignments = true;
          this.realizeStandFormat = false;
          this.reverseIntFormat = false;
        } else if (this.rightSelectedProductDetails.length > 1) {
          this.incPeripherals = true;
          this.incIntermediaryDetails = false;
          this.alfrescoExportFormat = false;
          this.showKeyAlignments = true;
          this.realizeStandFormat = false;
          this.reverseIntFormat = false;
        } else {
          this.incPeripherals = false;
          this.incIntermediaryDetails = false;
          this.alfrescoExportFormat = false;
          this.showKeyAlignments = false;
          this.realizeStandFormat = false;
          this.reverseIntFormat = false;
        }
      }
    } else {
      this.incPeripherals = false;
      this.incIntermediaryDetails = false;
      this.alfrescoExportFormat = false;
      this.showKeyAlignments = false;
      this.realizeStandFormat = false;
      this.reverseIntFormat = false;
    }
  }

}
