<app-curriculum-menu></app-curriculum-menu>
<div class="parent advance-search-product-browse-section">
    <ul class="treeContainerUl treeNode ">
        <li class="treeItem" *ngFor="let currDiscipline of disciplineFacet">
            <app-manage-product-tree [discipline]="currDiscipline" [doFunction]="getdisciplinedprogram" [addEditProgramedDiscipline]="addEditProgramedDiscipline"></app-manage-product-tree>
            <ul *ngIf="currDiscipline.disciplinedprogramFacet" class="treeContainerUl treeNode ">
                <li class="treeItem" *ngFor="let currDisciplinedprogram of currDiscipline.disciplinedprogramFacet">
                    <app-manage-product-tree [discipline]="currDiscipline" [disciplinedprogram]="currDisciplinedprogram" [addEditProgramedDiscipline]="addEditProgramedDiscipline"
                        [deleteProgramedDiscipline]="deleteProgramedDiscipline" [doFunction]="getprogram" [addEditProgram]="addEditProgram"></app-manage-product-tree>
                    <ul *ngIf="currDisciplinedprogram.programFacet" class="treeContainerUl treeNode ">
                        <li class="treeItem" *ngFor="let currProgram of currDisciplinedprogram.programFacet">
                            <app-manage-product-tree [discipline]="currDiscipline" [disciplinedprogram]="currDisciplinedprogram" [program]="currProgram"
                                [addEditProgram]="addEditProgram" [addEditProduct]="addEditProduct" [deleteProgram]="deleteProgram" [doFunction]="getCourse"></app-manage-product-tree>
                            <ul *ngIf="currProgram.productFacet" class="treeContainerUl treeNode ">
                                <li class="treeItem" *ngFor="let currProduct of currProgram.productFacet">
                                    <app-manage-product-tree [discipline]="currDiscipline" [disciplinedprogram]="currDisciplinedprogram" [addEditProduct]="addEditProduct"
                                            [program]="currProgram" [product]="currProduct" [deleteProduct]="deleteProduct"></app-manage-product-tree>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</div>
<ng-template #areYouSurePopup>
    <app-warning-popup [DoFunctionOnSuccess]="popupData?.doFunctionOnSuccess" [PopupMessage]="popupData?.popupMessage"
         [ConfirmationMessage]="popupData?.confirmationMessage" [ButtonLabel] ="popupData?.buttonLabel" 
         [modalRef]="modalRef"></app-warning-popup>
 </ng-template>

 <ng-template #addorEditproduct>
    <app-add-edit-product-popup [modalRef]="modalRef" [createItemBean]="modalData" [isAddFlagTrue]="addFlag"
        [doSuccessFunction]="saveOrUpdate"></app-add-edit-product-popup>
 </ng-template>
