<div id="tabsContainerId" class="span6 correlation-right-tab-margin correlationTabContainerId rightCorrelation">
	<ul id="tabs" class="tabs">
		<li id="right-standard-tab-head" class="tab-link tab-head-settings"
			(click)="showConfirmationPopup(statusChangeRightPopupTemp,'curriculum')" data-tab="right-standard-tabcontent"
			[ngClass]="{'current': (selectTab === 'right-standard-tabcontent') }">
			Curriculum Standard </li>
		<li id="right-product-tab-head" class="tab-link tab-head-settings current" data-tab="right-product-tabcontent"
			(click)="showConfirmationPopup(statusChangeRightPopupTemp,'product')"
			[ngClass]="{'current': (selectTab === 'right-product-tabcontent') }"> Product</li>
	</ul>
	<div id="right-standard-tab-content" class="correlation-report-tab-content"
		[ngClass]="{'current': (selectTab === 'right-standard-tabcontent') }">
		<div class="correlation-list-panel">
			<div>
				<select id="right-standard-tab" class="correlation-dropdown" [(ngModel)]="selectedList.subject"
					(change)="loadCountry()" required="true">
					<option value="" hidden selected>
						{{disciplineMessage}}
					</option>
					<option *ngFor="let subjectList of disciplineList" [ngValue]="subjectList">
						{{subjectList.subjectDesc}}</option>
				</select>
			</div>
			<div>
				<select id="right-standard-tab" class="correlation-dropdown" [(ngModel)]="selectedList.country"
					(change)="loadCurriculum()" required="true">
					<option value="" hidden selected>
						{{countryMessage}}
					</option>
					<option *ngFor="let subjectList of countryList" [ngValue]="subjectList">
						{{subjectList.countryDesc}}</option>
				</select>
			</div>
			<div>
				<select id="right-standard-tab" class="correlation-dropdown" [(ngModel)]="selectedList.curriculum"
					(change)="loadGrade()" required="true">
					<option value="" hidden selected>
						{{curriculumMessage}}
					</option>
					<option *ngFor="let subjectList of curriculumList" [ngValue]="subjectList">
						{{subjectList.curriculumTitle}}</option>
				</select>
			</div>
			<div>
				<select id="right-standard-tab" class="correlation-dropdown" [(ngModel)]="selectedList.curGrade"
					required="true" (change)="loadStrand()">
					<option value="" hidden selected>
						{{gradeMessage}}
					</option>
					<option *ngFor="let subjectList of grades" [ngValue]="subjectList">
						{{subjectList.grade}}</option>
				</select>
			</div>
			<div *ngIf="strandList?.length">
				<select id="right-standard-tab" class="correlation-dropdown" [(ngModel)]="selectedList.strand" 
					required="true">
					<option value="" hidden selected>
						{{strandMessage}}
					</option>
					<option *ngFor="let subjectList of strandList" [ngValue]="subjectList">
						{{subjectList.gradeName}}</option>
				</select>
			</div>
			<div class="row-fluid correlation-row-gab">
				<div class="span6 text-right" style="margin-right: -36px;margin-bottom: 20px;">
					<button type="button" class="correlation-button correlation-report-lo-button" aria-label="select"
						(click)="selectCurriculum()">Add
					</button>
				</div>
			</div>
			<div class="row-fluid correlation-row-gab mbleClassAddRightCorr">
				<table class="table table-bordered CorrTableFont correlation-text-box">
					<tr>
						<th class="width65P">Curriculum/Product title</th>
						<th class="width15P">Grade</th>
						<th class="width20P">Strand</th>
						<th></th>
					</tr>
					<tr *ngFor="let standard of selectedCurriculumDetails;  let i = index" class="posRelative">
						<td class="width65P" *ngIf="standard.standard">{{standard.standard}}</td>
						<td class="width15P" *ngIf="standard.standard">{{standard.grade}}</td>
						<td class="width20P" *ngIf="standard.standard">{{standard.strand}}
						</td>
						<td *ngIf="standard.standard">
							<span class="postionRoundIcon smallDeleteIcon" (click)="removeStandardSelection(i)"></span>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div id="right-product-tab-content" class="correlation-report-tab-content" [ngClass]="{'current': (selectTab === 'right-product-tabcontent') }">
		<div id="content-list-panel" class="correlation-list-panel content-background-color" style="min-height: 390px;">		
			<div>
				<select id="right-standard-tab" class="correlation-dropdown" [(ngModel)]="selectedList.prdSubject"
					required="true" (change)="loadProgram()">
					<option value="" hidden selected>
						{{prdDisciplineMessage}}
					</option>
					<option *ngFor="let subjectList of prdDisciplineList" [ngValue]="subjectList">
						{{subjectList.subjectDesc}}</option>
				</select>
			</div>
			<div>
				<select id="right-standard-tab" class="correlation-dropdown" [(ngModel)]="selectedList.program"
					required="true" (change)="loadCourse()">
					<option value="" hidden selected>
						{{programMessage}}
					</option>
					<option *ngFor="let subjectList of programList" [ngValue]="subjectList">
						{{subjectList.name}}</option>
				</select>
			</div>
			<div>
				<select id="right-standard-tab" class="correlation-dropdown" [(ngModel)]="selectedList.course"
					required="true" (change)="loadPdGrade()">
					<option value="" hidden selected>
						{{courseMessage}}
					</option>
					<option *ngFor="let subjectList of courses" [ngValue]="subjectList">
						{{subjectList.name}}</option>
				</select>
			</div>
			<div>
				<select id="right-standard-tab" class="correlation-dropdown" (change)="loadPdProduct()"
					[(ngModel)]="selectedList.prdGrade" required="true">
					<option value="" hidden selected>
						{{prdGradeMessage}}
					</option>
					<option *ngFor="let subjectList of prgrades" [ngValue]="subjectList">
						{{subjectList.notation}}</option>
				</select>
			</div>
			<div>
				<select id="right-standard-tab" class="correlation-dropdown" [(ngModel)]="selectedList.product"
					required="true">
					<option value="" hidden selected>
						{{prdProductMessage}}
					</option>
					<option *ngFor="let subjectList of pdProducts" [ngValue]="subjectList">
						{{subjectList.name}}</option>
				</select>
			</div>
			<div class="row-fluid correlation-row-gab">
				<div class="span6 text-right" style="margin-right: -36px;margin-bottom: 20px;">
					<button type="button" class="correlation-button" aria-label="select"
						(click)="selectProduct()">Add
					</button>
				</div>
			</div>
			<div class="row-fluid correlation-row-gab mbleClassAddRightCorr">
				<table class="table table-bordered CorrTableFont correlation-text-box">
					<tr>
						<th class="width75P">Curriculum/Product title</th>
						<th class="width20P">Grade</th>
						<th></th>
					</tr>
					<tr *ngFor="let product of selectedProductDetails;  let i = index">
						<td class="width75P" *ngIf="product.product">{{product.product}}</td>
						<td class="width20P" *ngIf="product.grade">{{product.grade}}</td>
						<td *ngIf="product.product">
							<span class="postionRoundIcon smallDeleteIcon" (click)="removeProductSelection(i)"></span>
						</td>
					</tr>
				</table>
			</div>
		</div>			
	</div>
</div>
<ng-template #statusChangeRightPopupTemp>
	<app-warning-popup [DoFunctionOnSuccess]="confirmDeleteGrade" [PopupMessage]="popupData.popupMessage"
		[ButtonLabel]="popupData.buttonLabel" [modalRef]="modalRef"></app-warning-popup>
</ng-template>