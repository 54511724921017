<app-curriculum-menu [loadCurriculumList]='globalVar.loadCurriculumList' [addTabClass]='globalVar.addTabClass'></app-curriculum-menu>
<div class="curriculumTab updateCurrTab" style="min-height: 220px;">
    <div class="curriculumEmptyListPanel">
		<div class="row updateCurrRow">
			<div class="col-sm-3 updateCurrWid300 validationCheck">
				<label class="font_13_333333_bold">{{globalVar?.languageString?.resources?.manageTab?.manageUploadDraftCurriculumUpdate}}</label>
                <select class="cmUpdateDraftDropDown currUpdateDraftDropDown"
                        [(ngModel)]="injectCurriculumBean.curriculumUploadBean.curriculumId"
                        [ngClass]="{ 'ng-invalid-required' : injectCurriculumBean.curriculumUploadBean.curriculumId == ''}"
                        tabindex="1" (change)="changeCurriculum()">
                    <option value="" hidden selected >{{globalVar?.languageString?.resources?.manageTab?.manageUploadSelectDraftCurriculum}}</option>
                    <option [value]="currciulumInfoBean.curriculumId" *ngFor="let currciulumInfoBean of globalVar?.curriculaListBean?.curriculaAlignmentBeanList"> {{currciulumInfoBean.curriculumTitle}} </option>
                </select>
			</div>
			<div class="span2Update col-sm-8">
                <!-- <input [(ngModel)]="injectCurriculumBean.curriculumUploadBean.appendCustom" type="checkbox"
                        class="appendTitleInput" placeholder="Append (Custom) in title"
                        name="Append (Custom) in title"
                        tabindex="8">
                <span class="col-sm-2 font_13_333333_bold appendTitleSpan" style="margin-left: 6px;">{{globalVar?.languageString?.resources?.manageTab?.appendCustomTitle}}</span> -->
                <input type="checkbox" [disabled]="injectCurriculumBean.curriculumUploadBean.fileType === 'AB XML'" [(ngModel)]="injectCurriculumBean.curriculumUploadBean.includeIntermediaryAlignments">
                <span class="col-sm-2 font_13_333333_bold incIntermedAlign" style="margin-left: 6px;">Include Intermediary Alignments</span>
            </div>
		         
        </div>
        <div>
            <div style="padding-top: 20px; border-top: 1px solid #E4E4E4;">
                <div style="font-size: 20px; color: #333333;">{{globalVar?.languageString?.resources?.manageTab?.manageUploadFileType}}</div>
                <div class="dispInlBlk">
                    <input type="radio" name="fileType" [value]="'AB XML'" [(ngModel)]="injectCurriculumBean.curriculumUploadBean.fileType" (checked)="true" (change)="resetIncludeIntermediary()"><span style="font-size: 13px; color: #333333;">{{globalVar?.languageString?.resources?.manageTab?.manageUploadABXML}}</span><br>
                </div>
                <div class="dispInlBlk">
                    <input type="radio" name="fileType" [value]="'Custom Excel'" [(ngModel)]="injectCurriculumBean.curriculumUploadBean.fileType"><span style="font-size: 13px; color: #333333;">{{globalVar?.languageString?.resources?.manageTab?.manageUploadCustomExcel}}</span>
                </div>
            </div>

        	<!-- <div style="padding-top: 20px; border-top: 1px solid #E4E4E4; margin-bottom: 10px;">
                <div style="font-size: 20px; color: #333333;">{{applicationCache.languageString.resources.manageTab.manageUploadFileType}}</div>
                <div class="dispInlBlk">
                	<input type="radio" name="fileType" value="AB XML" ng-init="injectCurriculumBean.curriculumUploadBean.fileType='AB XML'" ng-model="injectCurriculumBean.curriculumUploadBean.fileType" ng-checked="true" ng-change="resetIncludeIntermediary()"><span style="font-size: 13px; color: #333333;">{{applicationCache.languageString.resources.manageTab.manageUploadABXML}}</span></input><br>
                </div>
                <div class="dispInlBlk">
                	<input type="radio" name="fileType" value="Custom Excel" ng-model="injectCurriculumBean.curriculumUploadBean.fileType"><span style="font-size: 13px; color: #333333;">{{applicationCache.languageString.resources.manageTab.manageUploadCustomExcel}}</span></input>
                </div>
            </div> -->
            <div style="padding-top: 20px; border-top: 1px solid #E4E4E4;">
                <div style="font-size: 20px; color: #333333;">{{globalVar?.languageString?.resources?.manageTab?.manageUploadUpdateFileMessage}}</div>
                <div *ngIf="injectCurriculumBean.curriculumUploadBean.fileType" class="curriculumEmptyListPanelText" style="border: none;">{{globalVar?.languageString?.resources?.manageTab?.manageUploadUpdateFileInfoMessage}}</div>
                <div *ngIf="!injectCurriculumBean.curriculumUploadBean.fileType" class="curriculumEmptyListPanelText" style="border: none;">Please choose upload file type to select the file</div>
            </div>
            <div style="padding-top: 10px;">
                <app-uploader [action]="'api/curriculum/reUpload'" [bean]="injectCurriculumBean?.curriculumUploadBean" [fromScreen]="'updateCurriculum'"></app-uploader>
            </div>
        </div>    
    </div>
</div>
