import { Component, Input, OnInit } from '@angular/core';
import { GlobalVariablesService } from 'src/app/services/global-variables/global-variables.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-add-edit-product-popup',
  templateUrl: './add-edit-product-popup.component.html',
  styleUrls: ['./add-edit-product-popup.component.scss']
})
export class AddEditProductPopupComponent implements OnInit {
  @Input() modalRef: any;
  @Input() createItemBean: any;
  @Input() isAddFlagTrue: boolean = true;
  @Input() doSuccessFunction: any;

  constructor(
    public globalVar: GlobalVariablesService,
  ) { }

  ngOnInit(): void {
    this.processMinMaxGrade();
  }

  public processMinMaxGrade = () => {
    let minGradeIri = _.clone(this.createItemBean.MinGrade);
    let maxGradeIri = _.clone(this.createItemBean.MaxGrade);
    this.createItemBean.MinGrade = minGradeIri ? _.filter(this.globalVar.gradeListForProductEdit, { 'gradeIRI': minGradeIri })[0].grade : 'PK';
    this.createItemBean.MaxGrade = maxGradeIri ? _.filter(this.globalVar.gradeListForProductEdit, { 'gradeIRI': maxGradeIri })[0].grade : 'PK';
  }

  doClosePopup = () => {
    this.modalRef.hide();
  };

}
