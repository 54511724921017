<button type="button" class="popupXClose close pull-right" aria-label="Close" (click)="doClosePopup()">×
</button>
<div class="EditCurriculumPopup">
    <div style="color: #333333;" class="font_20_666666"><span [hidden]="!(createItemBean?.addItem)">{{globalVar?.languageString?.resources?.manageTab?.curriculumEditPopupAddLabel}}</span><span [hidden]="createItemBean?.addItem">{{globalVar?.languageString?.resources?.manageTab?.curriculumEditPopupEditLabel}}</span> {{globalVar?.languageString?.resources?.manageTab?.curriculumLocalGradeEditLabel}}</div>
    <div class="marginTop30">
        <div style="margin-top: 0; min-height: 100px; overflow: auto;">
            <div class="marginBottom10" [hidden]="!(createItemBean?.addItem && createItemBean?.gradeName)">
                <div class="font_15_666666 fontBold">{{globalVar?.languageString?.resources?.manageTab?.curriculumEditPopupGradeLabel}}</div>
                <div>{{ createItemBean?.gradeName }}</div>
            </div>
            <div class="marginBottom10">
                <div class="font_15_666666 fontBold">{{globalVar?.languageString?.resources?.manageTab?.curriculumEditPopupMinGradeDrpDownLabel}}</div>
                <select class="font_12_666666 marginTop5px" [(ngModel)]="createItemBean.gradeMinIRI"
                    required="required">
                    <option *ngFor="let GradeBean of cmtGrades" value="{{GradeBean.gradeIRI}}">{{GradeBean.grade}}</option>
                </select>
            </div>
            <div class="marginBottom10">
                <div class="font_15_666666 fontBold">{{globalVar?.languageString?.resources?.manageTab?.curriculumEditPopupMaxGradeDrpDownLabel}}</div>
                <select class="font_12_666666 marginTop5px" [(ngModel)]="createItemBean.gradeMaxIRI"
                    required="required">
                    <option *ngFor="let GradeBean of cmtGrades" value="{{GradeBean.gradeIRI}}">{{GradeBean.grade}}</option>
                </select> 

                <span [hidden]="isMinMaxGradeRangeRight()" class="font_12_666666" style="color: #E34A2F; margin-left: 10px;">{{globalVar?.languageString?.resources?.manageTab?.curriculumEditPopupWrongGradeRangeMsg}}</span>
            </div>
            <div class="marginBottom10">
                <div class="font_15_666666 fontBold">{{globalVar?.languageString?.resources?.manageTab?.curriculumEditPopupLocalGradeNameLabel}}</div>
                <input id="localGradeName" [(ngModel)]="createItemBean.localGradeName" type="text" class="form-control" placeholder="{{globalVar?.languageString?.resources?.manageTab?.curriculumEditPopupLocalGradeNameTextLabel}}"
                       required="required">
            </div>
        </div>
        <div class="form-horizontal marginTop20">
            <input type="submit" [disabled]="!createItemBean.localGradeName || !createItemBean.gradeMinIRI || !createItemBean.gradeMaxIRI || !isMinMaxGradeRangeRight()" value="{{globalVar?.languageString?.resources?.others?.saveButtonLabel}}" class="cmButton cmButtonFull-green cmButton-standard"
                (click)="doFunctionOnSuccess()"/>
            <a (click) = "doClosePopup()" class="cmButton cmButton-url">{{globalVar?.languageString?.resources?.others?.cancelBtnLabel}}</a>
        </div>
    </div>
</div>