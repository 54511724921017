<div class="loader-container" *ngIf="loading">
    <div class="loading-overlay"></div>
    <div class="loading-icon">
        <img src="../../../assets/images/loading_100x100.gif" />
    </div>
</div>
<div class="intermediary-section">
    <div class="mapping-alignment-label-container">
        <span class="mapping-alignment-label" [textContent]="getIntermediaryScreenName()"></span>
    </div>
    <div (ngInit)="intermediaryScreenView = 'default'">
        <div [ngSwitch]="intermediaryScreenView" ngAnimate="'wave'">
            <div *ngSwitchCase="'default'">

                <div id="tabsContainerId" class="intrm-tab-content">
                    <ul id="tabs" class="tabs">
                        <li id="tab-head-1" [ngClass]="{'current': (selectedTab1 === true) }" data-tab="tab-1"
                            (click)="selectTab('tab-head-1')" [textContent]="'Curriculum Standard'">
                        </li>
                        <li id="tab-head-2" [ngClass]="{'current': (selectedTab1 === false) }" data-tab="tab-2"
                            (click)="selectTab('tab-head-2')" [textContent]="'Product'">
                        </li>
                    </ul>
                    <div *ngIf="selectedTab1" id="tab-1" class="tab-content {{selectedTab1 === true ? 'current' : ''}}">
                        <div class="intermediary-list-panel validationCheck">
                            <div>
                                <select class="intermediary-dropdown" [(ngModel)]="selectedList.subject"
                                    (change)="loadSubject()" required="true">
                                    <option value="" hidden selected>
                                        {{disciplineMessage}}
                                    </option>
                                    <option *ngFor="let subjectList of intermediaryMappingDataBean"
                                        [ngValue]="subjectList">
                                        {{subjectList.subjectDesc}}</option>
                                </select>
                            </div>
                            <div>
                                <select class="intermediary-dropdown" [(ngModel)]="selectedList.country"
                                    (change)="loadCountry()" required="true">
                                    <option value="" hidden selected>
                                        {{countryMessage}}
                                    </option>
                                    <option *ngFor="let countryObject of countryList?.countryBeanList">
                                        {{countryObject.countryDesc}}</option>
                                </select>
                            </div>
                            <div>
                                <select class="intermediary-dropdown" [(ngModel)]="selectedList.curriculum"
                                    (change)="loadCurriculum()" required="true">
                                    <option value="" hidden selected>
                                        {{curriculumMessage}}
                                    </option>
                                    <option *ngFor="let curriculumObject of tempCurriculumList"
                                        [ngValue]="curriculumObject">
                                        {{curriculumObject.curriculumTitle}}</option>
                                </select>
                            </div>
                            <div>
                                <select class="intermediary-dropdown" [(ngModel)]="selectedList.curGrade"
                                    required="true">
                                    <option value="" hidden selected>{{gradeMessage}}</option>
                                    <option *ngFor="let gradeObject of gradeBeanList" [ngValue]="gradeObject">
                                        {{gradeObject.grade}}</option>
                                </select>
                            </div>
                            <div class="intermediary-dropdown">
                            </div>
                            <div>
                                <div id="standard-bottom-link" class="intermediary-bottom-link row-fluid">
                                    <div class="span4">
                                    </div>
                                    <div class="span4 intermediary-create-alignment-link"
                                        (click)="viewHierarchyScreen()" id="advancedsearch"
                                        [textContent]="'CREATE ALIGNMENT'">
                                        <img class="intermediary-bottom-img" src="resources/images/icon_arraow.png">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="!selectedTab1" id="tab-2"
                        class="tab-content {{selectedTab1 === false ? 'current' : ''}}">
                        <div id="content-list-panel"
                            class="intermediary-list-panel blue-background-color validationCheck">
                            <div class="validationCheck">
                                <select class="intermediary-dropdown" [(ngModel)]="prdSubject" (change)="loadProgram()">
                                    <option value="" hidden selected>{{disciplineMessage}}</option>
                                    <option *ngFor="let prodSubject of intermediaryMappingBeanSubject"
                                        [ngValue]="prodSubject">
                                        {{prodSubject.subjectDesc}}</option>
                                </select>
                            </div>
                            <div>
                                <select class="intermediary-dropdown" [(ngModel)]="program" (change)="loadCourse()"
                                    required="true">
                                    <option value="" hidden selected>{{programMessage}}</option>
                                    <option *ngFor="let programObject of intermediaryMappingBeanProgram"
                                        [ngValue]="programObject">
                                        {{programObject.name}}</option>
                                </select>
                            </div>
                            <div>
                                <select class="intermediary-dropdown" [(ngModel)]="course" (change)="loadGrade()"
                                    required="true">
                                    <option value="" hidden selected>{{courseMessage}}</option>
                                    <option *ngFor="let courseObject of intermediaryMappingBeanCourse"
                                        value="{{courseObject.id}}">
                                        {{courseObject.name}}</option>
                                </select>
                            </div>
                            <div>
                                <select class="intermediary-dropdown" [(ngModel)]="prdGrade" (change)="loadProduct()"
                                    required="true">
                                    <option value="" hidden selected>{{gradeMessage}}</option>
                                    <option *ngFor="let gradeObject of intermediaryMappingBeanPrdGrade">
                                        {{gradeObject.notation}}</option>
                                </select>
                            </div>
                            <div>
                                <select class="intermediary-dropdown" [(ngModel)]="product" required="true">
                                    <option value="" hidden selected>{{productMessage}}</option>
                                    <option *ngFor="let productObject of intermediaryMappingBeanProdList">
                                        {{productObject.name}}</option>
                                </select>
                            </div>
                            <div>
                                <div id="content-bottom-link"
                                    class="intermediary-bottom-link row-fluid grey-background-color">
                                    <div class="span4">
                                    </div>
                                    <div class="span4 intermediary-create-alignment-link bottom-text"
                                        (click)="viewProductHierarchyScreen()" id="createalign"
                                        [textContent]="'CREATE ALIGNMENT'">
                                        <img class="intermediary-bottom-img"
                                            src="resources/images/icon_arraow_block.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>