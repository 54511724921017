import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayRange'
})
export class ArrayRangePipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }
  transform(arr: any, lower: any, upper: any) {
    for (var i = lower; i <= upper; i++) {
      arr.push(i);
    }
    return arr;
  };


}
