import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HttpRestClientService } from 'src/app/services/common/http-rest-client.service';
import { ConfirmationPopupService } from 'src/app/services/confirmation-popup/confirmation-popup.service';
import { GlobalVariablesService } from 'src/app/services/global-variables/global-variables.service';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-subs-popup',
  templateUrl: './edit-subs-popup.component.html',
  styleUrls: ['./edit-subs-popup.component.scss']
})
export class EditSubsPopupComponent implements OnInit {
  @Input() createItemBean: any;
  public tools: object = {
    items: [ 'Bold', 'Italic', 'Underline']
  };
  @Input() modalRef: any;
  @Input() saveTopic: any;

  public height: number = 500;
  public pleasewait: boolean = false;
  public disbaledAfterClick: boolean = false;

  @ViewChild('templateRTE') rteEle: RichTextEditorComponent | undefined;


  constructor(
    private httpRestClient : HttpRestClientService,
    public globalVar: GlobalVariablesService,
    private modalService: BsModalService,
    private confirmationPopup: ConfirmationPopupService,
    private actRoute : ActivatedRoute
  ) { }

  ngOnInit(): void {
  }

  public saveTopicData = () => {
    this.disbaledAfterClick=true;
    this.pleasewait=true;
    this.createItemBean.topicDesc = "" + this.rteEle?.getHtml();
    this.saveTopic(this.createItemBean);
  }

  public closePopup = () => {
    this.modalRef.hide();
  }

}
