import { Component, OnInit, AfterViewInit, AfterContentInit } from '@angular/core';
import { GlobalVariablesService } from '../../services/global-variables/global-variables.service';
import { HttpRestClientService } from '../../services/common/http-rest-client.service';
import { ConfirmationPopupService } from '../../services/confirmation-popup/confirmation-popup.service';
import * as _ from 'underscore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  public hasUser: boolean | undefined;

  constructor(
    public globalVar: GlobalVariablesService,
    private httpRestClient: HttpRestClientService,
    private confirmationPopup: ConfirmationPopupService,
    private route: Router
  ) { }

  ngOnInit(): void {
    this.initApprovedCurriculumList();
    if (!this.globalVar.filterSubjectsHomePage || !this.globalVar.filterCountriesHomePage) {
      this.preloadIngestData();
    }
    this.globalVar.activeMainMenu = 'view';
  }

  ngDoCheck(): void {
    this.hasUser = this.globalVar.hasUser;
  }

  public initApprovedCurriculumList = () => {
    this.loadApprovedCurriculumList();
    this.globalVar.activeMainMenu = 'view';
  };

  public preloadIngestData = () => {
    this.globalVar.loading = true;
    let url = 'api/curriculum/ingestion'
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.globalVar.filterSubjectsHomePage = result.subjectFacets;
      this.globalVar.filterCountriesHomePage = result.countryFacets;
      this.globalVar.loading = false;
      // this.makeActiveTabVisible();
    }, (error) => {
      this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.createCurriculumPreloadIngetionDataErrorMessage);
      this.globalVar.loading = false;
    });
  }

  public loadApprovedCurriculumList = () => {
    this.globalVar.curriculaListBean = undefined;
    this.httpRestClient.getForUrl('api/view/list/approved', undefined, undefined).subscribe((result) => {
      this.globalVar.curriculaListBean = result;
      this.updateListSize();
    }, (err) => {
      if (err.status == 500) {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
      }
    });
  }

  public setSelectedCountryDetails = (curriculumCountryList: any) => {
    var currContList = _.clone(curriculumCountryList);
    let tempObj = {
      curriculumCountryListBean: [currContList]
    };
    this.globalVar.curriculaListBean = _.clone(tempObj);
    this.route.navigate(["view/list/approved/" + curriculumCountryList.country]);
  }

  getStandardUriLookup()
  {
    this.route.navigate(["view/standardURI"]);
  }

  public updateListSize = () => {
    var listSize = 0;
    for (var x = 0; x < this.globalVar.curriculaListBean.curriculumCountryListBean.length; x++) {
      var curriculumCountryListBean = this.globalVar.curriculaListBean.curriculumCountryListBean[x];
      listSize = listSize + curriculumCountryListBean.curriculaInfoList.length;
    }
    this.globalVar.curriculaListBean.listSize = listSize;
  }

  public setSelectedIntermediaryDetails = (subjectDet: any) => {
    this.globalVar.isFromHome = 'homePage';
    var modifiedSubject = {
      "subjectCode": subjectDet.facetUri,
      "subjectDesc": subjectDet.facetName
    }
    this.globalVar.homeSelectedSubjDet = _.clone(subjectDet);
    this.globalVar.intermediaryPreSelectedDropdown = {};
    this.globalVar.intermediaryPreSelectedDropdown.isProduct = false
    this.globalVar.intermediaryPreSelectedDropdown.selectedList = {}
    this.globalVar.intermediaryPreSelectedDropdown.selectedList.subject = modifiedSubject;
    this.route.navigate(["view/intermediaries/" + subjectDet.facetName]);
  }

}
