import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { HttpRestClientService } from 'src/app/services/common/http-rest-client.service';
import { ConfirmationPopupService } from 'src/app/services/confirmation-popup/confirmation-popup.service';
import { GlobalVariablesService } from 'src/app/services/global-variables/global-variables.service';
import * as _ from 'underscore';
import { result } from 'underscore';

@Component({
  selector: 'app-manage-product',
  templateUrl: './manage-product.component.html',
  styleUrls: ['./manage-product.component.scss']
})
export class ManageProductComponent implements OnInit {
  public disciplineFacet: any;
  public popupData: any;
  public modalRef: BsModalRef | undefined;
  @ViewChild('areYouSurePopup') private areYouSurePopup: TemplateRef<any> | undefined;
  @ViewChild('addorEditproduct') private addorEditproduct: TemplateRef<any> | undefined;

  public currDisciplineNode: any;
  public currDisciplinedprogramNode: any;
  public currProgramNode: any;
  public currProductNode: any

  public iriType: any;
  public modalData: any;
  // public popupDoFunction: any;
  public addFlag: boolean = true;

  constructor(
    public globalVar: GlobalVariablesService,
    private httpRestClient: HttpRestClientService,
    private confirmationPopup: ConfirmationPopupService,
    private modalService: BsModalService,
    private route: Router
  ) { }

  ngOnInit(): void {
    this.loadProductDiscipline();
  }

  public loadProductDiscipline = () => {
    let url = 'api/discipline';
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.disciplineFacet = result.facet;
    }, (error) => {
      if (error.status == 500) {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
      }
    })
  }

  public getdisciplinedprogram = (currDiscipline: any, event: any) => {
    let url = 'api/disciplinedprogram?sub=' + encodeURIComponent(currDiscipline.facetName) + '&withHaspart=false';
    let element = document.getElementById(currDiscipline.facetName);
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      currDiscipline.disciplinedprogramFacet = result ? result.item : [];
      event?.classList.remove("treeLoading");
      event?.classList.add("treeOpen");
      if (!(element?.classList.contains('treeOpen'))) {
        element?.classList.add("treeOpen");
        element?.classList.remove("visHidden");
        element?.parentElement?.parentElement?.parentElement?.classList.add("treeOpen");
      }
      if (!result) {
        element?.classList.add("visHidden");
      }
    }, (error) => {
      if (error.status == 500) {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
      }
    });
  }

  public getprogram = (currDisciplinedProgram: any, event: any) => {
    let url = 'api/program?iri=' + encodeURIComponent(currDisciplinedProgram.id) + '&expand=partial';
    let element = document.getElementById(currDisciplinedProgram.id);
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      currDisciplinedProgram.programFacet = result ? result.hasPart : [];
      event?.classList.remove("treeLoading");
      event?.classList.add("treeOpen");
      if (!(element?.classList.contains('treeOpen'))) {
        element?.classList.add("treeOpen");
        element?.classList.remove("visHidden");
        element?.parentElement?.parentElement?.parentElement?.classList.add("treeOpen");
      }
      if (!result) {
        element?.classList.add("visHidden");
      }
    }, (error) => {
      if (error.status == 500) {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
      }
    });
  }

  public getCourse = (currProgram: any, event: any) => {
    let url = 'api/course?iri=' + encodeURIComponent(currProgram.id) + '&expand=partial';
    let element = document.getElementById(currProgram.id);
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      if (result?.errorMessage) {
        this.confirmationPopup.showErrorMessage(result?.errorMessage);
      }
      currProgram.productFacet = result ? result.hasPart : [];
      event?.classList.remove("treeLoading");
      event?.classList.add("treeOpen");
      if (!(element?.classList.contains('treeOpen'))) {
        element?.classList.add("treeOpen");
        element?.classList.remove("visHidden");
        element?.parentElement?.parentElement?.parentElement?.classList.add("treeOpen");
      }
      if (!result) {
        element?.classList.add("visHidden");
      }
    }, (error) => {
      if (error.status == 500) {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
      }
    });
  }

  public addEditProgramedDiscipline = (isAdd: any, discipline: any, disciplinedprogram: any, program: any, product: any) => {
    let createItemBean;
    if (isAdd) {
      createItemBean = {
        forProgram: true,
        ProgramName: ""
      };
    } else {
      createItemBean = {
        forProgram: true,
        ProgramName: disciplinedprogram.name
      };
    }
    this.iriType = 'programs'
    this.currDisciplineNode = discipline;
    this.currDisciplinedprogramNode = disciplinedprogram;
    this.currProgramNode = undefined;
    this.currProductNode = undefined;
    this.modalData = createItemBean;
    this.addFlag = isAdd;
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    this.modalRef = this.modalService.show(this.addorEditproduct ? this.addorEditproduct : '',config);
    this.modalRef.setClass("addAuthorityPopup");
  }

  public deleteProgramedDiscipline = (disciplinedprogram: any, discipline: any) => {
    this.currDisciplineNode = discipline;
    this.currDisciplinedprogramNode = disciplinedprogram;
    this.currProgramNode = undefined;
    this.currProductNode = undefined;
    this.iriType = 'programs';
    let popData = {
      popupMessage: this.globalVar.languageString.resources.manageTab.productDeleteConfirmMessage + '[' + disciplinedprogram.name + ']?',
      buttonLabel: 'Yes',
      confirmationMessage: undefined,
      doFunctionOnSuccess: this.confirmDeletionToc,
    };
    this.popupData = popData;
    this.modalRef = this.modalService.show(this.areYouSurePopup ? this.areYouSurePopup : '');
  }

  public addEditProgram = (isAdd: any, discipline: any, disciplinedprogram: any, program: any, product: any) => {
    let createItemBean;
    if (isAdd) {
      createItemBean = {
        forCourses: true,
        CourseName: ""
      };
    } else {
      createItemBean = {
        forCourses: true,
        CourseName: program.name
      };
    }
    this.iriType = 'courses'
    this.currDisciplineNode = discipline;
    this.currDisciplinedprogramNode = disciplinedprogram;
    this.currProgramNode = program;
    this.currProductNode = product;
    this.modalData = createItemBean;
    this.addFlag = isAdd;
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    this.modalRef = this.modalService.show(this.addorEditproduct ? this.addorEditproduct : '',config);
    this.modalRef.setClass("addAuthorityPopup");
  }

  public deleteProgram = (program: any, disciplinedprogram: any, discipline: any) => {
    this.currDisciplineNode = discipline;
    this.currDisciplinedprogramNode = disciplinedprogram;
    this.currProgramNode = program;
    this.currProductNode = undefined;
    this.iriType = 'courses';
    let popData = {
      popupMessage: this.globalVar.languageString.resources.manageTab.productDeleteConfirmMessage + '[' + program.name + ']?',
      buttonLabel: 'Yes',
      confirmationMessage: undefined,
      doFunctionOnSuccess: this.confirmDeletionToc,
    };
    this.popupData = popData;
    this.modalRef = this.modalService.show(this.areYouSurePopup ? this.areYouSurePopup : '');
  }

  public addEditProduct = (isAdd: any, discipline: any, disciplinedprogram: any, program: any, product: any) => {
    if (!this.globalVar.gradeListForProductEdit) {
      this.getGradeRange();
    }
    let createItemBean = {
      typeList: [{ id: "Print", value: "Print" }, { id: "Web", value: "Web" }],
      forProduct: true,
      ProductTitle: '',
      MinGrade: '',
      MaxGrade: '',
      Country: '',
      State: '',
      MarketPrivince: '',
      ISBN10: '',
      ISBN13: '',
      Type: 'Print'
    };
    if (!isAdd) {
      createItemBean.ProductTitle = product.name;
      createItemBean.MinGrade = product.levelRange.minLevel;
      createItemBean.MaxGrade = product.levelRange.maxLevel;
      createItemBean.Country = product.country;
      createItemBean.State = product.state;
      createItemBean.MarketPrivince = product.province;
      createItemBean.ISBN10 = product.isbn10;
      createItemBean.ISBN13 = product.isbn13;
      createItemBean.Type = product.alignType;
    }
    this.iriType = 'products'
    this.currDisciplineNode = discipline;
    this.currDisciplinedprogramNode = disciplinedprogram;
    this.currProgramNode = program;
    this.currProductNode = product;
    this.modalData = createItemBean;
    this.addFlag = isAdd;
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    this.modalRef = this.modalService.show(this.addorEditproduct ? this.addorEditproduct : '',config);
    this.modalRef.setClass("addAuthorityPopup");
  }

  public deleteProduct = (product: any, program: any, disciplinedprogram: any, discipline: any) => {
    this.currDisciplineNode = discipline;
    this.currDisciplinedprogramNode = disciplinedprogram;
    this.currProgramNode = program;
    this.currProductNode = product;
    this.iriType = 'products';
    if (product.hasPlan) {
      this.checkForAlignments(product);
    } else {
      let popData = {
        popupMessage: this.globalVar.languageString.resources.manageTab.productDeleteConfirmMessage + '[' + product.name + ']?',
        buttonLabel: 'Yes',
        confirmationMessage: undefined,
        doFunctionOnSuccess: this.confirmDeletionToc,
      };
      this.popupData = popData;
      this.modalRef = this.modalService.show(this.areYouSurePopup ? this.areYouSurePopup : '');
    }
  }

  public checkForAlignments = (product: any) => {
    let url = 'api/intermediary/alignmentsExits?iri=' + product.hasPlan;
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      if (result === true) {
        let popData = {
          popupMessage: this.globalVar.languageString.resources.manageTab.productDeleteConfirmMessageForAlignments + '[' + product.name + ']?',
          buttonLabel: 'Yes',
          confirmationMessage: undefined,
          doFunctionOnSuccess: this.confirmDeletionToc,
        };
        this.popupData = popData;
        this.modalRef = this.modalService.show(this.areYouSurePopup ? this.areYouSurePopup : '');
      } else {
        let popData = {
          popupMessage: this.globalVar.languageString.resources.manageTab.productDeleteConfirmMessage + '[' + product.name + ']?',
          buttonLabel: 'Yes',
          confirmationMessage: undefined,
          doFunctionOnSuccess: this.confirmDeletionToc,
        };
        this.popupData = popData;
        this.modalRef = this.modalService.show(this.areYouSurePopup ? this.areYouSurePopup : '');
      }
    })
  }

  public confirmDeletionToc = () => {
    let url = '';
    if (this.iriType == 'products') {
      url = 'api/plan/delete?iri=' + this.currProductNode.id + '&iriType=products&parentIRI=' + this.currProgramNode.id;
    } else if (this.iriType == 'courses') {
      url = 'api/plan/delete?iri=' + this.currProgramNode.id + '&iriType=courses&parentIRI=' + this.currDisciplinedprogramNode.id;
    } else if (this.iriType == 'programs') {
      url = 'api/plan/delete?iri=' + this.currDisciplinedprogramNode.id + '&iriType=programs&parentIRI=' + this.currDisciplineNode.facetName;
    }
    const headers: any = {
      responseType: 'text'
    };
    this.httpRestClient.postForUrl(url, undefined, headers).subscribe((result) => {
      if (this.iriType == 'products') {
        this.confirmationPopup.showSuccessMessage('Product' + this.globalVar.languageString.resources.manageTab.productDeleteSuccessMessage);
        this.getCourse(this.currProgramNode, undefined);
      } else if (this.iriType == 'programs') {
        this.confirmationPopup.showSuccessMessage('Program' + this.globalVar.languageString.resources.manageTab.productDeleteSuccessMessage);
        this.getdisciplinedprogram(this.currDisciplineNode, undefined);
      } else if (this.iriType == 'courses') {
        this.confirmationPopup.showSuccessMessage('Course' + this.globalVar.languageString.resources.manageTab.productDeleteSuccessMessage);
        this.getprogram(this.currDisciplinedprogramNode, undefined);
      }
    }, (error) => {
      if (error.status == 500) {
        this.confirmationPopup.showErrorMessage(error.error);
      }
    })
  }

  public getGradeRange = () => {
    let url = "api/curriculum/graderange";
    this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
      this.globalVar.gradeListForProductEdit = result.cmtGradeList;
    });
  }

  public isNameExist = (itemName: any) => {
    // if(this.iriType==='programs'){ 
    //   _.contains(this.currDisciplineNode, {'label':itemName})
    // }
    return false;
  }

  public saveOrUpdate = (isAddFlagTrue: any, createItemBean: any) => {
    if (this.iriType === 'products') {
      if (this.isNameExist(createItemBean.ProductTitle)) {
        this.confirmationPopup.showErrorMessage("Product name already exist.");
      } else {
        this.SaveOrUpdateProduct(isAddFlagTrue, createItemBean);
      }
    } else if (this.iriType === 'courses') {
      if (this.isNameExist(createItemBean.CourseName)) {
        this.confirmationPopup.showErrorMessage("Course name already exist.");
      } else {
        this.SaveOrUpdateCourse(isAddFlagTrue, createItemBean);
      }
    } else if (this.iriType === 'programs') {
      if (this.isNameExist(createItemBean.ProgramName)) {
        this.confirmationPopup.showErrorMessage("Program name already exist.");
      } else {
        this.SaveOrUpdateProgram(isAddFlagTrue, createItemBean);
      }
    }
  }

  public SaveOrUpdateProduct = (isAddFlagTrue: any, createItemBean: any) => {
    let url;
    if (isAddFlagTrue) {
      url = 'api/product/createProduct?countryCoverage=' + createItemBean.Country + '&iri=' + encodeURIComponent(this.currProgramNode.id) + '&isbn10=' + createItemBean.ISBN10 +
        '&isbn13=' + createItemBean.ISBN13 + '&maxGrade=' + createItemBean.MaxGrade + '&minGrade=' + createItemBean.MinGrade + '&name=' + createItemBean.ProductTitle + '&provinceCoverage=' +
        createItemBean.MarketPrivince + '&stateCoverage=' + createItemBean.State + '&type=' + createItemBean.Type;
      const headers: any = {
        responseType: 'text'
      };
      this.httpRestClient.putForUrl(url, headers, undefined).subscribe((result) => {
        this.getCourse(this.currProgramNode, undefined);
        this.modalRef?.hide();
      }, (error) => {
        if (error.status == 200) {
          this.getCourse(this.currProgramNode, undefined);
          this.modalRef?.hide();
        }
        else {
          this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorMessage + (isAddFlagTrue ? this.globalVar.languageString.resources.manageTab.curriculumErrorInCreating : this.globalVar.languageString.resources.manageTab.curriculumErrorInUpdating) + " " + this.globalVar.languageString.resources.manageTab.productLabel);
        }
      })
    } else {
      url = 'api/product/updateProduct?countryCoverage=' + createItemBean.Country + '&iri=' + encodeURIComponent(this.currProductNode.id) + '&isbn10=' + createItemBean.ISBN10 +
        '&isbn13=' + createItemBean.ISBN13 + '&maxGrade=' + createItemBean.MaxGrade + '&minGrade=' + createItemBean.MinGrade + '&name=' + createItemBean.ProductTitle + '&provinceCoverage=' +
        createItemBean.MarketPrivince + '&stateCoverage=' + createItemBean.State + '&type=' + createItemBean.Type;
      const headers: any = {
        responseType: 'text'
      };
      this.httpRestClient.postForUrl(url, undefined, headers).subscribe((result) => {
        this.getCourse(this.currProgramNode, undefined);
        this.modalRef?.hide();
      }, (error) => {
        if (error.status == 200) {
          this.getCourse(this.currProgramNode, undefined);
          this.modalRef?.hide();
        }
        else {
          this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorMessage + (isAddFlagTrue ? this.globalVar.languageString.resources.manageTab.curriculumErrorInCreating : this.globalVar.languageString.resources.manageTab.curriculumErrorInUpdating) + " " + this.globalVar.languageString.resources.manageTab.productLabel);
        }
      })
    }
  }

  public SaveOrUpdateCourse = (isAddFlagTrue: any, createItemBean: any) => {
    let url;
    if (isAddFlagTrue) {
      url = 'api/product/createCourse?name=' + createItemBean.CourseName + '&iri=' + encodeURIComponent(this.currDisciplinedprogramNode.id);
      const headers: any = {
        responseType: 'text'
      };
      this.httpRestClient.putForUrl(url, headers, undefined).subscribe((result) => {
        this.getprogram(this.currDisciplinedprogramNode, undefined);
        this.modalRef?.hide();
      }, (error) => {
        if (error.status == 200) {
          this.getprogram(this.currDisciplinedprogramNode, undefined);
          this.modalRef?.hide();
        }
        else {
          this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorMessage + (isAddFlagTrue ? this.globalVar.languageString.resources.manageTab.curriculumErrorInCreating : this.globalVar.languageString.resources.manageTab.curriculumErrorInUpdating) + " " + this.globalVar.languageString.resources.manageTab.courseLabel);
        }
      })
    } else {
      url = 'api/product/updateCourse?name=' + createItemBean.CourseName + '&iri=' + encodeURIComponent(this.currProgramNode.id);
      const headers: any = {
        responseType: 'text'
      };
      this.httpRestClient.postForUrl(url, undefined, headers).subscribe((result) => {
        this.getprogram(this.currDisciplinedprogramNode, undefined);
        this.modalRef?.hide();
      }, (error) => {
        if (error.status == 200) {
          this.getprogram(this.currDisciplinedprogramNode, undefined);
          this.modalRef?.hide();
        }
        else {
          this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorMessage + (isAddFlagTrue ? this.globalVar.languageString.resources.manageTab.curriculumErrorInCreating : this.globalVar.languageString.resources.manageTab.curriculumErrorInUpdating) + " " + this.globalVar.languageString.resources.manageTab.courseLabel);
        }
      })
    }
  }

  public SaveOrUpdateProgram = (isAddFlagTrue: any, createItemBean: any) => {
    let url;
    if (isAddFlagTrue) {
      url = 'api/product/createProgram?name=' + createItemBean.ProgramName + '&sub=' + encodeURIComponent(this.currDisciplineNode.facetName);
      const headers: any = {
        responseType: 'text'
      };
      this.httpRestClient.putForUrl(url, headers, undefined).subscribe((result) => {
        this.getdisciplinedprogram(this.currDisciplineNode, undefined);
        this.modalRef?.hide();
      }, (error) => {
        if (error.status == 200) {
          this.getdisciplinedprogram(this.currDisciplineNode, undefined);
          this.modalRef?.hide();
        }
        else {
          this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorMessage + (isAddFlagTrue ? this.globalVar.languageString.resources.manageTab.curriculumErrorInCreating : this.globalVar.languageString.resources.manageTab.curriculumErrorInUpdating) + " " + this.globalVar.languageString.resources.manageTab.programLabel);
        }
      })
    } else {
      url = 'api/product/updateProgram?name=' + createItemBean.ProgramName + '&iri=' + encodeURIComponent(this.currDisciplinedprogramNode.id);
      const headers: any = {
        responseType: 'text'
      };
      this.httpRestClient.postForUrl(url, undefined, headers).subscribe((result) => {
        this.getdisciplinedprogram(this.currDisciplineNode, undefined);
        this.modalRef?.hide();
      }, (error) => {
        if (error.status == 200) {
          this.getdisciplinedprogram(this.currDisciplineNode, undefined);
          this.modalRef?.hide();
        }
        else {
          this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.curriculumErrorMessage + (isAddFlagTrue ? this.globalVar.languageString.resources.manageTab.curriculumErrorInCreating : this.globalVar.languageString.resources.manageTab.curriculumErrorInUpdating) + " " + this.globalVar.languageString.resources.manageTab.programLabel);
        }
      })
    }
  }

}
