<button type="button" class="popupXClose close pull-right" aria-label="Close" (click)="doClosePopup()">×
</button>
 <div class="areYouSurePop updateWarningCurriculum">
    <div class="marginTop10">
        <div class="childOnePop"><span>{{PopupMessage}}</span></div>
        <div class = "subTopic" *ngIf="subPopupMessage?.length"><span *ngFor="let sub of subPopupMessage">{{sub}}</span></div>
        <div class="topicsToBeDeleted">
        	<ul>
        		<li *ngFor="let topicObj of Data">
        			<span *ngif="topicObj.standardCode && !topicObj.intermediaryStmt && !topicObj.intermediaryStatementCode">{{topicObj.standardCode}} {{topicObj.topicDesc}}</span>
        			<span *ngif="!topicObj.standardCode && !topicObj.intermediaryStmt && !topicObj.intermediaryStatementCode">{{topicObj}}</span>
        			<span *ngif="topicObj.intermediaryStmt && topicObj.intermediaryStatementCode"><strong>{{topicObj.intermediaryStatementCode}}</strong> {{topicObj.intermediaryStmt}}</span>
        		</li>
        	</ul>
        </div>
        <div class="form-horizontal childTwoPop">
            <input type="submit"
                   id="buttonOk"
                   value="{{ ButtonLabel }}"
                   class="cmButton cmButtonFull-green"
                   (click)="doPopupSuccess()"
                   [disabled]="globalVar.generatingXLS"/>
            <input *ngIf="!isCancel" type="submit"
                   id="buttonCancel"
                   value="No"
                   class="cmButton cmButtonFull-red"
                   (click)="doClosePopup()"
                   [disabled]="globalVar.generatingXLS"/>
                   
			 <input *ngIf="isCancel" type="submit"
                   id="buttonCancel"
                   value="Cancel"
                   class="cmButton cmButtonFull-red"
                   (click)="doClosePopup()"
                   [disabled]="globalVar.generatingXLS"/>
                   
        </div>

    </div>
</div>
 