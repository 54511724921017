<button type="button" class="popupXClose close pull-right" aria-label="Close" (click)="doClosePopup()">×
</button>
<div class="addOrEditProgramSection" *ngIf="createItemBean?.forProgram">
    <div style="color: #333333;" class="font_20_666666"><span [hidden]="!isAddFlagTrue">{{globalVar?.languageString.resources.manageTab.curriculumEditPopupAddLabel}}</span><span [hidden]="isAddFlagTrue">{{globalVar?.languageString.resources.manageTab.curriculumEditPopupEditLabel}}</span> {{globalVar?.languageString.resources.manageTab.programLabel}}</div>
    <div class="marginTop30" >
        <div style="margin-top: 0; min-height: 100px; overflow: auto;">           
            <div class="marginBottom10 validationCheck">
                <div class="font_15_666666 fontBold">{{globalVar?.languageString.resources.manageTab.programLabelName}}</div>
                <input id="localGradeName"  [(ngModel)]="createItemBean.ProgramName" type="text" class="form-control pgmCrseInput" placeholder="{{globalVar?.languageString.resources.manageTab.programLabelNameTextLabel}}"
                       required="required" [ngClass]="{ 'ng-invalid-required' : createItemBean.ProgramName == ''}"
                       autofocus="autofocus">
            </div>
        </div>
        <div class="form-horizontal marginTop20">
            <input type="submit" [disabled]="!createItemBean.ProgramName" value="{{globalVar?.languageString.resources.others.saveButtonLabel}}" class="cmButton cmButtonFull-green cmButton-standard"
                   (click)="doSuccessFunction(isAddFlagTrue, createItemBean)"/>
            <a (click)="doClosePopup()" class="cmButton cmButton-url">{{globalVar?.languageString.resources.others.cancelBtnLabel}}</a>
        </div>

    </div>
</div>
<!-- forCourses -->
<div class="addOrEditProgramSection" *ngIf="createItemBean?.forCourses">
    <div style="color: #333333;" class="font_20_666666"><span [hidden]="!isAddFlagTrue">{{globalVar?.languageString.resources.manageTab.curriculumEditPopupAddLabel}}</span><span [hidden]="isAddFlagTrue">{{globalVar?.languageString.resources.manageTab.curriculumEditPopupEditLabel}}</span> {{globalVar?.languageString.resources.manageTab.courseLabel}}</div>
    <div class="marginTop30" >
        <div style="margin-top: 0; min-height: 100px; overflow: auto;">           
            <div class="marginBottom10 validationCheck">
                <div class="font_15_666666 fontBold">{{globalVar?.languageString.resources.manageTab.courseLabelName}}</div>
                <input id="localGradeName"  [(ngModel)]="createItemBean.CourseName" type="text" class="form-control pgmCrseInput" placeholder="{{globalVar?.languageString.resources.manageTab.courseLabelNameTextLabel}}"
                       required="required" [ngClass]="{ 'ng-invalid-required' : createItemBean.CourseName == ''}"
                       autofocus="autofocus">
            </div>
        </div>
        <div class="form-horizontal marginTop20">
            <input type="submit" [disabled]="!createItemBean.CourseName" value="{{globalVar?.languageString.resources.others.saveButtonLabel}}" class="cmButton cmButtonFull-green cmButton-standard"
                   (click)="doSuccessFunction(isAddFlagTrue, createItemBean)"/>
            <a (click)="doClosePopup()" class="cmButton cmButton-url">{{globalVar?.languageString.resources.others.cancelBtnLabel}}</a>
        </div>

    </div>
</div>
<!-- forProduct -->
<div class="addOrEditProgramSection" *ngIf="createItemBean?.forProduct">
    <div style="color: #333333;" class="font_20_666666"><span [hidden]="!isAddFlagTrue">{{globalVar?.languageString.resources.manageTab.curriculumEditPopupAddLabel}}</span><span [hidden]="isAddFlagTrue">{{globalVar?.languageString.resources.manageTab.curriculumEditPopupEditLabel}}</span> {{globalVar?.languageString.resources.manageTab.productLabel}}</div>
    <div class="marginTop30">
        <div class="form-horizontal addOrEditProdCls1">           
            <div class="form-group marginBottom5">
                <label for="ProductTitle" style="text-align: left;width: 250px;" class="control-label col-sm-2 font_15_666666 fontBold">{{globalVar?.languageString.resources.manageTab.productLabelName}}</label>     
                <div class="col-sm-10 validationCheck">               
                    <input id="ProductTitle"  [(ngModel)]="createItemBean.ProductTitle" type="text" class="form-control productDataWidth" 
                        placeholder="{{globalVar?.languageString.resources.manageTab.productLabelTextLabel}}"
                        [ngClass]="{ 'ng-invalid-required' : createItemBean.ProductTitle == ''}"
                        required="required">
                 </div>
            </div>
            <div class="form-group marginBottom5">
                <label for="MinGrade" style="text-align: left;width: 250px;" class="control-label col-sm-2 font_15_666666 fontBold">{{globalVar?.languageString.resources.manageTab.productMinGrade}}</label>
                <div class="col-sm-10">      
                <select class="alignmentDropDown productDataWidth" id="MinGrade"
                    [(ngModel)]="createItemBean.MinGrade">
                    <option *ngFor="let GradeBean of globalVar?.gradeListForProductEdit" value="{{GradeBean.grade}}">{{GradeBean.grade}}</option>
                </select>
                </div>
            </div>
            <div class="form-group marginBottom5">
              	<label for="MaxGrade" style="text-align: left;width: 250px;" class="control-label col-sm-2  font_15_666666 fontBold">{{globalVar?.languageString.resources.manageTab.productMaxGrade}}</label>
              	<div class="col-sm-10">    
                    <select class="alignmentDropDown productDataWidth"id="MaxGrade"
                        [(ngModel)]="createItemBean.MaxGrade">
                        <option *ngFor="let GradeBean of globalVar?.gradeListForProductEdit" value="{{GradeBean.grade}}">{{GradeBean.grade}}</option>
                    </select>                       
                </div>
            </div>
            <div class="form-group marginBottom5">             
                <label for="Country" style="text-align: left;width: 250px;" class="control-label col-sm-2  font_15_666666 fontBold">{{globalVar?.languageString.resources.manageTab.productCountryLabelName}}</label>
                <div class="col-sm-10 validationCheck">    
                    <input id="Country" [(ngModel)]="createItemBean.Country"  type="text" class="form-control productDataWidth" 
                        placeholder="{{globalVar?.languageString.resources.manageTab.productCountryLabelTextLabel}}"
                        required="required" [ngClass]="{ 'ng-invalid-required' : createItemBean.Country == ''}" >
				</div>                       
            </div>
            <div class="form-group marginBottom5">
                <label for="State" style="text-align: left;width: 250px;" class="control-label col-sm-2  font_15_666666 fontBold">{{globalVar?.languageString.resources.manageTab.productStateLabelName}}</label>
                <div class="col-sm-10 validationCheck">    
                    <input id="State" [(ngModel)]="createItemBean.State"  type="text" class="form-control productDataWidth" 
                        placeholder="{{globalVar?.languageString.resources.manageTab.productStateLabelTextLabel}}"
                        required="required" [ngClass]="{ 'ng-invalid-required' : createItemBean.State == ''}">
				</div>                         
            </div>
             <div class="form-group marginBottom5">               
                <label for="MarketPrivince" style="text-align: left;width: 250px;" class="control-label col-sm-2 font_15_666666 fontBold">{{globalVar?.languageString.resources.manageTab.productMarketProvinceLabelName}}</label>
                <div class="col-sm-10">    
                    <input id="MarketPrivince" [(ngModel)]="createItemBean.MarketPrivince" type="text" class="form-control productDataWidth" 
                        placeholder="{{globalVar?.languageString.resources.manageTab.productMarketProvinceLabelTextLabel}}">
				</div>					                        
            </div>
             <div class="form-group marginBottom5">            
                <label for="ISBN10" style="text-align: left;width: 250px;" class="control-label col-sm-2 font_15_666666 fontBold">{{globalVar?.languageString.resources.manageTab.productISBN10LabelName}}</label>
                <div class="col-sm-10">    
                    <input id="ISBN10"  [(ngModel)]="createItemBean.ISBN10"  type="text" class="form-control productDataWidth" 
                        placeholder="{{globalVar?.languageString.resources.manageTab.productISBN10LabelTextLabel}}"
                        maxlength="10">
                </div>
            </div>
            <div class="form-group marginBottom5">
                <label for="ISBN13" style="text-align: left;width: 250px;" class="control-label col-sm-2 font_15_666666 fontBold">{{globalVar?.languageString.resources.manageTab.productISBN13LabelName}}</label>
                <div class="col-sm-10">    
                    <input id="ISBN13" [(ngModel)]="createItemBean.ISBN13"  type="text" class="form-control productDataWidth"
                        placeholder="{{globalVar?.languageString.resources.manageTab.productISBN13LabelTextLabel}}"
                        maxlength="13">
                </div>
            </div>
             <div class="form-group marginBottom5">               
                    <label for="Type" style="text-align: left;width: 250px;" class="control-label col-sm-2 font_15_666666 fontBold">{{globalVar?.languageString.resources.manageTab.productTypeLabelName}}</label>
                    <div class="col-sm-10">    
                        <select class="alignmentDropDown productDataWidth" id="ype"
                            [(ngModel)] ="createItemBean.Type">
                            <option *ngFor="let Type of createItemBean.typeList" value="{{Type.id}}"> {{Type.value}} </option>
                        </select>
                    </div>
            </div>
        </div>
        <div class="form-horizontal marginTop20">
            <input type="submit" style="margin-bottom: 20px;" [disabled]="!createItemBean.ProductTitle || !createItemBean.MinGrade || !createItemBean.MaxGrade || !createItemBean.Country || !
                createItemBean.State || !createItemBean.Type" value="{{globalVar?.languageString.resources.others.saveButtonLabel}}" class="cmButton cmButtonFull-green cmButton-standard"
                (click)="doSuccessFunction(isAddFlagTrue, createItemBean)"/>
            <a (click)="doClosePopup()"style="margin-bottom: 20px;" class="cmButton cmButton-url">{{globalVar?.languageString.resources.others.cancelBtnLabel}}</a>
        </div>

    </div>
</div>

