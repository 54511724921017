import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { ConfirmationPopupService } from '../../services/confirmation-popup/confirmation-popup.service';
import { GlobalVariablesService } from '../../services/global-variables/global-variables.service';
import { HttpRestClientService } from '../../services/common/http-rest-client.service';
import { ApplicationLoaderService } from '../../services/common/application-loader.service';
import { Router } from '@angular/router';
import * as _ from "underscore";
import { $ } from 'protractor';
import { HttpHeaders } from '@angular/common/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CurriculumService } from 'src/app/services/curriculum/curriculum.service';
import { PreloadDataService } from '../../services/common/preload-data.service';
// import { LoginComponent } from '../../components/login/login.component';
// import { ManagePageComponent } from '../../components/manage-page/manage-page.component';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {
  @Input() fromScreen: any;
  @Input() action: any;
  @Input() bean: any = {
    text: "",
    subject: "",
    program: "",
    product: "",
    course: "",
    country: "",
    authority: "",
    curriculumSet: "",
    year: ""
  };
  @Input() subsList: any;
  @Input() subSet: any;
  public selectedFile: any;
  public avatar: any;
  public progress: any;
  public errorUploadResult: any;
  public warningUploadResult: any;
  public errorMessages: any;
  public conversionSuccessful: any;
  public curriculumID: any;
  public disableUploadButton: any;
  public fileName: any;
  public disbaledAfterClick: boolean = false;
  public dataBean: any = new FormData();
  public selectedElement: any;
  public checkForAlignmentsResults: any;
  public modalRef: BsModalRef | undefined;
  public uploader: any;
  public uploadedFile: any;
  public popupData: any = {};

  @ViewChild('uploaderPopup') private uploaderPopup: TemplateRef<any> | undefined;

  constructor(
    public globalVar: GlobalVariablesService,
    public confirmationPopup: ConfirmationPopupService,
    public httpRestClient: HttpRestClientService,
    public curriculumService: CurriculumService,
    private route: Router,
    private modalService: BsModalService,
    // public loginComp: LoginComponent,
    // public manageComp: ManagePageComponent,
    public applicationLoader: ApplicationLoaderService,
    public PreloadData: PreloadDataService
  ) {
    this.PreloadData.beanValueChanged.subscribe(value => {
      if (value == true) {
        this.disableUploadButton = this.getDisableValue();
      }
    })
  }

  ngOnInit(): void {
    this.initApplicationCache();
    this.disableUploadButton = this.getDisableValue();
    this.curriculumService.uploadDetails.subscribe((data: any) => {
      this.disableUploadButton = this.getDisableValue();
    })
  }

  ngOnChanges(): void {
    this.disableUploadButton = this.getDisableValue();
  }



  getDisableValue() {
    if (this.fromScreen == 'uploadSub') {
      return this.selectedFile ? false : true;
    }
    if (this.fromScreen == 'updateSub') {
      return this.selectedFile && this.subSet ? false : true;
    }
    if (this.fromScreen == 'updateCurriculum') {
      return this.selectedFile && this.bean.curriculumId ? false : true;
    }
    if (this.fromScreen == 'intermediary') {
      return !this.bean.subject;
    } else if (this.fromScreen == 'product') {
      return !this.bean.subject ||
        !this.bean.program ||
        !this.bean.course ||
        !this.bean.product;
    }
    else {
      return !this.bean.subject ||
        !this.bean.country ||
        !this.bean.state ||
        !this.bean.authority ||
        !this.bean.curriculumSet ||
        !this.bean.customTitle ||
        !this.bean.year;
    }

  }

  public clickOnUploadeInput = () => {
    let element = document.getElementById('fileName');
    element ? element.click() : "";
  }

  public changeFileName = (el: any) => {
    if (el.value == '') {
      // return false;
    } else {
      this.selectedElement = el;
      var ar = el.value.split('\\'), filename = ar[ar.length - 1];
      this.selectedFile = filename;
      this.uploadedFile = el.files[0];
      this.avatar = filename + this.globalVar.languageString.resources.manageTab.uploaderFileSelectedMessage;
      // var file = el.files[0];
      // var reader = new FileReader();
      // reader.onload = (e) => {
      //   // binary data
      //   console.log(e.target ? e.target.result : '');
      // };
      // reader.onerror = function(e) {
      //   // error occurred
      //   console.log('Error : ' + e.type);
      // };
      // reader.readAsBinaryString(file);
    }
    this.disableUploadButton = this.getDisableValue();

  };

  initApplicationCache = () => {
    this.applicationLoader.initilizeApplicationCache(() => {
      this.progress = 0;
      this.avatar = this.globalVar.languageString.resources.manageTab.uploaderSelectFileMessage;
      this.selectedFile = undefined;
      this.errorUploadResult = undefined;
      this.warningUploadResult = undefined;
      this.errorMessages = [];
      this.conversionSuccessful = true;
      this.curriculumID = undefined;
    });
  }

  public resetUploadFileName = (element: any) => {
    element.value = null;
  };

  public intermediaryUploadSuccess = () => {
    let ar = this.selectedElement.value.split('\\')
    let filename = ar[ar.length - 1];
    this.action = '';
    // $form.removeAttr($rootScope.applicationCache.languageString.resources.manageTab.uploaderFormActionAttribute);
    if (this.conversionSuccessful) {
      this.disbaledAfterClick = false;
      // this.confirmationPopup.showSuccessMessage("'" + filename + this.globalVar.languageString.resources.manageTab.uploaderUploadSuccessMessage);
      this.confirmationPopup.showSuccessMessage("Your Intermediary update is being processed. You will receive an email when it is complete, with a success or an error report.");
      this.avatar = this.globalVar.languageString.resources.manageTab.uploaderSelectFileMessage;
      this.selectedFile = undefined;
      this.route.navigate(["curriculums/intermediary"]);
    } else {
      this.errorUploadResult = this.globalVar.languageString.resources.manageTab.uploaderErrorInConvertingFileMessage + filename + "'";
      this.disbaledAfterClick = false;
    }
  }

  public sendFile = (fileName: any) => {
    this.dataBean = new FormData();
    this.dataBean.append('uploader', this.uploadedFile);
    this.progress = 0;
    this.disbaledAfterClick = true;
    if (this.bean.fileType == undefined) {
      this.bean.fileType = "AB XML";
    }
    this.bean.userMail = this.globalVar?.userProfile?.userMail;
    if (this.fromScreen == 'updateCurriculum') {
      this.dataBean.append('curriculumID', this.bean.curriculumId);
      this.dataBean.append('appendCustom', this.bean.appendCustom);
      this.dataBean.append('includeIntermediaryAlignments', this.bean.includeIntermediaryAlignments);
      this.dataBean.append('fileType', this.bean.fileType);
      this.checkForAlignments();
    } else if (this.fromScreen === 'uploadSub' || this.fromScreen === 'updateSub') {
      this.dataBean.append('curriculumIRI', this.PreloadData.selectedCurriculumDetails.curriculumId);
      this.dataBean.append('Description', this.PreloadData.selectedCurriculumDetails.curriculumTitle);
      this.dataBean.append('subSetIRI', this.subSet);
      this.dataBean.append('subSetName', this.getSubsname(_.clone(this.subSet)));
      this.continueUpload();
    } else if (this.fromScreen === 'product') {
      console.log(!_.isUndefined(this.bean.program));
      this.dataBean.append('subject', this.bean.subject);
      this.dataBean.append('program', (!_.isUndefined(this.bean.program) ? this.bean.program.name : ''));
      this.dataBean.append('course', (!_.isUndefined(this.bean.course) ? this.bean.course.name : ''));
      this.dataBean.append('product', (!_.isUndefined(this.bean.product) ? this.bean.product.name : ''));
      this.dataBean.append('programIri', (!_.isUndefined(this.bean.program) ? this.bean.program.id : ''));
      this.dataBean.append('courseIri', (!_.isUndefined(this.bean.course) ? this.bean.course.id : ''));
      this.dataBean.append('productIri', (!_.isUndefined(this.bean.product) ? this.bean.product.id : ''));
      this.dataBean.append('fileType', this.bean.fileType);
      this.bean.appendCustom ? this.dataBean.append('appendCustom', this.bean.appendCustom) : this.dataBean.append('appendCustom', false);
      this.dataBean.append('customTitle', (!_.isUndefined(this.bean.customTitle) ? this.bean.customTitle : ''));
      this.bean.ignoreIntermediaryAlignments ? this.dataBean.append('ignoreIntermediaryAlignments', this.bean.ignoreIntermediaryAlignments) : this.dataBean.append('ignoreIntermediaryAlignments', false);
      this.dataBean.append('userMail', this.bean.userMail);
      this.dataBean.append('description', this.bean.description ? this.bean.description : '');
      this.checkForAlignments();
    } else {
      console.log(!_.isUndefined(this.bean.program));
      this.dataBean.append('subject', this.bean.subject);
      this.dataBean.append('program', (!_.isUndefined(this.bean.program) ? this.bean.program.name : ''));
      this.dataBean.append('course', (!_.isUndefined(this.bean.course) ? this.bean.course.name : ''));
      this.dataBean.append('product', (!_.isUndefined(this.bean.product) ? this.bean.product.name : ''));
      this.dataBean.append('programIri', (!_.isUndefined(this.bean.program) ? this.bean.program.id : ''));
      this.dataBean.append('courseIri', (!_.isUndefined(this.bean.course) ? this.bean.course.id : ''));
      this.dataBean.append('productIri', (!_.isUndefined(this.bean.product) ? this.bean.product.id : ''));
      this.dataBean.append('text', this.bean.text);
      // this.bean.text ? this.dataBean.append('country', this.bean.country) : '';
      this.dataBean.append('country', this.bean.country);
      this.dataBean.append('state', this.bean.state);
      this.dataBean.append('authority', this.bean.authority);
      this.dataBean.append('curriculumSet', this.bean.curriculumSet);
      this.dataBean.append('year', this.bean.year);
      this.dataBean.append('lastUpdatedUrl', '');
      this.dataBean.append('sourceUrl', this.bean.sourceUrl);
      this.dataBean.append('fileType', this.bean.fileType);
      this.dataBean.append('curriculumType', this.bean.curriculumType);
      this.bean.appendCustom ? this.dataBean.append('appendCustom', this.bean.appendCustom) : this.dataBean.append('appendCustom', false);
      this.dataBean.append('customTitle', (!_.isUndefined(this.bean.customTitle) ? this.bean.customTitle : ''));
      this.dataBean.append('ignoreIntermediaryAlignments', this.bean.ignoreIntermediaryAlignments);
      this.bean.includeIntermediaryAlignments ? this.dataBean.append('includeIntermediaryAlignments', this.bean.includeIntermediaryAlignments) : this.dataBean.append('includeIntermediaryAlignments', false);
      this.dataBean.append('userMail', this.bean.userMail);
      this.dataBean.append('description', this.bean.description ? this.bean.description : '');
      if (this.fromScreen == 'product' || this.fromScreen == 'intermediary') {
        if (this.fromScreen == 'intermediary') {
          this.bean.fileType = "Excel";
          this.dataBean.append('skillMigrationImport',this.bean.skillMigrationImport);
          this.dataBean.append('skillsGUIDOverwrite',this.bean.skillsGUIDOverwrite);
        }
        this.checkForAlignments();
      } else {
        this.continueUpload();
      }
    }
  }

  public checkForDuplicateId = () => {
    if (this.checkForAlignmentsResults && this.checkForAlignmentsResults.validationResultMap && this.checkForAlignmentsResults.validationResultMap.duplicateIDList && this.checkForAlignmentsResults.validationResultMap.duplicateIDList.length > 0) {
      this.popupData.popupMessage = 'Note: The following ids are being duplicated.\nPlease click "OK" to proceed'
      // this.popupData.confirmationMsg = 'Uploading...';
      this.popupData.buttonLabel = 'Ok';
      this.popupData.popupCallBack = this.continueUpload;
      this.popupData.data = this.checkForAlignmentsResults.validationResultMap.duplicateIDList;
      this.popupData.isCancel = false;
      this.modalRef = this.modalService.show(this.uploaderPopup ? this.uploaderPopup : '');
    }
    else if (this.checkForAlignmentsResults && this.checkForAlignmentsResults.validationResultMap && this.checkForAlignmentsResults.validationResultMap.lowLevelStdCodeList && this.checkForAlignmentsResults.validationResultMap.lowLevelStdCodeList.length > 0) {
      this.popupData.popupMessage = undefined;
      this.checkForAlignmentsResults.validationResultMap.lowLevelStdCodeList.forEach((element: any) => {
        this.popupData.popupMessage = this.popupData.popupMessage ? this.popupData.popupMessage + element : element;
        this.popupData.popupMessage = this.popupData.popupMessage + '\n';
      });
      this.popupData.popupMessage = this.popupData.popupMessage + 'Please click "OK" to proceed'
      // this.popupData.confirmationMsg = 'Uploading...';
      this.popupData.buttonLabel = 'Ok';
      this.popupData.popupCallBack = this.continueUpload;
      this.popupData.data = this.checkForAlignmentsResults.validationResultMap.duplicateIDList;
      this.popupData.isCancel = true;
      this.modalRef = this.modalService.show(this.uploaderPopup ? this.uploaderPopup : '');
    }
    else {
      this.continueUpload();
    }
  }

  public checkForAlignments = () => {
    this.dataBean.append('mappingValidation', true);
    this.progress = 1;
    this.disbaledAfterClick = true;
    let url = _.clone(this.action);
    this.httpRestClient.postForUrl(url, this.dataBean, undefined).subscribe((result: any) => {
      this.progress = 0;
      this.disbaledAfterClick = false;
      this.popupData.subPopupMessage = [];
      if (this.bean.fileType === "AB XML" && this.fromScreen !== 'product') {
        if (result.length) {
          this.popupData.popupMessage = 'Note: Topics and alignments that do not exist in the updated file (Excel/XML) will be deleted from CMT.\nPlease click "OK" to proceed with the deletion of Topics shown below OR Click "Cancel" to stop the processing.'
          // this.popupData.confirmationMsg = 'Uploading...';
          this.popupData.buttonLabel = 'Ok';
          this.popupData.popupCallBack = this.continueUpload;
          this.popupData.data = result;
          this.popupData.isCancel = true;
          this.modalRef = this.modalService.show(this.uploaderPopup ? this.uploaderPopup : '');
          // PopupDialogService.updateCurriculumWarningPopup('Note: Topics and alignments that do not exist in the updated file (Excel/XML) will be deleted from CMT.\nPlease click "OK" to proceed with the deletion of Topics shown below OR Click "Cancel" to stop the processing.', 'Uploading...' , 'Ok', continueUpload, result, true);
        } else {
          this.continueUpload();
        }
      } else if (this.fromScreen === 'intermediary') {
        this.checkForAlignmentsResults = result;
        if (result && result.intermediaryValidationMap && result.intermediaryValidationMap.InterBeanList && result.intermediaryValidationMap.InterBeanList.length > 0) {
          this.popupData.popupMessage = 'Warning: There are intermediaries to be deleted that are still aligned.\nPlease click "OK" to proceed with the deletion of intermediaries shown below OR Click "Cancel" to stop the processing.'
          // this.popupData.confirmationMsg = 'Uploading...';
          this.popupData.buttonLabel = 'Ok';
          this.popupData.popupCallBack = this.continueUpload;
          this.popupData.data = result.intermediaryValidationMap.InterBeanList;
          this.popupData.isCancel = true;
          this.modalRef = this.modalService.show(this.uploaderPopup ? this.uploaderPopup : '');
          // PopupDialogService.updateCurriculumWarningPopup('Warning: There are intermediaries to be deleted that are still aligned.\nPlease click "OK" to proceed with the deletion of intermediaries shown below OR Click "Cancel" to stop the processing.', 'Uploading...' , 'Ok', continueUpload, result.intermediaryValidationMap.InterBeanList, true);
        } else {
          if (result.conversionSuccessful) {
            this.conversionSuccessful = result.conversionSuccessful;
            this.errorMessages = result.messages;
            this.intermediaryUploadSuccess();
          }
          else {
            this.conversionSuccessful = result.conversionSuccessful;
            this.errorUploadResult = this.globalVar.languageString.resources.manageTab.uploaderErrorInConvertingFileMessage;
            this.errorMessages = result.messages;
          }
        }
      } else {
        this.checkForAlignmentsResults = result;
        if (result && result.validationResultMap && result.validationResultMap.topicBeanList && result.validationResultMap.topicBeanList.length > 0) {
          this.popupData.subPopupMessage = [];
          this.popupData.popupMessage = 'Note: Topics and alignments that do not exist in the updated file (Excel/XML) will be deleted from CMT.\nPlease click "OK" to proceed with the deletion of Topics shown below OR Click "Cancel" to stop the processing.';
          result.validationResultMap.topicBeanList.forEach((item: any) => {
            this.popupData.subPopupMessage.push(item.standardCode + item.topicDesc);
          })
          // this.popupData.confirmationMsg = 'Uploading...';
          this.popupData.buttonLabel = 'Ok';
          this.popupData.popupCallBack = this.checkForDuplicateId;
          this.popupData.data = result.validationResultMap.topicBeanList;
          this.popupData.isCancel = true;
          this.modalRef = this.modalService.show(this.uploaderPopup ? this.uploaderPopup : '');
          // PopupDialogService.updateCurriculumWarningPopup('Note: Topics and alignments that do not exist in the updated file (Excel/XML) will be deleted from CMT.\nPlease click "OK" to proceed with the deletion of Topics shown below OR Click "Cancel" to stop the processing.', 'Uploading...' , 'Ok', checkForDuplicateId, result.validationResultMap.topicBeanList, true);
        } else {
          this.checkForDuplicateId();
        }
      }
    }, (error) => {
      let ar = this.selectedElement.value.split('\\'), filename = ar[ar.length - 1];
      this.disbaledAfterClick = false;
      this.errorUploadResult = this.globalVar.languageString.resources.manageTab.uploaderErrorMessage + filename + "'";
      this.errorMessages = [];
      // remove the action attribute from the form
      this.action = '';
      this.progress = 0;
    });
  }

  public getSubsname = (subsIRI: any) => {
    var listedSubs = _.clone(this.subsList);
    var selectedSubs = _.find(listedSubs, function (item) {
      return item.subSetIRI === subsIRI;
    });
    return (selectedSubs && selectedSubs.subSetName) ? selectedSubs.subSetName : "";
  }

  public continueUpload = () => {
    let url = _.clone(this.action);
    this.progress = 1;
    this.disbaledAfterClick = true;
    this.dataBean.set('mappingValidation', 'false');
    // this.dataBean.mappingValidation = false;
    this.httpRestClient.postForUrl(url, this.dataBean, undefined).subscribe((conversionReport) => {
      let el = this.selectedElement;
      this.progress = 0;
      var ar = el.value.split('\\'), filename = ar[ar.length - 1];
      this.errorUploadResult = undefined;
      this.warningUploadResult = undefined;
      this.curriculumID = conversionReport.curriculumID;
      this.conversionSuccessful = conversionReport.conversionSuccessful;
      this.errorMessages = conversionReport.messages;


      if (this.conversionSuccessful) {
        this.disbaledAfterClick = false;
        if (this.errorMessages.length > 0) {
          this.warningUploadResult = this.globalVar.languageString.resources.manageTab.uploaderListOfWarningMessage + filename + "'";
        }

        if (this.bean.ignoreIntermediaryAlignments != undefined && (this.bean.ignoreIntermediaryAlignments || !this.bean.ignoreIntermediaryAlignments)) {
          this.confirmationPopup.showSuccessMessage("We will notify you shortly once your request has been completed via E-mail");
        } else {
          this.confirmationPopup.showSuccessMessage("'" + filename + this.globalVar.languageString.resources.manageTab.uploaderUploadSuccessMessage);
        }

        this.avatar = this.globalVar.languageString.resources.manageTab.uploaderSelectFileMessage;
        this.selectedFile = undefined;

        if (this.fromScreen === this.globalVar.languageString.resources.manageTab.uploaderFindPathForIntermediary) {
          this.route.navigate(["curriculums/intermediary"]);
        } else if (this.fromScreen === this.globalVar.languageString.resources.manageTab.uploaderFindPathForProduct) {
          this.route.navigate(["/product/upload"]);
          // $timeout(function(){
          //   this.$parent.loadDisciplinedProgram();
          // },1000);
        } else if (this.fromScreen == 'updateCurriculum') {
          this.route.navigate(["/curriculum/" + this.curriculumID]);
        } else if (this.fromScreen == 'uploadSub' || this.fromScreen == 'updateSub') {
          this.route.navigate(["/substitution/" + this.bean.curriculumId]);
        } else {
          this.route.navigate(["/curriculum/" + this.curriculumID]);
        }
      } else {
        this.errorUploadResult = this.globalVar.languageString.resources.manageTab.uploaderErrorInConvertingFileMessage + filename + "'";
        this.disbaledAfterClick = false;
      }



    }, (error) => {
      let el = this.selectedElement;
      var ar = el.value.split('\\'), filename = ar[ar.length - 1];
      this.disbaledAfterClick = false;
      this.errorUploadResult = this.globalVar.languageString.resources.manageTab.uploaderErrorMessage + filename + "'";
      this.errorMessages = [];
      this.progress = 0;
      this.action = '';
    })
    //  $form.ajaxSubmit({
    //        type: 'POST',
    //        data: this.dataBean,

    //        // headers: {},
    //        uploadProgress: function (event, position, total, percentComplete) {

    //            this.$apply(function () {
    //                this.errorUploadResult = undefined;
    //                this.warningUploadResult = undefined;
    //                this.curriculumID = undefined;
    //                this.errorMessages = [];
    //                this.progress = percentComplete;
    //            });

    //        }
  }

}
