<div class="countryCss" *ngIf="country && !authority && !curriculumSet"> 
    <i class="treeIcon collapsedTree" id="{{country.facetUri}}" (click)="expandCollapse(country, $event)" ></i>
    <div class="contentContainer">
        <span class="product-browse-add-icon" (click)="addAuthorityFunc(true, country, undefined)"></span>
        {{ country.facetName }}
    </div>
</div>
<div class="countryCss" *ngIf="country && authority && !curriculumSet"> 
    <i class="treeIcon collapsedTree" id="{{authority.facetUri}}" (click)="expandCollapse(authority, $event)" ></i>
    <div class="contentContainer browse-program-color">
        <span class="product-browse-add-icon" (click)="addCurriculumSetFunc(true, country, authority, undefined)"></span>
        <span class="product-browse-edit-icon" (click)="addAuthorityFunc(false, country, authority)"></span>
        {{ authority.facetName }}
    </div>
</div>
<div class="countryCss" *ngIf="country && authority && curriculumSet"> 
    <!-- <i class="treeIcon collapsedTree" (click)="expandCollapse(curriculumSet, $event)" ></i> -->
    <div class="contentContainer browse-product-color">
        <span class="product-browse-edit-icon" (click)="addCurriculumSetFunc(false, country, authority, curriculumSet)"></span>
        {{ curriculumSet.facetName }}
    </div>
</div>

