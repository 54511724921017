import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { HttpRestClientService } from '../../services/common/http-rest-client.service';
import { GlobalVariablesService } from '../../services/global-variables/global-variables.service';
import * as _ from "underscore";
import { ConfirmationPopupService } from '../confirmation-popup/confirmation-popup.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationLoaderService {
  public languageString:any = {};
  public approvalStageMessage = new BehaviorSubject(this.languageString);
  public currentApprovalStageMessage = this.approvalStageMessage.asObservable();
  pprovalStageMessage = this.approvalStageMessage.asObservable();

  constructor(
    private httpRestClient: HttpRestClientService,
    private globalVar: GlobalVariablesService,
    private confirmationPopup: ConfirmationPopupService
    ) { }

  public initilizeApplicationCache = (initilizeApplicationCacheCallback: any) => {
    if (_.isEmpty(this.globalVar.languageString))
    {
      this.globalVar.languageString = { };
    }
    this.initializeLanguageStringsCache( () => {
      if (initilizeApplicationCacheCallback) {
        initilizeApplicationCacheCallback();
      }
    });
  }

  public initializeLanguageStringsCache (callback: any) {
    if (_.isEmpty(this.languageString)) {
        var url = 'api/resourcestrings?languageCode=en-US';
        this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((data) => {
          this.languageString = data;
          this.globalVar.languageString = data;
          this.approvalStageMessage.next(data);
          if (callback) {
            callback();
          }
        },(error) => {
          if (error.status === 500) {
            this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
              if (callback)
              {
                callback();
              }
          } else {
            this.confirmationPopup.showErrorMessage("Unable to load resource String data");
          }
          
        });
    } else {
        if (callback)
          {
            callback();
          }
    }
}
  
}
